import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import STORAGEKEY from "../../config/APP/app.config";
import { ApiPost } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import { getCookie, setCookie } from "../../helper/utils";
import { changeLoginState } from "../../redux/action/loginAction";
import { getUserData, getUserInfo } from "../../redux/action/userDataAction";
import AccountDeactive from "./AccountDeactive";
import ForgotPass from "./ForgotPass.js";
import "./Login.css";

const Login = ({ onHide, show, onHideNew, onShow, loginValue }) => {
  const loginCredential = {
    email: "",
    password: "",
  };

  const login_Err = {
    emailError: "",
    emailFormatErr: "",
    passError: "",
  };

  const { t } = useTranslation();
  const [statelogin, setStatelogin] = useState(loginCredential);
  const [loginErrors, setLoginErrors] = useState(login_Err);
  const [keepMeLogin, setKeepMeLogin] = useState(true);
  const [saveEmail, setSaveEmail] = useState(true);
  const [incorrectPass, setIncorrectPass] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [forgotModal, setForgotModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const [checked, setChecked] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const onHideLogin = () => {
    setForgotModal(true);
    // onHideNew(false);
    onHide(false);
  };

  const onHideSignup = () => {
    // setSignupModal(true);
    // onHideNew(false);
    onHide(false);
  };
  const loginValidation = () => {
    let flag = true;
    let login_Err = {
      emailError: "",
      emailFormatErr: "",
      passError: "",
    };

    const validEmail = new RegExp(
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
    );

    if (statelogin.email && !validEmail.test(statelogin.email)) {
      login_Err.emailFormatErr = `${t("logIn.Errors.InvalidEmail")}`;
      flag = false;
    }

    if (statelogin.email === "") {
      login_Err.emailError = `${t("logIn.Errors.Email")}`;
      flag = false;
    }

    if (statelogin.password === "") {
      login_Err.passError = `${t("logIn.Errors.Password")}`;
      flag = false;
    }

    setLoginErrors(login_Err);
    setIncorrectPass("");

    return flag;
  };

  useEffect(() => {
    setLocalEmail();

    const getEmail = getCookie("email");
    if (getEmail !== null && getEmail !== "") {
      setStatelogin({ ...statelogin, email: getEmail });
      // setSaveEmail(true);
    }
  }, []);

  //For Save Email Functionality in Login Part
  const setLocalEmail = () => {
    const email = AuthStorage.getStorageData(STORAGEKEY.email);
    if (email) {
      setStatelogin({
        ...statelogin,
        email: email,
      });
    }
  };

  // useEffect(()=>{

  // },[loginValue]);

  useEffect(() => {
    const getEmail = getCookie("email");
    if (getEmail !== null && getEmail !== "") {
      setStatelogin({ ...statelogin, email: getEmail });
    }
  }, [show]);

  const login = () => {
    if (!loginValidation()) {
      return;
    }

    if (saveEmail === true) {
      setCookie("email", statelogin.email, 1440);
    } else {
      setCookie("email", "", 1440);
    }

    ApiPost("user/auth/login", {
      email: statelogin.email,
      password: statelogin.password,
    })
      .then((res) => {
        setStatelogin(loginCredential);

        if (saveEmail) {
          AuthStorage.setStorageData(STORAGEKEY.email, statelogin.email, true);
        } else {
          AuthStorage.deleteKey(STORAGEKEY.email);
        }

        AuthStorage.setStorageData(STORAGEKEY.token, res.data.token, true);
        delete res.data.token;
        AuthStorage.setStorageJsonData(STORAGEKEY.userData, res.data, true);
        dispatch(changeLoginState(true));
        dispatch(getUserData());
        res.data.is_active === false && setDeactivatedNotice(true);
        history.push("/");
        onHideNew(false);
      })
      .catch((error) => {
        if (error === "Wrong Email") {
          setIncorrectPass("");
          setInvalidEmail(`${t("logIn.Errors.InvalidEmail")}`);
        }

        if (error === "Wrong Password") {
          setInvalidEmail("");
          setIncorrectPass(`${t("logIn.Errors.IncorrectPass")}`);
        }
      });

    // });
  };
  const closeSignup = () => {
    setSignupModal(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      login();
      event.preventDefault();
    }
  };

  const emailRegExp = (e) => {
    const confirmEmail = new RegExp(
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
    );
    if (!confirmEmail.test(e)) {
      setLoginErrors({
        ...loginErrors,
        emailError: "",
        emailFormatErr: `${t("logIn.Errors.EmailForm")}`,
      });
    } else {
      setLoginErrors({
        ...loginErrors,
        emailError: "",
        emailFormatErr: "",
      });
    }
  };

  return (
    <>
      <Modal
        show={show}
        centered
        className="login-modal"
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
      >
        <Modal.Header>
          <Modal.Title className="w-100 position-relative">
            <Button
              variant="transparentBtn"
              className="mobileClose"
              onClick={() => {
                onHideSignup();
              }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6.49951L8 13.9995L18 21.4995"
                  stroke="#1B1C1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
            <h5 className="custom-modal-title">{t("logIn.Log_In")}</h5>
            <Button
              variant="transparentBtn"
              className="pcClose position-absolute right-close-btn"
              onClick={() => {
                onHideSignup();
              }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="20.6973"
                  y1="7.75988"
                  x2="7.75901"
                  y2="20.6981"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <line
                  x1="20.5702"
                  y1="20.6992"
                  x2="7.63194"
                  y2="7.76096"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </Button>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="login-modal-body">
          <div className="login-modal-inner">
            <div className="single-input">
              <div className="position-relative input-group">
                <label className="input-title">{t("logIn.Email")}</label>
                <input
                  type="email"
                  placeholder={t("logIn.Placeholder.Email")}
                  name="email"
                  onChange={(e) => {
                    emailRegExp(e.target.value);
                    setStatelogin({ ...statelogin, email: e.target.value });
                    // setSaveEmail(saveEmail)
                  }}
                  onKeyDown={handleKeyDown}
                  value={statelogin.email}
                />
                {loginErrors.emailError && (
                  <p className="login-form-error">{loginErrors.emailError}</p>
                )}
                {loginErrors.emailFormatErr && (
                  <p className="login-form-error">
                    {loginErrors.emailFormatErr}
                  </p>
                )}
                {!loginErrors.emailError &&
                  !loginErrors.emailFormatErr &&
                  invalidEmail && (
                    <p className="login-form-error">{invalidEmail}</p>
                  )}
              </div>
              <div className="position-relative input-group mb-30">
                <label className="input-title">{t("logIn.Password")}</label>
                <input
                  type="password"
                  placeholder={t("logIn.Placeholder.Password")}
                  value={statelogin.password}
                  onChange={(e) => {
                    setLoginErrors({
                      ...loginErrors,
                      passError: "",
                    });
                    setIncorrectPass("");
                    setStatelogin({ ...statelogin, password: e.target.value });
                  }}
                  onKeyDown={handleKeyDown}
                />
                {loginErrors.passError && (
                  <p className="login-form-error">{loginErrors.passError}</p>
                )}
                {!loginErrors.passError && incorrectPass && (
                  <p className="login-form-error">{incorrectPass}</p>
                )}
                {/* {!loginErrors.passError && incorrectPass && (
                                <p className="log-error">{incorrectPass}</p>
                            )} */}
                {/* <p className='login-form-error'>Incorrect password</p> */}
              </div>
            </div>
            <div className="radio-row">
              <label className="container-checkbox" htmlFor="keepLogin">
                <input
                  id="keepLogin"
                  name="keepLogin"
                  type="checkbox"
                  checked={keepMeLogin}
                  onChange={(e) => {
                    setKeepMeLogin(!keepMeLogin);
                  }}
                />
                <span className="checkmark circle">
                  {keepMeLogin ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="9" cy="9" r="9" fill="#42B6E6" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.93326 8.76596C4.63788 9.06133 4.63788 9.54023 4.93326 9.8356L7.41815 12.3205C7.64658 12.5489 7.98475 12.6007 8.26293 12.4758C8.38351 12.4419 8.49732 12.3775 8.5922 12.2826L13.6883 7.18649C13.9837 6.89112 13.9837 6.41222 13.6883 6.11685C13.393 5.82148 12.9141 5.82148 12.6187 6.11685L7.98624 10.7493L6.00289 8.76596C5.70752 8.47059 5.22863 8.47059 4.93326 8.76596Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="9"
                        fill="white"
                        stroke="#DCDCDC"
                      />
                    </svg>
                  )}
                </span>
                {t("logIn.Keep_me_signin")}
              </label>
              <label className="container-checkbox" htmlFor="saveEmail">
                <input
                  id="saveEmail"
                  name="saveEmail"
                  type="checkbox"
                  checked={saveEmail}
                  onChange={(e) => {
                    setSaveEmail(!saveEmail);
                  }}
                />
                <span className="checkmark circle">
                  {saveEmail ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="9" cy="9" r="9" fill="#42B6E6" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.93326 8.76596C4.63788 9.06133 4.63788 9.54023 4.93326 9.8356L7.41815 12.3205C7.64658 12.5489 7.98475 12.6007 8.26293 12.4758C8.38351 12.4419 8.49732 12.3775 8.5922 12.2826L13.6883 7.18649C13.9837 6.89112 13.9837 6.41222 13.6883 6.11685C13.393 5.82148 12.9141 5.82148 12.6187 6.11685L7.98624 10.7493L6.00289 8.76596C5.70752 8.47059 5.22863 8.47059 4.93326 8.76596Z"
                        fill="white"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="10"
                        cy="10"
                        r="9"
                        fill="white"
                        stroke="#DCDCDC"
                      />
                    </svg>
                  )}
                </span>
                {t("logIn.Save_email_add")}
              </label>
            </div>
            <div className="btns-group">
              <Button
                variant="no-line"
                className="forgotBtn d-block w-100"
                onClick={() => {
                  onHideLogin();
                }}
              >
                {" "}
                {t("logIn.Forgot_Password")}
              </Button>
              {statelogin.email && statelogin.password ? (
                <Button
                  variant="confirmBtn"
                  className={
                    statelogin.email || statelogin.password
                      ? "loginBtn active d-block w-100"
                      : "loginBtn disable d-block w-100"
                  }
                  onClick={login}
                >
                  {" "}
                  {t("logIn.Log_In")}
                </Button>
              ) : (
                <Button
                  disabled={loginErrors && true}
                  variant="confirmBtn"
                  className={
                    statelogin.email || statelogin.password
                      ? "loginBtn active d-block w-100"
                      : "loginBtn disable d-block w-100"
                  }
                  onClick={login}
                >
                  {" "}
                  {t("logIn.Log_In")}
                </Button>
              )}
            </div>

            <div className="have-account-row">
              <p>
                {t("logIn.Dont_have_acc")}
                <Link
                  to={"/SignUp"}
                  className="font-blue"
                  onClick={() => {
                    onHideSignup();
                  }}
                >
                  {" "}
                  {t("signUp.Sign_Up")}{" "}
                </Link>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {forgotModal && (
        <ForgotPass
          showForgot={forgotModal}
          onHideForgot={() => setForgotModal(false)}
        />
      )}

      {deactivatedNotice && (
        <AccountDeactive
          hostDeactive={deactivatedNotice}
          setHostDeactive={() => setDeactivatedNotice(false)}
        />
      )}
    </>
  );
};

export default Login;

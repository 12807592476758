import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import AlreadyHostModal from "../modal/AlreadyHostModal";
import * as QueryString from "query-string";
import CKeditor from "../../components/CKeditor";
import { array } from "prop-types";
import "../hostmyown/HostMyOwn.css";
import { setGlobalSearch } from "redux/action/persistDataAction";
import DatePickerCustomHeaderOneMonth from "./../../components/DatePickerCustomHeaderOneMonth";
import { useRef } from "react";
import useRootClose from "react-overlays/esm/useRootClose";
import CustomModal from "./../../components/CustomModal";
import AddEventsAndPlaces from "./../hostmyown/AddEventsAndPlaces";

function HostProfile({
  setShowHostProfile,
  setRefreshHost,
  created_by,
  maxDate,
  minDate,
}) {
  const { userData } = useSelector((state) => state.userData);
  const { t } = useTranslation();
  const history = useHistory();

  const [hostingNotice, setHostingNotice] = useState(false);
  const [hostingDate, setHostingDate] = useState();
  const [hostTour, sethostTour] = useState(false);
  const [hostNotice, sethostNotice] = useState(false);
  const [hasDelete, sethasDelete] = useState(false);

  const [likeDelete, setlikeDelete] = useState(false);

  const [newItinerary, setNewItinerary] = useState({});

  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //Checking Region
  const [regionErr, setRegionErr] = useState(false);
  const checkRegion = () => {
    setRegionErr(false);
    for (let i = 0; i < itineraryList.length - 1; i++) {
      if (itineraryList[i].region !== itineraryList[i + 1].region) {
        setRegionErr(true);
        break;
      }
    }
  };
  //Itinerary List
  const [itineraryList, setItineraryList] = useState([]);
  const resetFormData = {
    title: "",
    aboutTour: "",
    startsAt: "",
    hostType: "Local",
    other: "",
    pax: "",
    introduction: "",
    transportation: "",
    image: undefined,
    image_url: "",
    imageMain: undefined,
    imageMain_url: "",
    category: "",
    category_ko: "",
  };

  const [isOtherTrasport, setIsOtherTrasport] = useState(false);
  const persistData = useSelector((state) => state.persistData);
  const [formData, setFormData] = useState(resetFormData);
  const [isDisabled, setIsDisabled] = useState(true);
  const params = QueryString.parse(history.location.search);

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("23:30");

  useEffect(() => {
    if (formData.transportation === "Other") {
      setIsOtherTrasport(true);
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          other: "",
        };
      });
      setIsOtherTrasport(false);
    }
  }, [formData.transportation]);
  const [showTours, setShowTours] = useState(false);
  const [wishlist, setWishList] = useState(false);
  //Time Dropdown
  const [days, setDays] = useState([]);
  const [endT, setEndT] = useState([]);
  const getDays = () => {
    let hours = [];
    let minutes = 0;
    let m = "0";
    let h = "0";

    for (let i = 0; i <= 24; i++) {
      for (let j = 0; j <= 1; j++) {
        if (i !== 24 && j < 30) {
          if (minutes === 60) {
            minutes = 0;
          }

          if (i < 10) {
            h = "0" + i.toString();
          } else {
            h = i.toString();
          }

          if (minutes < 10) {
            m = "0" + minutes.toString();
          } else {
            m = minutes.toString();
          }

          hours.push({
            value: h.toString() + ":" + m.toString(),
            label: h.toString() + ":" + m.toString(),
          });
          minutes += 30;
        }
      }
    }
    setDays(hours);
  };

  const getEndTime = () => {
    let end = [];
    let minutes = 0;
    let m = "0";
    let h = "0";
    let start_hour = startTime.split(":");

    for (let i = parseInt(start_hour[0]); i <= 23; i++) {
      for (let j = 0; j < 2; j++) {
        if (i < 10) {
          h = "0" + i.toString();
        } else {
          h = i.toString();
        }

        if (j === 0) {
          minutes = 0;
        } else {
          minutes = 30;
        }

        if (minutes < 10) {
          m = "0" + minutes.toString();
        } else {
          m = minutes.toString();
        }

        end.push({
          value: h + ":" + m.toString(),
          label: h.toString() + ":" + m.toString(),
        });
        if (start_hour[0] + ":" + start_hour[1] === h + ":" + m) {
          end.pop();
        }
        minutes += 30;
      }
    }

    if (start_hour[1] === "30") {
      end.shift();
    }
    end.push({ value: "23:59", label: "23:59" });
    setEndT(end);
  };

  useEffect(() => {
    setEndT([]);
    setEndTime("");

    getEndTime();
  }, [startTime]);

  useEffect(() => {
    isAlreadyHosting();
    getDays();
    setIsDisabled(!validation());
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sethostNoticeBtn = () => {
    sethostTour(false);
    sethostNotice(true);
  };

  //Creating Hosting
  const createHosting = (id) => {
    window.scrollTo(0, 0);
    ApiPost("hosting/create", {
      type: formData.hostType,
      date: moment(hostingDate).format("YYYY-MM-DD"),
      start_time: startTime,
      end_time: endTime,
      location: formData.startsAt,
      transportation: isOtherTrasport
        ? formData.other
        : formData.transportation,
      pax: formData.pax,
      host_information: formData.introduction,
      itinerary_id: id,
      confirm_status: "request_review",
    }).then((res) => {
      // sethostNotice(true);
      sethostTour(false);
      setRefreshHost(Math.random());
      history.push(`/profile`);
    });
  };

  const removeHostProfile = () => {
    // props.topFocus.current.scrollIntoView({ behavior: "smooth" });
    setShowHostProfile(false);
    window.scrollTo(0, 0);
  };

  // Trasportation

  const trasportOption = [
    { name: t("Host_Own.Options.Car") },
    { name: t("Host_Own.Options.Taxi") },
    { name: t("Host_Own.Options.Public_Transportation") },
  ];

  const sethasDeleteBtn = () => {
    sethasDelete(true);
    setlikeDelete(false);
  };
  //Delete Itinerary
  const [deleteID, setDeleteID] = useState("");
  const deleteItinerary = (id) => {
    setItineraryList((prev) => prev.filter((x) => x.id !== id));
  };

  const [tourDetails, setTourDetails] = useState(false);
  const [tourCourse, setTourCourse] = useState();

  const [canHost, setCanHost] = useState(false);
  const isAlreadyHosting = () => {
    ApiGet("hosting/isAlreadyHosted").then((res) => {
      setCanHost(res?.data?.status);
    });
  };

  const validation = () => {
    let Err = {
      startsAtErr: false,
      hostingDateErr: false,
      otherErr: false,
      paxErr: false,
      introductionErr: false,
      startTimeErr: false,
      endTimeErr: false,
      trasportationErr: false,
    };

    if (!formData.aboutTour) {
      Err.aboutTourErr = true;
    }
    if (!hostingDate) {
      Err.hostingDateErr = true;
    }
    if (!formData.introduction) {
      Err.introductionErr = true;
    }
    if (isOtherTrasport && !formData.other) {
      Err.otherErr = true;
    }
    if (!formData.pax || formData.pax === "0") {
      Err.paxErr = true;
    }
    if (!formData.startsAt) {
      Err.startsAtErr = true;
    }
    if (!formData.transportation) {
      Err.trasportationErr = true;
    }
    if (!startTime) {
      Err.startTimeErr = true;
    }
    if (!endTime) {
      Err.endTimeErr = true;
    }

    if (
      !Err.hostingDateErr &&
      !Err.introductionErr &&
      !Err.otherErr &&
      !Err.paxErr &&
      !Err.startsAtErr &&
      !Err.trasportationErr &&
      !Err.startTimeErr &&
      !Err.endTimeErr
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsDisabled(!validation());
  }, [formData, startTime, endTime, isOtherTrasport, itineraryList]);

  const remove = (type) => {
    if (type === "about") {
      if (
        formData.image_url !== "" &&
        (formData.image == null || formData.image === undefined)
      ) {
      } else {
        setFormData({ ...formData, image_url: "", image: null });
      }
    } else {
      if (
        formData.imageMain_url !== "" &&
        (formData.imageMain == null || formData.imageMain === undefined)
      ) {
      } else {
        setFormData({ ...formData, imageMain_url: "", imageMain: null });
      }
    }
  };

  // ckeditor
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [chooseEnd, setChooseEnd] = useState(false);
  const [startDate, setStartDate] = useState("");
  const OpenCalendar = (e) => {
    setCalendarOpen(true);

    const name = e.target.name;

    if (name === "startDate") {
      setChooseEnd(false);
    } else {
      setChooseEnd(true);
    }
  };

  let dispatch = useDispatch();
  const onChangeDate = (dates) => {
    setStartDate(dates);
    setHostingDate(dates);
    dispatch(
      setGlobalSearch({
        field: "startHeaderDate",
        value: dates ? moment(dates).format("Y/MM/DD") : null,
      })
    );

    if (dates) {
      setCalendarOpen(false);
    }
  };

  const target = useRef();
  const handleRootClose = () => setCalendarOpen(false);
  useRootClose(target, handleRootClose, {
    disabled: !calendarOpen,
  });

  return (
    <>
      <section id="HostMyOwn" className="admin-create">
        <div className="wrap">
          {itineraryList && itineraryList?.length > 0 && (
            <div className="selected-img d-flex flex-wrap">
              {itineraryList?.map((data, i) =>
                data?.image?.map((x) => (
                  <>
                    <div className="imgArea">
                      <img
                        className="objectfit-cover"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "../../../img/download.svg";
                        }}
                        src={x}
                        alt=""
                      />
                    </div>
                  </>
                ))
              )}
            </div>
          )}

          <div className="create-my-itinerary">
            <div className="user-info">
              <h5 className="tourTitle d-flex align-items-center">
                {t("Host_Own.Profile")}
              </h5>
              <div className="user-basic-info d-flex align-items-center">
                <div className="circle user-img">
                  <img src={userData?.avatar || "./img/Avatar.png"} alt="" />
                </div>
                <div className="full-my-info d-flex flex-column">
                  <div className="info-title d-flex align-items-center">
                    <h6>{t("Host_Own.Gender")}</h6>
                    <p>
                      {userData.gender === "MALE" ? t("Male") : t("Female")}
                    </p>
                  </div>
                  <div className="info-title  d-flex align-items-center">
                    <h6>{t("Host_Own.Age")}</h6>
                    <p>
                      {" "}
                      {userData?.age_group}
                      {AuthStorage.getLang() === "en" ? "'s" : "대"}
                    </p>
                  </div>
                  <div className="info-title d-flex align-items-center">
                    <h6> {t("Host_Own.Nationality")}</h6>
                    <p>{userData?.nationality}</p>
                  </div>
                </div>
              </div>
              <div className="HostType">
                <h5 className="tourTitle d-flex align-items-center">
                  {t("Host_Own.Host_Type")}
                  <img
                    src="../../../img/required.svg"
                    alt=""
                    className="required-hashtag"
                  />
                </h5>
                <div className="label-groups d-flex">
                  <div className="lable-item">
                    <input
                      type="radio"
                      name="hostType"
                      id="Local"
                      value="Local"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label
                      htmlFor="Local"
                      className="d-flex align-items-center justify-content-center"
                    >
                      {"Local Host" && (
                        <div className="radio-check p-0 m-0 text-center  h-56">
                          {t("Host_Own.Local_Host")}
                        </div>
                      )}
                    </label>
                  </div>
                  <div className="label-item">
                    <input
                      type="radio"
                      name="hostType"
                      id="Travel"
                      value="Travel"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label
                      htmlFor="Travel"
                      className="d-flex align-items-center justify-content-center"
                    >
                      {"Local Host" && (
                        <div className="radio-check p-0 m-0 text-center">
                          {t("Host_Own.Travel_Host")}
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="tour-info">
              <h5 className="tourTitle d-flex align-items-center">
                {t("Host_Own.Hosting_Date")}
                <img
                  src="../../../img/required.svg"
                  alt=""
                  className="required-hashtag"
                />
              </h5>
              <div className="d-flex justify-content-between  flex-wrap align-items-center position-relative">
                {/* calendar */}
                <div className="search-calendar d-flex align-items-center">
                  <div
                    className="d-flex input-calendar align-items-center"
                    onClick={() => {
                      setCalendarOpen(true);
                    }}
                  >
                    <svg
                      onClick={(e) => {
                        OpenCalendar(e);
                      }}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        className="noFill"
                        x="2.75"
                        y="4.75"
                        width="14.5"
                        height="12.5"
                        rx="2.25"
                        fill="white"
                        stroke="#42B6E6"
                        stroke-width="1.5"
                      />
                      <path
                        d="M5.69922 2.75C5.69922 2.33579 6.03501 2 6.44922 2C6.86343 2 7.19922 2.33579 7.19922 2.75V4.5H5.69922V2.75Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M5.5 11.25C5.5 10.8358 5.83579 10.5 6.25 10.5H6.75C7.16421 10.5 7.5 10.8358 7.5 11.25C7.5 11.6642 7.16421 12 6.75 12H6.25C5.83579 12 5.5 11.6642 5.5 11.25Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M9 11.25C9 10.8358 9.33579 10.5 9.75 10.5H10.25C10.6642 10.5 11 10.8358 11 11.25C11 11.6642 10.6642 12 10.25 12H9.75C9.33579 12 9 11.6642 9 11.25Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M12.5 11.25C12.5 10.8358 12.8358 10.5 13.25 10.5H13.75C14.1642 10.5 14.5 10.8358 14.5 11.25C14.5 11.6642 14.1642 12 13.75 12H13.25C12.8358 12 12.5 11.6642 12.5 11.25Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M5.5 14C5.5 13.5858 5.83579 13.25 6.25 13.25H6.75C7.16421 13.25 7.5 13.5858 7.5 14C7.5 14.4142 7.16421 14.75 6.75 14.75H6.25C5.83579 14.75 5.5 14.4142 5.5 14Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M9 14C9 13.5858 9.33579 13.25 9.75 13.25H10.25C10.6642 13.25 11 13.5858 11 14C11 14.4142 10.6642 14.75 10.25 14.75H9.75C9.33579 14.75 9 14.4142 9 14Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M12.5 14C12.5 13.5858 12.8358 13.25 13.25 13.25H13.75C14.1642 13.25 14.5 13.5858 14.5 14C14.5 14.4142 14.1642 14.75 13.75 14.75H13.25C12.8358 14.75 12.5 14.4142 12.5 14Z"
                        fill="#42B6E6"
                      />
                      <path
                        d="M12.5996 2.75C12.5996 2.33579 12.9354 2 13.3496 2C13.7638 2 14.0996 2.33579 14.0996 2.75V4.5H12.5996V2.75Z"
                        fill="#42B6E6"
                      />
                      <rect
                        x="18"
                        y="7.5"
                        width="1.5"
                        height="16"
                        rx="0.75"
                        transform="rotate(90 18 7.5)"
                        fill="#42B6E6"
                      />
                    </svg>
                    <input
                      className="schedule"
                      type="text"
                      name="Date"
                      readOnly
                      autoComplete="off"
                      value={
                        startDate && moment(startDate).format("YYYY.MM.DD")
                      }
                      placeholder={t("Host_Own.Hosting_Date2")}
                      onClick={(e) => {
                        OpenCalendar(e);
                      }}
                    />
                  </div>
                  <Select
                    classNamePrefix="dateArea-select"
                    options={days}
                    name="startDate"
                    isSearchable={false}
                    onChange={(e) => {
                      setStartTime(e.value);
                    }}
                    isOptionDisabled={(option) =>
                      option.isDisabled ? true : false
                    }
                    placeholder={t("Host_Own.Placeholder.Start_Time")}
                    className="select"
                  />
                  <span className="divder">-</span>
                  <Select
                    classNamePrefix="dateArea-select"
                    options={endT}
                    name="endDate"
                    isSearchable={false}
                    onChange={(e) => {
                      setEndTime(e.value);
                    }}
                    placeholder={t("Host_Own.Placeholder.End_Time")}
                    className="select"
                  />
                  <input
                    type="text"
                    name="startsAt"
                    value={formData.startsAt}
                    maxLength={AuthStorage.getLang() === "en" ? 18 : 15}
                    placeholder={t("Host_Own.Placeholder.Start_At")}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>

                {calendarOpen && (
                  <>
                    <div className={"createContainer calendar"} ref={target}>
                      {}
                      <DatePicker
                        calendarClassName={"custom-container"}
                        monthClassName="custom-month"
                        selected={startDate}
                        minDate={
                          minDate !== null
                            ? new Date(minDate) < new Date()
                              ? new Date()
                              : new Date(minDate)
                            : new Date()
                        }
                        maxDate={maxDate !== null ? new Date(maxDate) : null}
                        onChange={(e) => {
                          let today = moment(new Date()).format("YYYY.MM.DD");
                          let selectDate = moment(e).format("YYYY.MM.DD");
                          let timeNow = new Date().getHours();
                          let minutesNow = new Date().getMinutes();
                          let minTime = "";
                          let arr = [];
                          let arr2 = [];
                          if (today === selectDate) {
                            days.map((x) => arr.push(x.value));
                            if (minutesNow < 30) {
                              if (timeNow < 9) {
                                minTime = "0" + timeNow + ":30";
                              } else if (timeNow === 9) {
                                minTime = timeNow + 1 + ":30";
                              } else {
                                minTime = timeNow + ":30";
                              }
                            } else {
                              if (timeNow < 9) {
                                minTime = "0" + (timeNow + 1) + ":00";
                              } else if (timeNow >= 9) {
                                minTime = timeNow + 1 + ":00";
                              } else {
                                minTime = timeNow + ":00";
                              }
                            }
                            let index = arr.indexOf(minTime);
                            const setObj = [];
                            Object.keys(days).map(
                              (item, i) =>
                                (setObj[i] = {
                                  value: days[i].value,
                                  label: days[i].label,
                                  isDisabled: i < index ? true : false,
                                })
                            );
                            setDays(setObj);

                            console.log(days);
                          } else {
                            const setObj = [];
                            Object.keys(days).map(
                              (item, i) =>
                                (setObj[i] = {
                                  value: days[i].value,
                                  label: days[i].label,
                                  isDisabled: false,
                                })
                            );
                            setDays(setObj);
                          }
                          onChangeDate(e);
                        }}
                        startDate={startDate}
                        showPopperArrow={false}
                        inline
                        renderCustomHeader={(p) => (
                          <DatePickerCustomHeaderOneMonth {...p} />
                        )}
                        locale={AuthStorage.getLang() === "en" ? "en-US" : "ko"}
                        formatWeekDay={
                          AuthStorage.getLang() === "en"
                            ? (nameOfDay) => nameOfDay.substr(0, 3)
                            : (nameOfDay) => nameOfDay.substr(0, 1)
                        }
                      />
                    </div>
                  </>
                )}
                <p className="single-day-hosting">
                  {t("Host_Own.Single_Day_Hosting")}
                </p>
              </div>
              <div className="tour-transportation">
                <h5 className="tourTitle d-flex align-items-center">
                  {" "}
                  {t("Host_Own.Transportation")}
                  <img
                    src="../../../img/required.svg"
                    alt=""
                    className="required-hashtag"
                  />
                </h5>

                <div className="label-groups d-flex">
                  <>
                    {trasportOption.map((t, i) => (
                      <div className="label-item">
                        <input
                          type="radio"
                          name="transportation"
                          id={`transportation${i}`}
                          value={t.name}
                          onClick={(e) => {
                            handleChange(e);
                          }}
                        />
                        <label
                          htmlFor={`transportation${i}`}
                          className="d-flex align-items-center justify-content-center"
                          dangerouslySetInnerHTML={{ __html: t.name }}
                        />
                      </div>
                    ))}
                    <div className="label-item ">
                      <input
                        type="radio"
                        name="transportation"
                        id="transportation_other"
                        value="Other"
                        maxLength={AuthStorage.getLang() === "en" ? 18 : 15}
                        onClick={(e) => {
                          handleChange(e);
                        }}
                      />
                      <label
                        htmlFor="transportation_other"
                        className="d-flex align-items-center justify-content-center"
                      >
                        {"Other" && (
                          <div className="radio-check">
                            {t("Host_Own.Options.Other")}
                          </div>
                        )}
                      </label>
                    </div>
                  </>
                  {formData.transportation === "Other" && (
                    <input
                      className="trasporttaion"
                      type="text"
                      name="other"
                      value={formData.other}
                      maxLength={AuthStorage.getLang() === "en" ? 18 : 15}
                      placeholder={t("Host_Own.Placeholder.Other")}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="mainPax position-relative res-main-box">
                <h5 className="tourTitle d-flex align-items-center">
                  {" "}
                  {t("Host_Own.Pax_Number")}
                  <img
                    src="../../../img/required.svg"
                    alt=""
                    className="required-hashtag"
                  />
                </h5>

                <div className="paxnumber position-relative">
                  <input
                    type="text"
                    name="pax"
                    value={formData.pax.toString()}
                    placeholder={t("Host_Own.Placeholder.Pax_Number")}
                    onChange={(e) => {
                      const value = e.target.value;
                      const re = /^[0-9\b]+$/;

                      if (!value || value === "" || re.test(value)) {
                        handleChange(e);
                      }
                    }}
                    maxLength={1}
                  />
                  <span className="position-absolute paxnumText">
                    {" "}
                    {t("Host_Own.Pax")}{" "}
                  </span>
                </div>
              </div>

              <div className="introducemain position-relative res-introducemain-box">
                <h5 className="tourTitle d-flex align-items-center">
                  {t("Host_Own.Introduce_Yourself")}
                  <img
                    src="../../../img/required.svg"
                    alt=""
                    className="required-hashtag"
                  />
                </h5>

                <div className="tour-introduction position-relative">
                  <TextareaAutosize
                    name="introduction"
                    value={formData.introduction}
                    placeholder={t("Host_Own.Placeholder.Introduce_Yourself")}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    minRows={6}
                    maxLength={500}
                  />
                  <p className="max-length position-absolute">
                    <span
                      className={
                        formData.introduction.length > 400
                          ? "font-red"
                          : "font-blue"
                      }
                    >
                      {formData.introduction.length}
                    </span>
                    /500
                  </p>
                </div>
              </div>

              {/* <div className="editorinfomation">
                <h5 className="tourTitle d-flex align-items-center">
                  {t("Host_Own.Editor_Information")}
                </h5>
                <p>
                  {created_by === "Compastrips" ? (
                    <span> {created_by}</span>
                  ) : (
                    <span> {created_by}</span>
                  )}
                </p>
              </div> */}
            </div>
            <div className="btn-groups d-flex justify-content-center">
              <Button
                variant="cancelBtn"
                onClick={() => {
                  setFormData(resetFormData);
                  history.push("/");
                }}
              >
                {" "}
                {t("Host_Own.Cancel")}
              </Button>
              <Button
                variant="confirmBtn"
                disabled={isDisabled}
                className="host"
                onClick={() => {
                  sethostTour(true);
                }}
              >
                {" "}
                {t("Host_Own.Host")}
              </Button>
            </div>
          </div>
        </div>
      </section>

      <AddEventsAndPlaces
        set={setShowTours}
        value={showTours}
        wishlist={wishlist}
        setCourseList={setItineraryList}
        selectedCourse={itineraryList}
      />

      <Modal
        show={tourDetails}
        onHide={() => {
          setTourDetails(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text tour-details-modal custom-modal"
            : "noto-text tour-details-modal custom-modal"
        }
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title
            id="tour-course-title"
            className="d-flex justify-content-between w-100"
          >
            {wishlist ? (
              <h6 className="font-30-bold color-dark h-40">
                {t("Tour_Course_Details.Header")}
              </h6>
            ) : (
              <h6 className="font-30-bold color-dark h-40">
                {t("Tour_Course_Details.Header")}
              </h6>
            )}
            <Button
              variant="transparentBtn"
              onClick={() => setTourDetails(false)}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="20.6973"
                  y1="7.75988"
                  x2="7.75901"
                  y2="20.6981"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <line
                  x1="20.5702"
                  y1="20.6992"
                  x2="7.63194"
                  y2="7.76096"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </Button>

            {/* Responsive close Button End */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="itinery_details_modal p-0">
          <div className="tour-details-body">
            <table className="tour-details-table">
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.City")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.region}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Category")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.category}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Name")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.name}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Photos")}
                  </h6>
                </th>
                <td>
                  <div className="upload-pic d-flex flex-wrap">
                    {tourCourse?.image.map((data, i) => (
                      <div className="imgArea">
                        <img
                          className="objectfit-cover"
                          key={i}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../../../img/download.svg";
                          }}
                          src={data}
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Date")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.closing_date && tourCourse?.opening_date ? (
                      <>
                        {moment(tourCourse?.opening_date).format("YYYY-MM-DD")}{" "}
                        -{" "}
                        {moment(tourCourse?.closing_date).format("YYYY-MM-DD")}
                      </>
                    ) : (
                      "-"
                    )}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black about-th">
                    {t("Tour_Course_Details.About")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray about-td">
                    {tourCourse?.summary}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Address")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.address}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.URL")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.website}
                  </h6>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Phone_Number")}
                  </h6>
                </th>
                <td>
                  <h6 className="font-16-normal color-darkgray h-28">
                    {tourCourse?.telephone}
                  </h6>
                </td>
              </tr>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={hostTour}
        onHide={() => {
          sethostTour(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Host_Own.Host_Popup.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            {AuthStorage.getLang() === "en" ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: t("Host_Own.Host_Popup.Contents"),
                }}
              />
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    t("Host_Own.Host_Popup.Contents1") +
                    userData.user_name +
                    t("Host_Own.Host_Popup.Contents2"),
                }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            onClick={() => {
              sethostTour(false);
            }}
          >
            {t("Host_Own.Host_Popup.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            onClick={() => {
              createHosting(params.id);
            }}
          >
            {t("Host_Own.Host_Popup.Host_Btn")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={hostNotice}
        onHide={() => {
          sethostNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Notice_Popup.Notice")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p dangerouslySetInnerHTML={{ __html: t("Notice_Popup.Body") }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            onClick={() => {
              newItinerary && history.push(`itinerary?id=${newItinerary?.id}`);
              sethostNotice(false);
              removeHostProfile();
            }}
          >
            {t("Notice_Popup.OK")}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={likeDelete}
        onHide={() => {
          setlikeDelete(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Host_Own.Delete_Course.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal-signup-title "></div>
          <div className="HostDeleteContent">
            <div className="DeleteContent">
              <p className="font-24-normal h-36 color-black mt-36 text-center">
                {t("Host_Own.Delete_Course.Body")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-between mt-40 HostDeleteBtnBox">
          <div className="">
            <button
              className="HostMyOwnCancle"
              onClick={() => {
                setlikeDelete(false);
              }}
            >
              {t("Host_Own.Delete_Course.Cancel")}
            </button>
          </div>

          <div className="">
            <button
              className="HostMyOwnDelete"
              onClick={() => {
                sethasDeleteBtn();
                deleteItinerary(deleteID);
              }}
            >
              {t("Host_Own.Delete_Course.Delete")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={hasDelete}
        onHide={() => {
          sethasDelete(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal del-modal"
            : "noto-text welcome-modal del-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div className="modal-signup-title ">
            <h3 className="h-36">{t("Host_Own.Delete_Course_Popup.Title")}</h3>
          </div>
          <div className="ConfDelete">
            <div className="ConfDeleteContent">
              <p className="font-24-normal h-36 color-black mt-36 text-center">
                {t("Host_Own.Delete_Course_Popup.Body")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className="w-100 mt-40">
          <div className="ConfirmDeleteBtn">
            <button
              className="ConfOk"
              onClick={() => {
                sethasDelete(false);
              }}
            >
              {t("Host_Own.Delete_Course_Popup.OK")}
            </button>
          </div>
        </div>
      </Modal>

      <AlreadyHostModal
        hostingNotice={hostingNotice}
        setHostingNotice={() => setHostingNotice(false)}
      />
    </>
  );
}

export default HostProfile;

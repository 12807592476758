import { PUB_TRENDINGNOW_SEARCH, PUB_TRENDINGNOW_UPDATE_LIST } from "../type";

const initialState = {
  keyword: "",
  country_code: "all",
};

export const trendingNowReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUB_TRENDINGNOW_SEARCH:
      return {
        ...state,
        keyword: action.payload,
      };
    case PUB_TRENDINGNOW_UPDATE_LIST:
      return {
        ...state,
        country_code: action.payload,
      };
    default:
      return state;
  }
};

import React, { useState, useEffect, useRef } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useLocation, useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import Reviews from "./Reviews";
// import UserHostList from "./UserHostList";
// import ApplicationPax from "./ApplicationPax";
import { useDispatch, useSelector } from "react-redux";
import Event from "./Event";
import AuthStorage from "../../helper/AuthStorage";
import { checkImageURL, transporTation } from "../../helper/utils";
import {
  setChatId,
  setMessageState,
  setOtherUserData,
} from "../../redux/action/chatDataAction";
import { db } from "../../firebaseConfig";
import firebase from "firebase";
import { useTranslation } from "react-i18next";

const DeleteItinerary = () => {
  const { is_loggedin } = useSelector((state) => state.login);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [data, setData] = useState([]);
  const [review, setReview] = useState([]);
  const [refreshHost, setRefreshHost] = useState(0);
  const [hostingUser, setHostingUser] = useState([]);

  const [isCompas, setIsCompas] = useState("");
  const [refreshPax, setRefreshPax] = useState(0);
  const [refreshReview, setRefreshReview] = useState(0);
  const [host, setHost] = useState();

  const { userData } = useSelector((state) => state.userData);

  const history = useHistory();
  const params = QueryString.parse(history.location.search);

  const focus = useRef(null);
  const topFocus = useRef(null);

  const pathname = useLocation();

  // Create a chat
  const dispatch = useDispatch();
  const { chatData, message_open } = useSelector((state) => state.chatData);
  const createChat = async (current_user, other_user) => {
    let doc_id;
    if (
      !current_user.id &&
      !other_user.id &&
      current_user.id === other_user.id
    ) {
      return;
    }
    if (current_user.id > other_user.id) {
      doc_id = current_user.id + other_user.id;
    } else {
      doc_id = other_user.id + current_user.id;
    }

    if (chatData.find((x) => x.id === doc_id)) {
      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    } else {
      const ref = db.collection(AuthStorage.getMessageCollection()).doc(doc_id);
      await ref.set(
        {
          [`${current_user.id}_count`]: 0,
          [`${other_user.id}_count`]: 0,
          lastMessage: "",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ids: [current_user.id, other_user.id],
          [`${current_user.id}`]: {
            id: current_user.id,
            name: current_user.name,
            profile_url: current_user.profile_url,
          },
          [`${other_user.id}`]: {
            id: other_user.id,
            name: other_user.name,
            profile_url: other_user.profile_url,
          },
        },
        { merge: true }
      );

      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getHostingUser();

    if (history.action === "POP") {
      setIsCompas("");
      return;
    }
  }, [pathname, refreshPax]);

  const getItinerary = () => {
    ApiGet(
      `itinerary/itinerary-course-deleted/${params.id}?creator=${params.creator}`
    )
      .then((data) => {
        data.data.images = data.data?.images;
        setData(data.data);
      })
      .catch(() => setData([]));
    ApiGet(
      `itinerary/review-by-itinerary-deleted/${params.id}?creator=${params.creator}`
    )
      .then((data) => setReview(data.data))
      .catch(() => setReview([]));
  };
  useEffect(() => {
    ApiGet(
      `itinerary/review-by-itinerary-deleted/${params.id}?creator=${params.creator}`
    )
      .then((data) => setReview(data.data))
      .catch(() => setReview([]));
  }, [refreshReview]);

  const getHostingUser = () => {
    ApiGet(
      `hosting/host-itinerary-deleted/${params.id}?creator=${params.creator}&hostingId=${params.hostingId}`
    ).then((res) => {
      setHostingUser(res.data);
      // setHost(res?.data.filter((x: any) => console.log("xxx",x)))
      setHost(res?.data[0]);
    });
  };

  useEffect(() => {
    getHostingUser();
  }, [refreshHost, userData]);

  useEffect(() => {
    getItinerary();
    getHostingUser();
  }, []);

  const [delayLike, setDelayLike] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const Like = (id) => {
    setDelayLike(true);
    setIsLiked(!isLiked);
    ApiPost(`user/wishlist/${id}`, {
      hosting_id: params.hostingId,
    })
      .then((res) => {
        setDelayLike(false);
      })
      .catch((err) => {
        // console.log("Fail", err);
      });
  };
  return (
    // <div className="mt-211 tour-single-page">
    <div className="ninetwenty-container">
      <div className="mini-container">
        <div className="event-info-page">
          <div className="images-main d-flex">
            <div className="main-images position-relative">
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../../img/event-main-image.svg";
                }}
                src={
                  data?.images && data?.images[0]
                    ? data?.images[0]
                    : "../../../img/event-main-image.svg"
                }
                alt=""
              />
              {data?.images?.length > 0 && (
                <button
                  className="itinerary-viewAll-btn"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  View All {data.images && data.images.length} Photos
                </button>
              )}
            </div>
            <div className="small-main-images">
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../../img/dow-1.svg";
                }}
                src={
                  data?.images && data?.images[1]
                    ? data?.images[1]
                    : "../../../img/dow-1.svg"
                }
                alt=""
              />
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../../img/dow-1.svg";
                }}
                src={
                  data?.images && data?.images[2]
                    ? data?.images[2]
                    : "../../../img/dow-1.svg"
                }
                alt=""
              />
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../../img/dow-1.svg";
                }}
                src={
                  data?.images && data?.images[3]
                    ? data?.images[3]
                    : "../../../img/dow-1.svg"
                }
                alt=""
              />
              <img
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../../img/dow-1.svg";
                }}
                src={
                  data?.images && data?.images[4]
                    ? data?.images[4]
                    : "../../../img/dow-1.svg"
                }
                alt=""
              />
            </div>

            {isOpen && (
              <Lightbox
                mainSrc={data.images[photoIndex]}
                nextSrc={data.images[(photoIndex + 1) % data.images.length]}
                prevSrc={
                  data.images[
                    (photoIndex + data.images.length - 1) % data.images.length
                  ]
                }
                imageTitle={photoIndex + 1 + "/" + data.images.length}
                onCloseRequest={() => {
                  setIsOpen(false);
                }}
                onMovePrevRequest={() => {
                  setPhotoIndex(
                    (photoIndex + data.images.length - 1) % data.images.length
                  );
                }}
                onMoveNextRequest={() => {
                  setPhotoIndex((photoIndex + 1) % data.images.length);
                }}
              />
            )}
          </div>

          <div className="main-container">
            <div className="d-flex">
              <div className="w-740">
                <Event
                  reviews={review ? review : null}
                  deleted={"deleted"}
                  creator={params.creator}
                />
                {(!(
                  hostingUser[0]?.user.id === userData?.id &&
                  hostingUser[0]?.status !== "COMPLETED"
                ) ||
                  (hostingUser.map((x) => x.user.id).includes(userData?.id) &&
                    hostingUser.filter((x) => x.user.id === userData?.id)[0]
                      ?.status === "COMPLETED")) &&
                  review?.recentReview?.length !== 0 && (
                    <Reviews reviews={review ? review : null} />
                  )}
              </div>

              <div ref={topFocus} className="ml-auto">
                <>
                  {host && (
                    <div className="hosting-inner-list">
                      <div className="Usersidebar">
                        <div className="">
                          <div className=" single-local-hostsecinner postion-relative">
                            <div className="likechetBox">
                              <div className="join-pro">
                                {host?.user?.id !== userData.id && (
                                  <div
                                    className="join-msg"
                                    style={{ marginRight: "26px" }}
                                  >
                                    <img
                                      src={`${
                                        userData?.notification &&
                                        host?.user.notification_on
                                          ? "./img/msg.svg"
                                          : "./img/msg-grey.png"
                                      }`}
                                      alt=""
                                      className="chatbox"
                                      onClick={() => {
                                        userData?.notification &&
                                          host?.user.notification_on &&
                                          createChat(
                                            {
                                              id: userData.id,
                                              name: userData.user_name,
                                              profile_url: userData.avatar,
                                            },
                                            {
                                              id: host?.user.id,
                                              name: host?.user.user_name,
                                              profile_url: host?.user.avatar,
                                            }
                                          );
                                      }}
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="tout-created">
                                <div className="download-heart-icon button ">
                                  <div className="heart-div">
                                    <input
                                      type="checkbox"
                                      checked={isLiked}
                                      disabled={delayLike}
                                      onClick={() =>
                                        is_loggedin && Like(host?.user.id)
                                      }
                                      id="id"
                                      className="instruments"
                                    />
                                    <label htmlFor="id" className="check mb-0">
                                      {!isLiked && (
                                        <svg
                                          width="39"
                                          height="38"
                                          viewBox="0 0 39 38"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                                            stroke="#C9C9C9"
                                            stroke-width="2"
                                          />
                                          <rect
                                            x="22"
                                            y="13"
                                            width="17"
                                            height="14"
                                            fill="white"
                                          />
                                          <rect
                                            x="29"
                                            y="14"
                                            width="2"
                                            height="12"
                                            fill="#C9C9C9"
                                          />
                                          <rect
                                            x="36"
                                            y="19"
                                            width="2"
                                            height="12"
                                            transform="rotate(90 36 19)"
                                            fill="#C9C9C9"
                                          />
                                        </svg>
                                      )}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="single-local-host-profile">
                              <img
                                src={host?.user?.avatar || "./img/Avatar.png"}
                                alt=""
                                style={{ objectFit: "cover" }}
                              />
                              <div className="pro-tag-name">
                                <div className="pro-name-suah">
                                  <h3>
                                    {(host?.user?.user_name).length < 9
                                      ? host?.user?.user_name
                                      : (host?.user?.user_name).slice(0, 9) +
                                        "..."}
                                  </h3>
                                  <img
                                    src={checkImageURL(host?.user?.nationality)}
                                    style={{ objectFit: "cover" }}
                                    alt="flag"
                                  />
                                </div>
                                <div className="pro-tag-suah">
                                  {host?.hosting?.type === "Local" ? (
                                    <div className="host-catrgory">
                                      <p>{t("Local_Host")}</p>
                                    </div>
                                  ) : (
                                    <div className="travel-host-catrgory">
                                      <p>{t("Traveler_Host")}</p>
                                    </div>
                                  )}
                                  {host?.user?.gender === "MALE" ? (
                                    <div className="host-gender">
                                      <p>{t("Male")}</p>
                                    </div>
                                  ) : (
                                    <div className="host-gender">
                                      <p>{t("Female")}</p>
                                    </div>
                                  )}
                                  <div className="host-ages">
                                    {AuthStorage.getLang() === "en" ? (
                                      <p>
                                        {host?.user?.age_group}'
                                        {t("Age_Groups")}
                                      </p>
                                    ) : (
                                      <p className="d-flex align-items-center justify-content-center">
                                        {host?.user?.age_group}
                                        <span>{t("Age_Groups")}</span>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex mt-40 mb-40 hostlistbtnbox">
                              <div className="single-host-appstatus hostingDelete mt-40">
                                <label className=" p-0">
                                  {params.creator === "Host" &&
                                  data.is_deleted === false &&
                                  data.disclosure !== "PRIVATE" ? (
                                    <>{t("This_itinerary_has_been_deleted")}</>
                                  ) : (
                                    <>
                                      {data.is_deleted === true ||
                                      data.disclosure === "PRIVATE" ? (
                                        <>
                                          {t(
                                            "This_itinerary_has_been_deleted_by_Admin"
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="UserhostlistDate">
                        <div className="d-flex justify-content-between align-items-center main-date-content">
                          <p className=" date-title">
                            {t("Host_Details.Date&Time")}
                          </p>
                          <p className="main-date">
                            {host?.date?.replaceAll("-", ".")}{" "}
                            {host?.start_time?.slice(0, 5)}
                            {" - "}
                            {host?.end_time?.slice(0, 5)}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center maintour-content">
                          <p className="tourstart-title">
                            {t("Host_Details.Starts_At")}
                          </p>
                          <p className="tourdetails">{host?.location}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center main-transportation-content">
                          <p className="transprotation-title">
                            {t("Host_Details.Transportation")}
                          </p>
                          <p className="transportaion-details">
                            {AuthStorage.getLang() === "ko"
                              ? transporTation(host?.transportation)
                              : host?.transportation}
                          </p>
                        </div>
                      </div>

                      <div
                        className={
                          host?.hosting?.participate_count
                            ? "pax-list-inner-hosted"
                            : ""
                        }
                      >
                        <div className="mt-delete">
                          <pre
                            className={
                              AuthStorage.getLang() === "en"
                                ? "roboto-text delete-admin"
                                : "noto-text delete-admin"
                            }
                          >
                            {host?.host_information}
                          </pre>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default DeleteItinerary;

import React, { useEffect, useState } from "react";
import { Card, Tabs } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiGet } from "../../helper/API/ApiData";
import CustorServiceDetails from "./CustorServiceDetails";
import ReactHtmlParser from "react-html-parser";

import moment from "moment";

function MobileCustomerServices() {
  const { t } = useTranslation();

  const [value, setvalue] = useState("");
  const [perPage, setPerPage] = useState(12);
  const [pageNo, setPageNo] = useState(1);
  const [faqPerPage, setFaqPerPage] = useState(12);
  const [faqPageNo, setFaqPageNo] = useState(1);
  const [openNotice, setOpenNotice] = useState(false);
  const [noticeId, setNoticeId] = useState();
  const [noticeData, setNoticeData] = useState({
    count: 0,
    notices: [
      {
        content: "",
        created_at: "",
        id: 0,
        title: "",
        updated_at: "",
        view_count: 0,
      },
    ],
  });

  const [show, setShow] = useState([]);
  const [faqData, setFaqData] = useState({
    count: 0,
    faq: [
      {
        answer: "",
        created_at: "",
        id: 0,
        question: "",
        updated_at: "",
        view_count: 0,
      },
    ],
  });
  const [faqKeyword, setFaqKeyword] = useState("");

  //For FAQ
  const getFaqData = () => {
    ApiGet(
      `general/faq?keyword=${faqKeyword}&per_page=${faqPerPage}&page_number=${faqPageNo}`
    ).then((res) => {
      const _faqData = { ...faqData };
      _faqData.count = res.data.count;
      _faqData.faq =
        _faqData.faq[0].created_at === ""
          ? res.data.faq
          : _faqData.faq.concat(res.data.faq);
      setFaqData(_faqData);
    });
  };

  //For Notice
  const getNoticeData = () => {
    ApiGet(
      `general/notice?keyword=${value}&per_page=${perPage}&page_number=${pageNo}`
    ).then((res) => {
      // setNoticeData(res.data);
      const _noticeData = { ...noticeData };
      _noticeData.count = res.data.count;
      _noticeData.notices =
        _noticeData.notices[0].created_at === ""
          ? res.data.notices
          : _noticeData.notices.concat(res.data.notices);
      setNoticeData(_noticeData);
    });
  };

  useEffect(() => {
    getNoticeData();
  }, [pageNo, openNotice]);

  useEffect(() => {
    getFaqData();
  }, [faqPageNo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openNotice]);

  const handlechange = (id) => {
    setShow(id);
  };

  const [loadElements, setLoadElements] = useState(12);

  const sliceNotice = noticeData.notices.slice(0, loadElements);

  const loadmoreNoticeBtn = () => {
    setLoadElements(loadElements + 12);
  };

  return (
    <div className="cnotice-page mobile-customer-services">
      <div className="mini-container">
        <div className="CustomerService-title">
          <h1 className="ServiceTitle">
            {t("Customer_Service.Customer_Service")}
          </h1>
          <Tabs className="grid" defaultActiveKey="notice" transition={false}>
            <Tab eventKey="notice" title={t("Customer_Service.Notice")}>
              {!openNotice ? (
                <>
                  <div className="input d-flex align-items-center">
                    <input
                      name=""
                      type="text"
                      value={value}
                      placeholder={t("Customer_Service.Keyword")}
                      // fromrowStyleclass=""
                      // InputstyleClass="input-css"
                      // lablestyleClass=""
                      onChange={(e) => {
                        setvalue(e.target.value);
                      }}
                    />
                    <button onClick={() => getNoticeData()}>
                      <img src="./img/CustomerSearch.svg" alt="" />{" "}
                      <span> {t("Customer_Service.Search")}</span>
                    </button>
                  </div>
                  <div className="total-items">
                    <h5>
                      {t("Customer_Service.Total")}
                      {noticeData.count}
                      {t("Customer_Service.Items")}
                    </h5>
                  </div>
                  <table>
                    <tr className="th">
                      <th className="th-no text-center">No</th>
                      <th className="th-title text-center">
                        {t("Customer_Service.Title")}
                      </th>
                      <th className="th-data text-center">
                        {t("Customer_Service.Date")}
                      </th>
                      <th className="th-views text-center">
                        {t("Customer_Service.Views")}
                      </th>
                    </tr>
                    {sliceNotice &&
                      sliceNotice.map((items, i) => {
                        const title = items.title.replace(
                          value,
                          `<b>${value}</b>`
                        );
                        return (
                          <tr className="td">
                            <td className="td-no text-center">
                              {noticeData.count - (pageNo - 1) * 10 - i}
                            </td>
                            <td
                              className="td-title"
                              onClick={() => {
                                setNoticeId(noticeData.notices[i].id);
                                setOpenNotice(true);
                              }}
                            >
                              {ReactHtmlParser(title)}
                            </td>
                            <td className="td-date text-center">
                              {moment(items.created_at).format(
                                "YYYY.MM.DD HH:mm"
                              )}
                            </td>
                            <td className="td-viwes text-center">
                              {items.view_count}
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                  {noticeData.count > noticeData.notices.length && (
                    <div
                      className="mobile-pagination-faq"
                      onClick={loadmoreNoticeBtn}
                    >
                      <button
                        onClick={() => {
                          setPageNo(pageNo + 1);
                        }}
                      >
                        More
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <CustorServiceDetails
                  setOpenNotice={setOpenNotice}
                  noticeId={noticeId}
                  getNoticeData={getNoticeData}
                  noticeData={noticeData}
                />
              )}
            </Tab>

            <Tab eventKey="faq" title={t("Customer_Service.FAQ")}>
              <div className="input d-flex align-items-center">
                <input
                  name=""
                  type="text"
                  value={faqKeyword}
                  placeholder={t("Customer_Service.Keyword")}
                  // fromrowStyleclass=""
                  // InputstyleClass="input-css"
                  // lablestyleClass=""
                  onChange={(e) => {
                    setFaqKeyword(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    getFaqData();
                  }}
                >
                  <img src="./img/CustomerSearch.svg" alt="" />{" "}
                  <span> {t("Customer_Service.Search")}</span>{" "}
                </button>
              </div>
              <div className="total-items">
                <h5>
                  {t("Customer_Service.Total")}
                  {faqData?.count}
                  {t("Customer_Service.Items")}
                </h5>
              </div>
              <div className="faq-table">
                {faqData?.faq?.map((items, i) => {
                  const faqTitle = items.question.replace(
                    faqKeyword,
                    `<b>${faqKeyword}</b>`
                  );
                  return (
                    <Accordion>
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={items.id.toString()}
                        >
                          <div className="card-img d-flex align-items-center">
                            <div className="Q-round">
                              <span>Q</span>
                            </div>
                            <div
                              className="w-100"
                              onClick={() => {
                                handlechange(items.id);
                              }}
                            >
                              <p>{ReactHtmlParser(items.question)}</p>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        {show === items.id && (
                          <Accordion.Collapse eventKey={items.id.toString()}>
                            <Card.Body className="faq-body">
                              {items.answer}
                            </Card.Body>
                          </Accordion.Collapse>
                        )}
                      </Card>
                    </Accordion>
                  );
                })}
              </div>
              <div className="mobile-pagination-faq">
                {faqData.count > faqData.faq.length && (
                  <button
                    onClick={() => {
                      setFaqPageNo(faqPageNo + 1);
                    }}
                  >
                    More
                  </button>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default MobileCustomerServices;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { BaseURL } from "../../helper/API/ApiData";
import "./ForgotPassword.css";

const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const resetErr = {
    passFormateErr: false,
    passErr: false,
    confirmPassErr: false,
    passMatch: false,
  };
  const [errors, setErrors] = useState(resetErr);
  const [isSubmited, setIsSubmited] = useState(false);

  const validateInputs = () => {
    let resetFormError = {
      passFormateErr: false,
      passErr: false,
      confirmPassErr: false,
      passMatch: false,
    };

    const resrtPassword = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,16})");

    if (!resrtPassword.test(password)) {
      resetFormError.passFormateErr = true;
    }

    if (!password) {
      resetFormError.passErr = true;
    }

    if (!confirmPass) {
      resetFormError.confirmPassErr = true;
    }

    if (confirmPass !== password) {
      resetFormError.passMatch = true;
    }

    setErrors(resetFormError);

    if (
      !resetFormError.confirmPassErr &&
      !resetFormError.passErr &&
      !resetFormError.passFormateErr &&
      !resetFormError.passMatch
    ) {
      return true;
    }

    return false;
  };

  const resetPass = async () => {
    setIsSubmited(true);

    if (!validateInputs()) {
      return;
    }
    try {
      const res = await axios
        .post(
          BaseURL + "user/forgot",
          { password: password },
          {
            headers: {
              Authorization: `Bearer ${queryParams.token}`,
            },
          }
        )
        .then((res) => {
          history.push("/");
        });
    } catch (error) {}
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      resetPass();
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (isSubmited) {
      validateInputs();
    }
  }, [password, confirmPass]);

  return (
    <>
      <main id="forgotPw" className="wrap">
        <h3 className="page-title">{t("Forgot_Password.Reset_Password")}</h3>
        <p className="page-desc">{t("Forgot_Password.Pass_config")}</p>
        <div className="InputBox">
          <label>{t("Forgot_Password.New_Password")}</label>
          <input
            // label={t('Forgot_Password.New_Password')}
            // fromrowStyleclass="mt-4"
            name="resetPass"
            value={password}
            placeholder=""
            type="password"
            // InputstyleClass="custom-input mb-0 mt-20"
            // lablestyleClass="font-20-bold color-black"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          {errors.passErr && (
            <p className="text-danger">{t("Forgot_Password.Error.Password")}</p>
          )}
          {password && errors.passFormateErr && (
            <p className="text-danger">
              {t("Forgot_Password.Error.Password_Formate")}
            </p>
          )}
        </div>
        <div className="InputBox pb-0">
          <label>{t("Forgot_Password.Confirm_Pass")}</label>
          <input
            // label={t('Forgot_Password.Confirm_Pass')}
            // fromrowStyleclass="mt-4"
            name="resetCPass"
            value={confirmPass}
            placeholder=""
            type="password"
            // InputstyleClass="custom-input mb-0 mt-20"
            // lablestyleClass="font-20-bold color-black"
            onChange={(e) => setConfirmPass(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {errors.confirmPassErr && (
            <p className="text-danger">
              {t("Forgot_Password.Error.ConfirmPass")}
            </p>
          )}
          {confirmPass && errors.passMatch && (
            <p className="text-danger">
              {t("Forgot_Password.Error.PassMatch")}
            </p>
          )}
        </div>

        {password && confirmPass ? (
          !errors.confirmPassErr && !errors.passMatch ? (
            <Button
              variant="confirmBtn"
              disabled={!errors.confirmPassErr ? false : true}
              onClick={resetPass}
            >
              {t("Forgot_Password.Confirm")}
            </Button>
          ) : (
            <Button variant="confirmBtn" disabled onClick={resetPass}>
              {t("Forgot_Password.Confirm")}
            </Button>
          )
        ) : (
          <Button variant="confirmBtn" disabled onClick={resetPass}>
            {t("Forgot_Password.Confirm")}
          </Button>
        )}
      </main>
    </>
  );
};

export default ForgotPassword;

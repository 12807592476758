import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { ApiGet } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import AccountDeactive from "../modal/AccountDeactive";
import AlreadyHostModal from "../modal/AlreadyHostModal";
import HostCard from "./HostCard";
import LikedPlaces from "./LikedPlaces";
import TourCard from "./TourCard";
import { Button } from "react-bootstrap";

const MyWishListPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [tourCoures, setTourCoures] = useState([]);
  const [tourCoursCount, setTourCoursCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [itineraryCount, setItineraryCount] = useState(0);
  const [hostCount, setHostCount] = useState(0);
  const [feeds, setFeeds] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [hostingNotice, setHostingNotice] = useState(false);
  const is_active = AuthStorage.isUserActive();
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);

  useEffect(() => {
    setFeeds([]);
    itineraryGet();
    hostingGet();
    tourCouresGet();
  }, [refresh]);

  const itineraryGet = () => {
    ApiGet("itinerary/getItineraryWishlist").then((res) => {
      setFeeds(res.data.itinerary);
      setItineraryCount(res.data.count);
    });
  };

  const searchTours = async () => {
    setFeeds([]);
    setActiveTab(0);
    const res = await ApiGet("itinerary/getItineraryWishlist?all=true");
    setFeeds(res.data.itinerary);
    setItineraryCount(res.data.count);
    console.log("위시리스트 확인", res);
  };

  const searchHosts = async () => {
    setFeeds([]);
    setActiveTab(2);
    const res = await ApiGet("hosting/getHostWishlist?all=true");
    setFeeds(res.data.host);
  };

  const hostingGet = () => {
    ApiGet("hosting/getHostWishlist?all=true").then((res) => {
      setHostCount(res.data.count);
    });
  };
  const tourCouresGet = () => {
    ApiGet("itinerary/getUserWishLocation?all=true")
      .then((res) => {
        setTourCoures(res.data.data);
        setTourCoursCount(res.data.count);
        console.log("위시리스트 확인", res);
      })
      .catch((err) => {
        console.log("에러", err);
      });
  };
  const searchToursCoures = async () => {
    setTourCoures([]);
    setActiveTab(0);
    const res = await ApiGet("itinerary/getUserWishLocation?all=true");
    setTourCoures(res.data.data);
  };

  const hostownPage = () => {
    history.push("/hostitinerary");
  };

  const [canHost, setCanHost] = useState(false);
  const isAlreadyHosting = () => {
    ApiGet("hosting/isAlreadyHosted").then((res) => {
      setCanHost(res?.data?.status);
    });
  };

  useEffect(() => {
    isAlreadyHosting();
  }, []);

  return (
    <section id="myWishlist" className="wrap">
      <div className="d-flex align-items-center">
        <h2 className="page-title">{t("My_Account.Wishlist")}</h2>
        <div className="details-header mt-100 d-flex align-items-center">
          <Button
            variant="cancelBtn"
            className="host-my-own-btn d-flex align-items-center"
            onClick={() => {
              if (is_active) {
                !canHost ? setHostingNotice(true) : hostownPage();
              } else {
                setDeactivatedNotice(true);
              }
            }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="2.75"
                y="4.75"
                width="14.5"
                height="12.5"
                rx="2.25"
                fill="white"
                stroke="#42B6E6"
                stroke-width="1.5"
              />
              <path
                d="M5.83984 2.75C5.83984 2.33579 6.17563 2 6.58984 2C7.00406 2 7.33984 2.33579 7.33984 2.75V4.5H5.83984V2.75Z"
                fill="#42B6E6"
              />
              <path
                d="M6.5 11C6.5 10.5858 6.83579 10.25 7.25 10.25H12.75C13.1642 10.25 13.5 10.5858 13.5 11C13.5 11.4142 13.1642 11.75 12.75 11.75H7.25C6.83579 11.75 6.5 11.4142 6.5 11Z"
                fill="#42B6E6"
              />
              <path
                d="M10 14.5C9.58579 14.5 9.25 14.1642 9.25 13.75L9.25 8.25C9.25 7.83579 9.58579 7.5 10 7.5C10.4142 7.5 10.75 7.83579 10.75 8.25L10.75 13.75C10.75 14.1642 10.4142 14.5 10 14.5Z"
                fill="#42B6E6"
              />
              <path
                d="M12.6016 2.75C12.6016 2.33579 12.9373 2 13.3516 2C13.7658 2 14.1016 2.33579 14.1016 2.75V4.5H12.6016V2.75Z"
                fill="#42B6E6"
              />
            </svg>

            <span> {t("Homepage.Hero.Host_MyOwn")}</span>
          </Button>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items center">
        <div className="myPage-tabs mt-40 h-39 mb-40 d-flex align-items-center">
          {
            <>
              <span
                className={activeTab === 0 ? `active` : ""}
                onClick={() => {
                  searchToursCoures();
                  setActiveTab(0);
                }}
              >
                {`${t("My_Account.TourCourse")}`}&nbsp;({tourCoursCount})
              </span>
            </>
          }
          {
            <>
              <span
                className={activeTab === 1 ? `active` : ""}
                onClick={() => {
                  searchTours();
                  setActiveTab(1);
                }}
              >
                {AuthStorage.getLang() === "en" ? (
                  <>
                    {`${t("My_Account.Tours")}`}&nbsp;({itineraryCount})
                  </>
                ) : (
                  <>
                    {`${t("My_Account.Tour")}`}&nbsp;({itineraryCount})
                  </>
                )}
              </span>
            </>
          }
          {
            <>
              <span
                className={activeTab === 2 ? `active` : ""}
                onClick={() => {
                  searchHosts();
                  setActiveTab(2);
                }}
              >
                {AuthStorage.getLang() === "en" ? (
                  <>
                    {t("My_Account.Hosts")}&nbsp;({hostCount})
                  </>
                ) : (
                  <>
                    {t("My_Account.Host")}&nbsp;({hostCount})
                  </>
                )}
              </span>
            </>
          }
        </div>
        {/* <Link to="/wishlist" className="moreLink">
          {t("My_Account.View_All")}{" "}
        </Link> */}
      </div>
      {activeTab === 0 ? (
        <>
          <section id="wishlist">
            <LikedPlaces
              searchToursCoures={searchToursCoures}
              tourCoures={tourCoures}
              setRefresh={setRefresh}
            />
          </section>

          <AlreadyHostModal
            hostingNotice={hostingNotice}
            setHostingNotice={() => setHostingNotice(false)}
          />
        </>
      ) : activeTab === 1 ? (
        feeds.length === 0 ? (
          <div className="noFeed w-100">
            <h4>{t("My_Account.No_Wishlist")}</h4>
          </div>
        ) : (
          <div className="tour-card-list">
            {feeds.map((feed, i) => {
              const isCancled =
                !(feed.creator === "Compastrips") && feed.status === "CANCELED";
              return (
                <TourCard
                  key={i}
                  canlike={true}
                  UpdateCount={setItineraryCount}
                  // activeTab="wishlist"
                  items={{ ...feed, isLike: true }}
                  setRefresh={setRefresh}
                  isCancled={isCancled}
                />
              );
            })}
          </div>
        )
      ) : feeds.length === 0 ? (
        <div className="noFeed w-100">
          <h4>{t("My_Account.No_Wishlist")}</h4>
        </div>
      ) : (
        feeds.map((feed, i) => (
          <HostCard
            key={i}
            items={{ ...feed, isLike: true }}
            searchHosts={searchHosts}
            setRefresh={setRefresh}
          />
        ))
      )}

      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </section>
  );
};

export default MyWishListPage;

import React from "react";
import { useTranslation } from "react-i18next";
import AuthStorage from "../../helper/AuthStorage";
import { Button, Modal } from "react-bootstrap";
import Rating from "react-rating";
import ReactTextareaAutosize from "react-textarea-autosize";

function ReviewPopup({
  show,
  onHide,
  onSubmit,
  feedback,
  setFeedback,
  stars,
  setStars,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal review-modal"
            : "noto-text custom-modal review-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Review_Popup.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p dangerouslySetInnerHTML={{ __html: t("Review_Popup.Body") }} />
          </div>

          <div className="text-center reviewRating pcReviewRating">
            <Rating
              emptySymbol={
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.645 7.73874C25.8104 7.23442 26.1386 6.79393 26.5821 6.48126C27.0255 6.16858 27.561 6 28.1109 6C28.6607 6 29.1963 6.16858 29.6397 6.48126C30.0832 6.79393 30.4114 7.23442 30.5768 7.73874L35.0297 20.9083H49.394C49.9635 20.8876 50.5244 21.0468 50.991 21.3615C51.4576 21.6762 51.8042 22.1292 51.9779 22.6513C52.1516 23.1733 52.1429 23.7356 51.9529 24.2524C51.763 24.7692 51.4024 25.212 50.9262 25.5131L39.2672 33.6405L43.7201 46.8331C43.8962 47.3356 43.9006 47.8791 43.7328 48.3842C43.5649 48.8894 43.2335 49.3296 42.787 49.6408C42.3404 49.9519 41.8022 50.1176 41.2508 50.1137C40.6994 50.1098 40.1637 49.9364 39.722 49.6189L28.0391 41.3995L16.3801 49.5499C15.9384 49.8673 15.4027 50.0407 14.8513 50.0446C14.2999 50.0486 13.7617 49.8829 13.3151 49.5717C12.8686 49.2606 12.5372 48.8203 12.3693 48.3152C12.2015 47.81 12.2059 47.2665 12.382 46.764L16.8349 33.5714L5.17591 25.444C4.6997 25.1429 4.33908 24.7001 4.14916 24.1833C3.95923 23.6666 3.95045 23.1042 4.12415 22.5822C4.29785 22.0602 4.64448 21.6072 5.11108 21.2924C5.57768 20.9777 6.13859 20.8185 6.7081 20.8393H21.0724L25.645 7.73874Z"
                    stroke="#DCDCDC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              fullSymbol={
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M25.645 7.73874C25.8104 7.23442 26.1386 6.79393 26.5821 6.48126C27.0255 6.16858 27.561 6 28.1109 6C28.6607 6 29.1963 6.16858 29.6397 6.48126C30.0832 6.79393 30.4114 7.23442 30.5768 7.73874L35.0297 20.9083H49.394C49.9635 20.8876 50.5244 21.0468 50.991 21.3615C51.4576 21.6762 51.8042 22.1292 51.9779 22.6513C52.1516 23.1733 52.1429 23.7356 51.9529 24.2524C51.763 24.7692 51.4024 25.212 50.9262 25.5131L39.2672 33.6405L43.7201 46.8331C43.8962 47.3356 43.9006 47.8791 43.7328 48.3842C43.5649 48.8894 43.2335 49.3296 42.787 49.6408C42.3404 49.9519 41.8022 50.1176 41.2508 50.1137C40.6994 50.1098 40.1637 49.9364 39.722 49.6189L28.0391 41.3995L16.3801 49.5499C15.9384 49.8673 15.4027 50.0407 14.8513 50.0446C14.2999 50.0486 13.7617 49.8829 13.3151 49.5717C12.8686 49.2606 12.5372 48.8203 12.3693 48.3152C12.2015 47.81 12.2059 47.2665 12.382 46.764L16.8349 33.5714L5.17591 25.444C4.6997 25.1429 4.33908 24.7001 4.14916 24.1833C3.95923 23.6666 3.95045 23.1042 4.12415 22.5822C4.29785 22.0602 4.64448 21.6072 5.11108 21.2924C5.57768 20.9777 6.13859 20.8185 6.7081 20.8393H21.0724L25.645 7.73874Z"
                    fill="#FF5F5F"
                    stroke="#FF5F5F"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              initialRating={stars}
              onChange={(e) => {
                setStars(e);
              }}
              stop={5}
            />
          </div>
          <div className="text-center reviewRating mobileReviewRating">
            <Rating
              emptySymbol={
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.4994 7.10362C18.6026 6.78351 18.8073 6.50393 19.0838 6.30546C19.3604 6.107 19.6944 6 20.0373 6C20.3803 6 20.7143 6.107 20.9908 6.30546C21.2674 6.50393 21.4721 6.78351 21.5752 7.10362L24.3524 15.4626H33.311C33.6662 15.4495 34.016 15.5505 34.307 15.7503C34.5981 15.9501 34.8142 16.2376 34.9226 16.5689C35.0309 16.9003 35.0254 17.2572 34.907 17.5852C34.7885 17.9132 34.5636 18.1943 34.2666 18.3854L26.9952 23.544L29.7724 31.9177C29.8822 32.2366 29.885 32.5816 29.7803 32.9022C29.6756 33.2228 29.4689 33.5023 29.1904 33.6998C28.9119 33.8973 28.5762 34.0025 28.2323 34C27.8884 33.9975 27.5543 33.8874 27.2789 33.6859L19.9925 28.4688L12.7211 33.6421C12.4456 33.8436 12.1116 33.9536 11.7677 33.9561C11.4238 33.9586 11.0881 33.8534 10.8096 33.6559C10.5311 33.4585 10.3244 33.179 10.2197 32.8584C10.115 32.5378 10.1178 32.1928 10.2276 31.8738L13.0048 23.5002L5.73339 18.3415C5.43638 18.1504 5.21147 17.8694 5.09302 17.5414C4.97457 17.2134 4.9691 16.8564 5.07743 16.5251C5.18576 16.1937 5.40194 15.9062 5.69295 15.7065C5.98395 15.5067 6.33378 15.4057 6.68897 15.4188H15.6476L18.4994 7.10362Z"
                    stroke="#C8C4C4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              fullSymbol={
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.4994 7.10362C18.6026 6.78351 18.8073 6.50393 19.0838 6.30546C19.3604 6.107 19.6944 6 20.0373 6C20.3803 6 20.7143 6.107 20.9908 6.30546C21.2674 6.50393 21.4721 6.78351 21.5752 7.10362L24.3524 15.4626H33.311C33.6662 15.4495 34.016 15.5505 34.307 15.7503C34.5981 15.9501 34.8142 16.2376 34.9226 16.5689C35.0309 16.9003 35.0254 17.2572 34.907 17.5852C34.7885 17.9132 34.5636 18.1943 34.2666 18.3854L26.9952 23.544L29.7724 31.9177C29.8822 32.2366 29.885 32.5816 29.7803 32.9022C29.6756 33.2228 29.4689 33.5023 29.1904 33.6998C28.9119 33.8973 28.5762 34.0025 28.2323 34C27.8884 33.9975 27.5543 33.8874 27.2789 33.6859L19.9925 28.4688L12.7211 33.6421C12.4456 33.8436 12.1116 33.9536 11.7677 33.9561C11.4238 33.9586 11.0881 33.8534 10.8096 33.6559C10.5311 33.4585 10.3244 33.179 10.2197 32.8584C10.115 32.5378 10.1178 32.1928 10.2276 31.8738L13.0048 23.5002L5.73339 18.3415C5.43638 18.1504 5.21147 17.8694 5.09302 17.5414C4.97457 17.2134 4.9691 16.8564 5.07743 16.5251C5.18576 16.1937 5.40194 15.9062 5.69295 15.7065C5.98395 15.5067 6.33378 15.4057 6.68897 15.4188H15.6476L18.4994 7.10362Z"
                    fill="#FF5F5F"
                  />
                </svg>
              }
              initialRating={stars}
              onChange={(e) => {
                setStars(e);
              }}
              stop={5}
            />
          </div>
          <ReactTextareaAutosize
            className="w-100"
            minRows={6}
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            name="aboutTour"
            value={feedback}
            placeholder={t("Review_Popup.Write_Review")}
            maxLength={500}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            onClick={onHide}
          >
            {t("Review_Popup.Cancel")}
          </Button>

          {feedback.length < 1 ? (
            <Button
              variant="confirmBtn"
              className="reviewSubmit reviewSubmit-disable"
            >
              {t("Review_Popup.Submit")}
            </Button>
          ) : (
            <Button
              variant="confirmBtn"
              className="reviewSubmit"
              // ButtonStyle="join-apply-btn"
              onClick={onSubmit}
            >
              {t("Review_Popup.Submit")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReviewPopup;

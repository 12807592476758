import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Tabs from "../../common/Tabs";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import * as QueryString from "query-string";
import moment from "moment";
import AuthStorage from "../../helper/AuthStorage";
import Rating from "react-rating";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";

import TourCard from "../user/TourCard";
import Slider from "react-slick";
import { Message } from "components/Message";
import { Button } from "react-bootstrap";
import { setGlobalSearch } from "redux/action/persistDataAction";
import { pubTrendingNowState } from "redux/action/trendingNowAction";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostPro from "../../components/HostPro";

const TourList = ({
  LoginModalClick,
  setActiveIndex,
  activeIndex,
  activeTab,
  setActiveTab,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(0);
  const { is_loggedin } = useSelector((state) => state.login);
  // const [activeTab, setActiveTab] = useState("All");
  const [activeKeyword, setActiveKeyword] = useState("");
  const [slickePosition, setSlickPosition] = useState(false);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [total, setTotal] = useState(1);
  const [perPage, setPerPage] = useState(80);
  const persistData = useSelector((state) => state.persistData);
  // const tabsList = ["All", "Local Picks", "K-pop", "Festivals & Events", "Popular Destinations"];
  let searchParam = QueryString.parse(history.location.search);

  const [tabList, setTabList] = useState([
    {
      category: "All",
      value: `${t("Homepage.NavBar.All")}`,
      label: `${t("Homepage.NavBar.All")}`,
      keyword: "",
    },
    {
      category:
        AuthStorage.getLang() === "en" ? "Local Picks" : "현지인만 아는",
      value: `${t("Homepage.NavBar.Local_Picks")}`,
      label: `${t("Homepage.NavBar.Local_Picks")}`,
      keyword: "",
    },
    {
      category: AuthStorage.getLang() === "en" ? "Arts & Culture" : "문화예술",
      value: `${t("Homepage.NavBar.ArtCulture")}`,
      label: `${t("Homepage.NavBar.ArtCulture")}`,
      keyword: "",
    },
    {
      category:
        AuthStorage.getLang() === "en" ? "Festivals & Events" : "축제와 이벤트",
      value: `${t("Homepage.NavBar.Festivals")}`,
      label: `${t("Homepage.NavBar.Festivals")}`,
      keyword: "",
    },
    {
      category:
        AuthStorage.getLang() === "en" ? "Popular Destination" : "인기명소",
      value: `${t("Homepage.NavBar.Popular")}`,
      label: `${t("Homepage.NavBar.Popular")}`,
      keyword: "",
    },
  ]);

  useEffect(() => {
    setPage(1);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    getSearchedItinerary();
  }, [activeTab, page, location, activeKeyword, refresh]);

  //search
  const dispatch = useDispatch();
  const returnCategoryActiveData = (ActiveTab) => {
    let category = "";
    let category_ko = "";
    if (AuthStorage.getLang() === "en") {
      switch (ActiveTab) {
        case "All":
          category = "All";
          category_ko = "전체";
          break;

        case "Popular Destination":
          category = "Popular Destination";
          category_ko = "인기명소";
          break;

        case "Local Picks":
          category = "Local Picks";
          category_ko = "현지인만 아는";
          break;

        case "Festivals & Events":
          category = "Festivals & Events";
          category_ko = "축제와 이벤트";
          break;

        case "Arts & Culture":
          category = "Arts & Culture";
          category_ko = "문화예술";
          break;

        default:
          break;
      }
    } else {
      switch (ActiveTab) {
        case "전체":
          category = "All";
          category_ko = "전체";
          break;

        case "인기명소":
          category = "Popular Destination";
          category_ko = "인기명소";
          break;

        case "현지인만 아는":
          category = "Local Picks";
          category_ko = "현지인만 아는";
          break;

        case "축제와 이벤트":
          category = "Festivals & Events";
          category_ko = "축제와 이벤트";
          break;

        case "문화예술":
          category = "Arts & Culture";
          category_ko = "문화예술";
          break;

        default:
          break;
      }
    }

    return {
      category: category,
      category_ko: category_ko,
    };
  };

  const getSearchedItinerary = () => {
    if (searchParam) {
      setLoading(true);
      // setErr(false);

      let ActiveTab =
        `${activeTab}` === "All"
          ? { category: "", category_ko: "" }
          : returnCategoryActiveData(activeTab);
      let ActiveKeyword = activeKeyword;

      ApiPost(
        `itinerary/getItineraryOnHome?page_number=${page}&per_page=${perPage}`,
        {
          search_term: persistData?.globalSearch?.searchHeaderTerm || null,
          start_date: persistData?.globalSearch?.startHeaderDate || null,
          end_date: persistData?.globalSearch?.endHeaderDate || null,
          category: ActiveTab?.category,
          category_ko: ActiveTab?.category_ko,
        }
      )
        .then((res) => {
          setData((prev) => {
            if (res.data.itinerary > 0 || page > 1) {
              return [...res.data.itinerary];
            } else {
              return [...res.data.itinerary];
            }
          });
          setTotal(res.data.count);
          setLoading(false);
        })
        .catch((e) => {
          setData(null);
        });
    }
  };

  const isEmpty = (value) => {
    if (value == null || value === "null") {
      return true;
    }
    if (typeof value == "object") {
      return Object.keys(value).length === 0;
    }
    return (
      (Array.isArray(value) && value.length === 0) ||
      value === undefined ||
      value === "undefined" ||
      value === null ||
      value === ""
    );
  };

  const changeDateType = (date) => {
    const x = new Date(date);
    const y = x.getMonth();
    const w = x.getDate();
    const z = x.getFullYear().toString();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // return month[y] + " " + w + ", " + z;
    if (AuthStorage.getLang() === "en") {
      return moment(date).locale(AuthStorage.getLang()).format("MMMM D, yyyy");
    } else {
      return moment(date)
        .locale(AuthStorage.getLang())
        .format("yyyy년 M월 D일");
    }
  };

  const changeTimeFormat = (time) => {
    if (!time) {
      return "";
    }
    let Time = time.split(":");
    return Time[0] + ":" + Time[1];
  };

  //Like function
  const [delayLike, setDelayLike] = useState(false);
  const Like = (id) => {
    setDelayLike(true);
    localLike(id);
    ApiPost(`itinerary/wishlist/${id}`, {})
      .then((res) => {
        setDelayLike(false);
      })
      .catch((err) => {});
  };

  const localLike = (itineraryId) => {
    setData(
      data.map((itinerary) => {
        if (itinerary.id === itineraryId) {
          itinerary.isLike = !itinerary.isLike;
        }
        return itinerary;
      })
    );
  };

  const Search = async (index) => {
    setData([]);
    setPage(1);
    setActiveTab(tabList[index]?.category);
    setActiveKeyword(tabList[index]?.keyword);
    let searchParam =
      "?keyword=" +
      (activeKeyword || "") +
      "&startDate=" +
      (persistData?.globalSearch?.startHeaderDate || "") +
      "&endDate=" +
      (persistData?.globalSearch?.endHeaderDate || "");
    history.push({
      pathname: "/",
      search: searchParam,
    });
  };

  const getCreatorString = (creator, creator_show) => {
    if (creator === "Compastrips" && creator_show === "Compastrips") {
      return `<span class="compastrips-span "> ${creator_show} </span>`;
    } else if (creator === "Host" && creator_show === "Host") {
      return `<span class="host-green "> ${creator_show} </span>`;
    } else {
      return `<span class="host-black "> ${creator_show} </span>`;
    }
  };

  useEffect(() => {
    // const browserLang = navigator.language || navigator.userLanguage;
    ApiGet("general/trendingnow/get/list?code=all").then((res) => {
      setTabList([
        ...tabList,
        ...res.data.map((x) => {
          return {
            category: "",
            value: x.message,
            label: x.message,
            keyword: x.message,
          };
        }),
      ]);
    });
  }, []);

  const tabScroll = useRef();
  const tabWidth = useRef();

  const [popMembers, setPopMenbers] = useState(null);
  var cardItemCnt = 0;
  var cardItemPopMCnt = 0;

  const getHosts = () => {
    const url = `hosting/hosts?host=&gender=&age=&per_page=80&page_number=1`;
    ApiGet(url)
      .then((res) => {
        console.log("res   ", res);
        setPopMenbers(res.data.hosts.reverse());
      })
      .catch((err) => {
        console.log("에러", err);
      });
  };

  useEffect(() => {
    getHosts();
  }, []);

  const [show, setShow] = useState(false);
  const [hostingId, setHostingId] = useState("");
  const [popMSpan, setPopMSpan] = useState("4");
  const [popMItemCnt, setPopMItemCnt] = useState(8);

  const [resize, setResize] = useState(window.innerWidth);
  const handleResize = () => {
    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      setPopMSpan("5");
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      setPopMSpan("4");
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      setPopMSpan("3");
    } else if (window.innerWidth <= 1053) {
      setPopMSpan("2");
    }

    if (window.innerWidth >= 1786 && window.innerWidth <= 1920) {
      setPopMItemCnt(8);
    } else if (window.innerWidth >= 1602 && window.innerWidth <= 1785) {
      setPopMItemCnt(7);
    } else if (window.innerWidth >= 1416 && window.innerWidth <= 1601) {
      setPopMItemCnt(6);
    } else if (window.innerWidth >= 1223 && window.innerWidth <= 1415) {
      setPopMItemCnt(5);
    } else if (window.innerWidth >= 1150 && window.innerWidth <= 1222) {
      setPopMItemCnt(4);
    } else if (window.innerWidth <= 1149) {
      setPopMItemCnt(3);
    }

    setResize(window.innerWidth);
  };

  useEffect(() => {
    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      setPopMSpan("5");
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      setPopMSpan("4");
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      setPopMSpan("3");
    } else if (window.innerWidth <= 1053) {
      setPopMSpan("2");
    }

    if (window.innerWidth >= 1786 && window.innerWidth <= 1920) {
      setPopMItemCnt(8);
    } else if (window.innerWidth >= 1602 && window.innerWidth <= 1785) {
      setPopMItemCnt(7);
    } else if (window.innerWidth >= 1416 && window.innerWidth <= 1601) {
      setPopMItemCnt(6);
    } else if (window.innerWidth >= 1223 && window.innerWidth <= 1415) {
      setPopMItemCnt(5);
    } else if (window.innerWidth >= 1150 && window.innerWidth <= 1222) {
      setPopMItemCnt(4);
    } else if (window.innerWidth <= 1149) {
      setPopMItemCnt(3);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tour-list-section pc-card">
      <div ref={tabScroll} className="tour-list-scroll position-relative">
        <div
          className={!slickePosition ? "d-flex tab-list" : "d-flex tab-list"}
          ref={tabWidth}
        >
          {tabList.map((tab, i) => {
            return (
              <Button
                variant="mainTab"
                key={i}
                className={i === activeIndex && "active"}
                // disabled={activeTab === tab.category ? true : false}
                // onClick={() => filterData(tab.value)}
                onClick={() => {
                  setActiveIndex(i);
                  setActiveTab(tab.category);
                  setActiveKeyword(tab.keyword);
                  Search(i);
                  dispatch(
                    setGlobalSearch({
                      field: "searchHeaderTerm",
                      value: tab.keyword,
                    })
                  );
                }}
              >
                {`${tab.label}`}
              </Button>
            );
          })}
        </div>
        {slickePosition && (
          <Button variant="transparent" className="prevBtn">
            <svg
              onClick={() => {
                setSlickPosition(false);
                tabWidth.current.style.left = "0";
              }}
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_128_16613)">
                <path
                  d="M0.5 16C0.5 24.5604 7.43959 31.5 16 31.5C24.5604 31.5 31.5 24.5604 31.5 16C31.5 7.43959 24.5604 0.5 16 0.5C7.43959 0.5 0.5 7.43959 0.5 16Z"
                  fill="white"
                  stroke="#1B1C1E"
                />
                <path
                  d="M17.5 11L11.5 16L17.5 21"
                  stroke="#1B1C1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_128_16613">
                  <rect
                    width="32"
                    height="32"
                    fill="white"
                    transform="matrix(-1 0 0 -1 32 32)"
                  />
                </clipPath>
              </defs>
            </svg>
          </Button>
        )}
        {!slickePosition &&
          tabScroll.current?.offsetWidth < tabWidth.current?.offsetWidth && (
            <Button variant="transparent" className="nextBtn">
              <svg
                onClick={() => {
                  setSlickPosition(true);
                  const animation =
                    tabScroll.current?.offsetWidth -
                    tabWidth.current?.offsetWidth;
                  if (animation < 0) {
                    tabWidth.current.style.left = `${animation}px`;
                  } else {
                  }
                }}
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="31"
                  height="31"
                  rx="15.5"
                  fill="white"
                  stroke="#1B1C1E"
                />
                <path
                  d="M14.5 21L20.5 16L14.5 11"
                  stroke="#1B1C1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          )}
        <div className="tab-line"></div>
      </div>
      <div className={"content-card-tour-list"}>
        {data &&
          data.map((item, i) => {
            if (i === 0) {
              return (
                <TourCard
                  items={item}
                  setRefresh={setRefresh}
                  key={i}
                  LoginModalClick={LoginModalClick}
                />
              );
            } else if (i !== 0 && i % 16 === 0) {
              cardItemPopMCnt++;
              return (
                <>
                  <div
                    style={{
                      gridColumnStart: 1,
                      gridColumnEnd: popMSpan,
                    }}
                  >
                    <div>
                      <h4>Check out our popular members</h4>
                    </div>
                    <div className="popular-members">
                      {popMembers.map((popM, popIdx) => {
                        if (
                          (cardItemPopMCnt - 1) * popMItemCnt <= popIdx &&
                          popIdx < popMItemCnt * cardItemPopMCnt
                        ) {
                          return (
                            <div
                              className="popular-members-item"
                              onClick={() => {
                                setShow(true);
                                setHostingId(popM.hosting_id);
                              }}
                            >
                              <div
                                className="host-img circle"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  src={popM.avatar || "./img/Avatar.png"}
                                  alt=""
                                  className="HostProfileImg"
                                  style={{
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                              {popM.user_name}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <TourCard
                    items={item}
                    setRefresh={setRefresh}
                    key={i}
                    LoginModalClick={LoginModalClick}
                  />
                </>
              );
            } else {
              return (
                <TourCard
                  items={item}
                  setRefresh={setRefresh}
                  key={i}
                  LoginModalClick={LoginModalClick}
                />
              );
            }
          })}
      </div>

      {total > perPage && (
        <div className="pagination-tourlist">
          <Pagination
            itemClass="page-item-custom"
            activeLinkClass="activepage"
            linkClass="page-link-custom"
            linkClassFirst="page-first-arrow"
            linkClassPrev="page-first-arrow arrow-previous-custom"
            linkClassNext="page-first-arrow arrow-next-custom"
            linkClassLast="page-first-arrow"
            prevPageText={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 15.5195L7 10.5195L13 5.51953"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            firstPageText={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.5195L4.5 10.5195L10.5 5.51953"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.5 15.5195L9.5 10.5195L15.5 5.51953"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            // firstPageText={<><img src="./img/doublePolygon.svg" /></>}
            // lastPageText={<><img src='./img/DublePolygonRight.svg' /></>}
            nextPageText={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 15.5195L13 10.5195L7 5.51953"
                  stroke="#42B6E6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            lastPageText={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 5.51953L15.5 10.5195L9.5 15.5195"
                  stroke="#42B6E6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.5 5.51953L10.5 10.5195L4.5 15.5195"
                  stroke="#42B6E6"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            activePage={page}
            itemsCountPerPage={perPage}
            pageRangeDisplayed={10}
            totalItemsCount={total}
            onChange={(e) => {
              setPage(e);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          />
        </div>
      )}
      {show && (
        <HostPro
          hostingId={hostingId}
          show={show}
          setShow={setShow}
          data={data}
        ></HostPro>
      )}
    </div>
  );
};

export default TourList;

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Rating from "react-rating";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AuthStorage from "../../helper/AuthStorage";
import ReadMore from "../../components/ReadMore";
import TextareaAutosize from "react-textarea-autosize";
import { ApiPost } from "../../helper/API/ApiData";
import { RootStateOrAny, useSelector } from "react-redux";
import ReviewPopup from "../modal/ReviewPopup";
import Comments from "./Comments";
import AccountDeactive from "../modal/AccountDeactive";
import { Button, Modal } from "react-bootstrap";
import Login from "../modal/Login";
import { Footer } from "layout/footer/Footer";

const initialComment = {
  itinerary: "",
  message: "",
  position: 0,
  parent_comment: "",
};

const initialReplay = {
  itinerary: "",
  message: "",
  position: 0,
  parent_comment: "",
};

function Reviews(props) {
  const [stars, setStars] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [reviewPopup, setReviewPopup] = useState(false);
  const [comment, setComment] = useState(initialComment);
  const [replay, setReplay] = useState(initialReplay);
  const { t } = useTranslation();
  const { is_loggedin } = useSelector((state) => state?.login);
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const { userData } = useSelector((state) => state.userData);
  const [reviewNot, setReviewNot] = useState(false);
  const [reviewCount, setReviewCount] = useState(3);
  const [modalShow, setModalShow] = useState(false);
  const is_active = AuthStorage.isUserActive();
  const [alredyReviewed, setAlredyReviewed] = useState(false);
  const [noComment, setNoComment] = useState(false);

  useEffect(() => {
    if (props.itinerary_id) {
      setComment({ ...comment, itinerary: props.itinerary_id });
      setReplay({ ...replay, itinerary: props.itinerary_id });
    }
  }, [props.itinerary_id]);

  // useEffect(() => {
  //   console.log(replay);
  // }, [replay]);

  const changeDateType = (date) => {
    const x = new Date(date);
    const y = x.getMonth();
    const z = x.getFullYear().toString();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // return month[y - 1] + " " + z
    return moment(x).locale(AuthStorage.getLang()).format("MMMM YYYY");
  };

  const Review = (rating) => {
    if (rating >= 0 && rating <= 1) {
      return `${t("Wasn't_Good")}`;
    } else if (rating > 1 && rating <= 2) {
      return `${t("Disappointed")}`;
    } else if (rating > 2 && rating <= 3) {
      return `${t("Decent")}`;
    } else if (rating > 3 && rating <= 4) {
      return `${t("Satisfied")}`;
    } else {
      return `${t("Very_Satisfied")}`;
    }
  };

  const [showComments, setShowComments] = useState(true);
  const [showReview, setShowReview] = useState(false);
  const [moreComment, setMoreComment] = useState(10);
  const [moreReview, setMoreReview] = useState(3);

  const openCommentSection = () => {
    setShowComments(true);
    setShowReview(false);
  };

  const openReviewSection = () => {
    setShowReview(true);
    setShowComments(false);
  };

  const postComment = () => {
    ApiPost("comment", comment)
      .then(() => {
        setComment((prev) => ({
          ...prev,
          message: "",
          parent_comment: "",
          position: 0,
        }));
        props.setRefreshComment(Math.random());
      })
      .catch((error) => {
        console.error(error);
        // ui  디자인 나오면 수정 필요

        setNoComment(true);
      });
  };

  const sendReviews = async () => {
    await ApiPost("hosting/makeReview", {
      star: stars,
      content: feedback,
      itinerary_id: props.itinerary_id,
    }).then((res) => {
      setReviewPopup(false);
      setStars(0);
      setFeedback("");
      setReviewNot(true);
      props.setRefreshReview(Math.random());
    });
  };

  useEffect(() => {
    function handleResize() {
      if (window.screen.availWidth >= 310 && window.screen.availWidth <= 428) {
        setReviewCount(3);
      } else {
        setReviewCount(
          props?.reviews?.recentReview
            ? props?.reviews?.recentReview?.length
            : 3
        );
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (window.screen.availWidth >= 310 && window.screen.availWidth <= 428) {
      setReviewCount(3);
    } else {
      setReviewCount(
        props?.reviews?.recentReview ? props?.reviews?.recentReview?.length : 3
      );
    }
  }, [props?.reviews?.recentReview?.length]);

  const isUserAlreadyReviewed =
    props?.reviews?.recentReview?.filter(
      (review) => review?.user?.id == userData.id
    ).length != 0;

  // console.log(isUserAlreadyReviewed);
  // console.log(userData.id, "Login User Id");

  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {props.reviews && (
        <>
          <div className="space-top-scroll" id="comments"></div>
          <div className="total-review-about">
            <div
              className={
                props.creator === "Compastrips"
                  ? "review-tab d-flex "
                  : "review-tab d-flex only-for-host-review"
              }
            >
              {/* <div className="review-title"> */}
              <h3
                className={
                  showComments && props.creator === "Compastrips"
                    ? "title-active"
                    : "title-deactive"
                }
                onClick={openCommentSection}
              >
                {t("TourList.Comments")}
                <span>&nbsp;({props.commentData?.count})</span>
              </h3>
              {props.creator === "Compastrips" && (
                <h3
                  className={showReview ? "title-active" : "title-deactive"}
                  onClick={openReviewSection}
                >
                  {" "}
                  {t("Itinerary_Desc.ReviewsTwo")}{" "}
                  <span>
                    (
                    {props.reviews?.recentReview &&
                      props.reviews?.recentReview.length}
                    )
                  </span>
                </h3>
              )}
            </div>

            {showComments && (
              <div className="comment-inner">
                <TextareaAutosize
                  minRows={2.56}
                  maxRows={6}
                  placeholder={t("Comment.Write_A_Comment")}
                  className="comment-write"
                  value={comment.message}
                  onChange={(e) =>
                    setComment({ ...comment, message: e.target.value })
                  }
                  resize="none"
                />
                <div className="d-flex justify-content-end comment-btn">
                  <Button
                    variant="confirmBtn"
                    className="post-comment-btn"
                    onClick={() => {
                      if (is_loggedin) {
                        if (is_active) {
                          postComment();
                        } else {
                          setComment({ ...comment, message: "" });
                          setDeactivatedNotice(true);
                        }
                      } else {
                        setModalShow(true);
                      }
                    }}
                  >
                    {t("Comment.Post")}
                  </Button>
                </div>
                {props.commentData.comments?.map(
                  (data, i) =>
                    i < moreComment && (
                      <Comments
                        key={i}
                        itinerary_id={props.itinerary_id}
                        commentData={data}
                        setRefreshComment={props.setRefreshComment}
                        setNoComment={setNoComment}
                      />
                    )
                )}
                {props.commentData.comments?.length > moreComment ? (
                  <div className="showBtn d-flex justify-content-end showBtn-last">
                    <Button
                      className="moreComment"
                      variant="outline-black"
                      onClick={() => {
                        setMoreComment(moreComment + 10);
                      }}
                    >
                      {t("Comment.More")}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 7L10 13L5 7"
                          stroke="#1b1c13"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Button>
                  </div>
                ) : (
                  props.commentData.comments?.length > 10 && (
                    <div className="showBtn d-flex justify-content-end showBtn-last">
                      <Button
                        className="lessComment"
                        variant="outline-black"
                        onClick={() => {
                          setMoreComment(10);
                        }}
                      >
                        {t("Comment.Less")}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 7L10 13L5 7"
                            stroke="#1b1c13"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Button>
                    </div>
                  )
                )}
              </div>
            )}

            {showReview && (
              <>
                {/* <h4 className="review-counting">
                  {t("Itinerary_Desc.Reviews")}
                  <span className="font-red">
                    {" "}
                    &nbsp;
                    {props.reviews?.recentReview &&
                      props.reviews?.recentReview.length}
                  </span>
                </h4> */}
                <div className="d-flex align-items-center total-rating-with-star">
                  <h4 className="total-per-rating">
                    {props.reviews.star !== "NaN" ? props.reviews.star : "0.0"}
                  </h4>
                  <div
                    className="tour-rating cursor-p"
                    onClick={() => {
                      // applicationAccepted ?

                      // if (is_loggedin && props.creator === "Compastrips") {
                      if (is_active) {
                        {
                          isUserAlreadyReviewed && is_loggedin
                            ? setAlredyReviewed(true)
                            : setReviewPopup(true);
                        }
                      } else {
                        is_loggedin
                          ? setDeactivatedNotice(true)
                          : setModalShow(true);
                      }
                      // }
                    }}
                  >
                    {/* <img src="./img/color-star.svg" alt="" />
                                <img src="./img/color-star.svg" alt="" />
                                <img src="./img/color-star.svg" alt="" />
                                <img src="./img/color-star.svg" alt="" />
                                <img src="./img/star.svg" alt="" /> */}

                    <Rating
                      emptySymbol={
                        <img
                          src="./img/star.svg"
                          className="starimage"
                          alt=""
                        />
                      }
                      fullSymbol={
                        <img
                          src="./img/colorstar.svg"
                          className="colorstarimage"
                          alt=""
                        />
                      }
                      initialRating={props.reviews.star}
                      readonly={true}
                      stop={5}
                    />
                  </div>
                </div>

                <div className="host-review-row">
                  {resize <= 768
                    ? props.reviews.recentReview &&
                      props.reviews.recentReview.map(
                        (review, key) =>
                          key < moreReview && (
                            <div className="single-host-review" key={review.id}>
                              <div className="user-review-info align-items-center">
                                <div className="user-img circle">
                                  {review.user.avatar ? (
                                    <img
                                      src={review.user.avatar}
                                      alt="avatar"
                                    />
                                  ) : (
                                    <img src="/img/Avatar.png" alt="avatar" />
                                  )}
                                </div>
                                <div className="review-date-name">
                                  <h4 className="user-name">
                                    {review.user.user_name}
                                  </h4>
                                  <h5 className="review-time">
                                    {changeDateType(review.updated_at)}
                                  </h5>
                                </div>
                              </div>
                              <div className="rating-with-single align-items-center">
                                {/* <img src="./img/color-star.svg" alt="" /> */}
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8285C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                                    fill="#FF5F5F"
                                  />
                                </svg>

                                <h5>
                                  {review.star}.0 {Review(review.star)}
                                </h5>
                              </div>
                              {/* <div className='readmore-content'> */}
                              {/* <ReadMore> {review.content} </ReadMore> */}
                              {/* </div> */}
                              <ReadMore children={review.content} />
                            </div>
                            // <>
                            //   {key < reviewCount ? (
                            //   ) : (
                            //     ""
                            //   )}
                            // </>
                          )
                      )
                    : props.reviews.recentReview &&
                      props.reviews.recentReview.map((review, key) => (
                        <>
                          {key < reviewCount ? (
                            <div className="single-host-review" key={review.id}>
                              <div className="user-review-info align-items-center">
                                <div className="user-img circle">
                                  {review.user.avatar ? (
                                    <img
                                      src={review.user.avatar}
                                      alt="avatar"
                                    />
                                  ) : (
                                    <img src="/img/Avatar.png" alt="avatar" />
                                  )}
                                </div>
                                <div className="review-date-name">
                                  <h4 className="user-name">
                                    {review.user.user_name}
                                  </h4>
                                  <h5 className="review-time">
                                    {changeDateType(review.updated_at)}
                                  </h5>
                                </div>
                              </div>
                              <div className="rating-with-single align-items-center">
                                {/* <img src="./img/color-star.svg" alt="" /> */}
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8285C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                                    fill="#FF5F5F"
                                  />
                                </svg>

                                <h5>
                                  {review.star}.0 {Review(review.star)}
                                </h5>
                              </div>
                              {/* <div className='readmore-content'> */}
                              {/* <ReadMore> {review.content} </ReadMore> */}
                              {/* </div> */}
                              <ReadMore children={review.content} />
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  {resize <= 768 &&
                    (props.reviews.recentReview.length > moreReview ? (
                      <div className="res-more w-100">
                        <Button
                          variant="cancelBtn"
                          className="w-100"
                          onClick={() =>
                            props.reviews.recentReview &&
                            props.reviews.recentReview.length >= reviewCount
                              ? setMoreReview((prev) => prev + 3)
                              : ""
                          }
                        >
                          More
                        </Button>
                      </div>
                    ) : null)}
                </div>
              </>
            )}
          </div>
        </>
      )}

      <Modal
        show={reviewNot}
        onHide={() => {
          setReviewNot(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Review_Notice.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="font-24-normal color-darkgray h-60">
              {t("Review_Notice.Body")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              setReviewNot(false);
            }}
          >
            {t("Review_Notice.OK")}
          </Button>
        </Modal.Footer>
        {/* <div className="w-100 mt-50">
          <div
            className={
              AuthStorage.getLang() === "en"
                ? "accept-noticebtnbox w-100"
                : "accept-noticebtnboxko w-100"
            }
          ></div>
        </div> */}
      </Modal>
      <Modal
        show={noComment}
        onHide={() => {
          setNoComment(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Comments_Notice.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="font-24-normal color-darkgray h-60">
              {t("Comments_Notice.ErrorBody")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              setNoComment(false);
            }}
          >
            {t("Review_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={alredyReviewed}
        onHide={() => {}}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Review_Notice.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{ __html: t("Review_Notice.ErrorBody") }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            onClick={() => {
              setAlredyReviewed(false);
            }}
          >
            {t("Review_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ReviewPopup
        stars={stars}
        setStars={setStars}
        feedback={feedback}
        setFeedback={setFeedback}
        show={reviewPopup}
        onHide={() => {
          setReviewPopup(false);
        }}
        onSubmit={sendReviews}
      />
      <Login
        show={modalShow}
        loginValue=""
        onHide={() => setModalShow(false)}
        onHideNew=""
        onShow=""
      />
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </>
  );
}

export default Reviews;

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import firebase from "firebase";
import { db } from "../firebaseConfig";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setChatState, setMessageState } from "../redux/action/chatDataAction";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { dateFunction } from "../helper/utils";
import AuthStorage from "../helper/AuthStorage";
import { setReadOnly } from "../redux/action/chatDataAction";

const Message = ({ id, data }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);

  const { message_open, readOnly } = useSelector((state) => state.chatData);

  const inputFocus = useRef();
  const { userData } = useSelector((state) => state.userData);

  const dispatch = useDispatch();

  const sendMessage = async (e) => {
    e.preventDefault();
    if (text.trimStart().length) {
      try {
        let messageData = await db
          .collection(AuthStorage.getMessageCollection())
          .doc(id)
          .collection("messages")
          .add({
            sendBy: userData.id,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            content: text,
          });

        if (messageData) {
          await db
            .collection(AuthStorage.getMessageCollection())
            .doc(id)
            .update({
              [`${userData.id}_count`]:
                firebase.firestore.FieldValue.increment(1),
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              lastMessage: text,
            });
          setText("");
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const getMessages = () => {
    const unsubscribe = db
      .collection(AuthStorage.getMessageCollection())
      .doc(id)
      .collection("messages")
      .orderBy("timestamp", "asc")
      .onSnapshot(
        async (snapshot) => {
          let change = snapshot.docChanges();
          if (change && message_open) {
            try {
              await db
                .collection(AuthStorage.getMessageCollection())
                .doc(id)
                .update({
                  [`${data.id}_count`]: 0,
                });
            } catch (error) {}
          }
          return setMessages(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        },
        (err) => {
          // console.log("Error in getMessages: ", err);
        }
      );
    return unsubscribe;
  };

  const setCount = async () => {
    try {
      await db
        .collection(AuthStorage.getMessageCollection())
        .doc(id)
        .update({
          [`${data.id}_count`]: 0,
        });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const unsubscribe = getMessages();

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setCount();
  }, [message_open]);

  useEffect(() => {
    if (inputFocus.current) {
      inputFocus.current.scrollIntoView();
    }
  }, [messages]);

  const msgOnkeyUp = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      sendMessage(event);
    }
  };

  return (
    <div>
      <Modal
        show={message_open}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text wrap position-relative m-auto allchatmodal"
            : "noto-text wrap position-relative m-auto allchatmodal"
        }
        contentClassName="position-absolute chatModal "
      >
        <Modal.Header className="modal-chat-header d-flex align-items-center justify-content-between">
          <div className="header-left d-flex align-items-center">
            <div className="d-flex align-items-center chat-backgBtn">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                onClick={() => {
                  dispatch(setChatState(true));
                  dispatch(setMessageState(false));
                  dispatch(setReadOnly(!readOnly));
                }}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6.5L8 14L18 21.5"
                  stroke="#1B1C1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {/* <svg
                width="10"
                height="14"
                viewBox="0 0 10 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 13L0.999998 7L9 1"
                  stroke="#1B1C1E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg> */}
            </div>
            <h4 className="user-name">{data?.name}</h4>
          </div>
          <div
            className="header-right d-flex align-items-center"
            style={{ height: "20px", lineHeight: "20px", cursor: "pointer" }}
            onClick={() => {
              dispatch(setMessageState(false));
              dispatch(setReadOnly(!readOnly));
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="20.6992"
                y1="7.75988"
                x2="7.76096"
                y2="20.6981"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <line
                x1="20.5712"
                y1="20.6973"
                x2="7.63292"
                y2="7.75901"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </Modal.Header>
        <Modal.Body className="chat-scroll-two">
          <div className="contentof-chat">
            {messages.map((x, i) => (
              <>
                {moment(
                  new Date(messages[i - 1]?.data?.timestamp?.toDate())
                ).format("YYYY.MM.DD") !==
                moment(new Date(messages[i]?.data?.timestamp?.toDate())).format(
                  "YYYY.MM.DD"
                ) ? (
                  <div className="text-center font-16-normal color-darkgray chat-date">
                    <p>
                      {dateFunction(
                        moment(
                          new Date(
                            messages[i]?.data?.timestamp
                              ?.toDate()
                              .toDateString()
                          )
                        ).toString()
                      )}
                    </p>
                  </div>
                ) : null}
                <div ref={messages.length - 1 === i ? inputFocus : null}>
                  {x.data.sendBy === data.id ? (
                    <div className="d-flex  position-relative mt-20 secUserChat">
                      {messages[i - 1]?.data.sendBy !==
                        messages[i]?.data.sendBy && (
                        <div className="user-imgcha circlet">
                          <img
                            className="objectfit-cover"
                            src={data?.avatar || "./img/Avatar.png"}
                            alt=""
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      )}
                      <div className="single-chat ml-82 w-100">
                        {messages[i - 1]?.data.sendBy !==
                          messages[i]?.data.sendBy && (
                          <h3 className="font-blue">{data?.name}</h3>
                        )}
                        <div className="d-flex align-items-end UserMainChat">
                          <div className="secChatHead">
                            <p className="mb-0 mt-21 content-of-msg-two">
                              {x.data.content}
                            </p>
                          </div>
                          <>
                            <p className="font-12-normal white-space color-darkgray ml-30 custom-date-chat-two">
                              {moment(
                                new Date(x.data?.timestamp?.toDate())
                              ).format("YYYY.MM.DD HH:mm")}
                            </p>
                          </>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex mt-20 position-relative">
                      <div className="single-chat  w-100">
                        <div className="d-flex justify-content-end align-items-end userSendMsg">
                          <p className="font-12-normal color-darkgray white-space mr-30 custom-date-chat">
                            {moment(
                              new Date(x.data?.timestamp?.toDate())
                            ).format("YYYY.MM.DD HH:mm")}
                          </p>
                          <div className="chateContentMsg">
                            <p
                              className="mb-0 mt-21 content-of-msg "
                              dangerouslySetInnerHTML={{
                                __html: x?.data.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </Modal.Body>

        {!readOnly ? (
          <Modal.Footer className="submit-complet">
            <form
              className="submit-chat bg-white d-flex align-items-center"
              onSubmit={sendMessage}
            >
              <TextareaAutosize
                onKeyUp={msgOnkeyUp}
                id="textArea"
                rows={4}
                maxRows={5}
                placeholder={t("Chat.Enter_Message")}
                onChange={(e) => setText(e.target.value)}
                value={text}
                style={{ width: "100%", border: "none", outline: "none" }}
              />
              <button type="submit">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="11.5" stroke="#7E7E7E" />
                  <path
                    d="M9.88273 7.87703L15.3123 11.951L9.88273 16.0249"
                    stroke="#7E7E7E"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </form>
          </Modal.Footer>
        ) : (
          <div className="admin-msg-only">
            <p>{t("Messages_Translate")}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Message;

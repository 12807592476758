export const IS_LOADING = "IS_LOADING";

export const USER_DATA = "USER_DATA";
export const USER_DATA_ERR = "USER_DATA_ERR";
export const USER_NOTIFICATION_TOGGLE = "USER_NOTIFICATION_TOGGLE";

export const CHANGE_LOGIN_STATE = "CHANGE_LOGIN_STATE";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";

export const CHAT_DATA = "CHAT_DATA";
export const MESSAGE_STATE = "MESSAGE_STATE";
export const CHAT_STATE = "CHAT_STATE";
export const SET_OTHER_USER = "SET_OTHER_USER";
export const CHAT_ID = "CHAT_ID";
export const READ_ONLY_CHAT = "READ_ONLY_CHAT";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const CHANGE_GLOBAL_SEARCH = "CHANGE_GLOBAL_SEARCH";
export const CLEAR_GLOBAL_SEARCH = "CLEAR_GLOBAL_SEARCH";

export const PUB_TRENDINGNOW_SEARCH = "PUB_TRENDINGNOW_SEARCH";
export const SUB_TRENDINGNOW_SEARCH = "SUB_TRENDINGNOW_SEARCH";
export const PUB_TRENDINGNOW_UPDATE_LIST = "PUB_TRENDINGNOW_UPDATE_LIST";

export const USER_INFO = "USER_INFO";

import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Nav } from "react-bootstrap";
// import { Row } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import Swal from "sweetalert2";
import STORAGEKEY from "../../config/APP/app.config";
import {
  ApiGetNoAuth,
  ApiPatch,
  ApiPost,
  ApiPostNoAuth,
} from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import { useDispatch } from "react-redux";
import { getUserData } from "../../redux/action/userDataAction";
import { changeLoginState } from "../../redux/action/loginAction";
import { Gender } from "../../helper/utils";
import { DrawingManager } from "@react-google-maps/api";
registerLocale("ko", ko);

function EditProfile() {
  const history = useHistory();
  const dispatch = useDispatch();

  const Data = {
    age_group: "",
    avatar: "",
    dob: "",
    email: "",
    first_name: "",
    gender: "",
    id: "",
    last_name: "",
    mobile: "",
    nationality: "",
    token: "",
    user_name: "",
  };

  const inputErr = {
    password: "",
    confirmPass: "",
    passMatch: "",
    userName: "",
  };
  const inputSucc = {
    confirmPass: "",
    userName: "",
  };

  const [profileData, setProfileData] = useState(Data);
  const [error, setError] = useState(inputErr);
  const [success, setSuccess] = useState(inputSucc);
  const [deleteAcc, setDeleteAcc] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isSubmitedPass, setIsSubmitedPass] = useState(false);
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [oldMobile, setOldMobile] = useState("");
  const [oldCountryCode, setOldCountryCode] = useState("");
  const [vcodeErr, setVcodeErr] = useState(false);
  const [vcode, setVcode] = useState("");
  const [agree, setAgree] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [imgSrc, setImgSrc] = useState("./img/Avatar.png");
  const [cCode, setcCode] = useState({
    label: "",
    value: "",
  });

  const { userData } = useSelector((state) => state.userData);

  useEffect(() => {
    setProfileData(userData);
  }, [userData]);

  useEffect(() => {
    let phone = [];

    if (profileData && profileData.mobile) {
      phone = profileData.mobile.split(" ");
    }

    if (phone.length) {
      setcCode({
        label: `(${phone[0]}) ${profileData.nationality}`,
        value: phone[0],
      });
      setPhoneNumber(phone[phone.length - 1]);
      setOldMobile(phone[phone.length - 1]);
      setOldCountryCode(phone[0]);
    }
  }, [profileData]);

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const inputValidation = () => {
    let Err = {
      password: "",
      confirmPass: "",
      passMatch: "",
      userName: "",
    };

    const validPassword = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,16})");
    if (!validPassword.test(pass)) {
      Err.password = `${t("Edit_Profile.Error.Password")}`;
    }
    if (!confirmPass) {
      Err.confirmPass = `${t("Edit_Profile.Error.ConfirmPass")}`;
    }
    if (pass !== confirmPass) {
      Err.passMatch = `${t("Edit_Profile.Error.PassMatch")}`;
    }

    setError(Err);

    if (!Err.password && !Err.passMatch) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (profileData?.avatar) {
      setImgSrc(profileData?.avatar);
    } else {
      setImgSrc("./img/Avatar.png");
    }
  }, [profileData]);

  //Save Data
  const handleSubmit = () => {
    setIsSubmited(true);
    setShowSave(true);
    setTimeout(() => setShowSave(false), 3000);
    if (oldMobile !== phoneNumber || oldCountryCode !== cCode.value) {
      if (!isVerified) {
        // setVcodeErr(`${t('Edit_Profile.Error.verifyNumber')}`)
        setVcodeErr(true);
        return;
      }
    }

    let formData = new FormData();
    formData.append("user_name", profileData.user_name);
    formData.append("mobile", cCode.value + " " + phoneNumber);

    if (selectedFile) {
      formData.append("avatar", selectedFile);
    }

    ApiPatch("user/auth/edit", formData).then((res) => {
      setVcodeErr(false);
      dispatch(getUserData());
    });
  };

  const [countryCode, setCountryCode] = useState([]);

  const getCountryData = async () => {
    try {
      const res = await ApiGetNoAuth("general/country");
      setCountryCode(
        res.data.map((x) => {
          return {
            value: `${x.code.toString()}`,
            label: `(${x.code.toString()}) ${x.name}`,
          };
        })
      );
    } catch (error) {}
  };

  const [isVerified, setIsVerified] = useState(false);
  const [isOneTimeVerified, setOneTimeIsVerified] = useState(false);
  const [sendVCode, setSendVCode] = useState(false);

  //Send OTP
  const sendOTP = () => {
    setSendVCode(true);
    setShowCountDown(true);
    setStart(true);
    setOtpErr("");
    reset();
    ApiPostNoAuth("user/otp-send", {
      mobile: cCode.value + " " + phoneNumber,
    });
  };

  //Mobile Number Verification
  const mobileVerification = () => {
    setIncorrectOTP(false);
    setIncorrectMobile(false);

    ApiPostNoAuth("user/otp-verify", {
      mobile: cCode.value + " " + phoneNumber,
      code: vcode,
    })
      .then((res) => {
        setIsVerified(true);
        setOneTimeIsVerified(true);
        setVcodeErr(false);
        setOver(true);
        setShowCountDown(false);
        setSendVCode(false);
        setIncorrectOTP(false);
        setIncorrectMobile(false);
        setOldMobile(phoneNumber);
        setOldCountryCode(cCode.value);
      })
      .catch((error) => {
        if (error === "Incorrect OTP") {
          setIncorrectOTP(true);
        }

        if (error === "Incorrect Mobile Number") {
          setIncorrectMobile(true);
        }
      });
  };

  useEffect(() => {
    if (isOneTimeVerified) {
      setIsVerified(false);
    }
  }, [phoneNumber, cCode]);

  //Count Down Timer
  const [start, setStart] = useState(false);
  const [otpErr, setOtpErr] = useState("");
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [incorrectMobile, setIncorrectMobile] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([parseInt("3"), parseInt("0")]);

  const tick = () => {
    if (over) return;
    if (m === 0 && s === 0) {
      setOver(true);
      setOtpErr(`${t("Edit_Profile.Time_excede")}`);
    } else if (s === 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt("3"), parseInt("0")]);
    setOver(false);
  };

  useEffect(() => {
    if (start) {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    }
  });
  //------------

  useEffect(() => {
    getCountryData();
  }, []);

  //Reset Password
  const resetPass = () => {
    setIsSubmitedPass(true);
    if (!inputValidation()) {
      return;
    }

    ApiPost("user/auth/reset", {
      new_password: pass,
    }).then((res) => {
      Swal.fire({
        position: "top",
        icon: "success",
        title: "Password reset successfully",
      });
    });
  };

  //Delete Account
  const deleteAccc = () => {
    ApiPatch("user/auth/delete", null).then((res) => {
      if (AuthStorage.getToken() === localStorage.getItem(STORAGEKEY.token)) {
        localStorage.removeItem(STORAGEKEY.token);
        localStorage.removeItem(STORAGEKEY.userData);
      } else {
        sessionStorage.removeItem(STORAGEKEY.token);
        sessionStorage.removeItem(STORAGEKEY.userData);
      }
      dispatch(changeLoginState(false));
      history.push("/");
    });
  };

  useEffect(() => {
    if (!selectedFile) {
      setImgSrc("./img/Avatar.png");
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);

    setImgSrc(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (isSubmitedPass) {
      inputValidation();
    }
  }, [pass, confirmPass]);

  //For Language Translation
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main id="edit-profile-page" className="wrap">
        <h1 className="page-title">{t("Edit_Profile.Edit_Profile")}</h1>
        {/* <h4 className="basic-info">{t("Edit_Profile.Profile")}</h4> */}
        <form className="user-info">
          <div className="d-flex justify-content-between">
            <div className="user-profile-area position-relative">
              <div className="host-img circle">
                <img
                  src={imgSrc}
                  alt="Profile"
                  className="upload-profile-img"
                  style={{
                    width: "110px",
                    height: "110px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <input
                name=""
                value=""
                type="file"
                id="actual-btn"
                className="customInput"
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    setSelectedFile(undefined);
                    return;
                  }
                  setSelectedFile(e.target.files[0]);
                }}
              />
              <label htmlFor="actual-btn" className="ChooseBtn">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="20" cy="20" r="20" fill="#42B6E6" />
                  <path
                    d="M16.4875 11.7572L17.4159 12.1286V12.1286L16.4875 11.7572ZM15.9732 13.043V14.043H16.6502L16.9016 13.4144L15.9732 13.043ZM24.3607 13.043L23.4322 13.4144L23.6836 14.043H24.3607V13.043ZM23.8464 11.7572L24.7748 11.3858V11.3858L23.8464 11.7572ZM17.4159 12.1286C17.5678 11.749 17.9355 11.5 18.3444 11.5V9.5C17.1177 9.5 16.0146 10.2469 15.559 11.3858L17.4159 12.1286ZM16.9016 13.4144L17.4159 12.1286L15.559 11.3858L15.0447 12.6716L16.9016 13.4144ZM15.9732 12.043H11.501V14.043H15.9732V12.043ZM11.501 12.043C10.1203 12.043 9.00098 13.1623 9.00098 14.543H11.001C11.001 14.2668 11.2248 14.043 11.501 14.043V12.043ZM9.00098 14.543V26.7905H11.001V14.543H9.00098ZM9.00098 26.7905C9.00098 28.1712 10.1203 29.2905 11.501 29.2905V27.2905C11.2248 27.2905 11.001 27.0666 11.001 26.7905H9.00098ZM11.501 29.2905H28.831V27.2905H11.501V29.2905ZM28.831 29.2905C30.2117 29.2905 31.331 28.1712 31.331 26.7905H29.331C29.331 27.0666 29.1071 27.2905 28.831 27.2905V29.2905ZM31.331 26.7905V14.543H29.331V26.7905H31.331ZM31.331 14.543C31.331 13.1623 30.2117 12.043 28.831 12.043V14.043C29.1071 14.043 29.331 14.2668 29.331 14.543H31.331ZM28.831 12.043H24.3607V14.043H28.831V12.043ZM22.9179 12.1286L23.4322 13.4144L25.2891 12.6716L24.7748 11.3858L22.9179 12.1286ZM21.9894 11.5C22.3983 11.5 22.766 11.749 22.9179 12.1286L24.7748 11.3858C24.3193 10.2469 23.2161 9.5 21.9894 9.5V11.5ZM18.3444 11.5H21.9894V9.5H18.3444V11.5ZM20.2602 24.8427C22.6546 24.8427 24.5956 22.9016 24.5956 20.5073H22.5956C22.5956 21.7971 21.55 22.8427 20.2602 22.8427V24.8427ZM15.9248 20.5073C15.9248 22.9016 17.8658 24.8427 20.2602 24.8427V22.8427C18.9704 22.8427 17.9248 21.7971 17.9248 20.5073H15.9248ZM20.2602 16.1719C17.8658 16.1719 15.9248 18.1129 15.9248 20.5073H17.9248C17.9248 19.2175 18.9704 18.1719 20.2602 18.1719V16.1719ZM24.5956 20.5073C24.5956 18.1129 22.6546 16.1719 20.2602 16.1719V18.1719C21.55 18.1719 22.5956 19.2175 22.5956 20.5073H24.5956Z"
                    fill="white"
                  />
                </svg>
              </label>
            </div>
          </div>
          <div className="basic-info-area pc-info">
            <div className="left">
              <div className="input-field">
                <label htmlFor="first_name">
                  <h3 className="adm-body-bold">{t("Edit_Profile.Name")}</h3>
                </label>
                <input
                  id="first_name"
                  value={profileData?.first_name + " " + profileData?.last_name}
                  name="first_name"
                  onChange={(e) => {
                    handleChange(e, "first_name");
                  }}
                  disabled
                  type="text"
                />
              </div>
              <div className="input-field">
                <label htmlFor="email">
                  <h3 className="adm-body-bold">{t("Edit_Profile.Email")}</h3>
                </label>
                <input
                  id="email"
                  name="email"
                  value={profileData?.email}
                  disabled
                />
              </div>
              <div className="input-field">
                <label htmlFor="">
                  <h3 className="adm-body-bold">
                    {t("Edit_Profile.Nationality")}
                  </h3>
                </label>
                <input
                  name="nationality"
                  value={profileData?.nationality}
                  type="text"
                  placeholder="Nationality"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  disabled
                  className="NationalityInput"
                />
              </div>
              <div className="input-field">
                <label htmlFor="">
                  <h3 className="adm-body-bold">{t("Edit_Profile.DOB")}</h3>
                </label>
                <input
                  name="dob"
                  dateFormat="YYYY.MM.DD"
                  locale={AuthStorage.getLang()}
                  value={profileData?.dob}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  disabled
                  className="EditDate"
                />
              </div>
            </div>
            <div className="right">
              <div className="input-field position-relative">
                <label htmlFor="">
                  <h3 className="adm-body-bold">
                    {t("Edit_Profile.Username")}
                  </h3>
                  <svg
                    className="essential"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                      fill="#FF5F5F"
                    />
                  </svg>
                </label>
                <input
                  name="user_name"
                  value={profileData?.user_name}
                  type="text"
                  placeholder="Username"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  disabled
                />
                {/* <div className="d-flex">
                  <Button
                    variant="confirmBtn formBtn"
                    className="disabledBtn"
                    onClick={() => {
                      // setUserNameVerifySubmit(true);
                      ApiPost("user/username-verify", {
                        username: profileData.user_name,
                      })
                        .then((res) => {
                          setSuccess({
                            ...success,
                            userName: t("signUp.Errors.UserNameVerifySuccess"),
                          });
                          setError({
                            ...error,
                            userName: "",
                          });
                        })
                        .catch((err) => {
                          setError({
                            ...error,
                            userName: t("signUp.Errors.UserNameVerify"),
                          });
                          setSuccess({
                            ...success,
                            userName: "",
                          });
                          console.log("error already use user name");
                          console.log(profileData.user_name);
                          console.log(error.userName);
                        });
                    }}
                    dangerouslySetInnerHTML={{
                      __html: t("signUp.DuplicateCheck"),
                    }}
                    disabled
                  />
                </div> */}
                {error.userName && (
                  <p className="text-danger">{error.userName}</p>
                )}
                {success.userName && (
                  <p className="text-danger font-blue">{success.userName}</p>
                )}
              </div>
              <div className="input-field position-relative">
                <label htmlFor="">
                  <h3 className="adm-body-bold">
                    {t("Edit_Profile.Password")}
                  </h3>
                  <svg
                    className="essential"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                      fill="#FF5F5F"
                    />
                  </svg>
                </label>
                <input
                  name="password"
                  placeholder={t("Edit_Profile.Placeholder.Password")}
                  type="password"
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                />
                {error.password && (
                  <p className="text-danger position-absolute text mt-1">
                    {t("Edit_Profile.Error.Password")}
                  </p>
                )}
              </div>
              <div className="input-field position-relative">
                <label htmlFor="">
                  <h3 className="adm-body-bold">{t("Edit_Profile.Confirm")}</h3>
                  <svg
                    className="essential"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                      fill="#FF5F5F"
                    />
                  </svg>
                </label>
                <div className="d-flex">
                  <input
                    name="password2"
                    InputstyleClass="pw2"
                    type="password"
                    placeholder={t("Edit_Profile.Placeholder.ConfirmPass")}
                    onChange={(e) => {
                      setConfirmPass(e.target.value);
                    }}
                  />

                  <Button variant="confirmBtn formBtn" onClick={resetPass}>
                    {" "}
                    {t("Edit_Profile.Reset")}
                  </Button>
                </div>
                {error.confirmPass && (
                  <p className="text-danger">
                    {t("Edit_Profile.Error.ConfirmPass")}
                  </p>
                )}
                {!error.confirmPass && error.passMatch && (
                  <p className="text-danger">
                    {t("Edit_Profile.Error.PassMatch")}
                  </p>
                )}
              </div>
              <div className="input-field">
                <label htmlFor="">
                  <h3 className="adm-body-bold">{t("Edit_Profile.Gender")}</h3>
                </label>
                <input
                  name="gender"
                  value={
                    profileData?.gender === "MALE" ? t("Male") : t("Female")
                  }
                  type="text"
                  placeholder="Gender"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  disabled
                  className="userGender"
                />
              </div>
              <div className="input-field">
                <label htmlFor="">
                  <h3 className="adm-body-bold">
                    {t("Edit_Profile.Phone_number")}
                  </h3>
                  <svg
                    className="essential"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                      fill="#FF5F5F"
                    />
                  </svg>
                </label>
                <div className="d-flex">
                  <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        height: "50px",
                        borderColor: "orange",
                        boxShadow: state.isFocused ? 0 : 0,
                        borderColor: "#dcdcdc",
                        "&:hover": {
                          borderColor: "#dcdcdc",
                        },
                        display: "flex",
                        alignItems: "center",
                      }),

                      singleValue: () => ({
                        height: "50px",
                        lineHeight: "50px",
                        position: "absolute",
                      }),
                      input: () => ({
                        height: "50px",
                        lineHeight: "50px",
                      }),
                      indicatorsContainer: () => ({
                        height: "fit-content",
                      }),
                    }}
                    className="contry-code-profile"
                    options={countryCode}
                    value={cCode}
                    name="countryCode"
                    placeholder="Country Code"
                    onChange={(e) => {
                      setcCode({
                        label: e.label,
                        value: e.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    name="phoneNumber"
                    value={phoneNumber}
                    placeholder="Phone Number"
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    maxLength={10}
                    className="userMobileNum"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info-area mobile-info">
            <div className="input-field">
              <label htmlFor="first_name">
                <h3 className="adm-body-bold">{t("Edit_Profile.Name")}</h3>
              </label>
              <input
                id="first_name"
                value={profileData?.first_name + " " + profileData?.last_name}
                name="first_name"
                onChange={(e) => {
                  handleChange(e, "first_name");
                }}
                disabled
                type="text"
              />
            </div>
            <div className="input-field position-relative">
              <label htmlFor="">
                <h3 className="adm-body-bold">{t("Edit_Profile.Username")}</h3>
                <svg
                  className="essential"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                    fill="#FF5F5F"
                  />
                </svg>
              </label>
              <input
                name="user_name"
                value={profileData?.user_name}
                type="text"
                placeholder="Username"
                onChange={(e) => {
                  handleChange(e);
                }}
                disabled
              />
              {/* <div className="d-flex">
                  <Button
                    variant="confirmBtn formBtn"
                    className="disabledBtn"
                    onClick={() => {
                      // setUserNameVerifySubmit(true);
                      ApiPost("user/username-verify", {
                        username: profileData.user_name,
                      })
                        .then((res) => {
                          console.log("username ", res);
                          setSuccess({
                            ...success,
                            userName: t("signUp.Errors.UserNameVerifySuccess"),
                          });
                          console.log("username ", res);
                          setError({
                            ...error,
                            userName: "",
                          });
                          console.log(profileData.user_name);
                          console.log(success.userName);
                        })
                        .catch((err) => {
                          setError({
                            ...error,
                            userName: t("signUp.Errors.UserNameVerify"),
                          });
                          setSuccess({
                            ...success,
                            userName: "",
                          });
                          console.log("error already use user name");
                          console.log(profileData.user_name);
                          console.log(error.userName);
                        });
                    }}
                    dangerouslySetInnerHTML={{
                      __html: t("signUp.DuplicateCheck"),
                    }}
                    disabled
                  />
                </div> */}
              {error.userName && (
                <p className="text-danger">{error.userName}</p>
              )}
              {success.userName && (
                <p className="text-danger font-blue">{success.userName}</p>
              )}
            </div>
            <div className="input-field">
              <label htmlFor="email">
                <h3 className="adm-body-bold">{t("Edit_Profile.Email")}</h3>
              </label>
              <input
                id="email"
                name="email"
                value={profileData?.email}
                disabled
              />
            </div>
            <div className="input-field position-relative">
              <label htmlFor="">
                <h3 className="adm-body-bold">{t("Edit_Profile.Password")}</h3>
                <svg
                  className="essential"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                    fill="#FF5F5F"
                  />
                </svg>
              </label>
              <input
                name="password"
                placeholder={t("Edit_Profile.Placeholder.Password")}
                type="password"
                onChange={(e) => {
                  setPass(e.target.value);
                }}
              />
              {error.password && (
                <p className="text-danger position-absolute text mt-1">
                  {t("Edit_Profile.Error.Password")}
                </p>
              )}
            </div>
            <div className="input-field position-relative">
              <label htmlFor="">
                <h3 className="adm-body-bold">{t("Edit_Profile.Confirm")}</h3>
                <svg
                  className="essential"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                    fill="#FF5F5F"
                  />
                </svg>
              </label>
              <div className="d-flex">
                <input
                  name="password2"
                  InputstyleClass="pw2"
                  type="password"
                  placeholder={t("Edit_Profile.Placeholder.ConfirmPass")}
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                />

                <Button variant="confirmBtn formBtn" onClick={resetPass}>
                  {" "}
                  {t("Edit_Profile.Reset")}
                </Button>
              </div>
              {error.confirmPass && (
                <p className="text-danger">
                  {t("Edit_Profile.Error.ConfirmPass")}
                </p>
              )}
              {!error.confirmPass && error.passMatch && (
                <p className="text-danger">
                  {t("Edit_Profile.Error.PassMatch")}
                </p>
              )}
            </div>
            <div className="input-field">
              <label htmlFor="">
                <h3 className="adm-body-bold">
                  {t("Edit_Profile.Nationality")}
                </h3>
              </label>
              <input
                name="nationality"
                value={profileData?.nationality}
                type="text"
                placeholder="Nationality"
                onChange={(e) => {
                  handleChange(e);
                }}
                disabled
                className="NationalityInput"
              />
            </div>
            <div className="input-field">
              <label htmlFor="">
                <h3 className="adm-body-bold">{t("Edit_Profile.Gender")}</h3>
              </label>
              <input
                name="gender"
                value={profileData?.gender === "MALE" ? t("Male") : t("Female")}
                type="text"
                placeholder="Gender"
                onChange={(e) => {
                  handleChange(e);
                }}
                disabled
                className="userGender"
              />
            </div>
            <div className="input-field">
              <label htmlFor="">
                <h3 className="adm-body-bold">{t("Edit_Profile.DOB")}</h3>
              </label>
              <input
                name="dob"
                dateFormat="YYYY.MM.DD"
                locale={AuthStorage.getLang()}
                value={profileData?.dob}
                onChange={(e) => {
                  handleChange(e);
                }}
                disabled
                className="EditDate"
              />
            </div>
            <div className="input-field">
              <label htmlFor="">
                <h3 className="adm-body-bold">
                  {t("Edit_Profile.Phone_number")}
                </h3>
                <svg
                  className="essential"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                    fill="#FF5F5F"
                  />
                </svg>
              </label>
              <div className="d-flex">
                <Select
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      height: "50px",
                      borderColor: "orange",
                      boxShadow: state.isFocused ? 0 : 0,
                      borderColor: "#dcdcdc",
                      "&:hover": {
                        borderColor: "#dcdcdc",
                      },
                      display: "flex",
                      alignItems: "center",
                    }),

                    singleValue: () => ({
                      height: "50px",
                      lineHeight: "50px",
                      position: "absolute",
                    }),
                    input: () => ({
                      height: "50px",
                      lineHeight: "50px",
                    }),
                    indicatorsContainer: () => ({
                      height: "fit-content",
                    }),
                  }}
                  className="contry-code-profile"
                  options={countryCode}
                  value={cCode}
                  name="countryCode"
                  placeholder="Country Code"
                  onChange={(e) => {
                    setcCode({
                      label: e.label,
                      value: e.value,
                    });
                  }}
                />
                <input
                  type="text"
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  maxLength={10}
                  className="userMobileNum"
                />
              </div>
            </div>
          </div>
          <div
            className={
              AuthStorage.getLang() === "en"
                ? "text-right delete-profiletwo"
                : "text-right delete-profiletwo"
            }
          >
            {/* <div className="text-right delete-profile"> */}

            <Nav.Link
              onClick={() => {
                setDeleteAcc(true);
              }}
            >
              {t("Edit_Profile.Delete")}
            </Nav.Link>
          </div>
          <div className="save-btn text-center">
            <Button
              variant="confirmBtn"
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("Edit_Profile.Save")}
            </Button>
          </div>
        </form>

        <Modal
          show={deleteAcc}
          onHide={() => {
            setDeleteAcc(false);
          }}
          dialogClassName={
            AuthStorage.getLang() === "en"
              ? "roboto-text custom-modal"
              : "noto-text custom-modal"
          }
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <h4 className="custom-modal-title">{t("Delete_Acc.Title")}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="body-content">
              <div className="welcome-content del-acc text-center mt-60">
                <div
                  className={
                    AuthStorage.getLang() === "en"
                      ? "delAccinnerText"
                      : "delAccinnerTextko"
                  }
                >
                  <p
                    className={
                      AuthStorage.getLang() === "en"
                        ? "deletebodyen"
                        : "deletebodyko"
                    }
                  >
                    {AuthStorage.getLang() === "en" ? (
                      <> {t("Delete_Acc.Body")}</>
                    ) : (
                      <>
                        {t("Delete_Acc.Body")}
                        <br /> {t("Delete_Acc.Body1")}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <label id="userDelete">
              <input
                type="checkbox"
                name="tremsOfUse"
                id="tremsOfUse"
                value=""
                onChange={(e) => {
                  setAgree(!agree);
                }}
                checked={agree}
                className="tremsCheckbox d-none"
              />
              {agree ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="9" cy="9" r="9" fill="#42B6E6" />
                  <rect
                    x="4.94141"
                    y="6.91406"
                    width="6.58125"
                    height="2.07692"
                    transform="rotate(44.9589 4.94141 6.91406)"
                    fill="white"
                  />
                  <rect
                    width="12.7529"
                    height="2.07692"
                    transform="matrix(-0.707614 0.706599 0.706599 0.707614 15.9473 2.76953)"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="9" cy="9" r="9" fill="#AEAEAE" />
                  <rect
                    x="4.94141"
                    y="6.91406"
                    width="6.58125"
                    height="2.07692"
                    transform="rotate(44.9589 4.94141 6.91406)"
                    fill="white"
                  />
                  <rect
                    width="12.7529"
                    height="2.07692"
                    transform="matrix(-0.707614 0.706599 0.706599 0.707614 15.9473 2.76953)"
                    fill="white"
                  />
                </svg>
              )}
              {t("Delete_Acc.Confirm")}
            </label>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="confirmBtn"
              onClick={() => {
                setDeleteAcc(false);
              }}
            >
              {t("Delete_Acc.Cancel")}
            </Button>
            <Button
              variant="cancelBtn"
              className={agree ? "DeleteBtn" : "DisabledDelete"}
              onClick={() => {
                setDeleteAcc(false);
                deleteAccc();
              }}
              disabled={!agree}
            >
              {t("Delete_Acc.Delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
      {showSave && (
        <Alert variant="primary" className="show-data-save wrap">
          <p>{t("Profile_Save.Body")}</p>
        </Alert>
      )}
    </>
  );
}

export default EditProfile;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthStorage from "../../helper/AuthStorage";

const AlreadyHostModal = ({ hostingNotice, setHostingNotice }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        show={hostingNotice}
        onHide={setHostingNotice}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal warning-demo custom-modal"
            : "noto-text welcome-modal warning-demo custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Host_Own.Already_Hosting.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <span
              dangerouslySetInnerHTML={{
                __html: t("Host_Own.Already_Hosting.T1"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            onClick={setHostingNotice}
          >
            {t("Host_Own.Already_Hosting.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AlreadyHostModal;

import { CHANGE_GLOBAL_SEARCH, CLEAR_GLOBAL_SEARCH } from "../type";

export const setGlobalSearch = (value) => (dispatch) => {
  dispatch({
    type: CHANGE_GLOBAL_SEARCH,
    payload: value,
  });
};

export const clearGlobalSearch = () => (dispatch) => {
  dispatch({
    type: CLEAR_GLOBAL_SEARCH,
  });
};

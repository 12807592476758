import AuthStorage from "helper/AuthStorage";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReadMore = ({ children }, fontStyle) => {
  const text = children;
  const more =
    AuthStorage.getLang() === "en"
      ? text.length > 111
        ? true
        : false
      : text.length > 79
      ? true
      : false;
  const [isReadMore, setIsReadMore] = useState(true);
  const { t } = useTranslation();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="read-more-desk">
      {AuthStorage.getLang() === "en"
        ? isReadMore
          ? text.slice(0, 116)
          : text
        : isReadMore
        ? text.slice(0, 79)
        : text}
      {more && isReadMore ? "..." : ""}&nbsp;
      <span onClick={toggleReadMore} className="readMore">
        {more && (isReadMore ? t("Show.All") : t("Show.Less"))}
      </span>
    </p>
  );
};

export default ReadMore;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import ReadMore from "../components/ReadMore";
import { useTranslation } from "react-i18next";
import { checkImageURL } from "../helper/utils";
import firebase from "firebase";
import { db } from "../firebaseConfig";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatId,
  setMessageState,
  setOtherUserData,
} from "../redux/action/chatDataAction";
import { ApiGet, ApiPost } from "../helper/API/ApiData";
import { useHistory } from "react-router-dom";
import AuthStorage from "../helper/AuthStorage";
import AccountDeactive from "../pages/modal/AccountDeactive";
import Lightbox from "react-image-lightbox";
import TourCard from "pages/user/TourCard";
import { ReactHtmlParser } from "react-html-parser";
import moment from "moment";

const HostPro = ({ hostingId, show, setShow, data }) => {
  const dispatch = useDispatch();
  const { is_loggedin } = useSelector((state) => state.login);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [hosting, setHosting] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [itineraryLike, setItineraryLike] = useState(false);
  const [delayLike, setDelayLike] = useState(false);
  const { userData } = useSelector((state) => state.userData);
  const { chatData, message_open } = useSelector((state) => state.chatData);
  const is_active = AuthStorage.isUserActive();
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const [hostMsgOff, setHostMsgOff] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (hostingId) getHosting(hostingId);
  }, [hostingId]);

  const getCreatorString = (creator, creator_show) => {
    if (creator_show === "User" || creator_show === "Host") {
      // return `<span class="font-green">${creator_show}</span>`;
      return <span>Created by User</span>;
    } else {
      // return `<span class="black-font">${creator_show}</span>`;
      return <span>{creator_show}</span>;
    }
  };

  const Like = (id, content) => {
    if (content === "user") {
      setDelayLike(true);
      setIsLiked(!isLiked);
      ApiPost(`user/wishlist/${id}`, {
        hosting_id: hostingId,
      })
        .then((res) => {
          setDelayLike(false);
        })
        .catch((err) => {});
    } else {
      setDelayLike(true);
      setItineraryLike(!itineraryLike);
      ApiPost(`user/wishlist/${id}`, {
        hosting_id: hostingId,
      })
        .then((res) => {
          setDelayLike(false);
        })
        .catch((err) => {});
    }
  };

  const getHosting = (id) => {
    ApiGet(`hosting/hostByHostingId/${id}`).then((res) => {
      setHosting(res.data);
      setIsLiked(res.data.user.like);
      setDelayLike(false);
      if (
        res.data.itinerary_wishlist.find(
          (item) => item.user_id === userData.id
        ) !== null &&
        res.data.itinerary_wishlist.find(
          (item) => item.user_id === userData.id
        ) !== undefined
      ) {
        setItineraryLike(true);
      } else {
        setItineraryLike(false);
      }
    });
  };

  const createChat = async (current_user, other_user) => {
    let doc_id;
    if (
      !current_user.id &&
      !other_user.id &&
      current_user.id === other_user.id
    ) {
      return;
    }
    if (current_user.id > other_user.id) {
      doc_id = current_user.id + other_user.id;
    } else {
      doc_id = other_user.id + current_user.id;
    }

    if (chatData.find((x) => x.id === doc_id)) {
      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    } else {
      const ref = db.collection(AuthStorage.getMessageCollection()).doc(doc_id);
      await ref.set(
        {
          [`${current_user.id}_count`]: 0,
          [`${other_user.id}_count`]: 0,
          lastMessage: "",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ids: [current_user.id, other_user.id],
          [`${current_user.id}`]: {
            id: current_user.id,
            name: current_user.name,
            profile_url: current_user.profile_url,
          },
          [`${other_user.id}`]: {
            id: other_user.id,
            name: other_user.name,
            profile_url: other_user.profile_url,
          },
        },
        { merge: true }
      );

      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    }
  };

  //for translation
  const { t } = useTranslation();

  const onmsgClick = () => {
    setShow(false);
    createChat(
      {
        id: userData.id,
        name: userData.user_name,
        profile_url: userData.avatar,
      },
      {
        id: hosting?.user.id ?? "",
        name: hosting?.user.user_name ?? "",
        profile_url: hosting?.user.avatar ?? "",
      }
    );
  };

  const handleClick = (itinerary_id, hostingID) => {
    window.open(
      `/itinerary?id=${itinerary_id}&hostingId=${hostingID}`,
      "_blank"
    );
  };

  const openImageSection = () => {
    setIsOpenImage(true);
  };

  // console.log("결과", hosting);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal host-modal-pro"
            : "noto-text custom-modal host-modal-pro"
        }
        centered
      >
        <Modal.Header className="position-relative">
          <h3 className="custom-modal-title">
            {t("Host_Profile_Popup.Title")}
          </h3>
          <Button
            variant="transparentBtn"
            className="pcClose position-absolute right-close-btn"
            onClick={() => {
              setShow(false);
            }}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="20.6973"
                y1="7.75988"
                x2="7.75901"
                y2="20.6981"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <line
                x1="20.5702"
                y1="20.6992"
                x2="7.63194"
                y2="7.76096"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {hosting && (
            <>
              <div className="host-info d-flex w-100  justify-content-between align-items-center">
                <div className="user-local-host d-flex align-items-center">
                  <div className="host-img circle">
                    <img
                      src={hosting.user?.avatar || "./img/Avatar.png"}
                      alt=""
                      className="HostProfileImg"
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      // onClick={() => {
                      //   setIsOpenImage(true);
                      // }}
                      onClick={openImageSection}
                    />
                    {isOpenImage && (
                      <div className="open-image-overlap">
                        <Lightbox
                          wrapperClassName="lightbox-profile"
                          mainSrc={hosting.user?.avatar || "./img/Avatar.png"}
                          onCloseRequest={() => {
                            setIsOpenImage(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="pro-tag-nameko">
                    <div className="name-with-flag d-flex align-items-center">
                      <h3 className="user-name">
                        {hosting.user?.user_name.length >= 36
                          ? hosting.user?.user_name.slice(0, 36) + ".."
                          : hosting.user?.user_name}
                      </h3>
                      <div className="flag-wrap circle">
                        <img
                          src={checkImageURL(hosting.user?.flag)}
                          alt="flag"
                          className="ko-img"
                        />
                      </div>
                    </div>

                    <div className="host-tag d-flex align-items-center">
                      <div
                        className={
                          hosting.hosting.type === "Local"
                            ? "host-category"
                            : "travel-host-category"
                        }
                      >
                        <p className="info">
                          {hosting.hosting.type === "Local"
                            ? t("Local_Host")
                            : t("Traveler_Host")}
                        </p>
                      </div>
                      <div className="hots-gender">
                        <p className="">
                          {hosting.user?.gender === "MALE"
                            ? t("Male")
                            : t("Female")}
                        </p>
                      </div>
                      <div className="hots-age">
                        <p className="">
                          {AuthStorage.getLang() === "en" ? (
                            <p>
                              {hosting.user?.age}'{t("Age_Groups")}
                            </p>
                          ) : (
                            <p className="d-flex align-items-center justify-content-center">
                              {hosting.user?.age}
                              <span>{t("Age_Groups")}</span>
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <>
                  {is_loggedin && (
                    <div className="d-flex join-pro">
                      {hosting?.user.id !== userData.id && (
                        <div
                          className="join-msgaaa "
                          onClick={() => {
                            if (!is_active) {
                              setDeactivatedNotice(true);
                              return;
                            }
                            userData?.notification &&
                              hosting.user.notification &&
                              createChat(
                                {
                                  id: userData.id,
                                  name: userData.user_name,
                                  profile_url: userData.avatar,
                                },
                                {
                                  id: hosting?.user.id,
                                  name: hosting?.user.user_name,
                                  profile_url: hosting?.user.avatar,
                                }
                              );
                          }}
                        >
                          <>
                            {userData.notification ? (
                              hosting.user.notification ? (
                                <svg
                                  class="chatbox"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.33396 19.0414C7.55286 19.1407 7.77175 19.4387 7.6623 19.7367L6.89617 21.8227C6.67727 21.922 6.89617 22.22 7.11506 22.22L15.4331 19.6374C19.2638 18.3461 22 15.0681 22 11.0948C22 5.73091 16.8559 1.45964 10.8363 2.05563C6.23948 2.45296 2.51825 5.83024 2.08045 10.1015C1.53321 13.9755 3.83162 17.4521 7.33396 19.0414Z"
                                    stroke="#42B6E6"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                  ></path>
                                  <path
                                    d="M8.91309 9.71143H15.5798"
                                    stroke="#42B6E6"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  ></path>
                                  <path
                                    d="M8.91309 13.0054H15.5798"
                                    stroke="#42B6E6"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  ></path>
                                </svg>
                              ) : (
                                <svg
                                  onClick={() => {
                                    setHostMsgOff(true);
                                  }}
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.33396 19.0414C7.55286 19.1407 7.77175 19.4387 7.6623 19.7367L6.89617 21.8227C6.67727 21.922 6.89617 22.22 7.11506 22.22L15.4331 19.6374C19.2638 18.3461 22 15.0681 22 11.0948C22 5.73091 16.8559 1.45964 10.8363 2.05563C6.23948 2.45296 2.51825 5.83024 2.08045 10.1015C1.53321 13.9755 3.83162 17.4521 7.33396 19.0414Z"
                                    stroke="#c9c9c9"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                  ></path>
                                  <path
                                    d="M8.91309 9.71143H15.5798"
                                    stroke="#c9c9c9"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  ></path>
                                  <path
                                    d="M8.91309 13.0054H15.5798"
                                    stroke="#c9c9c9"
                                    stroke-width="1.5"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                  ></path>
                                </svg>
                              )
                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.33396 19.0414C7.55286 19.1407 7.77175 19.4387 7.6623 19.7367L6.89617 21.8227C6.67727 21.922 6.89617 22.22 7.11506 22.22L15.4331 19.6374C19.2638 18.3461 22 15.0681 22 11.0948C22 5.73091 16.8559 1.45964 10.8363 2.05563C6.23948 2.45296 2.51825 5.83024 2.08045 10.1015C1.53321 13.9755 3.83162 17.4521 7.33396 19.0414Z"
                                  stroke="#c9c9c9"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                ></path>
                                <path
                                  d="M8.91309 9.71143H15.5798"
                                  stroke="#c9c9c9"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                ></path>
                                <path
                                  d="M8.91309 13.0054H15.5798"
                                  stroke="#c9c9c9"
                                  stroke-width="1.5"
                                  stroke-miterlimit="10"
                                  stroke-linecap="round"
                                ></path>
                              </svg>
                            )}
                          </>
                        </div>
                      )}

                      <div className="tout-created">
                        <div className="download-heart-icon button ">
                          <div className="heart-div">
                            <input
                              type="checkbox"
                              id="id2"
                              checked={isLiked}
                              // disabled={delayLike}
                              onClick={() => Like(hosting?.user.id, "user")}
                              className="instruments"
                            />
                            <label
                              htmlFor="id2"
                              className="text-white check mb-0"
                            >
                              {!isLiked ? (
                                <svg
                                  width="39"
                                  height="38"
                                  viewBox="0 0 39 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                                    stroke="#C9C9C9"
                                    stroke-width="2"
                                  ></path>
                                  <rect
                                    x="22"
                                    y="13"
                                    width="17"
                                    height="14"
                                    fill="white"
                                  ></rect>
                                  <rect
                                    x="29"
                                    y="14"
                                    width="2"
                                    height="12"
                                    fill="#C9C9C9"
                                  ></rect>
                                  <rect
                                    x="36"
                                    y="19"
                                    width="2"
                                    height="12"
                                    transform="rotate(90 36 19)"
                                    fill="#C9C9C9"
                                  ></rect>
                                </svg>
                              ) : (
                                <svg
                                  width="38"
                                  height="38"
                                  viewBox="0 0 38 38"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                                    fill="#FF5F5F"
                                    stroke="#FF5F5F"
                                    stroke-width="2"
                                  />
                                </svg>
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
              {hosting.showHosting ? (
                <div className="userImgContentko">
                  <p className="host-info-title">
                    {t("Host_Profile_Popup.Now_Hosting")}
                  </p>

                  <div className="hosting-list">
                    <div className="imgArea">
                      <img
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "../../../img/download.svg";
                        }}
                        src={
                          hosting.course.image && hosting.course.image !== ""
                            ? hosting.course.image
                            : "../../../img/download.svg"
                        }
                        alt=""
                        onClick={() =>
                          handleClick(hosting.itinerary.id, hostingId)
                        }
                      />
                      <div className="heart-div position-absolute">
                        <label
                          htmlFor={`wish${hosting.itinerary.id}`}
                          className={`text-white check`}
                        >
                          <input
                            type="checkbox"
                            id={`wish${hosting.itinerary.id}`}
                            checked={itineraryLike}
                            // disabled={delayLike}

                            className="instruments"
                          />
                          {!itineraryLike ? (
                            <svg
                              onClick={() => Like(hostingId, "itinerary")}
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d_2698_302425)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                                  fill="white"
                                />
                                <rect
                                  x="20.2852"
                                  y="10.7305"
                                  width="1.6832"
                                  height="8.97706"
                                  fill="white"
                                />
                                <rect
                                  x="25.5605"
                                  y="14.3164"
                                  width="1.6832"
                                  height="8.97706"
                                  transform="rotate(90 25.5605 14.3164)"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d_2698_302425"
                                  x="0"
                                  y="2"
                                  width="27.998"
                                  height="23.9023"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    floodOpacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset />
                                  <feGaussianBlur stdDeviation="1" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_2698_302425"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_2698_302425"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              onClick={() => Like(hostingId, "itinerary")}
                              width="28"
                              height="28"
                              viewBox="0 0 38 38"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                                fill="#FF5F5F"
                                stroke="#FF5F5F"
                                stroke-width="2"
                              />
                            </svg>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="modalImageContent">
                      <h3 className="hosting-title">
                        {hosting.itinerary.title}
                      </h3>
                      <div className="d-flex justify-content-between align-items-center location-with-comment">
                        <div className="modalcountryname d-flex align-items-center">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.48495 2.25C8.7291 2.25 9.79264 2.69558 10.6756 3.58675C11.5585 4.47791 12 5.55858 12 6.82874C12 7.46383 11.8445 8.19622 11.5334 9.02593C11.2224 9.85563 10.8462 10.6239 10.4047 11.3307C9.96321 12.0375 9.52174 12.7033 9.08027 13.3281C8.6388 13.9529 8.26756 14.4497 7.96656 14.8185L7.48495 15.3409C7.36455 15.218 7.20401 15.0336 7.00334 14.7878C6.80268 14.5419 6.44649 14.0656 5.93478 13.3588C5.42308 12.652 4.96656 11.9657 4.56522 11.2999C4.16388 10.6341 3.80268 9.88124 3.48161 9.04129C3.16054 8.20134 3 7.46383 3 6.82874C3 5.55858 3.43645 4.47791 4.30936 3.58675C5.18227 2.69558 6.2408 2.25 7.48495 2.25ZM7.48495 8.45743C7.92642 8.45743 8.30769 8.29865 8.62876 7.98111C8.94983 7.66357 9.11037 7.27945 9.11037 6.82874C9.11037 6.37804 8.94983 5.99392 8.62876 5.67638C8.30769 5.35883 7.92642 5.20006 7.48495 5.20006C7.04348 5.20006 6.66722 5.35883 6.35619 5.67638C6.04515 5.99392 5.88963 6.37804 5.88963 6.82874C5.88963 7.27945 6.04515 7.66357 6.35619 7.98111C6.66722 8.29865 7.04348 8.45743 7.48495 8.45743Z"
                              fill="#7E7E7E"
                            />
                          </svg>
                          <p>
                            {hosting.itinerary.region},&nbsp;
                            {hosting.itinerary.country}
                          </p>
                        </div>
                        <div className="review-with-heart d-flex align-items-center">
                          {hosting.review.comment !== 0 && (
                            <span>
                              {t("TourList.Comments")}&nbsp;(
                              {hosting.review.comment})
                            </span>
                          )}
                          {hosting.review.review !== 0 && (
                            <span className="d-flex star-review align-items-center">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.39977 3.22298C7.44103 3.08579 7.52291 2.96597 7.63354 2.88091C7.74416 2.79586 7.87776 2.75 8.01493 2.75C8.1521 2.75 8.2857 2.79586 8.39633 2.88091C8.50695 2.96597 8.58884 3.08579 8.63009 3.22298L9.74096 6.80542H13.3244C13.4665 6.79979 13.6064 6.84309 13.7228 6.9287C13.8392 7.01431 13.9257 7.13754 13.969 7.27954C14.0124 7.42154 14.0102 7.57452 13.9628 7.71509C13.9154 7.85567 13.8254 7.97611 13.7066 8.05802L10.7981 10.2689L11.9089 13.8576C11.9529 13.9943 11.954 14.1421 11.9121 14.2795C11.8702 14.4169 11.7876 14.5367 11.6762 14.6213C11.5648 14.706 11.4305 14.7511 11.2929 14.75C11.1554 14.7489 11.0217 14.7018 10.9116 14.6154L7.99701 12.3795L5.08845 14.5966C4.97826 14.683 4.84463 14.7301 4.70707 14.7312C4.56952 14.7323 4.43524 14.6872 4.32384 14.6025C4.21245 14.5179 4.12977 14.3981 4.08789 14.2607C4.04601 14.1233 4.04712 13.9755 4.09105 13.8388L5.20192 10.2501L2.29335 8.03923C2.17455 7.95732 2.08459 7.83688 2.03721 7.6963C1.98983 7.55573 1.98764 7.40275 2.03097 7.26075C2.0743 7.11875 2.16078 6.99552 2.27718 6.90991C2.39358 6.8243 2.53351 6.781 2.67559 6.78663H6.25904L7.39977 3.22298Z"
                                  fill="#1B1C1E"
                                />
                              </svg>
                              {hosting.review.star}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="createBy">
                          {getCreatorString(
                            hosting.itinerary.creator,
                            hosting?.itinerary.creator_show
                          )}
                        </h5>
                        {AuthStorage.getLang() === "en" ? (
                          <h1 className="pax-font">
                            <span className="font-blue">
                              {hosting?.hosting_count}&nbsp;
                              {t("Host_Own.pax3")}
                            </span>
                            {hosting?.hosting_count === 1
                              ? t("My_Account.Tour")
                              : t("My_Account.Tours")}
                          </h1>
                        ) : (
                          <h1 className="pax-font">
                            <span className="font-blue">
                              {hosting?.hosting_count}
                              {t("Host_Own.Pax")}
                            </span>
                            {t("Host_Own.Was_Hosting")}
                          </h1>
                        )}
                        {/* <h1 className="pax-font">
                          {t("Host_Own.pax1")}
                          <span className="font-blue">
                            <label>{hosting.hosting.participate_count}</label>
                            {t("Host_Own.pax3")}
                          </span>
                          /{hosting.hosting.pax}
                          {t("Host_Own.pax3")} {t("Host_Own.pax2")}
                        </h1> */}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-itmes-center justify-content-between date-timeko">
                    <p className="date">
                      {AuthStorage.getLang() === "en" ? (
                        <>
                          {moment(hosting.hosting.date).format("MMMM D, YYYY")}
                          &nbsp;{hosting.hosting.start.slice(0, 5)}
                          {" - "}
                          {hosting.hosting.end.slice(0, 5)}
                        </>
                      ) : (
                        <>
                          {hosting.hosting.date.replaceAll("-", ".")}{" "}
                          {hosting.hosting.start.slice(0, 5)}
                          {" - "}
                          {hosting.hosting.end.slice(0, 5)}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="userImgContentko">
                  <p className="host-info-title">
                    {t("Host_Profile_Popup.Now_Hosting")}
                  </p>
                  <div className="now-hosting-blank d-flex align-items-center">
                    <img src="/img/deleted-symbol.png" alt="" />
                    {AuthStorage.getLang() === "en" ? (
                      <h6>Not Hosting Now</h6>
                    ) : (
                      <h6>현재 호스팅을 하고 있지 않습니다</h6>
                    )}
                  </div>
                </div>
              )}
              {/* {AuthStorage.getLang() === "en" ? (
                hosting.showHosting ? (
                  <div
                    className="userImgContent"
                    onClick={() => handleClick(hosting.itinerary.id, hostingId)}
                  >
                    <div className="NewHostingtitle">
                      <p>{t("Host_Profile_Popup.Now_Hosting")}</p>
                    </div>

                    <div className="mt-24 d-flex mainProfile">
                      <div className="mr-50 host-profile-modal">
                        <img
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../../../img/download.svg";
                          }}
                          src={
                            hosting.course.image && hosting.course.image !== ""
                              ? hosting.course.image
                              : "../../../img/download.svg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="modalImageContent">
                        <h3 className="font-20-bold color-black h-24">
                          {" "}
                          {hosting.itinerary.title}
                        </h3>
                        <p className="modalcountryname">
                          {" "}
                          @ {hosting.itinerary.region} ,{" "}
                          {hosting.itinerary.country}
                        </p>
                        <p className="CountryReview">
                          {hosting?.itinerary?.creator === "Compastrips" && (
                            <img src="../../../img/ic-star.svg" alt="" />
                          )}
                          <span
                            className={
                              hosting?.itinerary?.creator === "Compastrips"
                                ? "review-host-modal"
                                : "review-host-modal ml-0"
                            }
                          >
                            {hosting?.itinerary?.creator === "Compastrips" && (
                              <>
                                {hosting.review.star} ({hosting.review.review})
                                · &nbsp;
                              </>
                            )}
                            {t("TourList.Comments")}{" "}
                            <b> {hosting.review.comment}</b>
                          </span>
                        </p>
                        <div className="loaction">
                          <h6 className="font-18-normal color-black ls-one mb-0">
                            <ReadMore>
                              <span>1.</span>{" "}
                              {hosting.course.courses
                                .toString()
                                .slice(0, 22)
                                .replace(",", " 2.")}{" "}
                              <span>...</span>
                            </ReadMore>
                            <p className="read-more-mobile">
                              <span>1.</span>
                              {hosting.course.courses
                                .toString()
                                .replace(",", " 2.")}{" "}
                            </p>
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="res-loaction">
                      <h6 className="font-18-normal color-black ls-one mb-0">
                        <ReadMore>
                          <span>1.</span>{" "}
                          {hosting.course.courses
                            .toString()
                            .slice(0, 22)
                            .replace(",", " 2.")}{" "}
                          <span>...</span>
                        </ReadMore>
                        <p className="read-more-mobile">
                          <span>1.</span>
                          {hosting.course.courses
                            .toString()
                            .replace(",", " 2.")}{" "}
                        </p>
                      </h6>
                    </div>

                    <div className="d-flex align-itmes-center justify-content-between date-time">
                      <p className="date">
                        {hosting.hosting.date.replaceAll("-", ".")}{" "}
                        {hosting.hosting.start.slice(0, 5)}
                        {" - "}
                        {hosting.hosting.end.slice(0, 5)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="userImgContent">
                    <div className="NewHostingtitle blank-host-info">
                      <p>{t("Host_Profile_Popup.Now_Hosting")}</p>
                      <div className="now-hosting-blank">
                        <img src="/img/deleted-symbol.png" alt="" />
                        <h6>Not Hosting Now</h6>
                      </div>
                    </div>
                  </div>
                )
              ) : hosting.showHosting ? (
                <div
                  className="userImgContentko"
                  onClick={() => handleClick(hosting.itinerary.id, hostingId)}
                >
                  <p className="host-info-title">
                    {t("Host_Profile_Popup.Now_Hosting")}
                  </p>

                  <div className="hosting-list">
                    <div className="imgArea">
                      <img
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "../../../img/download.svg";
                        }}
                        src={
                          hosting.course.image && hosting.course.image !== ""
                            ? hosting.course.image
                            : "../../../img/download.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="modalImageContent">
                      <h3 className="hosting-title">
                        {hosting.itinerary.title}
                      </h3>
                      <div className="d-flex justify-content-between align-items-center location-with-comment">
                        <div className="modalcountryname d-flex align-items-center">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.48495 2.25C8.7291 2.25 9.79264 2.69558 10.6756 3.58675C11.5585 4.47791 12 5.55858 12 6.82874C12 7.46383 11.8445 8.19622 11.5334 9.02593C11.2224 9.85563 10.8462 10.6239 10.4047 11.3307C9.96321 12.0375 9.52174 12.7033 9.08027 13.3281C8.6388 13.9529 8.26756 14.4497 7.96656 14.8185L7.48495 15.3409C7.36455 15.218 7.20401 15.0336 7.00334 14.7878C6.80268 14.5419 6.44649 14.0656 5.93478 13.3588C5.42308 12.652 4.96656 11.9657 4.56522 11.2999C4.16388 10.6341 3.80268 9.88124 3.48161 9.04129C3.16054 8.20134 3 7.46383 3 6.82874C3 5.55858 3.43645 4.47791 4.30936 3.58675C5.18227 2.69558 6.2408 2.25 7.48495 2.25ZM7.48495 8.45743C7.92642 8.45743 8.30769 8.29865 8.62876 7.98111C8.94983 7.66357 9.11037 7.27945 9.11037 6.82874C9.11037 6.37804 8.94983 5.99392 8.62876 5.67638C8.30769 5.35883 7.92642 5.20006 7.48495 5.20006C7.04348 5.20006 6.66722 5.35883 6.35619 5.67638C6.04515 5.99392 5.88963 6.37804 5.88963 6.82874C5.88963 7.27945 6.04515 7.66357 6.35619 7.98111C6.66722 8.29865 7.04348 8.45743 7.48495 8.45743Z"
                              fill="#7E7E7E"
                            />
                          </svg>
                          <p>
                            {hosting.itinerary.region},&nbsp;
                            {hosting.itinerary.country}
                          </p>
                        </div>
                        <div className="review-with-heart d-flex align-items-center">
                          {hosting.review.comment !== 0 && (
                            <span>
                              {t("TourList.Comments")}&nbsp;(
                              {hosting.review.comment})
                            </span>
                          )}
                          {hosting.review.review !== 0 && (
                            <span className="d-flex star-review align-items-center">
                              <svg
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.39977 3.22298C7.44103 3.08579 7.52291 2.96597 7.63354 2.88091C7.74416 2.79586 7.87776 2.75 8.01493 2.75C8.1521 2.75 8.2857 2.79586 8.39633 2.88091C8.50695 2.96597 8.58884 3.08579 8.63009 3.22298L9.74096 6.80542H13.3244C13.4665 6.79979 13.6064 6.84309 13.7228 6.9287C13.8392 7.01431 13.9257 7.13754 13.969 7.27954C14.0124 7.42154 14.0102 7.57452 13.9628 7.71509C13.9154 7.85567 13.8254 7.97611 13.7066 8.05802L10.7981 10.2689L11.9089 13.8576C11.9529 13.9943 11.954 14.1421 11.9121 14.2795C11.8702 14.4169 11.7876 14.5367 11.6762 14.6213C11.5648 14.706 11.4305 14.7511 11.2929 14.75C11.1554 14.7489 11.0217 14.7018 10.9116 14.6154L7.99701 12.3795L5.08845 14.5966C4.97826 14.683 4.84463 14.7301 4.70707 14.7312C4.56952 14.7323 4.43524 14.6872 4.32384 14.6025C4.21245 14.5179 4.12977 14.3981 4.08789 14.2607C4.04601 14.1233 4.04712 13.9755 4.09105 13.8388L5.20192 10.2501L2.29335 8.03923C2.17455 7.95732 2.08459 7.83688 2.03721 7.6963C1.98983 7.55573 1.98764 7.40275 2.03097 7.26075C2.0743 7.11875 2.16078 6.99552 2.27718 6.90991C2.39358 6.8243 2.53351 6.781 2.67559 6.78663H6.25904L7.39977 3.22298Z"
                                  fill="#1B1C1E"
                                />
                              </svg>
                              {hosting.review.star}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="createBy">
                          {hosting?.itinerary.creator_show}
                        </h5>
                        <h1 className="pax-font">
                          <span className="font-blue">
                            <label></label>
                            {hosting?.hosting_count}
                            {t("Host_Own.pax3")}
                          </span>
                          {t("Host_Own.Was_Hosting")}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-itmes-center justify-content-between date-timeko">
                    <p className="date">
                      {hosting.hosting.date.replaceAll("-", ".")}{" "}
                      {hosting.hosting.start.slice(0, 5)}
                      {" - "}
                      {hosting.hosting.end.slice(0, 5)}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="userImgContent">
                  <div className="NewHostingtitle blank-host-info">
                    <p>{t("Host_Profile_Popup.Now_Hosting")}</p>
                    <div className="now-hosting-blank">
                      <img src="/img/deleted-symbol.png" alt="" />
                      <h6>현재 호스팅을 하고 있지 않습니다</h6>
                    </div>
                  </div>
                </div>
              )} */}
            </>
          )}
        </Modal.Body>
      </Modal>
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
      <Modal
        show={hostMsgOff}
        onHide={() => {
          setHostMsgOff(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal msg-off-modal"
            : "noto-text custom-modal msg-off-modal"
        }
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Chat_Off.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p dangerouslySetInnerHTML={{ __html: t("Chat_Off.Host_Off") }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            onClick={() => {
              setHostMsgOff(false);
            }}
          >
            {t("Chat_Off.Button")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HostPro;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { ApiDelete, ApiPost } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import AccountDeactive from "../modal/AccountDeactive";
import { Button } from "react-bootstrap";

const initialReplay = {
  itinerary: "",
  message: "",
  position: 0,
  parent_comment: "",
};

const Comments = ({
  itinerary_id,
  commentData,
  setRefreshComment,
  borderbottom,
  setNoComment,
}) => {
  const { is_loggedin } = useSelector((state) => state.login);
  const [viewReply, setViewReply] = useState("");
  const [replay, setReplay] = useState(initialReplay);
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const is_active = AuthStorage.isUserActive();
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    setReplay({
      ...replay,
      itinerary: itinerary_id,
      position: +commentData.position + 1,
      parent_comment: commentData.id,
    });
  }, [commentData]);

  const postReplay = (parentId) => {
    const _replay = { ...replay };
    _replay.parent_comment = parentId;
    _replay.position = replay.position;
    if (!_replay) {
    } else {
      ApiPost("comment", _replay)
        .then(() => {
          setReplay((prev) => ({
            ...prev,
            message: "",
          }));
          setViewReply("");
          setRefreshComment(Math.random());
        })
        .catch((error) => {
          setNoComment(true);
        });
    }
  };

  const onDelete = (id) => {
    ApiDelete(`comment/${id}`, "")
      .then(() => {
        setRefreshComment(Math.random());
      })
      .catch((error) => console.error(error));
  };

  const onCancle = () => {
    setViewReply("");
    setReplay((prev) => ({
      ...prev,
      message: "",
    }));
  };

  return (
    <div
      className={
        !replay.moreReply ? "chat-reply-inner showMore" : "chat-reply-inner"
      }
    >
      <div className={"comment-reply"}>
        <div className="single-reply d-flex">
          {commentData.position > 0 && (
            <div className="recomment-image">
              {isMobile ? (
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2 2V8H8" stroke="#7E7E7E" />
                </svg>
              ) : (
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 6V18H30" stroke="#7E7E7E" />
                </svg>
              )}
            </div>
          )}
          {isMobile ? (
            <div className="flex-directtion-column w-100 mobile-comment">
              <div className="d-flex w-100 align-items-center mobile-comment-user">
                <div className="host-img circle">
                  {commentData.user.avatar ? (
                    <img src={commentData.user.avatar} alt="avatar" />
                  ) : (
                    <img
                      className="objectfit-cover"
                      src="/img/Avatar.png"
                      alt="avatar"
                    />
                  )}
                </div>
                <div
                  className={
                    commentData.position > 0
                      ? "single-reply-inner single-reply-inner2 "
                      : "single-reply-inner"
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center name-details">
                      <h3 className="user-name">
                        {commentData.user.user_name}
                      </h3>
                      <span className="comment-date">
                        &nbsp;·&nbsp;{moment(commentData.created_at).fromNow()}
                      </span>
                    </div>
                    <div className="d-flex align-items-center reply-delete-btn">
                      {is_loggedin && (
                        <>
                          {commentData.can_delete && (
                            <>
                              <button
                                className="delete"
                                onClick={(e) => {
                                  if (is_active) {
                                    onDelete(commentData.id);
                                  } else {
                                    setDeactivatedNotice(true);
                                  }
                                }}
                              >
                                {t("Comment.Delete")}
                              </button>
                              {commentData.position < 1 && <>&nbsp;·&nbsp;</>}
                            </>
                          )}
                          {commentData.is_deleted === false &&
                            commentData.position < 1 && (
                              <>
                                <button
                                  className="reply"
                                  onClick={() => {
                                    if (is_active) {
                                      setViewReply(commentData.id);
                                    } else {
                                      setDeactivatedNotice(true);
                                    }
                                  }}
                                >
                                  {t("Comment.Replay")}
                                </button>
                              </>
                            )}

                          {/* responsive Action button Start */}

                          {/* responsive Action button End */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  commentData.is_deleted
                    ? "full-comment-section deleted-user-msg"
                    : "full-comment-section"
                }
              >
                <div className="d-flex align-items-center">
                  {commentData.is_deleted === true ? (
                    AuthStorage.getLang() === "en" ? (
                      commentData.deleted_by === "ADMIN" ? (
                        <h5 className="comment-content deleted-comment">
                          <img
                            src="/img/deleted-symbol.png"
                            alt=""
                            className="deleted-symbols"
                          />
                          Comment deleted by Admin.
                        </h5>
                      ) : (
                        <h5 className="comment-content deleted-comment">
                          <img
                            src="/img/deleted-symbol.png"
                            alt=""
                            className="deleted-symbols"
                          />
                          Comment deleted by user.
                        </h5>
                      )
                    ) : commentData.deleted_by === "ADMIN" ? (
                      <h5 className="comment-content deleted-comment">
                        <img
                          src="/img/deleted-symbol.png"
                          alt=""
                          className="deleted-symbols"
                        />
                        코멘트가 어드민에 의해 삭제되었습니다.
                      </h5>
                    ) : (
                      <h5 className="comment-content deleted-comment">
                        <img
                          src="/img/deleted-symbol.png"
                          alt=""
                          className="deleted-symbols"
                        />
                        코멘트가 작성자에 의해 삭제되었습니다.
                      </h5>
                    )
                  ) : (
                    <pre
                      className="comment-content"
                      dangerouslySetInnerHTML={{
                        __html: commentData.message,
                      }}
                    ></pre>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="host-img circle">
                {commentData.user.avatar ? (
                  <img src={commentData.user.avatar} alt="avatar" />
                ) : (
                  <img
                    className="objectfit-cover"
                    src="/img/Avatar.png"
                    alt="avatar"
                  />
                )}
              </div>
              <div
                className={
                  commentData.position > 0
                    ? "single-reply-inner single-reply-inner2 "
                    : "single-reply-inner"
                }
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center name-details">
                    <h3 className="user-name">{commentData.user.user_name}</h3>
                    <span className="comment-date">
                      &nbsp;·&nbsp;{moment(commentData.created_at).fromNow()}
                    </span>
                  </div>
                  <div className="d-flex align-items-center reply-delete-btn">
                    {is_loggedin && (
                      <>
                        {commentData.can_delete && (
                          <>
                            <button
                              className="delete"
                              onClick={(e) => {
                                if (is_active) {
                                  onDelete(commentData.id);
                                } else {
                                  setDeactivatedNotice(true);
                                }
                              }}
                            >
                              {t("Comment.Delete")}
                            </button>
                            {commentData.position < 1 && <>&nbsp;·&nbsp;</>}
                          </>
                        )}
                        {commentData.is_deleted === false &&
                          commentData.position < 1 && (
                            <>
                              <button
                                className="reply"
                                onClick={() => {
                                  if (is_active) {
                                    setViewReply(commentData.id);
                                  } else {
                                    setDeactivatedNotice(true);
                                  }
                                }}
                              >
                                {t("Comment.Replay")}
                              </button>
                            </>
                          )}

                        {/* responsive Action button Start */}

                        {/* responsive Action button End */}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={
                    commentData.is_deleted
                      ? "full-comment-section deleted-user-msg"
                      : "full-comment-section"
                  }
                >
                  <div className="d-flex align-items-center">
                    {commentData.is_deleted === true ? (
                      AuthStorage.getLang() === "en" ? (
                        commentData.deleted_by === "ADMIN" ? (
                          <h5 className="comment-content deleted-comment">
                            <img
                              src="/img/deleted-symbol.png"
                              alt=""
                              className="deleted-symbols"
                            />
                            Comment deleted by Admin.
                          </h5>
                        ) : (
                          <h5 className="comment-content deleted-comment">
                            <img
                              src="/img/deleted-symbol.png"
                              alt=""
                              className="deleted-symbols"
                            />
                            Comment deleted by user.
                          </h5>
                        )
                      ) : commentData.deleted_by === "ADMIN" ? (
                        <h5 className="comment-content deleted-comment">
                          <img
                            src="/img/deleted-symbol.png"
                            alt=""
                            className="deleted-symbols"
                          />
                          코멘트가 어드민에 의해 삭제되었습니다.
                        </h5>
                      ) : (
                        <h5 className="comment-content deleted-comment">
                          <img
                            src="/img/deleted-symbol.png"
                            alt=""
                            className="deleted-symbols"
                          />
                          코멘트가 작성자에 의해 삭제되었습니다.
                        </h5>
                      )
                    ) : (
                      <pre
                        className="comment-content"
                        dangerouslySetInnerHTML={{
                          __html: commentData.message,
                        }}
                      ></pre>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {viewReply === commentData.id && (
          <div className="comment-write inner-content-writer">
            <TextareaAutosize
              minRows={3.25}
              maxRows={6}
              placeholder={t("Comment.Write_A_Comment2")}
              value={replay.message}
              onChange={(e) =>
                setReplay((prev) => ({
                  ...prev,
                  message: e.target.value,
                }))
              }
              onResize={false}
            />
            <div className="d-flex reply-comment">
              <Button
                variant="cancelBtn"
                className="post-comment-cancle"
                onClick={onCancle}
              >
                {t("Comment.Cancle")}
              </Button>
              <Button
                variant="confirmBtn"
                className="post-comment-btn"
                onClick={() => {
                  postReplay(commentData.id);
                }}
              >
                {t("Comment.Post")}
              </Button>
            </div>
          </div>
        )}
      </div>
      {commentData.replay.length > 4 && !replay.showMore ? (
        <>
          <Comments
            key={0}
            itinerary_id={commentData.replay[0].itinerary_id}
            commentData={commentData.replay[0]}
            setRefreshComment={setRefreshComment}
            borderbottom={false}
          />
          <div className="showBtn d-flex justify-content-end">
            <button
              onClick={() => {
                setReplay({
                  showMore: true,
                });
              }}
            >
              {t("Show.All")}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3659 6.30078L8.03255 10.3008L4.69922 6.30078"
                  stroke="#7E7E7E"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </>
      ) : commentData.replay.length > 4 && replay.showMore ? (
        <>
          {commentData.replay?.map((replay, i) => (
            <Comments
              key={i}
              itinerary_id={itinerary_id}
              commentData={replay}
              setRefreshComment={setRefreshComment}
              borderbottom={true}
            />
          ))}
          <div className="showBtn less d-flex justify-content-end">
            <button
              onClick={() => {
                setReplay({
                  showMore: false,
                });
              }}
            >
              {t("Show.Less")}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3659 6.30078L8.03255 10.3008L4.69922 6.30078"
                  stroke="#7E7E7E"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </>
      ) : (
        commentData.replay?.map((replay, i) => (
          <Comments
            key={i}
            itinerary_id={itinerary_id}
            commentData={replay}
            setRefreshComment={setRefreshComment}
            borderbottom={true}
          />
        ))
      )}
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </div>
  );
};

export default Comments;

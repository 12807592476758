import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChatData } from "../../redux/action/chatDataAction";
import Message from "../../components/Message";
import Terms from "../../Terms&Conditions/Terms&Condition";
import PrivacyPolicy from "../../Terms&Conditions/PrivacyPolicy";
import HeaderLogo from "../../components/HeaderLogo";
import "./Footer.css";
import AuthStorage from "helper/AuthStorage";

function Footer() {
  const isMobile = window.innerWidth <= 768;
  const [TermsUse, setTermsUse] = useState(false);
  const [Privacypolicy, setPrivacypolicy] = useState(false);
  const history = useHistory();
  const { userData } = useSelector((state) => state.userData);
  const { is_loggedin } = useSelector((state) => state.login);
  const location = useLocation();
  const { chatData, count, chat_open, message_open, id, otherUser, readOnly } =
    useSelector((state) => state.chatData);

  const dispatch = useDispatch();
  const goHomePage = () => {
    history.push("/");
  };
  useEffect(() => {
    if (userData && userData.hasOwnProperty("id")) {
      dispatch(getChatData(userData?.id));
    }
  }, [userData]);

  const { t } = useTranslation();

  const [resize, setResize] = useState(window.innerWidth);
  const [logoSize, setLogoSize] = useState("94px");

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setLogoSize("94px");
    } else {
      setLogoSize("120px");
    }

    setResize(window.innerWidth);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setLogoSize("94px");
    } else {
      setLogoSize("120px");
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <footer
        id="Footer"
        className={location.pathname === "/" ? "main-footer" : null}
      >
        <div className="wrap footer-inner d-flex">
          <Link className="logo position-relative" to={"/"}>
            {/* <HeaderLogo></HeaderLogo> */}
            <img
              src={"./img/cst_logo_vertical.svg"}
              width={logoSize}
              alt="cst logo img 3"
            />
          </Link>
          <div className="footer-content">
            <div className="list-of-links d-flex">
              <h6
                className="desk-customer-service"
                onClick={() => {
                  window.open("http://www.compasinteractive.com");
                }}
              >
                {t("Footer.Company")}
              </h6>
              <p>•</p>
              <h6
                onClick={() => {
                  setTermsUse(true);
                }}
              >
                {" "}
                {t("Footer.Terms_of_Use")}{" "}
              </h6>
              <p>•</p>
              <h6
                onClick={() => {
                  setPrivacypolicy(true);
                }}
              >
                {t("Footer.Privacy_Policy")}
              </h6>

              {/* <p>•</p>
                  <Link to="/customerService" className="desk-customer-service">
                    {t("Footer.Customer_Service")}
                  </Link>
                  <Link
                    to="/customerServices"
                    className="mobile-customer-services"
                  >
                    {t("Footer.Customer_Service")}
                  </Link> */}
              <p>•</p>
              <Link to="/inquirtPage">{t("Footer.Inquiries")}</Link>
            </div>

            <div className="copy-section">
              <p>Copyright © Compas Interactive Inc. All Rights Reserved.</p>
            </div>
          </div>
        </div>
        <div className="ninetwenty-container ">
          <div className="mini-container">
            <div className="d-flex footer-content-main">
              <div onClick={goHomePage}>
                <HeaderLogo></HeaderLogo>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {message_open && <Message id={id} data={otherUser} />}
      <Terms set={setTermsUse} value={TermsUse} />
      <PrivacyPolicy set={setPrivacypolicy} value={Privacypolicy} />
    </>
  );
}

export default Footer;

import React from "react";

const PrivacyUsecard_En = () => {
  return (
    <>
      <h1>1. Purpose of Personal Information Processing</h1>
      <p>
        Compas Interactive Inc. (or the “Company”) processes personal
        information for the purposes below. Personal information being processed
        will not be used for any purpose other than the purposes stated below;
        if there is any change to the purpose of use, Compas Interactive Inc.
        will take necessary measures, including obtaining separate consent under
        Article 18 of the ‘Personal Information Protection Act’.
        <br />
        a. Member registration and management
        <br />
        Confirmation of the intent to register as a member; identification and
        verification of the person himself/herself following the provision of
        membership services; maintenance and management of membership
        qualifications; prevention of wrongful use of services; various notices
        and notifications; grievance handling, etc.
        <br />
        b. Service development and Utilization for marketing/advertising
        <br />
        Statistical analysis concerning the use of COMPASTRIPS
        Service(hereinafter referred as to the ‘Service’); review of hosting
        services; survey and analysis necessary to improve services; service
        validity check; provision of event, advertising information and
        opportunities to participate; identification of access frequency or
        statistics of members’ service use.
        <br />
        c. Settlement of charges stemming from brokerage service provision
        <br />
        Reservation of travel products; provision of content; analysis of
        purchase preference and tendency; purchase and payment; delivery of
        goods or bills, etc.; identity verification for financial transactions
        and financial services; fee collection, etc.
      </p>
      <h1>2. Processing of Personal Information and Retention Period</h1>
      <p>
        a. Compass Interactive Inc. processes and retains personal information
        within the period of retention and use of personal information under the
        law or within the period of retention and use of personal information to
        which the information subject consented upon the collection of the
        personal information from the information subject.
        <br />
        b. Respective periods of personal information processing and retention
        are as follows:
        <br />
        1) Website membership registration and management: Until withdrawal from
        the business or organization’s website; provided, however, that in a
        case that falls under the following reasons, until such reasons no
        longer exist:
        <br />
        a) In the case of an ongoing investigation or examination due to a
        violation of applicable laws, until the investigation or examination
        ends.
        <br />
        b) In the case of remaining receivables/obligations relationship arising
        from the use of the website, until the settlement of the
        receivables/obligations relationship.
        <br />
        2) Records concerning location information under the Act on the
        Protection and Use of Location Information: 6 months.
        <br />
        3) Provision of goods or services: Until supply of goods or services are
        completed and the payment or settlement of the fees is completed;
        provided, however, that, in a case that falls under the following
        reasons, until the end of such period:
        <br />
        a) Records concerning transactions, including marks, advertisements, and
        contents of the contracts and performance thereof, etc. under the ‘Act
        on the Consumer Protection in Electronic Commerce’.
        <br />
        - Records related to marks and advertisements: 6 months.
        <br />
        - Records related to the cancellation of contracts or subscriptions, and
        payment for and supply of goods, etc: 5 years.
        <br />
        - Records related to the resolution of consumer complaints or disputes:
        3 years.
        <br />
        b) Preservation of data for confirmation of the fact of communications
        under the ‘Protection of Communications Secrets Act’.
        <br />- Records of service website visits: 3 months.
      </p>
      <h1>3. Personal Information Items Subject to the Processing</h1>
      <p>
        Compass Interactive Inc. processes the following personal information
        items:
        <br />
        a. Member registration and management
        <br />
        [Accounts]
        <br />
        ◎ Required Items: Name, nickname, email address, password, nationality,
        phone number, date of birth, gender
        <br />
        ◎ Optional Items: None
        <br />
        [For SNS member registration]
        <br />
        None
        <br />
        [Provision of location-based services]
        <br />
        Personal location information
        <br />
        [For identity verification]
        <br />
        Name, gender, date of birth, mobile phone number, telecommunications
        service provider, status as a citizen/foreigner, encoded identification
        information (CI), identification information of overlapped membership
        (DI)
        <br />
        [For legal representatives’ consent]
        <br />
        Information of the legal representative [name, gender, date of birth,
        mobile phone number, telecommunication service provider, status as a
        citizen/foreigner, encoded identification information (CI),
        identification information of overlapped membership (DI)
        <br />
        [Prevention of illegal/wrongful use and service analysis]
        <br />
        Auto-generated information and device information (device type, OS
        version), usage records (IP address, cookies, service usage records,
        etc.)
        <br />
        b. Paid Services
        <br />
        - For payments with credit cards: Credit card number (partial), name of
        the credit card company, etc.
        <br />
        - For payments with mobile phone numbers: Mobile phone number, payment
        approval number, etc.
        <br />
        - For account transfer: Account holder’s name, account number, bank of
        the account, etc.
        <br />
        - For using gift certificates: Gift certificate number, ID of the site
        concerned.
        <br />
        - For refund processing: Bank of the account, account number, account
        holder’s name, email.
        <br />
        - For issuing cash receipts: Mobil phone number, cash receipt card
        number.
        <br />
        c. Inquiries to the Customer Center
        <br />
        - Email, phone number, reservation number, etc.
        <br />
        d. Use of additional services or tailored services or event entry
        <br />- Name, mobile phone number, messenger ID, address, personal
        website/blog address, SNS-linked information (Facebook, Google+), etc.
      </p>
      <h1>4. Provision of Personal Information to Third Parties</h1>
      <p>
        a. Compass Interactive Inc. processes personal information of
        information subjects only within the scope stipulated under the purpose
        of processing personal information, provides personal information only
        in cases that fall under Articles 17 and 18 of the Personal Information
        Protection Act, such as where there are information subjects’ consent or
        special provisions under the law, and does not provide personal
        information of information subjects to a third party in any other cases.
        <br />
        b. During the use of the services of COMPASTRIPS, profile information
        may be shared with other users, but other information will not be
        disclosed or provided to a third party unless the consent of the
        information subject is obtained; provided, however, that, this does not
        apply in the following cases:
        <br />
        - If users consented in advance
        <br />
        - If it is based on provisions of the law
        <br />
        - If it is necessary for the settlement of fees arising from service
        provision
        <br />
        - If there is an investigative agency’s request for the purpose of
        investigations pursuant to the procedures and methods stipulated in the
        law
        <br />
        - If it relates to a court order or legal problems: The Company may
        provide your information for litigation defense or, in other cases, for
        responding to a subpoena, court order or other litigation, establishing
        or exercising legal rights according to the stipulations under the law.
        In this case, the Company may perform or waive to raise a legal
        objection or exercise rights.
        <br />
        - In the case of the Company’s transaction, such as corporate division,
        merger, consolidation or asset sale, or the Company’s bankruptcy
        <br />
        - If it is deemed necessary under the Company’s determination to
        investigate, prohibit or take measures concerning an act that is illegal
        or may be illegal:
        <br />
        - If it is necessary to protect and defend rights, properties or safety
        of members or other persons or if it is deemed to be in line with the
        Company’s rules or other contracts, the Company may provide your
        information.
        <br />
        c. Compass Interactive Inc., for smooth provision of services
        henceforth, may provide information to the minimum necessary extent upon
        obtaining the consent of the information subject in the following cases:
        <br />
        Receiver
        <br />
        Purpose of Provision
        <br />
        Provided Items
        <br />
        Period of Retention and Use
      </p>
      <h1>5. Entrustment of Personal Information Processing</h1>
      <p>
        a. Compas Interactive Inc., may entrust the duties of personal
        information processing as follows for the smooth handling of personal
        information duties henceforth:
        <br />
        Entrusted Person (Entrustee)
        <br />
        Entrusted Duties
        <br />
        Period of Retention and Use
        <br />
        b. Compas Interactive Inc., in entering into entrustment agreements,
        stipulates matters concerning the prohibition of personal information
        processing for purposes other than the purpose of performing the
        entrusted duties, technical and managerial protective measures,
        restrictions on re-entrustment, management and supervision of
        entrustees, matters concerning responsibilities like compensation for
        damages, etc., in documents, such as contracts and is supervising
        whether the entrustees safely process personal information, pursuant to
        Article 26 of the ‘Personal Information Protection Act’.
        <br />
        c. In the case of any changes to the details of the entrusted duties or
        entrustees, the Company will publicize the changes thereof through this
        Privacy Policy without delay.
      </p>
      <h1>6. Procedures and Methods of Destruction of Personal Information</h1>
      <p>
        a. Compas Interactive Inc., destroys personal information without delay
        when the personal information becomes no longer necessary in cases such
        as the expiration of the personal information retention period or the
        achievement of the purpose of processing.
        <br />
        b. If personal information must be preserved under other laws and
        regulations even though the personal information retention period
        consented to by the information subject has expired or the purpose of
        processing has been achieved, the personal information concerned is
        either moved to a separate database (DB) or stored in a separate storage
        location.
        <br />
        c. The procedure and method of destruction of personal information are
        as follows:
        <br />
        1) Destruction procedure
        <br />
        Information entered by the user for the use of the services, etc., after
        the purpose is achieved, will be destroyed after being stored for a
        certain period according to the internal policy and other grounds of
        information protection (see the period of retention and use) under
        applicable laws and regulations. Said personal information will be used
        for any purpose other than the purpose of retention unless it is due to
        the law.
        <br />
        2) Method of destruction
        <br />
        Compas Interactive Inc. destroys personal information recorded and
        stored in the form of electronic files so that the records may not be
        reproduced and, in the case of personal information recorded and stored
        in paper documents, destroys the information by shredding it with a
        shredder or by incinerating.
      </p>
      <h1>
        7. Measures concerning Destruction of Personal Information of Dormant
        Users
      </h1>
      <p>
        a. Compas Interactive Inc., destroys information of users who have not
        used the services for one year; provided, however, that, the Company may
        store and manage the information separately from the information of
        other users until the retention period prescribed by other laws and
        regulations has elapsed.
        <br />
        b. Compas Interactive Inc.. notifies users via methods like email and
        text messages possible to notify users of the fact that personal
        information will be destroyed, the expiry date of the period and
        personal information items to be destroyed at least 30 days before the
        destruction of the personal information.
        <br />
        c. Users who do not want their personal information to be destroyed may
        prevent the destruction by logging into the services before the
        expiration date of the period.
      </p>
      <h1>
        8. Measures concerning Destruction of Personal Information of Dormant
        Users
      </h1>
      <p>
        a. Compas Interactive Inc. converts the accounts of users who have not
        used the services for one year into dormant accounts and stores the
        personal information separately from the information of other users. The
        separated and stored personal information will be destroyed immediately
        upon its storage for one year.
        <br />
        b. Compas Interactive Inc. notifies users via methods like email and
        text messages possible to notify users of the fact that personal
        information will be separated and stored, the scheduled date of
        conversion into dormant accounts and personal information items to be
        separately stored at least 30 days before the conversion into dormant
        accounts.
        <br />
        c. Users who do not want the conversion into dormant accounts may
        prevent the conversion by logging into the services before the
        conversion into dormant accounts. In addition, even if a user’s account
        has been converted into a dormant account, the dormant account may be
        restored and the user may use the services normally according to the
        user’s consent if the user logs in.
      </p>
      <h1>
        9. Rights and Obligations of Information Subjects and Legal
        Representatives and Methods of Exercising the Rights and Obligations
      </h1>
      <p>
        a. The information subject may exercise the right to read, correct,
        delete or request suspension of processing of his or her personal
        information at any time to Compas Interactive Inc.
        <br />
        ※ Requests, such as reading personal information, etc., concerning
        children under the age of 14 shall be made directly by their legal
        representatives, and the information subject who is a minor of at least
        14 years old may personally exercise the rights concerning the
        information subject’s personal information or do so through his or her
        legal representative.
        <br />
        b. Exercise of rights may be directed at Compas Interactive Inc. in
        writing or via email or facsimile, etc. pursuant to Article 41,
        Paragraph 1 of the Enforcement Decree of the Personal Information
        Protection Act, the Company will take action in response thereto without
        delay.
        <br />
        c. Exercise of rights may be conducted through the information subject’s
        agent, such as his or her legal representative or delegated person. In
        this case, the information subject must submit the power of attorney
        under Attached Form No. 11 of the Public Notice on Personal Information
        Processing Methods (No. 2020-7).
        <br />
        d. Requests to read and suspend the processing of personal information
        may lead to a restriction on the rights of the information subject
        according to Article 35, Paragraph 4 and Article 37, Paragraph 2 of the
        Personal Information Protection Act.
        <br />
        e. Requests for correction and deletion of personal information may not
        be requested if the personal information is stipulated as the subject of
        collection in other laws and regulations.
        <br />
        f. In the case of requests for the reading, requests for correction or
        deletion, requests for suspension of processing, etc., based on the
        rights of the information subject, Compas Interactive Inc. confirms
        whether the requester is the information subject himself or herself or
        his or her authorized agent.
      </p>
      <h1>10. Measures to Ensure Personal Information Safety</h1>
      <p>
        Compas Interactive Inc. is implementing the following measures to ensure
        the safety of personal information:
        <br />
        a. Administrative measures: Establishment and implementation of internal
        management plans, operation of dedicated organizations and regular
        training of employees.
        <br />
        b. Technical measures: Management of access privileges to personal
        information processing systems, etc., installation of access control
        systems, encryption of personal information, installation and renewal of
        security programs.
        <br />
        c. Physical measures: Access control to computer rooms and data storage
        rooms, etc.
      </p>
      <h1>
        11. Matters concerning Installation, Operation and Refusal of Automatic
        Personal Information Collection Tools
      </h1>
      <p>
        a. Compas Interactive Inc. uses ‘cookies’ that store and retrieves user
        information from time to time in order to provide personalized services
        to users.
        <br />
        b. Cookies are small volumes of information that the servers being used
        to operate the website send to users’ computer browsers, are stored on
        the hard disks of the users’ terminals (PCs, smartphones, tablet PCs,
        etc.) and expire when the web browsers are closed or the users log out.
        <br />
        1) Purpose of use of cookies: Cookies are used to provide optimized
        information to users by identifying the forms of visits and usage of
        respective services and websites visited by users, popular search
        keywords and status of Secure Socket Layer (SSL) access, etc.
        <br />
        2) Installation, operation and refusal of cookies: Users may refuse
        cookie storage by the option setting in the Tools > Internet Option >
        Personal Information menu at the top of the web browser.
        <br />
        3) There may be difficulties in using personalized services if cookie
        storage is refused.
      </p>
      <h1>
        12. Matters concerning the Collection, Use and Refusal of Behavioral
        Information
      </h1>
      <p>
        a. Compas Interactive Inc. collects and uses behavioral information to
        provide personalized services and benefits optimized to information
        subjects, online personalized advertisements, etc. during the course of
        using the services.
        <br />
        b. Compas Interactive Inc. collects behavioral information as follows:
        <br />
        Item
        <br />
        Method of Behavioral Information Collection
        <br />
        Purpose of Behavioral Information Collection
        <br />
        Period of Retention and Usage and Information Processing Method
        Thereafter
        <br />
        Users’ website/app service visit history, search history and purchase
        history
        <br />
        Automatically collected when users visit/run the website and app
        <br />
        Provision of personalized product recommendation services (including
        advertisements) based on users’ interests and tendencies
        <br />
        Destroyed two years after the collection date
        <br />
        Receiver
        <br />
        Provided Items
        <br />
        Receivers’ Purpose of Use
        <br />
        Users’ website visit history, users’ app use history, users’ search
        history, advertisement identifier
        <br />
        Provision of personalized advertisement based on user interest
      </p>
      <h1>13. Standard for Determining Additional Use and Provision</h1>
      <p>
        a. Under Article 15, Paragraph 3 and Article 17, Paragraph 4 of the
        Personal Information Protection Act, Compas Interactive Inc.. may
        additionally use and provide personal information without the
        information subject’s consent by considering matters pursuant to Article
        14-2 of the Enforcement Decree of the Personal Information Protection
        Act.
        <br />
        Item
        <br />
        Purpose of Use and Provision
        <br />
        Period of Retention and Use
        <br />
        Name, contact information, address
        <br />
        Delivery of additional notices and information materials concerning
        services provided regularly
        <br />
        Immediate destruction when the purpose is achieved
        <br />
        b. Accordingly, Compas Interactive Inc. has considered the following to
        additionally use and provide information without the information
        subject’s consent:
        <br />
        ◎ Whether the purpose of additional use and provision of personal
        information has relevance to the original purpose of collection
        <br />
        ◎ In light of the circumstances in which personal information was
        collected or the processing practices, whether there is the
        predictability of additional use and provision
        <br />
        ◎ Whether the additional use and provision wrongfully infringes upon the
        information subject’s interest
        <br />◎ Whether necessary measures have been taken to ensure the safety
        of information, such as pseudonymization or encryption
      </p>
      <h1>14. Processing of Pseudonymized Information</h1>
      <p>
        For preparing statistics, scientific research, public interest record
        preservation, etc., Compas Interactive Inc. may pseudonymize personal
        information collected so that a specific individual cannot be identified
        and process the pseudonymous information as follows:
        <br />
        - Matters concerning pseudonymous information processing;
        <br />
        Category
        <br />
        Purpose of Processing
        <br />
        Items Processed
        <br />
        Retention and Use Period
        <br />
        - Matters concerning the provision of pseudonymous information to third
        parties (if applicable)
        <br />
        Receiver
        <br />
        Receivers’ Purpose of Use
        <br />
        Provided Items
        <br />
        Receiver’s Retention and Use Period
        <br />
        - Matters concerning entrustment of pseudonymous information processing
        (if applicable)
        <br />
        Entrusted Person (Entrustee)
        <br />
        Entrusted Duties
        <br />
        Retention and Use Period
        <br />
        - Matters concerning measures to ensure the safety of pseudonymous
        information under Article 28-4 of the Act (Obligation of Safety Measures
        for Pseudonymous Information, Etc.)
        <br />
        ◎ Administrative measures: Establishment and implementation of internal
        management plans, and regular training of employees.
        <br />
        ◎ Technical measures: Management of access privileges to personal
        information processing systems, etc., installation of access control
        systems, encryption of unique identification information, installation
        of security programs.
        <br />◎ Physical measures: Access control to computer rooms and data
        storage rooms, etc.
      </p>
      <h1>15. Personal Information Protection Manager</h1>
      <p>
        a. Compas Interactive Inc. takes the responsibility for the overall
        management of duties concerning personal information processing and has
        designated a personal information protection manager as follows to
        handle complaints of and damage relief for information subjects
        concerning personal information processing:
        <br />
        ◎ Personal information protection manager :<br />
        Name: MOON SEONG JOON
        <br />
        Position: CEO
        <br />
        Contact: Phone no. +82 2-511-8285,
        <br />
        E-mail:compastrips@compastrips.com, Fax no. 02-511-8286
        <br />
        ※You will be connected to the department in charge of personal
        information protection.
        <br />
        ◎ Department in charge of personal information protection :<br />
        Department Name: Management Team
        <br />
        Person in charge: MOON SEONG JOON
        <br />
        Contact: Phone no. +82 2-511-8285,
        <br />
        E-mail:compastrips@compastrips.com, Fax no. 02-511-8286
        <br />
        b. Information subjects may inquire about matters concerning all
        personal information protection-related questions, complaint handling,
        damage relief, etc. that arose during the course of using the services
        (or business) of Compas Interactive Inc.to the personal information
        protection manager and the department in charge of personal information
        protection. Compas Interactive Inc. will respond to and handle inquiries
        from information subjects without delay.
      </p>
      <h1>16. Requesting to Read Personal Information</h1>
      <p>
        Information subjects may request the following department to read
        personal information pursuant to Article 35 of the Personal Information
        Protection Act. Compas Interactive Inc. will strive to promptly process
        the request to read the personal information of the information subject.
        <br />
        ◎ Department receiving and handling requests to read personal
        information:
        <br />
        Department Name: Management Team
        <br />
        Person in charge: MOON SEONG JOON
        <br />
        Contact : Phone no. +82 2-511-8285,
        <br />
        E-mail:compastrips@compastrips.com, Fax no. 02-511-8286.
      </p>
      <h1>17. Methods of Remedying Rights and Interests Infringement</h1>
      <p>
        a. Information subjects may apply for dispute resolution or
        consultation, etc. to the Personal Information Dispute Mediation
        Committee, the Personal Information Infringement Reporting Center of the
        Korea Internet & Security Agency, etc. to obtain remedies against
        personal information infringement. With regard to other reports and
        consultations of personal information infringement, please contact the
        following agencies:
        <br />
        1) Personal Information Dispute Mediation Committee: (without area code)
        1833-6972 (www.kopico.go.kr)
        <br />
        2) Personal Information Infringement Reporting Center: (without area
        code) 118 (privacy.kisa.or.kr)
        <br />
        3) Supreme Prosecutors’ Office: (without area code) 1301 (www.spo.go.kr)
        <br />
        4) National Police Agency: (without area code) 182 (ecrm.cyber.go.kr)
        <br />
        b. Compas Interactive Inc. guarantees information subjects’ right of
        self-determination of personal information and endeavors to consult and
        remedy the damages arising from personal information infringement;
        should you need to report or consult, please contact the following
        department in charge:
        <br />
        ◎ Customer consultation and reporting concerning personal information
        protection:
        <br />
        Department Name: Management Team
        <br />
        Person in charge:: MOON SEONG JOON
        <br />
        Contact: : Phone no. +82 2-511-8285,
        <br />
        E-mail:compastrips@compastrips.com, Fax no. 02-511-8286
        <br />
        c. In response to requests under the rules under Article 35 (Reading
        Personal Information), Article 36 (Correction or Deletion of Personal
        Information), Article 37 (Suspension of Personal Information Processing,
        Etc.) under the Personal Information Protection Act, a person whose
        rights or interests have been infringed upon due to the disposition or
        omission conducted by the head of a public agency may request an
        administrative appeal according to the stipulations under the
        Administrative Appeals Act.
        <br />◎ Central Administrative Appeals Commission: (without area code)
        110 (www.simpan.go.kr)
      </p>
      <h1>18. Privacy Policy Revisions</h1>
      <p>
        a. This Privacy Policy takes effect from February 1st, 2023.
        <br />
        b. Previous versions of this Privacy Policy can be found below.
      </p>
      <h1>19. Scope of Application</h1>
      <p>
        Compass Interactive, Inc. holds responsibility and authority concerning
        this Privacy Policy, and the Korean version of the Privacy Policy
        applies only to service users in Korea who use COMPASTRIPS.
        <br />
        In addition, the collection of personal information by other business
        operators, such as linked pages, falls outside the application scope of
        this Privacy Policy.
      </p>
    </>
  );
};

export default PrivacyUsecard_En;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AuthStorage from "../../helper/AuthStorage";

const HostDetails = ({ tourCourse, tourDetails, setTourDetails }) => {
  const { t } = useTranslation();

  const setDate = (startDate, endDate) => {
    let date = "-";
    if (!startDate && !endDate) {
      date = "-";
    } else if (startDate && !endDate) {
      date = `${moment(startDate).format("YYYY.MM.DD")}`;
    } else {
      date = `${moment(startDate).format("YYYY.MM.DD")} - ${moment(
        endDate
      ).format("YYYY.MM.DD")}`;
      // date = `${moment(startDate).format("YYYY.MM.DD") + " (" + moment(startDate).format("ddd") + ") " + moment(startDate).format("h:mm") + " - " + moment(endDate).format("h:mm")}`;
    }

    return date;
  };

  return (
    <Modal
      show={tourDetails}
      onHide={() => {
        setTourDetails(false);
      }}
      dialogClassName={
        AuthStorage.getLang() === "en"
          ? "roboto-text tour-details-modal custom-modal"
          : "noto-text tour-details-modal custom-modal"
      }
    >
      <Modal.Header>
        <Modal.Title
          id="tour-details-title"
          className="d-flex justify-content-between w-100"
        >
          <h6>{t("Tour_Course_Details.Header")}</h6>
          <Button
            variant="transparentBtn"
            onClick={() => setTourDetails(false)}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="20.6973"
                y1="7.75988"
                x2="7.75901"
                y2="20.6981"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <line
                x1="20.5702"
                y1="20.6992"
                x2="7.63194"
                y2="7.76096"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="itinery_details_modal p-0">
        {tourCourse && (
          <div className="tour-details-body">
            <table className="tour-details-table">
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.City")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {tourCourse?.region + ", " + tourCourse?.country}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Category")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {tourCourse?.category}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Name")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {tourCourse?.name}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Photos")}
                  </h6>
                </th>
                <td>
                  <div className="upload-pic d-flex flex-wrap">
                    {tourCourse?.image.map((data, i) => (
                      <div className="imgArea">
                        <img
                          key={i}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../../../img/download.svg";
                          }}
                          src={data}
                          alt="download icon"
                        />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Date")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {setDate(
                      tourCourse?.opening_date,
                      tourCourse?.closing_date
                    )}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black about-th">
                    {t("Tour_Course_Details.About")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray about-td">
                    {tourCourse?.summary}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Address")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {tourCourse?.address}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.URL")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {tourCourse?.website}
                  </pre>
                </td>
              </tr>
              <tr>
                <th>
                  <h6 className="font-16-bold color-black">
                    {t("Tour_Course_Details.Phone_Number")}
                  </h6>
                </th>
                <td>
                  <pre className="font-16-normal color-darkgray h-28">
                    {tourCourse?.telephone}
                  </pre>
                </td>
              </tr>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default HostDetails;

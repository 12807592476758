import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import * as QueryString from "query-string";
import ReadMore from "../../components/ReadMore";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/en-in";

import { checkImageURL, transporTation } from "../../helper/utils";
import {
  setChatId,
  setMessageState,
  setOtherUserData,
} from "../../redux/action/chatDataAction";
import { db } from "../../firebaseConfig";
import firebase from "firebase";
import AuthStorage from "../../helper/AuthStorage";
import Login from "../modal/Login";
import AlreadyHostModal from "../modal/AlreadyHostModal";
// import STORAGEKEY from "../../config/APP/app.config";
import AccountDeactive from "../modal/AccountDeactive";

const initialHostList = {
  count: 0,
  hosts: [],
};

const HostList = (props) => {
  const { t } = useTranslation();
  const { is_loggedin } = useSelector((state) => state.login);

  const [hostProfile, sethostProfile] = useState(false);
  const [hostingCom, SethostingCom] = useState(false);
  const [comHostNotice, SetcomHostNotice] = useState(false);
  // const [index, setIndex] = useState(0)

  //Raj
  const [hostList, setHostList] = useState(initialHostList);
  const [showHostCount, setShowHostCount] = useState(3);
  const { userData } = useSelector((state) => state.userData);
  const [checkCancleBox, setCheckCancleBox] = useState(false);
  const [host, setHost] = useState();

  const [participantList, setParticipantList] = useState([]);
  const [hostProfileData, setHostProfileData] = useState();
  const [isLiked, setIsLiked] = useState(false);
  const [hostLike, setHostLike] = useState(false);
  const [isHostingCompleted, setIsHostingCompleted] = useState(false);
  const isMobile = window.innerWidth <= 428;

  //Popup State
  const [hostNotice, sethostNotice] = useState(false);
  const [cancleHosting, setCancleHosting] = useState(false);
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const params = QueryString.parse(history.location.search);
  const { notificationData } = useSelector((state) => state.notification);
  useEffect(() => {
    if (params.hostingId) props.setIsCompas(params.hostingId);
  }, []);

  const date = (date) => {
    const newDate = new Date(date);

    return `${newDate.toLocaleString(AuthStorage.getLang(), {
      year: "numeric",
      month: "long",
      day: "2-digit",
    })}  (${newDate.toLocaleString(AuthStorage.getLang(), {
      weekday: "long",
    })})`;
  };

  // handlers
  // const handleHostProfile = (index) => {
  //   sethostProfile(true);
  //   ApiGet(`hosting/hostByHostingId/${hostList.hosts[index].id}`).then(
  //     (res) => {
  //       setHostProfileData(res.data);
  //       setIsLiked(res.data.user.like);
  //       setHostLike(res.data.user.like);
  //     }
  //   );
  // };

  const focusHostProfile = () => {
    if (props.showHostProfile) {
      window.scrollBy({
        behavior: "smooth",
        top: props.focus.current.getBoundingClientRect().top - 70,
      });
    } else {
      props.setShowHostProfile(true);
    }
  };

  const cancleHostingFunction = () => {
    sethostNotice(true);
    setCancleHosting(false);
    ApiPut(`hosting/cancelHosting/${host.hosting.id}`, {}).then((res) => {
      setHost(null);
      setCheckCancleBox(false);
      props.setRefreshPax(Math.random());
    });
  };

  // Work by Ravi Chodvadiya
  const redirectToJoin = (id) => {
    props.setIsCompas(id);
    let searchParams = "?id=" + params.id + "&hostingId=" + id;

    history.push(window.location.pathname + searchParams);
  };

  // Like functonality
  const [delayLike, setDelayLike] = useState(false);
  const Like = (id) => {
    setDelayLike(true);
    setIsLiked(!isLiked);
    ApiPost(`user/wishlist/${id}`, {
      hosting_id: params.hostingId,
    })
      .then((res) => {
        setDelayLike(false);
      })
      .catch((err) => {
        // console.log("Fail", err);
      });
  };

  // const hostLiked = (id) => {
  //   setHostLike(!hostLike);
  //   ApiPost(`user/wishlist/${id}`, {}).then((res) => {});
  // };

  //Checking for hosting completion
  const checkIsComplete = (date, endTime) => {
    let time = moment(
      moment(date).format("YYYY:MM:DD") + " " + endTime,
      "YYYY:MM:DD HH:mm"
    );
    return time.toDate() < moment(new Date(), "YYYY:MM:DD HH:mm").toDate();
  };

  //Complete Hosting
  const completeHosting = () => {
    ApiPut(
      `hosting/completeHosting/${
        hostList.hosts.filter((y) => y.user.id === userData.id)[0].id
      }`,
      {}
    ).then((res) => {
      SethostingComBtn();
      setIsHostingCompleted(true);
      // setCheckReviewButton(true);
      props.setIsHostingCompleted(true);
      props.setRefreshPax(Math.random());
    });
  };

  // Effects
  useEffect(() => {
    ApiGet(`hosting/host-itinerary/${params.id}`).then((res) => {
      if (res.data.length != 0) {
        const _hostList = { ...hostList };
        _hostList.count = res.data.length;
        _hostList.hosts = res.data.filter(
          (data) => data.user.id === userData.id
        );
        _hostList.hosts = _hostList.hosts.concat(
          res.data.filter((data) => data.user.id !== userData.id)
        );
        setHostList(_hostList);
        !isMobile && setShowHostCount(_hostList.count);
      }

      // res.data.map((x: any) => {
      //   x.status != "COMPLETED" &&
      //     x.participants.map((xx: any) => {
      //       if (userData.id === xx.user.id) {
      //         redirectToJoin(x.id);
      //       }
      //     });
      // });
    });
  }, [checkCancleBox, props.refresh]);

  useEffect(() => {
    if (userData && is_loggedin) {
      if (params.noti === "list" || params.noti === "mypage") {
        ApiGet(`hosting/isrequested-hosting-complited/${params.id}`).then(
          (res) => {
            if (res.data) {
              ApiGet(`hosting/hostByHostingId/${res.data.id}`).then((ress) => {
                setHost(ress.data ? ress.data : null);
                if (ress.data?.hosting?.status === "COMPLETED") {
                  setIsHostingCompleted(true);
                }
                setHostLike(ress.data ? ress.data.user.like : null);
              });
              setCheckCancleBox(true);
            } else {
              setCheckCancleBox(false);
            }
          }
        );
      } else {
        ApiGet(`hosting/isrequested-hosting/${params.id}`).then((res) => {
          if (res.data) {
            ApiGet(`hosting/hostByHostingId/${res.data.id}`).then((ress) => {
              setHost(ress.data ? ress.data : null);
              if (ress.data?.hosting?.status === "COMPLETED") {
                setIsHostingCompleted(true);
              }
              setHostLike(ress.data ? ress.data.user.like : null);
            });
            setCheckCancleBox(true);
          } else {
            setCheckCancleBox(false);
          }
        });
      }
    } else {
      setCheckCancleBox(false);
    }
  }, [hostList, userData, props.refreshHost]);

  useEffect(() => {
    if (host?.hosting?.status === "UPCOMING") {
      ApiGet(`hosting/accept-participants/${host.hosting.id}`).then((res) => {
        setParticipantList(res.data.participants);
      });
    }
  }, [host, props.refresh]);

  useEffect(() => {
    if (props.showHostProfile) {
      props.focus.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.showHostProfile]);

  const SethostingComBtn = () => {
    SethostingCom(false);
    SetcomHostNotice(true);
  };

  // Create a chat
  const dispatch = useDispatch();
  const { chatData, message_open } = useSelector((state) => state.chatData);
  const createChat = async (current_user, other_user) => {
    let doc_id;
    if (
      !current_user.id &&
      !other_user.id &&
      current_user.id === other_user.id
    ) {
      return;
    }
    if (current_user.id > other_user.id) {
      doc_id = current_user.id + other_user.id;
    } else {
      doc_id = other_user.id + current_user.id;
    }

    if (chatData.find((x) => x.id === doc_id)) {
      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    } else {
      const ref = db.collection(AuthStorage.getMessageCollection()).doc(doc_id);
      await ref.set(
        {
          [`${current_user.id}_count`]: 0,
          [`${other_user.id}_count`]: 0,
          lastMessage: "",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ids: [current_user.id, other_user.id],
          [`${current_user.id}`]: {
            id: current_user.id,
            name: current_user.name,
            profile_url: current_user.profile_url,
          },
          [`${other_user.id}`]: {
            id: other_user.id,
            name: other_user.name,
            profile_url: other_user.profile_url,
          },
        },
        { merge: true }
      );

      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    }
  };

  // component helper function
  const showParticipants = () => (
    <>
      <div className="total-pax-joining">
        <p>
          {t("Host_Own.pax1")}
          <span>
            {host.hosting.participate_count}
            {t("Host_Own.pax3")}
          </span>
          /{host.hosting.pax}
          {t("Host_Own.pax3")}
          {t("Host_Own.pax2")}
        </p>
      </div>

      {participantList.map((participant, i) => (
        <div className="single-pax-list" key={i}>
          <div className="d-flex align-items-center">
            <img
              src={participant.user.avatar || "./img/Avatar.png"}
              alt=""
              style={{ width: "36px", height: "36px", borderRadius: "50%" }}
            />
            <h5>
              {participant.user.user_name.length >= 8
                ? participant.user.user_name.slice(0, 8) + ".."
                : participant.user.user_name}
            </h5>
          </div>

          <div className="d-flex align-items-center">
            <img
              src={checkImageURL(participant.user.nationality)}
              style={{ objectFit: "cover" }}
              alt=""
              className="pax-flag-imag"
            />
            {participant.user.gender === "MALE" ? (
              <div className="host-gender">
                <p>{t("Male")}</p>
              </div>
            ) : (
              <div className="host-gender">
                <p>{t("Female")}</p>
              </div>
            )}
            <div className="host-ages">
              {AuthStorage.getLang() === "en" ? (
                <p>
                  {participant.user.age_group}'{t("Age_Groups")}
                </p>
              ) : (
                <p className="d-flex align-items-center">
                  {participant.user.age_group}
                  <span>{t("Age_Groups")}</span>
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );

  const showHostList = () => {
    return (
      <>
        {[...hostList.hosts.slice(0, showHostCount)].map((items, i) => {
          if (items.confirm_status === "confirmed_review") {
            return (
              <div
                className="single-local-host d-flex align-items-center justify-content-between"
                key={i}
                onClick={() => {
                  redirectToJoin(items.id);
                }}
              >
                <div className="single-host-info ">
                  <div className="host-inner d-flex align-items-center">
                    <div className="position-relative">
                      <div className="host-img circle">
                        <img
                          src={items.user.avatar || "./img/Avatar.png"}
                          alt=""
                          style={{ objectFit: "cover" }}
                          className="desk-flag"
                        />

                        {/* responsive side menu start */}
                        <button
                          className="img-button"
                          onClick={() => {
                            redirectToJoin(items.id);
                          }}
                        >
                          <img
                            src={items.user.avatar || "./img/Avatar.png"}
                            alt=""
                            style={{ objectFit: "cover" }}
                            className="res-img"
                          />
                        </button>
                        {/* responsive side menu End */}
                      </div>
                      {userData.id === items.user.id && (
                        <svg
                          className="approval-right position-absolute"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="16" height="16" rx="8" fill="#42B6E6" />
                          <path
                            d="M6.30778 9.58671L6.70022 9.91142L7.0176 9.51303C7.74563 8.59917 9.17439 6.97686 11.1294 5.43536C9.52706 7.28616 8.19799 9.36994 7.39986 10.9519C7.25581 11.2374 6.88266 11.2677 6.71232 11.0849L4.17444 8.36167C4.093 8.27428 4.10127 8.13651 4.19259 8.05949L3.87022 7.67729L4.19259 8.05949C4.26984 7.99434 4.38264 7.99381 4.4605 8.05823L6.30778 9.58671Z"
                            fill="white"
                            stroke="white"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="pro-tag-name">
                      <div className="name-with-flag d-flex">
                        <h3 className="pro-name-suah ">
                          {items.user.user_name.length > 8
                            ? items.user.user_name.slice(0, 8) + ".."
                            : items.user.user_name}
                        </h3>
                        <div className="host-flag circle">
                          <img
                            src={checkImageURL(items.user.nationality)}
                            style={{ objectFit: "cover" }}
                            alt="flag"
                          />
                        </div>
                      </div>
                      <div className="pro-tag-suah d-flex">
                        {items.type === "Local" ? (
                          <div className="host-category">
                            <p>{t("Local_Host")}</p>
                          </div>
                        ) : (
                          <div className="travel-host-category">
                            <p>{t("Traveler_Host")}</p>
                          </div>
                        )}

                        {items.user.gender === "MALE" ? (
                          <div className="host-gender">
                            <p>{t("Male")}</p>
                          </div>
                        ) : (
                          <div className="host-gender">
                            <p>{t("Female")}</p>
                          </div>
                        )}
                        <div className="host-ages">
                          {AuthStorage.getLang() === "en" ? (
                            <p>
                              {items.user.age_group}'{t("Age_Groups")}
                            </p>
                          ) : (
                            <p className="d-flex align-items-center justify-content-center">
                              {items.user.age_group}
                              <span>{t("Age_Groups")}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="suah-date-time">
                    {date(items.date)} {items.start_time.slice(0, 5)} -&nbsp;
                    {items.end_time.slice(0, 5)}
                  </p>
                  {resize <= 768 ? (
                    <p className="total-host-join">
                      {/* {t("Host_Own.pax1")} */}
                      <span className="font-blue">
                        {items.participate_count}
                      </span>
                      {t("Host_Own.pax3")}/{items.pax}
                      {t("Host_Own.pax3")} {t("Host_Own.pax2")}
                    </p>
                  ) : (
                    <p className="total-host-join">
                      {/* {t("Host_Own.pax1")} */}
                      <span className="font-blue">
                        {items.participate_count}
                        {/* {t("Host_Own.pax3")} */}
                      </span>
                      /{items.pax}
                      {t("Host_Own.pax3")} {t("Host_Own.pax2")}
                    </p>
                  )}
                </div>
                <button
                  className="info-arrow-btn d-flex align-items-center"
                  onClick={() => {
                    redirectToJoin(items.id);
                  }}
                >
                  <img src="./img/host-arrow.svg" alt="host-arrow icon" />
                </button>

                {/* <div className="single-local-host-inner cursor-p"></div> */}
              </div>
            );
          }
        })}
        {/* Responsive More button start */}
        {hostList.count > showHostCount && (
          <div className="res-more-viewhost">
            <Button
              variant="cancelBtn"
              className="w-100"
              onClick={() => {
                setShowHostCount(showHostCount + 3);
              }}
            >
              More
            </Button>
          </div>
        )}
        {/* Responsive More button End */}
      </>
    );
  };

  const emptyParticipants = () => (
    <div className="main-emptycontent">
      <div className="mt-30">
        <p className="font-16-bold">
          {t("Host_Own.pax1")} 0{t("Host_Own.pax3")}/{host.hosting.pax}
          {t("Host_Own.pax3")} {t("Host_Own.pax2")}
        </p>
      </div>

      <div className="empty-detailscontent">
        <p className="font-18-normal color-darkgray mt-60 mb-60">
          {t("Empty_Participates.Text")}
        </p>
      </div>
    </div>
  );

  const emptyHostlist = () => (
    <p className="emptyHostList text-center">{t("Empty_Hostlist.Text")}</p>
  );

  const completeHostingBtn = () => (
    <>
      {host.hosting.participate_count === host.hosting.pax ? (
        <div className="">
          {checkIsComplete(host?.hosting?.date, host?.hosting?.end) ? (
            <Button
              variant="confirmBtn"
              className="single-host-appstatus w-100"
              onClick={() => {
                is_loggedin && SethostingCom(true);
              }}
            >
              <img
                src="./img/whiteWrite.svg"
                className=""
                alt="whiteWrite icon"
              />
              {t("Host_Details.Hosting_Complete.P1")}{" "}
              {t("Host_Details.Hosting_Complete.P2")}
            </Button>
          ) : (
            <>
              {host.user.id === userData.id ? (
                <Button className="black p-0 ">
                  <img src="./img/time-left.svg" alt="" className="time-left" />
                  {t("Host_Details.Hosting_Now")}
                </Button>
              ) : (
                <Button
                  variant="confirmBtn"
                  className="single-host-appstatus w-100"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27ZM11.5 15.5C12.3284 15.5 13 14.8284 13 14C13 13.1716 12.3284 12.5 11.5 12.5C10.6716 12.5 10 13.1716 10 14C10 14.8284 10.6716 15.5 11.5 15.5ZM22 14C22 14.8284 21.3284 15.5 20.5 15.5C19.6716 15.5 19 14.8284 19 14C19 13.1716 19.6716 12.5 20.5 12.5C21.3284 12.5 22 13.1716 22 14ZM9.26273 18.8157C8.87962 18.2188 9.3559 17.5 10.0651 17.5H10.2259C10.5829 17.5 10.9075 17.6942 11.1151 17.9847C12.2032 19.5074 13.9857 20.5 16 20.5C18.0143 20.5 19.7968 19.5074 20.8849 17.9847C21.0925 17.6942 21.4171 17.5 21.7741 17.5H21.9349C22.6441 17.5 23.1204 18.2188 22.7373 18.8157C21.3148 21.0317 18.8289 22.5 16 22.5C13.1711 22.5 10.6852 21.0317 9.26273 18.8157Z"
                      fill="white"
                    />
                  </svg>

                  {t("Host_Details.Full_House")}
                </Button>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          {checkIsComplete(host?.hosting?.date, host?.hosting?.end) ? (
            <Button
              className="ConfirmHosting"
              onClick={() => {
                is_loggedin && SethostingCom(true);
              }}
            >
              <img src="./img/WhiteRight.svg" alt="WhiteRight icon" />
              {t("Host_Details.Hosting_Completeds")}
            </Button>
          ) : (
            <button
              className={`${
                checkIsComplete(host?.hosting?.date, host?.hosting?.end)
                  ? "comp-my-host"
                  : "black"
              }  p-0`}
              disabled={checkIsComplete(
                host?.hosting?.date,
                host?.hosting?.end
              )}
            >
              {(host?.hosting?.date, host?.hosting?.end) ? (
                <img
                  src="./img/time-left.svg"
                  alt="time-left icon"
                  className="time-left"
                />
              ) : (
                <img src="./img/whiteWrite.svg" alt="whiteWrite icon" />
              )}
              {(host?.hosting?.date, host?.hosting?.end)
                ? t("Host_Details.Hosting_Now")
                : t("Host_Details.Hosting_Complete.P3")}
            </button>
          )}
        </div>
      )}
      <div className="">
        <button
          className="cancle-my-host p-0"
          onClick={() => {
            is_loggedin && props.setRefreshHost(Math.random());
            setCancleHosting(true);
          }}
        >
          {t("Host_Details.Cancle_My_Host")}
        </button>
      </div>
    </>
  );

  const showHostingInformationData = () => (
    <>
      <div className="hostlistsidebar-content infodetails">
        <pre
          className={
            AuthStorage.getLang() === "en" ? "roboto-text" : "noto-text"
          }
        >
          <p
            className={
              AuthStorage.getLang() === "en" ? "roboto-text" : "noto-text"
            }
          >
            {host.hosting.info}
          </p>
        </pre>
      </div>
    </>
  );

  const hostDetails = () => (
    <>
      <div className="Usersidebar">
        <div className="">
          <div className=" single-local-hostsecinner postion-relative">
            <div className="likechetBox">
              <div className="join-pro">
                {host?.user?.id !== userData.id && (
                  <div className="join-msg ">
                    {/* {console.log("HostList:529", host)} */}
                    <img
                      src={`${
                        userData?.notification && host?.user.notification_on
                          ? "./img/msg.svg"
                          : "./img/msg-grey.png"
                      }`}
                      alt=""
                      className="chatbox"
                      onClick={() => {
                        if (!is_active) {
                          setDeactivatedNotice(true);
                          return;
                        }
                        is_loggedin ? (
                          <>
                            {userData?.notification &&
                              host?.user.notification_on &&
                              createChat(
                                {
                                  id: userData.id,
                                  name: userData.user_name,
                                  profile_url: userData.avatar,
                                },
                                {
                                  id: host?.user.id,
                                  name: host?.user.user_name,
                                  profile_url: host?.user.avatar,
                                }
                              )}
                          </>
                        ) : (
                          notlogin()
                        );
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="tout-created ml-auto">
                <div className="download-heart-icon button ">
                  <div className="heart-div">
                    <input
                      type="checkbox"
                      checked={isLiked}
                      disabled={delayLike}
                      onChange={() => is_loggedin && Like(host?.user.id)}
                      id="id"
                      className="instruments"
                    />
                    <label htmlFor="id" className="check mb-0">
                      {!isLiked && (
                        <svg
                          width="39"
                          height="38"
                          viewBox="0 0 39 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                            stroke="#C9C9C9"
                            stroke-width="2"
                          />
                          <rect
                            x="22"
                            y="13"
                            width="17"
                            height="14"
                            fill="white"
                          />
                          <rect
                            x="29"
                            y="14"
                            width="2"
                            height="12"
                            fill="#C9C9C9"
                          />
                          <rect
                            x="36"
                            y="19"
                            width="2"
                            height="12"
                            transform="rotate(90 36 19)"
                            fill="#C9C9C9"
                          />
                        </svg>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-local-host-profile">
              <img
                src={host.user.avatar || "./img/Avatar.png"}
                alt=""
                style={{ objectFit: "cover" }}
              />
              <div className="pro-tag-name">
                <div className="pro-name-suah">
                  <h3>
                    {host.user.user_name.length < 9
                      ? host.user.user_name
                      : host.user.user_name.slice(0, 9) + "..."}
                  </h3>
                  <img
                    src={checkImageURL(host.user.flag)}
                    style={{ objectFit: "cover" }}
                    alt="flag"
                  />
                </div>
                <div className="pro-tag-suah">
                  {host.hosting.type === "Local" ? (
                    <div className="host-category">
                      <p>{t("Local_Host")}</p>
                    </div>
                  ) : (
                    <div className="travel-host-category">
                      <p>{t("Traveler_Host")}</p>
                    </div>
                  )}
                  {host.user.gender === "MALE" ? (
                    <div className="host-gender">
                      <p>{t("Male")}</p>
                    </div>
                  ) : (
                    <div className="host-gender">
                      <p>{t("Female")}</p>
                    </div>
                  )}
                  <div className="host-ages">
                    {AuthStorage.getLang() === "en" ? (
                      <p>
                        {host.user.age}'{t("Age_Groups")}
                      </p>
                    ) : (
                      <p className="d-flex align-items-center justify-content-center">
                        {host.user.age}
                        <span>{t("Age_Groups")}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-40 mb-40 hostlistbtnbox">
              {/* {console.log('isHostingCompleted', isHostingCompleted)} */}
              {isHostingCompleted ? (
                <Button
                  variant="cancelBtn"
                  className="single-host-appstatus w-100"
                >
                  <img src="./img/blueWrite.svg" alt="" />
                  {t("Host_Details.Hosting_Completed")}
                </Button>
              ) : (
                completeHostingBtn()
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="UserhostlistDate">
        <div className="d-flex justify-content-between align-items-center main-date-content">
          <p className=" date-title">{t("Host_Details.Date&Time")}</p>
          <p className="main-date">
            {host.hosting.date.replaceAll("-", ".")}{" "}
            {host.hosting.start.slice(0, 5)}
            {" - "}
            {host.hosting.end.slice(0, 5)}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center maintour-content">
          <p className="tourstart-title">{t("Host_Details.Starts_At")}</p>
          <p className="tourdetails">{host.hosting.location}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center main-transportation-content">
          <p className="transprotation-title">
            {t("Host_Details.Transportation")}
          </p>
          <p className="transportaion-details">
            {AuthStorage.getLang() === "ko"
              ? transporTation(host.hosting.transportation)
              : host.hosting.transportation}
          </p>
        </div>
      </div>
      <>{showHostingInformationData()}</>
      <div
        className={
          host.hosting.participate_count ? "pax-list-inner-hosted" : ""
        }
      >
        {/* <div className="pax-list-inner-hosted"> */}
        {host.hosting.participate_count
          ? showParticipants()
          : emptyParticipants()}
      </div>
    </>
  );

  const is_active = AuthStorage.isUserActive();
  const [modalShow, setModalShow] = useState(false);
  const notlogin = () => {
    setModalShow(true);
  };
  const [hostingNotice, setHostingNotice] = useState(false);

  const [canHost, setCanHost] = useState(false);
  const isAlreadyHosting = () => {
    ApiGet("hosting/isAlreadyHosted").then((res) => {
      setCanHost(res?.data?.status);
    });
  };

  useEffect(() => {
    isAlreadyHosting();
  }, []);
  return (
    <>
      <div className="hosting-inner-list">
        <div className="sidebar-host-button">
          {is_loggedin ? (
            <Button
              variant="confirmBtn"
              className="w-100"
              // onClick={focusHostProfile}
              onClick={() => {
                if (is_active) {
                  !canHost ? setHostingNotice(true) : focusHostProfile();
                } else {
                  setDeactivatedNotice(true);
                }
              }}
            >
              {t("Empty_Hostlist.Host_Btn")}
            </Button>
          ) : (
            <Button variant="confirmBtn" onClick={notlogin} className="w-100">
              {t("Empty_Hostlist.Host_Btn")}
            </Button>
          )}
          {!checkCancleBox && (
            <></>
            // <button
            //   onClick={() => is_loggedin ? focusHostProfile() : notlogin()}
            // >
            //   {t("Empty_Hostlist.Host_Btn")}
            // </button>
          )}
        </div>
        {!host && <></>}

        {/* if user has already hosted or not */}
        {hostList.hosts.filter((x) => x.confirm_status !== "request_review")
          .length !== 0 ? (
          <div className="host-list">{showHostList()}</div>
        ) : (
          emptyHostlist()
        )}

        <Login
          show={modalShow}
          loginValue=""
          onHide={() => setModalShow(false)}
          onHideNew=""
          onShow=""
        />
      </div>

      <AlreadyHostModal
        hostingNotice={hostingNotice}
        setHostingNotice={() => setHostingNotice(false)}
      />

      <Modal
        show={hostProfile}
        onHide={() => {
          sethostProfile(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal-pro"
            : "noto-text welcome-modal host-modal-pro"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0" closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal-signup-title ">
            <h3>{t("Host_Profile_Popup.Title")}</h3>
          </div>
          <div className="custom-border-b mt-40"></div>
          {hostProfileData ? (
            <>
              <div className="w-100 mt-33">
                <div className="host">
                  <div>
                    <img
                      src={hostProfileData?.user?.avatar || "./img/Avatar.png"}
                      alt=""
                    />
                  </div>
                  <div className="ml-20 w-100">
                    <div className="d-flex">
                      <div className="d-flex img-join-host h-36 ">
                        <h5 className="font-20-bold color-dark mr-18">
                          {hostProfileData.user.user_name.length > 8
                            ? hostProfileData.user.user_name.slice(0, 8) + ".."
                            : hostProfileData.user.user_name}
                        </h5>
                        <img
                          src={checkImageURL(hostProfileData.user.flag)}
                          alt="flag"
                          style={{ objectFit: "cover" }}
                        />
                      </div>

                      <div className="d-flex join-pro">
                        {hostProfileData?.user.id !== userData.id && (
                          <div className="join-msg">
                            {/* {console.log("HostList:743", hostProfileData)} */}
                            <img
                              src={`${
                                userData?.notification &&
                                hostProfileData?.user.notification_on
                                  ? "./img/msg.svg"
                                  : "./img/msg-grey.png"
                              }`}
                              alt=""
                              style={{
                                width: "23.33px",
                                height: "26.35px",
                                marginLeft: "18.97px",
                              }}
                              onClick={() => {
                                userData?.notification &&
                                  hostProfileData?.user.notification_on &&
                                  createChat(
                                    {
                                      id: userData.id,
                                      name: userData.user_name,
                                      profile_url: userData.avatar,
                                    },
                                    {
                                      id: hostProfileData?.user.id,
                                      name: hostProfileData?.user.user_name,
                                      profile_url: hostProfileData?.user.avatar,
                                    }
                                  );
                              }}
                            />
                          </div>
                        )}
                        <div className="tout-created ml-auto">
                          <div className="download-heart-icon button ">
                            <div className="heart-div">
                              <input
                                type="checkbox"
                                checked={isLiked}
                                disabled={delayLike}
                                onClick={() =>
                                  is_loggedin && Like(hostProfileData?.user.id)
                                }
                                id="id2"
                                className="instruments"
                              />
                              <label
                                htmlFor="id2"
                                className="text-white check mb-0"
                              >
                                {!isLiked ? (
                                  <svg
                                    width="39"
                                    height="38"
                                    viewBox="0 0 39 38"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                                      stroke="#C9C9C9"
                                      stroke-width="2"
                                    ></path>
                                    <rect
                                      x="22"
                                      y="13"
                                      width="17"
                                      height="14"
                                      fill="white"
                                    ></rect>
                                    <rect
                                      x="29"
                                      y="14"
                                      width="2"
                                      height="12"
                                      fill="#C9C9C9"
                                    ></rect>
                                    <rect
                                      x="36"
                                      y="19"
                                      width="2"
                                      height="12"
                                      transform="rotate(90 36 19)"
                                      fill="#C9C9C9"
                                    ></rect>
                                  </svg>
                                ) : (
                                  <svg
                                    width="38"
                                    height="38"
                                    viewBox="0 0 38 38"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                                      fill="#FF5F5F"
                                      stroke="#FF5F5F"
                                      stroke-width="2"
                                    />
                                  </svg>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="host-info mt-14">
                      <div
                        className={
                          hostProfileData.hosting.type === "Local"
                            ? "local-host-bg hots-tags"
                            : "travel-host-bg hots-tags"
                        }
                      >
                        <p className="info">
                          {hostProfileData.hosting.type === "Local"
                            ? t("Local_Host")
                            : t("Traveler_Host")}
                        </p>
                      </div>
                      <div className="hots-tags">
                        <p className="info">
                          {hostProfileData.user.gender === "MALE"
                            ? t("Male")
                            : t("Female")}
                        </p>
                      </div>
                      <div className="hots-tags">
                        <p className="info">
                          {hostProfileData.user.age}
                          {t("Age_Groups")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-border-b mt-30"></div>

              <div className="mt-30">
                <p className="font-20-bold color-dark h-24">
                  {t("Host_Profile_Popup.Now_Hosting")}
                </p>

                <div className="mt-24 d-flex">
                  <div className="mr-50 host-profile-modal">
                    <img src={hostProfileData.course.image} alt="" />
                    {/* <img src="./img / Image Base.svg" /> */}
                  </div>
                  <div>
                    <p className="font-20-bold color-black h-24">
                      {hostProfileData.itinerary.title}
                    </p>
                    <p className="font-16-normal color-darkgray h-22 mt-6">
                      @{hostProfileData.itinerary.region}
                      {hostProfileData.itinerary.country}
                    </p>
                    <p className="font-16-normal color-darkgray h-24 mt-10">
                      ★ {hostProfileData.review.star}｜{" "}
                      {t("Host_Profile_Popup.Reviews1")}
                      {hostProfileData.review.review}
                      {t("Host_Profile_Popup.Reviews2")} ｜
                      {/* <span>
                        <img src="./img/onlyheart.svg" alt="" />
                      </span>
                      {hostProfileData.review.like} */}
                    </p>
                    <div className="mt-18">
                      <h6 className="font-18-normal color-black ls-one">
                        <ReadMore>
                          {hostProfileData.course.courses.map((x, i) => {
                            return `${i + 1}. ${x} `;
                          })}
                        </ReadMore>
                      </h6>
                      <p className="font-16-normal color-darkgray mt-10">
                        {hostProfileData.hosting.date.replaceAll("-", ".")}
                        {hostProfileData.hosting.start.slice(0, 5)}
                        {" - "}
                        {hostProfileData.hosting.end.slice(0, 5)}
                      </p>
                      <h1 className="font-16-bold h-20 mt-20">
                        {t("Host_Own.pax1")}
                        <span className="blue-font">
                          {hostProfileData.hosting.participate_count}
                        </span>
                        /{hostProfileData.hosting.pax}
                        {t("ViewHost.pax1")} {t("ViewHost.pax2")}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>

      {/* Cancel Hosting Popup */}
      <Modal
        show={cancleHosting}
        onHide={() => {
          setCancleHosting(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal"
            : "noto-text welcome-modal host-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div className="modal-signup-title ">
            <h3>{t("Cancel_Hosting_Popup.Title")}</h3>
          </div>
          <div className="welcome-content welcome-body mt-60">
            <div className="canclehosting-inner">
              <div className="canclehostingcontenet">
                <h3 className="font-20-normal color-black">
                  {t("Cancel_Hosting_Popup.Question")}
                </h3>
                <p className="font-18-normal color-darkgray mt-34">
                  {t("Cancel_Hosting_Popup.Text")}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-between mt-40 canclehostingbtnbox">
          <div className="">
            <button
              // ButtonStyle="join-cancle-btn"
              onClick={() => {
                setCancleHosting(false);
              }}
              className="hostingcancle"
            >
              {t("Cancel_Hosting_Popup.Cancel")}
            </button>
          </div>
          <div className="">
            <button
              // ButtonStyle="join-apply-btn"
              onClick={() => cancleHostingFunction()}
              className="confirmHosting"
            >
              {t("Cancel_Hosting_Popup.Confirm")}
            </button>
          </div>
        </div>
      </Modal>

      {/* Cancel Hosting Notice Popup */}
      <Modal
        show={hostNotice}
        onHide={() => {
          sethostNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal"
            : "noto-text welcome-modal host-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div className="modal-signup-title ">
            <h3>{t("Cancel_Notice_Popup.Notice")}</h3>
          </div>
          <div className="accept-notice mt-60">
            <div className="acceptnotice-inner">
              <p className="h-60 text-center">
                {t("Cancel_Notice_Popup.Body")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className="w-100 mt-50">
          <div className="accept-okay">
            <button
              // ButtonStyle="app-sent-ok"
              onClick={() => {
                sethostNotice(false);
              }}
            >
              {t("Cancel_Notice_Popup.OK")}
            </button>
          </div>
        </div>
      </Modal>

      {/* Hosting Completed Popup */}
      <Modal
        show={hostingCom}
        onHide={() => {
          SethostingCom(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal"
            : "noto-text welcome-modal host-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            className={
              AuthStorage.getLang() === "en"
                ? "modal-signup-title "
                : "modal-signup-titleko "
            }
          >
            <h3 className="h-34">{t("Hosting_Complete.Title")}</h3>
          </div>
          <div className="com-hosting mt-60">
            <div
              className={
                AuthStorage.getLang() === "en"
                  ? "comHostingInner"
                  : "comHostingInnerko"
              }
            >
              <p className="text-center font-18-normal color-darkgray h-27">
                {t("Hosting_Complete.Text")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div
          className={
            AuthStorage.getLang() === "en"
              ? "d-flex justify-content-between mt-50 comHostingBtnbox"
              : "d-flex justify-content-between mt-50 comHostingBtnboxko"
          }
        >
          <div className="cancle">
            <button
              // ButtonStyle="join-cancle-btn"
              onClick={() => SethostingCom(false)}
            >
              {t("Hosting_Complete.Cancel")}
            </button>
          </div>
          <div className="yes">
            <button
              // ButtonStyle="join-apply-btn"
              onClick={() => completeHosting()}
            >
              {t("Hosting_Complete.Yes")}
            </button>
          </div>
        </div>
      </Modal>

      {/* Hosting Complete Notice Popup */}
      <Modal
        show={comHostNotice}
        onHide={() => {
          SetcomHostNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal"
            : "noto-text welcome-modal host-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div
            className={
              AuthStorage.getLang() === "en"
                ? "modal-signup-title h-34"
                : "modal-signup-titleko h-34"
            }
          >
            <h3>{t("Hosting_Complete.Notice.Title")}</h3>
          </div>
          <div className="yes-notice mt-60">
            <div
              className={
                AuthStorage.getLang() === "en"
                  ? "YesNoticeInner text-center"
                  : "YesNoticeInnerko text-center"
              }
            >
              <p className="font-17-normal">
                {t("Hosting_Complete.Notice.Body")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className="w-100 mt-50">
          <div
            className={
              AuthStorage.getLang() === "en" ? "YseNoticeBtn" : "YseNoticeBtnko"
            }
          >
            <button
              // ButtonStyle="app-sent-ok"
              onClick={() => {
                SetcomHostNotice(false);
              }}
            >
              {t("Hosting_Complete.Notice.OK")}
            </button>
          </div>
        </div>
      </Modal>
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </>
  );
};

export default HostList;

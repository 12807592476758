import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as QueryString from "query-string";
import moment from "moment";
import Rating from "react-rating";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import AuthStorage from "../../../helper/AuthStorage";
import { ApiGet, ApiPost } from "../../../helper/API/ApiData";
import Tabs from "../../../common/Tabs";
import TourCard from "../../user/TourCard";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import { pubTrendingNowState } from "redux/action/trendingNowAction";
import { setGlobalSearch } from "redux/action/persistDataAction";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostPro from "../../../components/HostPro";

const initialData = {
  count: 0,
  itinerary: [],
};

const TourListMobile = ({
  LoginModalClick,
  setActiveIndex,
  activeIndex,
  activeTab,
  setActiveTab,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(0);
  const { is_loggedin } = useSelector((state) => state.login);
  const [activeKeyword, setActiveKeyword] = useState("");
  const [activeSlide, setActiveSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const [hasMore, setHasMore] = useState(false);
  const observer = useRef();
  const [total, setTotal] = useState(1);
  const [perPage, setPerPage] = useState(80);
  const [scrollPosition, setScrollPosition] = useState(0);
  const persistData = useSelector((state) => state.persistData);
  // const tabsList = ["All", "Local Picks", "K-pop", "Festivals & Events", "Popular Destinations"];
  let searchParam = QueryString.parse(history.location.search);

  const [tabList, setTabList] = useState([
    {
      category: "All",
      value: `${t("Homepage.NavBar.All")}`,
      label: `${t("Homepage.NavBar.All")}`,
      keyword: "",
    },
    {
      category:
        AuthStorage.getLang() === "en" ? "Local Picks" : "현지인만 아는",
      value: `${t("Homepage.NavBar.Local_Picks")}`,
      label: `${t("Homepage.NavBar.Local_Picks")}`,
      keyword: "",
    },
    {
      category: "Arts & Culture",
      value: `${t("Homepage.NavBar.ArtCulture")}`,
      label: `${t("Homepage.NavBar.ArtCulture")}`,
      keyword: "",
    },
    {
      category: "Festivals & Events",
      value: `${t("Homepage.NavBar.Festivals")}`,
      label: `${t("Homepage.NavBar.Festivals")}`,
      keyword: "",
    },
    {
      category:
        AuthStorage.getLang() === "en" ? "Popular Destination" : "인기명소",
      value: `${t("Homepage.NavBar.Popular")}`,
      label: `${t("Homepage.NavBar.Popular")}`,
      keyword: "",
    },
  ]);

  useEffect(() => {
    setPage(1);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    getSearchedItinerary();
  }, [activeTab, perPage, page, location, activeKeyword, refresh]);

  //search
  const dispatch = useDispatch();
  const returnCategoryActiveData = (ActiveTab) => {
    let category = "";
    let category_ko = "";
    if (AuthStorage.getLang() === "en") {
      switch (ActiveTab) {
        case "All":
          category = "All";
          category_ko = "전체";
          break;

        case "Popular Destination":
          category = "Popular Destination";
          category_ko = "인기명소";
          break;

        case "Local Picks":
          category = "Local Picks";
          category_ko = "현지인만 아는";
          break;

        case "Festivals & Events":
          category = "Festivals & Events";
          category_ko = "축제와 이벤트";
          break;

        case "Arts & Culture":
          category = "Arts & Culture";
          category_ko = "문화예술";
          break;

        default:
          break;
      }
    } else {
      switch (ActiveTab) {
        case "전체":
          category = "All";
          category_ko = "전체";
          break;

        case "인기명소":
          category = "Popular Destination";
          category_ko = "인기명소";
          break;

        case "현지인만 아는":
          category = "Local Picks";
          category_ko = "현지인만 아는";
          break;

        case "축제와 이벤트":
          category = "Festivals & Events";
          category_ko = "축제와 이벤트";
          break;

        case "Arts & Culture":
          category = "Arts & Culture";
          category_ko = "문화예술";
          break;

        default:
          break;
      }
    }

    return {
      category: category,
      category_ko: category_ko,
    };
  };

  // const getSearchedItinerary = (loadNew = false) => {
  const getSearchedItinerary = () => {
    if (searchParam) {
      setLoading(true);
      // setErr(false);

      let ActiveTab =
        `${activeTab}` === "All"
          ? { category: "", category_ko: "" }
          : returnCategoryActiveData(activeTab);
      let ActiveKeyword = activeKeyword;
      ApiPost(
        // `itinerary/getItineraryOnHome?page_number=${page}&per_page=${perPage}`,
        `itinerary/getItineraryOnHome?&per_page=${perPage}`,
        {
          search_term: persistData?.globalSearch?.searchHeaderTerm || null,
          start_date: persistData?.globalSearch?.startHeaderDate || null,
          end_date: persistData?.globalSearch?.endHeaderDate || null,
          category: ActiveTab?.category,
          category_ko: ActiveTab?.category_ko,
        }
      )
        .then((res) => {
          setData((prev) => {
            if (res.data.itinerary > 0 || page > 1) {
              return [...res.data.itinerary];
            } else {
              return [...res.data.itinerary];
            }
          });
          setTotal(res.data.count);
          setActiveSlide({ activeSlide: 0 });
          // setHasMore(res.data.itinerary.length > 0);
          setLoading(false);
          window.scrollTo(0, scrollPosition);
        })
        .catch((e) => {
          // setErr(true);
        });
    }
  };

  const isEmpty = (value) => {
    if (value == null || value === "null") {
      return true;
    }
    if (typeof value == "object") {
      return Object.keys(value).length === 0;
    }
    return (
      (Array.isArray(value) && value.length === 0) ||
      value === undefined ||
      value === "undefined" ||
      value === null ||
      value === ""
    );
  };

  const changeDateType = (date) => {
    const x = new Date(date);
    const y = x.getMonth();
    const w = x.getDate();
    const z = x.getFullYear().toString();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // return month[y] + " " + w + ", " + z;
    if (AuthStorage.getLang() === "en") {
      return moment(date).locale(AuthStorage.getLang()).format("MMMM D, yyyy");
    } else {
      return moment(date)
        .locale(AuthStorage.getLang())
        .format("yyyy년 M월 D일");
    }
  };

  const changeTimeFormat = (time) => {
    if (!time) {
      return "";
    }
    let Time = time.split(":");
    return Time[0] + ":" + Time[1];
  };

  const Search = async (index) => {
    setData([]);
    setPage(1);
    setActiveTab(tabList[index]?.category);
    setActiveKeyword(tabList[index]?.keyword);
    let searchParam =
      "?keyword=" +
      (activeKeyword || "") +
      "&startDate=" +
      (persistData?.globalSearch?.startHeaderDate || "") +
      "&endDate=" +
      (persistData?.globalSearch?.endHeaderDate || "");
    history.push({
      pathname: "/",
      search: searchParam,
    });
  };

  const [tnList, setTNList] = useState([]);
  useEffect(() => {
    // const browserLang = navigator.language || navigator.userLanguage;
    ApiGet("general/trendingnow/get/list?code=all").then((res) => {
      setTabList([
        ...tabList,
        ...res.data.map((x) => {
          return {
            category: "",
            value: x.message,
            label: x.message,
            keyword: x.message,
          };
        }),
      ]);
    });
    // ApiGet("itinerary/getTrendingItinerary").then((res) => setData(res.data));
  }, []);

  const getCreatorString = (creator, creator_show) => {
    if (creator === "Compastrips" && creator_show === "Compastrips") {
      return `<span class="compastrips-span "> ${creator_show} </span>`;
    } else if (creator === "Host" && creator_show === "Host") {
      return `<span class="host-green "> ${creator_show} </span>`;
    } else {
      return `<span class="host-black "> ${creator_show} </span>`;
    }
  };

  // Keyword Search from Trending Now
  useSelector((state) => {
    const getKeyWord = state.trendingNowData.keyword;

    if (getKeyWord !== "") {
      ApiPost(
        `itinerary/getItineraryOnHome?page_number=${page}&per_page=${perPage}`,
        {
          search_term: persistData?.globalSearch?.searchHeaderTerm || null,
          start_date: persistData?.globalSearch?.startHeaderDate || null,
          end_date: persistData?.globalSearch?.endHeaderDate || null,
          category: "",
          category_ko: "",
        }
      )
        .then((res) => {
          setData((prev) => {
            if (res.data.itinerary > 0 || page > 1) {
              return [...res.data.itinerary];
            } else {
              return [...res.data.itinerary];
            }
          });
          setTotal(res.data.count);
          setCurrentSlide(0);
          // setHasMore(res.data.itinerary.length > 0);
          setLoading(false);
          setScrollPosition(0);
        })
        .catch((e) => {
          // setErr(true);
        });
    }
  });

  const [popMembers, setPopMenbers] = useState(null);
  var cardItemCnt = 0;
  var cardItemPopMCnt = 0;

  const getHosts = () => {
    const url = `hosting/hosts?host=&gender=&age=&per_page=80&page_number=1`;
    ApiGet(url)
      .then((res) => {
        setPopMenbers(res.data.hosts.reverse());
      })
      .catch((err) => {
        console.log("에러", err);
      });
  };

  useEffect(() => {
    getHosts();
  }, []);

  const [show, setShow] = useState(false);
  const [hostingId, setHostingId] = useState("");
  const [popMSpan, setPopMSpan] = useState("span 2");

  const [resize, setResize] = useState(window.innerWidth);
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setPopMSpan("span 2");
    } else if (window.innerWidth <= 720) {
      setPopMSpan("span 1");
    }
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tour-list-section mobile-section">
      <div className="tour-list-scroll">
        <div className="d-flex">
          {tabList.map((tab, i) => {
            return (
              <Button
                variant="mainTab"
                className={i === activeIndex && "active"}
                key={i}
                // disabled={activeTab === tab.category ? true : false}
                // onClick={() => filterData(tab.value)}
                onClick={() => {
                  setActiveIndex(i);
                  setActiveTab(tab.category);
                  setActiveKeyword(tab.keyword);
                  Search(i);
                  dispatch(
                    setGlobalSearch({
                      field: "searchHeaderTerm",
                      value: tab.keyword,
                    })
                  );
                }}
                arai-category={tab.category}
                arai-keyword={tab.kewyord}
              >
                {tab.label}
              </Button>
            );
          })}
        </div>
        {/* <Tabs ulClassName="tab-ul-class d-flex" liClassName="tab-li-class">
          {tabList.map((tab, i) => {
            return (
              <li
                key={i}
                className={
                  activeTab === tab.value ? "tab-card-active" : "tab-card"
                }
                // onClick={() => filterData(tab.value)}
                onClick={() => {
                  AuthStorage.getLang() === "en"
                    ? Search(tab.value)
                    : Search(tab.value);
                }}
              >
                {`${tab.label}`}
              </li>
            );
          })}
        </Tabs> */}
        <div className="tab-line"></div>
      </div>
      <div className="mobile-card">
        <div className={"content-card-tour-list"}>
          {data &&
            data.map((item, i) => {
              if (i === 0) {
                return (
                  <TourCard
                    items={item}
                    setRefresh={setRefresh}
                    key={i}
                    LoginModalClick={LoginModalClick}
                  />
                );
              } else if (i !== 0 && i % 16 === 0) {
                cardItemPopMCnt++;
                return (
                  <>
                    <div
                      style={{
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                      }}
                    >
                      <div>
                        <h4>Check out our popular members</h4>
                      </div>
                      <div className="popular-members">
                        {popMembers.map((popM, popIdx) => {
                          if (
                            (cardItemPopMCnt - 1) * 3 <= popIdx &&
                            popIdx < 3 * cardItemPopMCnt
                          ) {
                            return (
                              <div
                                className="popular-members-item"
                                onClick={() => {
                                  setShow(true);
                                  setHostingId(popM.hosting_id);
                                }}
                              >
                                <div
                                  className="host-img circle"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <img
                                    src={popM.avatar || "./img/Avatar.png"}
                                    alt=""
                                    className="HostProfileImg"
                                    style={{
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </div>
                                {popM.user_name}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <TourCard
                      items={item}
                      setRefresh={setRefresh}
                      key={i}
                      LoginModalClick={LoginModalClick}
                    />
                  </>
                );
              } else {
                return (
                  <TourCard
                    items={item}
                    setRefresh={setRefresh}
                    key={i}
                    LoginModalClick={LoginModalClick}
                  />
                );
              }
            })}
          {total > perPage && (
            <Button
              variant="outline-blue"
              className="mobile-more-btn"
              onClick={() => {
                setPerPage(perPage + 80);
                setScrollPosition(window.scrollY);
              }}
            >
              {t("Show.All2")}
            </Button>
          )}
        </div>
      </div>
      {show && (
        <HostPro
          hostingId={hostingId}
          show={show}
          setShow={setShow}
          data={data}
        ></HostPro>
      )}
    </div>
  );
};

export default TourListMobile;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const InquirtPage = () => {
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="Inquire" className="wrap single-page">
      <h2 className="page-title">{t("Inquiry.Inquiries")}</h2>

      <div className="d-flex inquiry-gap">
        <svg
          width="120"
          height="36"
          viewBox="0 0 120 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.2525 20.3033H13.8329C13.1073 21.3178 12.0954 22.0674 10.9405 22.4458C9.7856 22.8242 8.54634 22.8123 7.39832 22.4116C6.25029 22.0109 5.25172 21.2419 4.54409 20.2135C3.83646 19.1851 3.45567 17.9494 3.45567 16.6816C3.45567 15.4138 3.83646 14.1781 4.54409 13.1497C5.25172 12.1212 6.25029 11.3522 7.39832 10.9515C8.54634 10.5509 9.7856 10.5389 10.9405 10.9173C12.0954 11.2957 13.1073 12.0453 13.8329 13.0599H14.2396C14.6091 11.9327 15.1497 10.8753 15.8405 9.92847C14.9759 9.00622 13.9454 8.27261 12.8087 7.76997C11.6719 7.26732 10.4514 7.00563 9.2176 7C7.69338 7.0045 6.19387 7.40384 4.8526 8.16247C3.51133 8.9211 2.36992 10.0155 1.53001 11.3481C0.690104 12.6808 0.177759 14.2104 0.0386043 15.8007C-0.10055 17.3911 0.137806 18.9928 0.73244 20.4632C1.32707 21.9337 2.25954 23.2273 3.44679 24.2288C4.63405 25.2303 6.03926 25.9086 7.5373 26.2034C9.03533 26.4982 10.5797 26.4003 12.0329 25.9184C13.4861 25.4365 14.8029 24.5856 15.8663 23.4414C15.1738 22.4911 14.6291 21.4319 14.2525 20.3033Z"
            fill="#42B6E6"
          />
          <path
            d="M28.9179 8.11677C27.1489 7.23762 25.1553 6.98416 23.237 7.39452C21.3188 7.80489 19.5795 8.85687 18.2811 10.3922C16.9827 11.9275 16.1952 13.8631 16.0373 15.9078C15.8793 17.9525 16.3594 19.9956 17.4053 21.7297C18.4512 23.4638 20.0063 24.7952 21.8366 25.5234C23.6669 26.2517 25.6735 26.3374 27.5544 25.7678C29.4352 25.1982 31.0887 24.0039 32.2659 22.3648C33.4431 20.7257 34.0804 18.7304 34.0819 16.679C34.0833 14.886 33.5986 13.1294 32.6845 11.6136C31.7703 10.0979 30.4641 8.88522 28.9179 8.11677ZM29.2536 20.5476C29.2824 20.5778 29.3054 20.6136 29.321 20.653C29.3366 20.6925 29.3447 20.7348 29.3447 20.7775C29.3447 20.8203 29.3366 20.8626 29.321 20.902C29.3054 20.9415 29.2824 20.9773 29.2536 21.0075C29.2257 21.0395 29.1918 21.065 29.154 21.0825C29.1162 21.0999 29.0754 21.1089 29.0341 21.1089C28.9927 21.1094 28.9517 21.1006 28.9139 21.0831C28.876 21.0656 28.8422 21.0398 28.8146 21.0075L26.2713 18.3495L24.9803 26.0664L23.7474 18.3495L21.217 21.0007C21.1887 21.0321 21.1547 21.0572 21.117 21.0746C21.0794 21.092 21.0387 21.1014 20.9976 21.1022C20.9553 21.1019 20.9136 21.0928 20.8748 21.0754C20.836 21.058 20.8009 21.0326 20.7716 21.0007C20.7163 20.9382 20.6855 20.8561 20.6855 20.7708C20.6855 20.6855 20.7163 20.6034 20.7716 20.5408L23.2633 17.937L16.0272 16.5843L23.3859 15.2926L20.8039 12.5873C20.7486 12.5247 20.7178 12.4426 20.7178 12.3573C20.7178 12.272 20.7486 12.1899 20.8039 12.1274C20.8631 12.0658 20.9432 12.0313 21.0266 12.0313C21.11 12.0313 21.1901 12.0658 21.2493 12.1274L23.8313 14.8327L25.1223 7.25108L26.3359 14.8259L28.9179 12.1206C28.9761 12.0596 29.055 12.0254 29.1374 12.0254C29.2197 12.0254 29.2986 12.0596 29.3568 12.1206C29.415 12.1816 29.4477 12.2643 29.4477 12.3506C29.4477 12.4368 29.415 12.5195 29.3568 12.5805L26.607 15.3331L33.9786 16.6858L26.749 17.9573L29.2536 20.5476Z"
            fill="#42B6E6"
          />
          <path
            d="M75.1427 7.17286C73.1269 7.1401 71.1771 7.92527 69.7011 9.36414V7.53131H66.125V33.164H69.7785V24.0404C71.2469 25.431 73.1635 26.1874 75.1427 26.1573C77.5454 26.1573 79.8498 25.1572 81.5488 23.377C83.2478 21.5969 84.2023 19.1825 84.2023 16.6651C84.2023 14.1476 83.2478 11.7332 81.5488 9.95305C79.8498 8.17292 77.5454 7.17286 75.1427 7.17286ZM80.3906 16.6414C80.3906 20.023 78.1314 22.5592 75.1427 22.5592C73.6447 22.5592 72.2081 21.9357 71.1488 20.8259C70.0896 19.7161 69.4945 18.2109 69.4945 16.6414C69.4945 15.0719 70.0896 13.5666 71.1488 12.4568C72.2081 11.347 73.6447 10.7236 75.1427 10.7236C78.1314 10.7506 80.3906 13.2936 80.3906 16.6684V16.6414Z"
            fill="#42B6E6"
          />
          <path
            d="M99.5559 9.36414C98.08 7.92527 96.1301 7.1401 94.1143 7.17286C91.7116 7.17286 89.4072 8.17292 87.7082 9.95305C86.0092 11.7332 85.0547 14.1476 85.0547 16.6651C85.0547 19.1825 86.0092 21.5969 87.7082 23.377C89.4072 25.1572 91.7116 26.1572 94.1143 26.1572C96.1299 26.1886 98.0792 25.4036 99.5559 23.9659V25.8529H103.132V7.53131H99.5559V9.36414ZM99.7431 16.6684C99.7431 18.2344 99.1507 19.7366 98.0957 20.8458C97.0406 21.955 95.609 22.5809 94.1143 22.5863C91.1257 22.5863 88.8664 20.0365 88.8664 16.6684C88.8664 13.3004 91.1257 10.7506 94.1143 10.7506C95.605 10.7613 97.031 11.3898 98.0809 12.4986C99.1307 13.6074 99.719 15.1066 99.7173 16.6684H99.7431Z"
            fill="#42B6E6"
          />
          <path
            d="M56.5886 7.11328C53.7097 7.11328 51.5214 8.22921 50.1917 10.3461C48.9007 8.22921 46.7124 7.11328 43.8658 7.11328C42.0348 7.11503 40.2717 7.83947 38.9276 9.14225V7.53937H35.3516V25.8474H39.0051V15.6147C38.9829 14.9839 39.0842 14.355 39.3026 13.7667C39.5211 13.1785 39.8522 12.6433 40.2754 12.1941C40.6987 11.7449 41.2052 11.3912 41.7639 11.1548C42.3225 10.9185 42.9214 10.8043 43.5236 10.8195C47.4418 10.8195 47.7452 14.2011 47.7452 15.2697V25.8609H51.3988V15.6147C51.3737 14.9831 51.473 14.3529 51.6905 13.7633C51.9079 13.1737 52.2389 12.6373 52.6627 12.1875C53.0866 11.7377 53.5943 11.3841 54.1542 11.1488C54.7141 10.9134 55.3143 10.8014 55.9173 10.8195C59.7903 10.8195 60.1066 14.2011 60.1066 15.2697V25.8609H63.7214V14.0862C63.7279 9.77799 60.0227 7.11328 56.5886 7.11328Z"
            fill="#42B6E6"
          />
          <path
            d="M113.034 14.7932C109.761 14.4618 109.122 13.7923 109.122 12.7643C109.122 11.4116 110.51 10.5594 112.744 10.5594C114.474 10.6068 116.162 11.1317 117.637 12.0812L118.082 12.4261L119.702 9.38265L119.341 9.11887C117.277 7.80234 114.901 7.12253 112.486 7.15754C108.199 7.15754 105.437 9.3894 105.437 12.8386C105.437 14.6918 106.295 17.8637 112.04 18.2898C115.81 18.5739 116.32 19.5613 116.32 20.4946C116.32 22.456 113.783 22.7535 112.273 22.7535C110.33 22.8415 108.419 22.2279 106.857 21.0154L106.431 20.6705L104.952 23.0917L104.727 23.5042L105.094 23.7883C106.089 24.5323 108.154 26.0946 112.402 26.0946C116.92 26.0946 119.999 23.7207 119.999 20.1835C119.999 17.0792 117.63 15.2531 113.034 14.7932Z"
            fill="#42B6E6"
          />
        </svg>
        <div className="inquiry-content">
          <div className="cs-inquiry">
            <p className="inquiry-title ">Customer Service Inquiries</p>
            <p className="inquiry-details ">cs@compasinteractive.com</p>
          </div>

          <div className="business-inquiry">
            <p className="inquiry-title ">Business Inquiries</p>
            <p className="inquiry-details ">business@compasinteractive.com</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default InquirtPage;

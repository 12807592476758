import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthStorage from "../../helper/AuthStorage";

const Welcome = ({
  onHideWelocome,
  showWelcome,
  onShow,
  hideWelcom,
  welcomeTitle,
}) => {
  const { t } = useTranslation();

  const goLoging = () => {
    hideWelcom();
  };

  return (
    <div>
      <Modal
        show={showWelcome}
        centered
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal custom-modal"
            : "noto-text welcome-modal custom-modal"
        }
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Welcome.Welcome")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="welcom-black">{welcomeTitle},</p>
            <br />
            <br />
            <p>{t("Welcome.Success")}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="confirmBtn" className="w-100" onClick={goLoging}>
            {t("logIn.Log_In")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Welcome;

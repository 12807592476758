import React from "react";

const HeaderLogo = () => {
  return (
    <svg viewBox="0 0 120 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2525 15.3033H13.8329C13.1073 16.3178 12.0954 17.0674 10.9405 17.4458C9.7856 17.8242 8.54634 17.8123 7.39832 17.4116C6.25029 17.0109 5.25172 16.2419 4.54409 15.2135C3.83646 14.1851 3.45567 12.9494 3.45567 11.6816C3.45567 10.4138 3.83646 9.17808 4.54409 8.14965C5.25172 7.12123 6.25029 6.35219 7.39832 5.95152C8.54634 5.55086 9.7856 5.53888 10.9405 5.91728C12.0954 6.29568 13.1073 7.04528 13.8329 8.05985H14.2396C14.6091 6.93268 15.1497 5.87531 15.8405 4.92847C14.9759 4.00622 13.9454 3.27261 12.8087 2.76997C11.6719 2.26732 10.4514 2.00563 9.2176 2C7.69338 2.0045 6.19387 2.40384 4.8526 3.16247C3.51133 3.9211 2.36992 5.01547 1.53001 6.34813C0.690104 7.6808 0.177759 9.2104 0.0386043 10.8007C-0.10055 12.3911 0.137806 13.9928 0.73244 15.4632C1.32707 16.9337 2.25954 18.2273 3.44679 19.2288C4.63405 20.2303 6.03926 20.9086 7.5373 21.2034C9.03533 21.4982 10.5797 21.4003 12.0329 20.9184C13.4861 20.4365 14.8029 19.5856 15.8663 18.4414C15.1738 17.4911 14.6291 16.4319 14.2525 15.3033Z"
        fill="white"
      />
      <path
        d="M28.9179 3.11677C27.1489 2.23762 25.1553 1.98416 23.237 2.39452C21.3188 2.80489 19.5795 3.85687 18.2811 5.3922C16.9827 6.92753 16.1952 8.86314 16.0373 10.9078C15.8793 12.9525 16.3594 14.9956 17.4053 16.7297C18.4512 18.4638 20.0063 19.7952 21.8366 20.5234C23.6669 21.2517 25.6735 21.3374 27.5544 20.7678C29.4352 20.1982 31.0887 19.0039 32.2659 17.3648C33.4431 15.7257 34.0804 13.7304 34.0819 11.679C34.0833 9.88598 33.5986 8.12941 32.6845 6.61364C31.7703 5.09787 30.4641 3.88522 28.9179 3.11677ZM29.2536 15.5476C29.2824 15.5778 29.3054 15.6136 29.321 15.653C29.3366 15.6925 29.3447 15.7348 29.3447 15.7775C29.3447 15.8203 29.3366 15.8626 29.321 15.902C29.3054 15.9415 29.2824 15.9773 29.2536 16.0075C29.2257 16.0395 29.1918 16.065 29.154 16.0825C29.1162 16.0999 29.0754 16.1089 29.0341 16.1089C28.9927 16.1094 28.9517 16.1006 28.9139 16.0831C28.876 16.0656 28.8422 16.0398 28.8146 16.0075L26.2713 13.3495L24.9803 21.0664L23.7474 13.3495L21.217 16.0007C21.1887 16.0321 21.1547 16.0572 21.117 16.0746C21.0794 16.092 21.0387 16.1014 20.9976 16.1022C20.9553 16.1019 20.9136 16.0928 20.8748 16.0754C20.836 16.058 20.8009 16.0326 20.7716 16.0007C20.7163 15.9382 20.6855 15.8561 20.6855 15.7708C20.6855 15.6855 20.7163 15.6034 20.7716 15.5408L23.2633 12.937L16.0272 11.5843L23.3859 10.2926L20.8039 7.58728C20.7486 7.52475 20.7178 7.44261 20.7178 7.35733C20.7178 7.27205 20.7486 7.18989 20.8039 7.12736C20.8631 7.06582 20.9432 7.0313 21.0266 7.0313C21.11 7.0313 21.1901 7.06582 21.2493 7.12736L23.8313 9.83265L25.1223 2.25108L26.3359 9.82591L28.9179 7.12061C28.9761 7.05963 29.055 7.02536 29.1374 7.02536C29.2197 7.02536 29.2986 7.05963 29.3568 7.12061C29.415 7.1816 29.4477 7.26431 29.4477 7.35056C29.4477 7.43681 29.415 7.51952 29.3568 7.58051L26.607 10.3331L33.9786 11.6858L26.749 12.9573L29.2536 15.5476Z"
        fill="white"
      />
      <path
        d="M75.1427 2.17286C73.1269 2.1401 71.1771 2.92527 69.7011 4.36414V2.53131H66.125V28.164H69.7785V19.0404C71.2469 20.431 73.1635 21.1874 75.1427 21.1573C77.5454 21.1573 79.8498 20.1572 81.5488 18.377C83.2478 16.5969 84.2023 14.1825 84.2023 11.6651C84.2023 9.14757 83.2478 6.73319 81.5488 4.95305C79.8498 3.17292 77.5454 2.17286 75.1427 2.17286ZM80.3906 11.6414C80.3906 15.023 78.1314 17.5592 75.1427 17.5592C73.6447 17.5592 72.2081 16.9357 71.1488 15.8259C70.0896 14.7161 69.4945 13.2109 69.4945 11.6414C69.4945 10.0719 70.0896 8.56665 71.1488 7.45684C72.2081 6.34703 73.6447 5.72356 75.1427 5.72356C78.1314 5.75061 80.3906 8.29359 80.3906 11.6684V11.6414Z"
        fill="white"
      />
      <path
        d="M99.5559 4.36414C98.08 2.92527 96.1301 2.1401 94.1143 2.17286C91.7116 2.17286 89.4072 3.17292 87.7082 4.95305C86.0092 6.73318 85.0547 9.14757 85.0547 11.6651C85.0547 14.1825 86.0092 16.5969 87.7082 18.377C89.4072 20.1572 91.7116 21.1572 94.1143 21.1572C96.1299 21.1886 98.0792 20.4036 99.5559 18.9659V20.8529H103.132V2.53131H99.5559V4.36414ZM99.7431 11.6684C99.7431 13.2344 99.1507 14.7366 98.0957 15.8458C97.0406 16.955 95.609 17.5809 94.1143 17.5863C91.1257 17.5863 88.8664 15.0365 88.8664 11.6684C88.8664 8.30035 91.1257 5.75061 94.1143 5.75061C95.605 5.76134 97.031 6.38975 98.0809 7.49859C99.1307 8.60743 99.719 10.1066 99.7173 11.6684H99.7431Z"
        fill="white"
      />
      <path
        d="M56.5886 2.11328C53.7097 2.11328 51.5214 3.22921 50.1917 5.3461C48.9007 3.22921 46.7124 2.11328 43.8658 2.11328C42.0348 2.11503 40.2717 2.83947 38.9276 4.14225V2.53937H35.3516V20.8474H39.0051V10.6147C38.9829 9.98387 39.0842 9.35498 39.3026 8.76672C39.5211 8.17846 39.8522 7.64326 40.2754 7.19408C40.6987 6.7449 41.2052 6.39124 41.7639 6.15485C42.3225 5.91846 42.9214 5.80434 43.5236 5.81954C47.4418 5.81954 47.7452 9.20114 47.7452 10.2697V20.8609H51.3988V10.6147C51.3737 9.98311 51.473 9.35291 51.6905 8.7633C51.9079 8.17369 52.2389 7.6373 52.6627 7.1875C53.0866 6.73769 53.5943 6.3841 54.1542 6.14876C54.7141 5.91342 55.3143 5.80136 55.9173 5.81954C59.7903 5.81954 60.1066 9.20114 60.1066 10.2697V20.8609H63.7214V9.08617C63.7279 4.77799 60.0227 2.11328 56.5886 2.11328Z"
        fill="white"
      />
      <path
        d="M113.034 9.79323C109.761 9.46183 109.122 8.79227 109.122 7.76426C109.122 6.41161 110.51 5.55944 112.744 5.55944C114.474 5.60683 116.162 6.13174 117.637 7.08117L118.082 7.4261L119.702 4.38265L119.341 4.11887C117.277 2.80234 114.901 2.12253 112.486 2.15754C108.199 2.15754 105.437 4.3894 105.437 7.83865C105.437 9.69177 106.295 12.8637 112.04 13.2898C115.81 13.5739 116.32 14.5613 116.32 15.4946C116.32 17.456 113.783 17.7535 112.273 17.7535C110.33 17.8415 108.419 17.2279 106.857 16.0154L106.431 15.6705L104.952 18.0917L104.727 18.5042L105.094 18.7883C106.089 19.5323 108.154 21.0946 112.402 21.0946C116.92 21.0946 119.999 18.7207 119.999 15.1835C119.999 12.0792 117.63 10.2531 113.034 9.79323Z"
        fill="white"
      />
    </svg>
  );
};

export default HeaderLogo;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HostPro from "../../components/HostPro";
import { ApiPost } from "../../helper/API/ApiData";
import { checkImageURL } from "../../helper/utils";

const HostCard = ({
  items,
  searchHosts,
  setRefresh,
  setCountData,
  hostingGet,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [hostingId, setHostingId] = useState("");
  // const [like, setLike] = useState(items.isLike);
  const [delayLike, setDelayLike] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const Like = (id) => {
    setIsLiked(!isLiked);
    setDelayLike(true);
    ApiPost(`user/wishlist/${id}`, {
      hosting_id: items.hosting_id,
    })
      .then((res) => {
        setRefresh(Math.random());
        searchHosts();
        hostingGet();
      })
      .catch((err) => {});
  };

  return (
    <>
      <div
        className="w-100 wishlist-host d-flex align-items-center"
        onClick={() => {
          // history.push(`/itinerary?id=${items.itinerary_id}`)
          setHostingId(items.hosting_id);
          setShow(true);
        }}
      >
        <div className="host-img circle">
          <img
            src={items.avatar || "./img/Avatar.png"}
            alt=""
            style={{
              width: "54px",
              height: "54px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            className="whishlist-avatar"
          />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="host-info">
            <div className="d-flex align-items-center name-with-flag">
              <h5 className="host-name">{items.user_name}</h5>
              <div className="flag-wrap circle">
                <img src={checkImageURL(items.nationality)} alt="flag" />
              </div>
            </div>

            <div className="host-tag d-flex align-items-center">
              <div
                className={
                  items.host_type === "Local"
                    ? "host-category"
                    : "travel-host-category"
                }
              >
                <p className="info">
                  {items.host_type === "Local"
                    ? t("Local_Host")
                    : t("Traveler_Host")}
                </p>
              </div>
              <div className="wishHost">
                <p className="info">
                  {items.gender === "MALE" ? t("Male") : t("Female")}
                </p>
              </div>
              <div className="wishHost res-age">
                <p className="info">
                  {items.age_group}'{t("Age_Groups")}
                </p>
              </div>
            </div>
          </div>

          <div
            className="d-flex whishlist-hostpro ml-auto"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="tout-created">
              <div className="download-heart-icon button">
                <div className="heart-div">
                  <input
                    type="checkbox"
                    id={items.id}
                    checked={items.isLike}
                    disabled={delayLike}
                    // onClick={canlike ? () => Like(items.id) : () => { }}
                    className="instruments"
                  />
                  <label
                    htmlFor={items.id}
                    className={`text-white check`}
                    onClick={() => Like(items.id)}
                  >
                    {!items.isLike ? (
                      <svg
                        width="39"
                        height="38"
                        viewBox="0 0 39 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                          stroke="#C9C9C9"
                          stroke-width="2"
                        ></path>
                        <rect
                          x="22"
                          y="13"
                          width="17"
                          height="14"
                          fill="white"
                        ></rect>
                        <rect
                          x="29"
                          y="14"
                          width="2"
                          height="12"
                          fill="#C9C9C9"
                        ></rect>
                        <rect
                          x="36"
                          y="19"
                          width="2"
                          height="12"
                          transform="rotate(90 36 19)"
                          fill="#C9C9C9"
                        ></rect>
                      </svg>
                    ) : (
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.9394 7.02456L30.9418 7.02695C33.6861 9.71905 33.6861 14.1029 30.9418 16.795L30.9417 16.7951L27.6053 20.0694L26.8803 20.7808L26.904 20.8043L16.992 30.5945L3.0446 16.8185C0.318467 14.1259 0.318467 9.7383 3.0446 7.04568C5.7776 4.34627 10.2413 4.34627 12.9743 7.04568L16.2679 10.2988L16.9683 10.9906L17.671 10.301L21.0074 7.02681L21.0097 7.02456C23.7427 4.32515 28.2064 4.32515 30.9394 7.02456Z"
                          fill="#FF5F5F"
                          stroke="#FF5F5F"
                          stroke-width="2"
                        />
                      </svg>
                    )}
                  </label>
                  {/* {false && <img src="./img/Favourite.png" alt="" className='wishFavourite' />} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <HostPro
          hostingId={hostingId}
          show={show}
          setShow={setShow}
          data=""
        ></HostPro>
      )}
    </>
  );
};

export default HostCard;

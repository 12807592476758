import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiGet, ApiPut } from "../../helper/API/ApiData";
import { checkImageURL } from "../../helper/utils";
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatId,
  setMessageState,
  setOtherUserData,
} from "../../redux/action/chatDataAction";
import { db } from "../../firebaseConfig";
import AuthStorage from "../../helper/AuthStorage";
import { useHistory } from "react-router-dom";
import * as QueryString from "query-string";

const ApplicationPax = (props) => {
  const { t } = useTranslation();

  const [acceptApp, SetAcceptApp] = useState(false);
  const [acceptAppNotice, SetAcceptAppNotice] = useState(false);
  const [declineApp, SetDeclinetApp] = useState(false);
  const [declineAppNotice, SetDeclineAppNotice] = useState(false);
  const [activeTab, setActiveTab] = useState("STAND_BY");
  const [participantData, setParticipantData] = useState({
    participants: [],
    participate_count: 0,
    pax: 0,
  });
  const [id, setId] = useState("");
  const [host, setHost] = useState();
  const [participantID, setParticipantID] = useState({
    age_group: "",
    avatar: "",
    dob: "",
    first_name: "",
    flag: "",
    gender: "",
    id: "",
    last_name: "",
    nationality: "",
    user_name: "",
    notification_on: false,
  });
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const params = QueryString.parse(history.location.search);

  const searchTab = (tab) => {
    setActiveTab(tab);
  };

  const getParticipants = () => {
    if (props.hostingID) {
      ApiGet(`hosting/participants/${props?.hostingID}`).then((res) => {
        setParticipantData(res.data);
      });
    }
  };

  useEffect(() => {
    // getParticipants();
    getHostingUser();
  }, [activeTab, refresh, props]);

  useEffect(() => {
    getParticipants();
  }, [refresh]);

  const acceptReq = (id) => {
    ApiPut(`hosting/acceptParticipant/${id}`, {}).then((res) => {
      SetAcceptAppBtn();
      setRefresh(!refresh);
      getHostingUser();
    });
  };

  const declineReq = (id) => {
    ApiPut(`hosting/declineParticipant/${id}`, {}).then((res) => {
      SetDeclineAppNoticeBtn();
      setRefresh(!refresh);
      props.setRefresh(Math.random());
      getHostingUser();
    });
  };

  const SetAcceptAppBtn = () => {
    SetAcceptApp(false);
    SetAcceptAppNotice(true);
  };

  const SetDeclineAppNoticeBtn = () => {
    SetDeclinetApp(false);
    SetDeclineAppNotice(true);
  };

  // Create a chat
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.userData);
  const { chatData, message_open } = useSelector((state) => state.chatData);
  const createChat = async (current_user, other_user) => {
    let doc_id;
    if (
      !current_user.id &&
      !other_user.id &&
      current_user.id === other_user.id
    ) {
      return;
    }
    if (current_user.id > other_user.id) {
      doc_id = current_user.id + other_user.id;
    } else {
      doc_id = other_user.id + current_user.id;
    }

    if (chatData.find((x) => x.id === doc_id)) {
      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    } else {
      const ref = db.collection(AuthStorage.getMessageCollection()).doc(doc_id);
      await ref.set(
        {
          [`${current_user.id}_count`]: 0,
          [`${other_user.id}_count`]: 0,
          lastMessage: "",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ids: [current_user.id, other_user.id],
          [`${current_user.id}`]: {
            id: current_user.id,
            name: current_user.name,
            profile_url: current_user.profile_url,
          },
          [`${other_user.id}`]: {
            id: other_user.id,
            name: other_user.name,
            profile_url: other_user.profile_url,
          },
        },
        { merge: true }
      );

      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    }
  };

  const getHostingUser = () => {
    ApiGet(`hosting/host-itinerary/${params.id}`).then((res) => {
      setHost(res?.data.filter((x) => x?.user?.id === userData?.id)[0]);
    });
  };

  const reqParticipants = () => {
    const filteredParticipants = participantData.participants
      .filter((x) => x.req_status === activeTab)
      .sort(
        (a, b) =>
          new Date(a.requested_at).getTime() -
          new Date(b.requested_at).getTime()
      );
    return filteredParticipants.length ? (
      filteredParticipants.map((data, i) => (
        <div className="single-request-list d-flex" key={i}>
          <div className="host-info d-flex align-items-center">
            <div className="host-img circle d-flex">
              <img
                src={data.user.avatar || "./img/Avatar.png"}
                alt=""
                style={{ width: "54px", height: "54px", borderRadius: "50%" }}
                className="res-avatar"
              />
            </div>
            <div className="name-with-tag d-flex flex-column align-items-center">
              <div className="name-with-flag d-flex align-items-center">
                <h5 className="host-name">
                  {/* {data.user.first_name}
                                    &nbsp;
                                    {data.user.last_name} */}
                  {data.user.user_name.length > 8
                    ? data.user.user_name?.slice(0, 8) + ".."
                    : data.user.user_name}
                </h5>
                <div className="flag-wrap circle">
                  <img
                    src={checkImageURL(data.user.nationality)}
                    alt="flag"
                    style={{
                      width: "19.98px",
                      height: "19.98px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    className="res-flag"
                  />
                </div>
              </div>
              <div className="host-tag d-flex">
                <div
                  className={
                    data.user.gender === "MALE"
                      ? "hots-tags male-gender-tages"
                      : "hots-tags gender-tages"
                  }
                >
                  {/* <div className="hots-tags gender-tages"> */}
                  <p className="info">
                    {data.user.gender === "MALE" ? t("Male") : t("Female")}
                  </p>
                </div>
                <div className="hots-tags ages-tags">
                  <p className="info">
                    {data.user.age_group}
                    {AuthStorage.getLang() === "en" ? `'` : ""}
                    {t("Age_Groups")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="applicationDate d-flex align-items-center">
            {/* <div className="mr-48 applicationDate d-flex align-items-center"> */}
            <p>
              {t("Pax_Applications.Applied")} :{" "}
              {moment(data.requested_at).format("YYYY.MM.DD")}
            </p>{" "}
            &nbsp;·&nbsp;
            <p>
              {t("Pax_Applications.Status")}{" "}
              {data.req_status === "STAND_BY"
                ? `${t("Pax_Applications.Standing")}`
                : data.req_status === "ACCEPTED"
                ? `${t("Pax_Applications.Accepted")}`
                : `${t("Pax_Applications.Declined")}`}
            </p>
          </div>
          <div className="request-btns d-flex align-items-center">
            <Button
              variant="confirmBtn"
              className={`${
                userData?.notification && data?.user.notification_on
                  ? "ApplicationChat"
                  : "ApplicationChat deactive"
              }`}
              // ButtonStyle="chat-btn mr-15"

              onClick={() => {
                userData?.notification &&
                  data?.user.notification_on &&
                  createChat(
                    {
                      id: userData.id,
                      name: userData.user_name,
                      profile_url: userData.avatar,
                    },
                    {
                      id: data?.user.id,
                      name: data?.user.user_name,
                      profile_url: data?.user.avatar,
                    }
                  );
              }}
            >
              {t("Pax_Applications.Chat")}
            </Button>

            <>
              {data.req_status === "STAND_BY" ? (
                <>
                  {host && (
                    <>
                      {host.pax === host.participate_count ? (
                        <div className="route-setting-disabled">
                          <p className="position-relative">
                            <Button
                              variant="cancelBtn"
                              // ButtonStyle="chat-btn-border mr-15"
                              className={
                                host.pax === host.participate_count
                                  ? "Applidisabled"
                                  : "ApplicationAccept"
                              }
                              disabled={
                                host.pax === host.participate_count
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                setId(data.id);
                                setParticipantID(data.user);
                                SetAcceptApp(true);
                              }}
                            >
                              {t("Pax_Applications.Accept")}
                            </Button>
                            <span className="tooltiptext">
                              {t("You_exhausted_the_pax_limit")}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <Button
                          variant="cancelBtn"
                          // ButtonStyle="chat-btn-border mr-15"
                          className="ApplicationAccept"
                          onClick={() => {
                            setId(data.id);
                            setParticipantID(data.user);
                            SetAcceptApp(true);
                          }}
                        >
                          {t("Pax_Applications.Accept")}
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </>
            {/* {host.pax == counter ?
                                    <div className='route-setting'>
                                        <p>
                                            
                                            <span className="tooltiptext">ApplicationAccept</span>
                                        </p>
                                    </div>
                                    : ""} */}

            {data.req_status === "STAND_BY" ? (
              <Button
                variant="outline-red"
                className="ApplicationDecline"
                // ButtonStyle="chat-btn-border-danger"
                onClick={() => {
                  setId(data.id);
                  setParticipantID(data.user);
                  SetDeclinetApp(true);
                }}
              >
                {t("Pax_Applications.Decline")}
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
      ))
    ) : (
      <div className="h-423 ">
        <div className="font-22-normal text-center Noapplication">
          <p> {t("Pax_Applications.No_Application")}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="my-trip-requst-list">
        <h4 className="request-title">
          {t("Pax_Applications.Applications")} ({t("Pax_Applications.Pax1")}
          {participantData.pax} {t("Pax_Applications.Pax2")}
          <span className="color-gray"> | </span>{" "}
          {t("Pax_Applications.Vacancies1")}
          {participantData.pax - participantData.participate_count}
          {t("Pax_Applications.Vacancies2")})
        </h4>
        <div className="request-tabs">
          {
            <>
              <span
                className={activeTab === "STAND_BY" ? "active" : null}
                onClick={() => {
                  searchTab("STAND_BY");
                }}
              >
                {t("Pax_Applications.Standing")} (
                {
                  participantData?.participants?.filter(
                    (x) => x.req_status === "STAND_BY"
                  ).length
                }
                )
              </span>
              <span className="color-gray">
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </span>
            </>
          }

          {
            <>
              <span
                className={activeTab === "ACCEPTED" ? "active" : null}
                onClick={() => {
                  searchTab("ACCEPTED");
                }}
              >
                {t("Pax_Applications.Accepted")} (
                {
                  participantData?.participants?.filter(
                    (x) => x.req_status === "ACCEPTED"
                  ).length
                }
                )
              </span>
              <span className="color-gray">
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </span>
            </>
          }

          <span
            className={activeTab === "DECLINED" ? "active" : null}
            onClick={() => {
              searchTab("DECLINED");
            }}
          >
            {" "}
            {t("Pax_Applications.Declined")} (
            {
              participantData?.participants?.filter(
                (x) => x.req_status === "DECLINED"
              ).length
            }
            )
          </span>
        </div>

        {participantData?.participants?.length === 0 ? (
          <div className="text-center Noapplication">
            <p> {t("Pax_Applications.No_Application")}</p>
          </div>
        ) : (
          <div className="secMb111">{reqParticipants()}</div>
        )}
      </section>

      <Modal
        show={acceptApp}
        onHide={() => {
          SetAcceptApp(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Application_pax.Accept_Popup.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            {AuthStorage.getLang() === "en" ? (
              <p
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html:
                    t("Application_pax.Accept_Popup.Body1") +
                    participantID.user_name +
                    t("Application_pax.Accept_Popup.Body2"),
                }}
              />
            ) : (
              <p
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html:
                    participantID.user_name +
                    t("Application_pax.Accept_Popup.Body"),
                }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            onClick={() => SetAcceptApp(false)}
          >
            {t("Application_pax.Accept_Popup.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            // ButtonStyle="join-apply-btn"
            onClick={() => acceptReq(id)}
          >
            {t("Application_pax.Accept_Popup.Accept")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={acceptAppNotice}
        onHide={() => {
          SetAcceptAppNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Application_pax.Accept_Notice.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  participantID.user_name +
                  t("Application_pax.Accept_Notice.Body"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              props.setRefresh(Math.random());
              SetAcceptAppNotice(false);
            }}
          >
            {t("Application_pax.Accept_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={declineApp}
        onHide={() => {
          SetDeclinetApp(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Application_pax.Decline_Popup.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            {AuthStorage.getLang() === "en" ? (
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    t("Application_pax.Decline_Popup.Body1") +
                    participantID.user_name +
                    t("Application_pax.Decline_Popup.Body2"),
                }}
              />
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    participantID.user_name +
                    t("Application_pax.Decline_Popup.Body"),
                }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            onClick={() => SetDeclinetApp(false)}
          >
            {t("Application_pax.Decline_Popup.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            // ButtonStyle="join-apply-btn"
            onClick={() => declineReq(id)}
          >
            {t("Application_pax.Decline_Popup.Decline")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={declineAppNotice}
        onHide={() => {
          SetDeclineAppNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Application_pax.Decline_Notice.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  participantID.user_name +
                  t("Application_pax.Decline_Notice.Body"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              SetDeclineAppNotice(false);
            }}
          >
            {t("Application_pax.Decline_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApplicationPax;

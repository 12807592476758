import React, { useState, useEffect } from "react";
import { ApiGet } from "../../helper/API/ApiData";
import TourCard2 from "./TourCard2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MyHostingFeed = () => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [feeds, setFeeds] = useState([]);
  const [filter, setFilter] = useState([]);
  const [myOnly, setMyOnly] = useState(false);
  useEffect(() => {
    ApiGet("hosting/getHostingOfUser?all=true").then((res) => {
      setFeeds(res.data.itinerary);
      filterData(tabs[activeTab].tag, tabs[activeTab].status);
    });
  }, [refresh]);

  useEffect(() => {
    filterData(tabs[activeTab].tag, tabs[activeTab].status);
  }, [feeds]);
  const tabs = [
    {
      name: `${t("My_Account.Coming_Up")}`,
      status: "confirmed_review",
      tag: ["UPCOMING", "ONGOING"],
    },
    {
      name: `${t("My_Account.Standing")}`,
      status: ["request_review"],
      tag: ["UPCOMING", "ONGOING"],
    },
    // { name: `${t("My_Account.Coming_Up")}`, tag: ["confirmed_review"] },
    {
      name: `${t("My_Account.Completed")}`,
      status: ["completed_review", "confirmed_review"],
      tag: ["COMPLETED", "CANCELED"],
    },
    {
      name: `${t("My_Account.Declined")}`,
      status: ["reject_review"],
      tag: ["COMPLETED", "CANCELED", "UPCOMING", "ONGOING"],
    },
  ];

  // console.log("feed : ", feeds);

  useEffect(() => {
    ShowmyOnly(myOnly);
  }, [myOnly]);

  const ShowmyOnly = (myOnly) => {
    if (myOnly) {
      setFilter(filter.filter((feed) => feed.creator !== "Compastrips"));
    } else {
      filterData(tabs[activeTab].tag);
    }
  };
  const filterData = (tag, status) => {
    // setFilter(feeds.filter((feed) => tag.includes(feed.status)));
    setFilter(
      feeds.filter(
        (feed) =>
          status.includes(feed.hosting_confirm_status) &&
          tag.includes(feed.status)
      )
    );
  };

  const search = (index, tag, status) => {
    setActiveTab(index);
    setMyOnly(false);
    filterData(tag, status);
  };

  const countNumberOfItems = (tag, status) => {
    return feeds.filter(
      (feed) =>
        status.includes(feed.hosting_confirm_status) &&
        tag.includes(feed.status)
    ).length;
  };
  return (
    <section id="hosting">
      <h2 className="myPage-title">{t("My_Account.My_Hostings")}</h2>
      <div className="d-flex align-items-center justify-content-between">
        <div className="myPage-tabs page-tabs h-39 mt-79 mb-66">
          {tabs.map((tab, index) => (
            <>
              <span
                className={activeTab === index ? `active` : ``}
                onClick={() => search(index, tab.tag, tab.status)}
              >
                {tab.name}&nbsp;({countNumberOfItems(tab.tag, tab.status)})
              </span>
            </>
          ))}
        </div>
        <Link to="/myhosting" className="moreLink">
          {t("My_Account.View_All")}{" "}
        </Link>
      </div>
      <div className="tour-card-list">
        {filter.map(
          (feed, i) =>
            i <= 4 && (
              <TourCard2
                items={feed}
                status={feed.status}
                setRefresh={setRefresh}
              />
            )
        )}
      </div>
      {filter.length === 0 && (
        <div className="noFeed">
          <h4>{t("My_Account.No_Schedule")}</h4>
        </div>
      )}
    </section>
  );
};
export default MyHostingFeed;

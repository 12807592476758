import React from "react";
import { Route, Switch } from "react-router";
import Dashboard from "./dashboard/Dashboard";
import Layout from "../layout/Layout";
import HostMyOwn from "./hostmyown/HostMyOwn";
import ViewHost from "./viewhost/ViewHost";
import ItineraryDetails from "./singleEvent/ItineraryDetails";
import MyWishListPage from "./user/MyWishlistPage";
import Profile from "./user/Profile";
import EditProfile from "./user/EditProfile";
import MyHostingPage from "./user/MyHostingPage";
import AppliedHostingPage from "./user/AppliedHostingPage";
import InquirtPage from "./inquiry/InquirtPage";
import CustomerService from "./customer-service/CustomerService";
import DeleteItinerary from "./singleEvent/DeleteItinerary";
import ForgotPassword from "./forgotpass/ForgotPassword";
import ErrorPage from "./ErrorPage";
import MobileCustomerServices from "./customer-service/MobileCustomerServices";
import AuthStorage from "../helper/AuthStorage";
import Signup from "./Signup";
import Header from "layout/header/Header";
import Footer from "layout/footer/Footer";
import ErrorPage2 from "./ErrorPage2";

const Index = () => {
  return (
    <div
      className={AuthStorage.getLang() === "en" ? "roboto-text" : "noto-text"}
    >
      <Header />
      <Switch>
        {/* <RouteWrapper exact={true} path="/" component={Login} layout={Layout} isPrivateRoute={false} /> */}
        <RouteWrapper
          exact={true}
          path="/"
          component={Dashboard}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/SignUp"
          component={Signup}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/forgotpassword"
          layout={Layout}
          component={ForgotPassword}
          isPrivateRoute={false}
        />
        {/* <RouteWrapper exact={true} path="/event" component={Event} layout={Layout} isPrivateRoute={true} /> */}
        <RouteWrapper
          exact={true}
          path="/itinerary"
          component={ItineraryDetails}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/hostitinerary"
          component={HostMyOwn}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/viewhost"
          component={ViewHost}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/wishlist"
          component={MyWishListPage}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/profile"
          component={Profile}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/edit-profile"
          component={EditProfile}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/myhosting"
          component={MyHostingPage}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/appliedhosting"
          component={AppliedHostingPage}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/inquirtPage"
          component={InquirtPage}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/customerService"
          component={CustomerService}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/customerServices"
          component={MobileCustomerServices}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/deleteitinerary"
          component={DeleteItinerary}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/error"
          component={ErrorPage}
          layout={Layout}
          isPrivateRoute={true}
        />
        <RouteWrapper
          exact={true}
          path="/error2"
          component={ErrorPage2}
          layout={Layout}
          isPrivateRoute={true}
        />
      </Switch>
      <Footer />
    </div>
  );
};

export default Index;

function RouteWrapper({
  component: Component,
  layout: Layout,
  isPrivateRoute,
  ...rest
}) {
  return (
    <>
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    </>
  );
}

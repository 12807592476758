import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApiDelete, ApiPost } from "../../helper/API/ApiData";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import moment from "moment";
import AuthStorage from "../../helper/AuthStorage";
import ReactHtmlParser from "react-html-parser";

import "./TourCard.css";
import { Button } from "react-bootstrap";

const TourCard2 = ({
  items,
  canlike,
  UpdateCount,
  setRefresh,
  activeTab,
  status,
  status2,
}) => {
  // const [like, setLike] = useState(data.isLike);
  const [data, setData] = useState(items);
  const isMobile = window.innerWidth <= 428;

  const { t } = useTranslation();

  //Like Function
  const [delayLike, setDelayLike] = useState(false);
  const Like = (id) => {
    setDelayLike(true);
    setData((prev) => {
      return {
        ...prev,
        isLike: !prev.isLike,
      };
    });
    ApiPost(`itinerary/wishlist/${id}`, {}).then((res) => {
      setRefresh(Math.random());
      setDelayLike(false);
    });
  };

  const Remove = (id) => {
    setDelayLike(true);

    ApiDelete(`user/deleteItineraryUserApplied/${id}`, {}).then((res) => {
      setRefresh(Math.random());
      // setDelayLike(false);
    });
  };

  const Removewishlist = (id) => {
    setDelayLike(true);

    ApiPost(`itinerary/wishlist/${id}`, {}).then((res) => {
      setRefresh(Math.random());
      setDelayLike(false);
    });
  };

  const changeDateType = (date) => {
    const x = new Date(date);
    // const y = x.getMonth();
    // const w = x.getDate();
    // const z = x.getFullYear().toString();
    // const month = [
    //   "January",
    //   "February",
    //   "March",
    //   "April",
    //   "May",
    //   "June",
    //   "July",
    //   "August",
    //   "September",
    //   "October",
    //   "November",
    //   "December",
    // ];
    // return month[y] + " " + w + "," + z;
    if (AuthStorage.getLang() === "en") {
      return moment(x).locale(AuthStorage.getLang()).format("LL");
    } else {
      return moment(x).locale(AuthStorage.getLang()).format("yyyy년 M월 D일");
    }
  };

  const changeTimeFormat = (time) => {
    if (!time) {
      return "";
    }
    let Time = time.split(":");
    return Time[0] + ":" + Time[1];
  };

  const getCreatorString = (creator, creator_show) => {
    if (creator_show === "User" || creator_show === "Host") {
      return `<span class="black-font">Created by User</span>`;
    } else {
      return `<span class="black-font">${creator_show}</span>`;
    }
  };

  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {status === "DECLINED" || status2 === "DECLINED" ? (
        <div className={`tour-card res-card position-relative`}>
          <div className="imgArea-link position-relative w-100 d-block">
            <Link
              className="imgArea-link position-relative w-100 d-block"
              to={`/itinerary?id=${items?.id}${
                items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
              }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
              target={resize <= 768 ? "_self" : "_blank"}
            >
              {/* <div className='wishlistimg' style={{ backgroundImage: `url(${items?.image[0]})` }} ></div> */}
              <div className="imgArea w-100 h-100">
                <img
                  src={
                    items?.image && items?.image[0]
                      ? items?.image[0]
                      : "../../../img/main-page.svg"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../../../img/main-page.svg";
                  }}
                  alt=""
                />
              </div>
            </Link>
            <div className="heart-div position-absolute">
              <input
                type="checkbox"
                id={items.id}
                checked={data.isLike}
                // disabled={delayLike}
                onChange={() => Like(items.id)}
                // onClick={canlike ? () => Like(items.id) : () => { }}
                className="instruments"
              />
              <label htmlFor={items.id} className={`text-white check`}>
                {!data.isLike ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_2698_302425)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                        fill="white"
                      />
                      <rect
                        x="20.2852"
                        y="10.7305"
                        width="1.6832"
                        height="8.97706"
                        fill="white"
                      />
                      <rect
                        x="25.5605"
                        y="14.3164"
                        width="1.6832"
                        height="8.97706"
                        transform="rotate(90 25.5605 14.3164)"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_2698_302425"
                        x="0"
                        y="2"
                        width="27.998"
                        height="23.9023"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_2698_302425"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_2698_302425"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                      fill="#FF5F5F"
                      stroke="#FF5F5F"
                      stroke-width="2"
                    />
                  </svg>
                )}
              </label>
            </div>
          </div>
          <div className=" card-box">
            <Link
              to={`/itinerary?id=${items?.id}${
                items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
              }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
              target={resize <= 768 ? "_self" : "_blank"}
            >
              <h4 className="cardTitle">{items.title}</h4>
            </Link>

            <div className="d-flex location-with-comment align-items-center justify-content-between">
              <div className="location-row d-flex align-items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                    fill="#7E7E7E"
                  />
                </svg>

                <p>
                  {items.region}, {items.country}
                </p>
              </div>
              <div className="d-flex star-with-comment align-items-center">
                <div
                  className={
                    items.creator === "Compastrips"
                      ? "star-reviews"
                      : "star-reviews star-reviews star-reviews-responsive ml-1"
                  }
                >
                  {/* <div className="star-reviews"> */}
                  {items.creator === "Compastrips" ? (
                    <p className="card-comment">
                      {items.comment_count > 0 && (
                        <span>
                          {t("TourList.Comments")} ({items.comment_count})&nbsp;
                        </span>
                      )}
                      {items.creator === "Compastrips" &&
                        items.review_count > 0 && (
                          <>
                            <div className="res-star-list align-items-center justify-content-center">
                              <Rating
                                emptySymbol={
                                  <svg
                                    className="emptySymbol"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                      fill="#1B1C1E"
                                    />
                                  </svg>
                                }
                                fullSymbol={
                                  <svg
                                    className="fullSymbol"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                      fill="#1B1C1E"
                                    />
                                  </svg>
                                }
                                initialRating={items.star}
                                readonly={true}
                                stop={1}
                              />
                            </div>
                            {items.star}
                          </>
                        )}
                    </p>
                  ) : (
                    <p className="card-comment">
                      {items.comment_count > 0 && (
                        <span>
                          {t("TourList.Comments")} ({items.comment_count})
                        </span>
                      )}
                    </p>
                  )}
                </div>
                {/* <div className='d-flex align-items-center star-row main-star-row'> */}
              </div>
            </div>

            <div className="d-flex align-items-center">
              <h5 className="createdBy">
                {/* {items.created_by_show}{" "} */}
                {ReactHtmlParser(
                  getCreatorString(items.creator, items.creator_show)
                )}
              </h5>
              {items.hosted_count > 0 && (
                <h6 className="ml-auto tour-total-host">
                  <span className="font-blue">
                    {items.hosted_count}
                    {AuthStorage.getLang() !== "en" && "명"}
                  </span>
                  {AuthStorage.getLang() !== "en" && "이"}&nbsp;
                  {AuthStorage.getLang() === "en"
                    ? items.hosted_count === 1
                      ? t("My_Account.Tour")
                      : t("My_Account.Tours")
                    : t("My_Account.Tours")}
                </h6>
              )}
            </div>
            {items.creator === "Host" ? (
              <h5 className="date-tour-card">
                {items?.start_date ? (
                  <>
                    {changeDateType(items.start_date)}{" "}
                    {changeTimeFormat(items.start_time)}{" "}
                    {changeTimeFormat(items.end_time) !== "" &&
                      "- " + changeTimeFormat(items.end_time)}
                  </>
                ) : (
                  ""
                )}
              </h5>
            ) : (
              <h5 className="date-tour-card">
                {items.start_date ? (
                  <>
                    {changeDateType(items.start_date)}{" "}
                    {changeDateType(items.end_date) !== "" &&
                      "- " + changeDateType(items.end_date)}
                  </>
                ) : (
                  ""
                )}
              </h5>
            )}
          </div>
        </div>
      ) : status === "STAND_BY" ? (
        <div className={`tour-card res-card position-relative`}>
          <Link
            className="imgArea-link position-relative w-100 d-block"
            to={`/itinerary?id=${items?.id}${
              items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
            }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
            target={resize <= 768 ? "_self" : "_blank"}
          >
            {/* <div className='wishlistimg' style={{ backgroundImage: `url(${items?.image[0]})` }} ></div> */}
            <div className="imgArea w-100 h-100">
              <img
                src={
                  items?.image && items?.image[0]
                    ? items?.image[0]
                    : "../../../img/main-page.svg"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "../../../img/main-page.svg";
                }}
                alt=""
              />
            </div>
            <div className="heart-div position-absolute">
              <input
                type="checkbox"
                id={items.id}
                checked={data.isLike}
                // disabled={delayLike}
                onChange={() => Like(items.id)}
                // onClick={canlike ? () => Like(items.id) : () => { }}
                className="instruments"
              />
              <label htmlFor={items.id} className={`text-white check`}>
                {!data.isLike ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_2698_302425)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                        fill="white"
                      />
                      <rect
                        x="20.2852"
                        y="10.7305"
                        width="1.6832"
                        height="8.97706"
                        fill="white"
                      />
                      <rect
                        x="25.5605"
                        y="14.3164"
                        width="1.6832"
                        height="8.97706"
                        transform="rotate(90 25.5605 14.3164)"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_2698_302425"
                        x="0"
                        y="2"
                        width="27.998"
                        height="23.9023"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_2698_302425"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_2698_302425"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                      fill="#FF5F5F"
                      stroke="#FF5F5F"
                      stroke-width="2"
                    />
                  </svg>
                )}
              </label>
            </div>
          </Link>
          <div className=" card-box">
            <Link
              to={`/itinerary?id=${items?.id}${
                items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
              }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
              target={resize <= 768 ? "_self" : "_blank"}
            >
              <h4 className="cardTitle">{items.title}</h4>
            </Link>

            <div className="d-flex location-with-comment align-items-center justify-content-between">
              <div className="location-row d-flex align-items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                    fill="#7E7E7E"
                  />
                </svg>

                <p>
                  {items.region}, {items.country}
                </p>
              </div>
              <div className="d-flex star-with-comment align-items-center">
                <div
                  className={
                    items.creator === "Compastrips"
                      ? "star-reviews"
                      : "star-reviews star-reviews star-reviews-responsive ml-1"
                  }
                >
                  {/* <div className="star-reviews"> */}
                  {items.creator === "Compastrips" ? (
                    <p className="card-comment">
                      {items.comment_count > 0 && (
                        <span>
                          {t("TourList.Comments")} ({items.comment_count})&nbsp;
                        </span>
                      )}
                      {items.creator === "Compastrips" &&
                        items.review_count > 0 && (
                          <>
                            <div className="res-star-list align-items-center justify-content-center">
                              <Rating
                                emptySymbol={
                                  <svg
                                    className="emptySymbol"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                      fill="#1B1C1E"
                                    />
                                  </svg>
                                }
                                fullSymbol={
                                  <svg
                                    className="fullSymbol"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                      fill="#1B1C1E"
                                    />
                                  </svg>
                                }
                                initialRating={items.star}
                                readonly={true}
                                stop={1}
                              />
                            </div>
                            {items.star}
                          </>
                        )}
                    </p>
                  ) : (
                    <p className="card-comment">
                      {items.comment_count > 0 && (
                        <span>
                          {t("TourList.Comments")} ({items.comment_count})
                        </span>
                      )}
                    </p>
                  )}
                </div>
                {/* <div className='d-flex align-items-center star-row main-star-row'> */}
              </div>
            </div>

            <div className="d-flex align-items-center">
              <h5 className="createdBy">
                {/* {items.created_by_show}{" "} */}
                {ReactHtmlParser(
                  getCreatorString(items.creator, items.creator_show)
                )}
              </h5>
              {items.hosted_count > 0 && (
                <h6 className="ml-auto tour-total-host">
                  <span className="font-blue">
                    {items.hosted_count}
                    {AuthStorage.getLang() !== "en" && "명"}
                  </span>
                  {AuthStorage.getLang() !== "en" && "이"}&nbsp;
                  {AuthStorage.getLang() === "en"
                    ? items.hosted_count === 1
                      ? t("My_Account.Tour")
                      : t("My_Account.Tours")
                    : t("My_Account.Tours")}
                </h6>
              )}
            </div>
            {items.creator === "Host" ? (
              <h5 className="date-tour-card">
                {items?.start_date ? (
                  <>
                    {changeDateType(items.start_date)}{" "}
                    {changeTimeFormat(items.start_time)}{" "}
                    {changeTimeFormat(items.end_time) !== "" &&
                      "- " + changeTimeFormat(items.end_time)}
                  </>
                ) : (
                  ""
                )}
              </h5>
            ) : (
              <h5 className="date-tour-card">
                {items.start_date ? (
                  <>
                    {changeDateType(items.start_date)}{" "}
                    {changeDateType(items.end_date) !== "" &&
                      "- " + changeDateType(items.end_date)}
                  </>
                ) : (
                  ""
                )}
              </h5>
            )}
          </div>
        </div>
      ) : status === "CANCELED" ? (
        status2 === "ACCEPTED" || status2 === "STAND_BY" ? (
          <div className={`tour-card res-card position-relative`}>
            <div className="imgArea-link position-relative w-100 d-block">
              <Link
                className="imgArea-link position-relative w-100 d-block"
                to={`/itinerary?id=${items?.id}${
                  items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
                }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
                target={resize <= 768 ? "_self" : "_blank"}
              >
                {/* <div className='wishlistimg' style={{ backgroundImage: `url(${items?.image[0]})` }} ></div> */}
                <div className="imgArea w-100 h-100">
                  <img
                    src={
                      items?.image && items?.image[0]
                        ? items?.image[0]
                        : "../../../img/main-page.svg"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../../img/main-page.svg";
                    }}
                    alt=""
                  />
                </div>
              </Link>
              <div className="heart-div position-absolute">
                <input
                  type="checkbox"
                  id={items.id}
                  checked={data.isLike}
                  // disabled={delayLike}
                  onChange={() => Like(items.id)}
                  // onClick={canlike ? () => Like(items.id) : () => { }}
                  className="instruments"
                />
                <label htmlFor={items.id} className={`text-white check`}>
                  {!data.isLike ? (
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_2698_302425)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                          fill="white"
                        />
                        <rect
                          x="20.2852"
                          y="10.7305"
                          width="1.6832"
                          height="8.97706"
                          fill="white"
                        />
                        <rect
                          x="25.5605"
                          y="14.3164"
                          width="1.6832"
                          height="8.97706"
                          transform="rotate(90 25.5605 14.3164)"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_2698_302425"
                          x="0"
                          y="2"
                          width="27.998"
                          height="23.9023"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="1" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_2698_302425"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_2698_302425"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                        fill="#FF5F5F"
                        stroke="#FF5F5F"
                        stroke-width="2"
                      />
                    </svg>
                  )}
                </label>
              </div>
            </div>
            <div className=" card-box">
              <Link
                to={`/itinerary?id=${items?.id}${
                  items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
                }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
                target={resize <= 768 ? "_self" : "_blank"}
              >
                <h4 className="cardTitle">{items.title}</h4>
              </Link>

              <div className="d-flex location-with-comment align-items-center justify-content-between">
                <div className="location-row d-flex align-items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                      fill="#7E7E7E"
                    />
                  </svg>

                  <p>
                    {items.region}, {items.country}
                  </p>
                </div>
                <div className="d-flex star-with-comment align-items-center">
                  <div
                    className={
                      items.creator === "Compastrips"
                        ? "star-reviews"
                        : "star-reviews star-reviews star-reviews-responsive ml-1"
                    }
                  >
                    {/* <div className="star-reviews"> */}
                    {items.creator === "Compastrips" ? (
                      <p className="card-comment">
                        {items.comment_count > 0 && (
                          <span>
                            {t("TourList.Comments")} ({items.comment_count}
                            )&nbsp;
                          </span>
                        )}
                        {items.creator === "Compastrips" &&
                          items.review_count > 0 && (
                            <>
                              <div className="res-star-list align-items-center justify-content-center">
                                <Rating
                                  emptySymbol={
                                    <svg
                                      className="emptySymbol"
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                        fill="#1B1C1E"
                                      />
                                    </svg>
                                  }
                                  fullSymbol={
                                    <svg
                                      className="fullSymbol"
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                        fill="#1B1C1E"
                                      />
                                    </svg>
                                  }
                                  initialRating={items.star}
                                  readonly={true}
                                  stop={1}
                                />
                              </div>
                              {items.star}
                            </>
                          )}
                      </p>
                    ) : (
                      <p className="card-comment">
                        {items.comment_count > 0 && (
                          <span>
                            {t("TourList.Comments")} ({items.comment_count})
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                  {/* <div className='d-flex align-items-center star-row main-star-row'> */}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <h5 className="createdBy">
                  {/* {items.created_by_show}{" "} */}
                  {ReactHtmlParser(
                    getCreatorString(items.creator, items.creator_show)
                  )}
                </h5>
                {items.hosted_count > 0 && (
                  <h6 className="ml-auto tour-total-host">
                    <span className="font-blue">
                      {items.hosted_count}
                      {AuthStorage.getLang() !== "en" && "명"}
                    </span>
                    {AuthStorage.getLang() !== "en" && "이"}&nbsp;
                    {AuthStorage.getLang() === "en"
                      ? items.hosted_count === 1
                        ? t("My_Account.Tour")
                        : t("My_Account.Tours")
                      : t("My_Account.Tours")}
                  </h6>
                )}
              </div>
              {items.creator === "Host" ? (
                <h5 className="date-tour-card">
                  {items?.start_date ? (
                    <>
                      {changeDateType(items.start_date)}{" "}
                      {changeTimeFormat(items.start_time)}{" "}
                      {changeTimeFormat(items.end_time) !== "" &&
                        "- " + changeTimeFormat(items.end_time)}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              ) : (
                <h5 className="date-tour-card">
                  {items.start_date ? (
                    <>
                      {changeDateType(items.start_date)}{" "}
                      {changeDateType(items.end_date) !== "" &&
                        "- " + changeDateType(items.end_date)}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              )}
            </div>
            <div className="canceled-tour-card status-layer">
              <div className="status-layer-button">
                <h1 className="status-title">
                  {t("My_Account.Hosting_Canceled")}
                </h1>
                <Button
                  className="status-btn"
                  variant="cancelBtn"
                  // ButtonStyle="Remove-btn mt-32"
                  onClick={() => {
                    activeTab === "wishlist"
                      ? Removewishlist(items.id)
                      : Remove(items.participant_id);
                  }}
                >
                  {t("My_Account.Button.Remove")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={`tour-card res-card position-relative`}>
            <div className="imgArea-link position-relative w-100 d-block">
              <Link
                className="imgArea-link position-relative w-100 d-block"
                to={`/itinerary?id=${items?.id}${
                  items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
                }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
                target={resize <= 768 ? "_self" : "_blank"}
              >
                {/* <div className='wishlistimg' style={{ backgroundImage: `url(${items?.image[0]})` }} ></div> */}
                <div className="imgArea w-100 h-100">
                  <img
                    src={
                      items?.image && items?.image[0]
                        ? items?.image[0]
                        : "../../../img/main-page.svg"
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../../img/main-page.svg";
                    }}
                    alt=""
                  />
                </div>
              </Link>
              <div className="heart-div position-absolute">
                <input
                  type="checkbox"
                  id={items.id}
                  checked={data.isLike}
                  // disabled={delayLike}
                  onChange={() => Like(items.id)}
                  // onClick={canlike ? () => Like(items.id) : () => { }}
                  className="instruments"
                />
                <label htmlFor={items.id} className={`text-white check`}>
                  {!data.isLike ? (
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_2698_302425)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                          fill="white"
                        />
                        <rect
                          x="20.2852"
                          y="10.7305"
                          width="1.6832"
                          height="8.97706"
                          fill="white"
                        />
                        <rect
                          x="25.5605"
                          y="14.3164"
                          width="1.6832"
                          height="8.97706"
                          transform="rotate(90 25.5605 14.3164)"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_2698_302425"
                          x="0"
                          y="2"
                          width="27.998"
                          height="23.9023"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="1" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_2698_302425"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_2698_302425"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                        fill="#FF5F5F"
                        stroke="#FF5F5F"
                        stroke-width="2"
                      />
                    </svg>
                  )}
                </label>
              </div>
            </div>
            <div className=" card-box">
              <Link
                to={`/itinerary?id=${items?.id}${
                  items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
                }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
                target={resize <= 768 ? "_self" : "_blank"}
              >
                <h4 className="cardTitle">{items.title}</h4>
              </Link>

              <div className="d-flex location-with-comment align-items-center justify-content-between">
                <div className="location-row d-flex align-items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                      fill="#7E7E7E"
                    />
                  </svg>

                  <p>
                    {items.region}, {items.country}
                  </p>
                </div>
                <div className="d-flex star-with-comment align-items-center">
                  <div
                    className={
                      items.creator === "Compastrips"
                        ? "star-reviews"
                        : "star-reviews star-reviews star-reviews-responsive ml-1"
                    }
                  >
                    {/* <div className="star-reviews"> */}
                    {items.creator === "Compastrips" ? (
                      <p className="card-comment">
                        {items.comment_count > 0 && (
                          <span>
                            {t("TourList.Comments")} ({items.comment_count}
                            )&nbsp;
                          </span>
                        )}
                        {items.creator === "Compastrips" &&
                          items.review_count > 0 && (
                            <>
                              <div className="res-star-list align-items-center justify-content-center">
                                <Rating
                                  emptySymbol={
                                    <svg
                                      className="emptySymbol"
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                        fill="#1B1C1E"
                                      />
                                    </svg>
                                  }
                                  fullSymbol={
                                    <svg
                                      className="fullSymbol"
                                      width="16"
                                      height="17"
                                      viewBox="0 0 16 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                        fill="#1B1C1E"
                                      />
                                    </svg>
                                  }
                                  initialRating={items.star}
                                  readonly={true}
                                  stop={1}
                                />
                              </div>
                              {items.star}
                            </>
                          )}
                      </p>
                    ) : (
                      <p className="card-comment">
                        {items.comment_count > 0 && (
                          <span>
                            {t("TourList.Comments")} ({items.comment_count})
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                  {/* <div className='d-flex align-items-center star-row main-star-row'> */}
                </div>
              </div>

              <div className="d-flex align-items-center">
                <h5 className="createdBy">
                  {/* {items.created_by_show}{" "} */}
                  {ReactHtmlParser(
                    getCreatorString(items.creator, items.creator_show)
                  )}
                </h5>
                {items.hosted_count > 0 && (
                  <h6 className="ml-auto tour-total-host">
                    <span className="font-blue">
                      {items.hosted_count}
                      {AuthStorage.getLang() !== "en" && "명"}
                    </span>
                    {AuthStorage.getLang() !== "en" && "이"}&nbsp;
                    {AuthStorage.getLang() === "en"
                      ? items.hosted_count === 1
                        ? t("My_Account.Tour")
                        : t("My_Account.Tours")
                      : t("My_Account.Tours")}
                  </h6>
                )}
              </div>
              {items.creator === "Host" ? (
                <h5 className="date-tour-card">
                  {items?.start_date ? (
                    <>
                      {changeDateType(items.start_date)}{" "}
                      {changeTimeFormat(items.start_time)}{" "}
                      {changeTimeFormat(items.end_time) !== "" &&
                        "- " + changeTimeFormat(items.end_time)}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              ) : (
                <h5 className="date-tour-card">
                  {items.start_date ? (
                    <>
                      {changeDateType(items.start_date)}{" "}
                      {changeDateType(items.end_date) !== "" &&
                        "- " + changeDateType(items.end_date)}
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              )}
            </div>
          </div>
        )
      ) : (
        <div className={`tour-card res-card position-relative`}>
          <div className="imgArea-link position-relative w-100 d-block">
            <Link
              className="imgArea-link position-relative w-100 d-block"
              to={`/itinerary?id=${items?.id}${
                items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
              }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
              target={resize <= 768 ? "_self" : "_blank"}
            >
              {/* <div className='wishlistimg' style={{ backgroundImage: `url(${items?.image[0]})` }} ></div> */}
              <div className="imgArea w-100 h-100">
                <img
                  src={
                    items?.image && items?.image[0]
                      ? items?.image[0]
                      : "../../../img/main-page.svg"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../../../img/main-page.svg";
                  }}
                  alt=""
                />
              </div>
            </Link>
            <div className="heart-div position-absolute">
              <input
                type="checkbox"
                id={items.id}
                checked={data.isLike}
                // disabled={delayLike}
                onChange={() => Like(items.id)}
                // onClick={canlike ? () => Like(items.id) : () => { }}
                className="instruments"
              />
              <label htmlFor={items.id} className={`text-white check`}>
                {!data.isLike ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_2698_302425)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                        fill="white"
                      />
                      <rect
                        x="20.2852"
                        y="10.7305"
                        width="1.6832"
                        height="8.97706"
                        fill="white"
                      />
                      <rect
                        x="25.5605"
                        y="14.3164"
                        width="1.6832"
                        height="8.97706"
                        transform="rotate(90 25.5605 14.3164)"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_2698_302425"
                        x="0"
                        y="2"
                        width="27.998"
                        height="23.9023"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_2698_302425"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_2698_302425"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                      fill="#FF5F5F"
                      stroke="#FF5F5F"
                      stroke-width="2"
                    />
                  </svg>
                )}
              </label>
            </div>
          </div>
          <div className=" card-box">
            <Link
              to={`/itinerary?id=${items?.id}${
                items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
              }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
              target={resize <= 768 ? "_self" : "_blank"}
            >
              <h4 className="cardTitle">{items.title}</h4>
            </Link>

            <div className="d-flex location-with-comment align-items-center justify-content-between">
              <div className="location-row d-flex align-items-center">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                    fill="#7E7E7E"
                  />
                </svg>

                <p>
                  {items.region}, {items.country}
                </p>
              </div>
              <div className="d-flex star-with-comment align-items-center">
                <div
                  className={
                    items.creator === "Compastrips"
                      ? "star-reviews"
                      : "star-reviews star-reviews star-reviews-responsive ml-1"
                  }
                >
                  {/* <div className="star-reviews"> */}
                  {items.creator === "Compastrips" ? (
                    <p className="card-comment">
                      {items.comment_count > 0 && (
                        <span>
                          {t("TourList.Comments")} ({items.comment_count})&nbsp;
                        </span>
                      )}
                      {items.creator === "Compastrips" &&
                        items.review_count > 0 && (
                          <>
                            <div className="res-star-list align-items-center justify-content-center">
                              <Rating
                                emptySymbol={
                                  <svg
                                    className="emptySymbol"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                      fill="#1B1C1E"
                                    />
                                  </svg>
                                }
                                fullSymbol={
                                  <svg
                                    className="fullSymbol"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.39977 2.73274C7.44103 2.59556 7.52291 2.47573 7.63354 2.39068C7.74416 2.30562 7.87776 2.25977 8.01493 2.25977C8.1521 2.25977 8.2857 2.30562 8.39633 2.39068C8.50695 2.47573 8.58884 2.59556 8.63009 2.73274L9.74096 6.31519H13.3244C13.4665 6.30955 13.6064 6.35285 13.7228 6.43847C13.8392 6.52408 13.9257 6.6473 13.969 6.78931C14.0124 6.93131 14.0102 7.08428 13.9628 7.22486C13.9154 7.36543 13.8254 7.48588 13.7066 7.56779L10.7981 9.77863L11.9089 13.3673C11.9529 13.504 11.954 13.6519 11.9121 13.7893C11.8702 13.9267 11.7876 14.0465 11.6762 14.1311C11.5648 14.2157 11.4305 14.2608 11.2929 14.2597C11.1554 14.2587 11.0217 14.2115 10.9116 14.1252L7.99701 11.8893L5.08845 14.1064C4.97826 14.1927 4.84463 14.2399 4.70707 14.241C4.56952 14.242 4.43524 14.197 4.32384 14.1123C4.21245 14.0277 4.12977 13.9079 4.08789 13.7705C4.04601 13.6331 4.04712 13.4852 4.09105 13.3485L5.20192 9.75984L2.29335 7.549C2.17455 7.46709 2.08459 7.34664 2.03721 7.20607C1.98983 7.0655 1.98764 6.91252 2.03097 6.77052C2.0743 6.62851 2.16078 6.50529 2.27718 6.41968C2.39358 6.33406 2.53351 6.29076 2.67559 6.2964H6.25904L7.39977 2.73274Z"
                                      fill="#1B1C1E"
                                    />
                                  </svg>
                                }
                                initialRating={items.star}
                                readonly={true}
                                stop={1}
                              />
                            </div>
                            {items.star}
                          </>
                        )}
                    </p>
                  ) : (
                    <p className="card-comment">
                      {items.comment_count > 0 && (
                        <span>
                          {t("TourList.Comments")} ({items.comment_count})
                        </span>
                      )}
                    </p>
                  )}
                </div>
                {/* <div className='d-flex align-items-center star-row main-star-row'> */}
              </div>
            </div>

            <div className="d-flex align-items-center">
              <h5 className="createdBy">
                {/* {items.created_by_show}{" "} */}
                {ReactHtmlParser(
                  getCreatorString(items.creator, items.creator_show)
                )}
              </h5>
              {items.hosted_count > 0 && (
                <h6 className="ml-auto tour-total-host">
                  <span className="font-blue">
                    {items.hosted_count}
                    {AuthStorage.getLang() !== "en" && "명"}
                  </span>
                  {AuthStorage.getLang() !== "en" && "이"}&nbsp;
                  {AuthStorage.getLang() === "en"
                    ? items.hosted_count === 1
                      ? t("My_Account.Tour")
                      : t("My_Account.Tours")
                    : t("My_Account.Tours")}
                </h6>
              )}
            </div>
            {items.creator === "Host" ? (
              <h5 className="date-tour-card">
                {items?.start_date ? (
                  <>
                    {changeDateType(items.start_date)}{" "}
                    {changeTimeFormat(items.start_time)}{" "}
                    {changeTimeFormat(items.end_time) !== "" &&
                      "- " + changeTimeFormat(items.end_time)}
                  </>
                ) : (
                  ""
                )}
              </h5>
            ) : (
              <h5 className="date-tour-card">
                {items.start_date ? (
                  <>
                    {changeDateType(items.start_date)}{" "}
                    {changeDateType(items.end_date) !== "" &&
                      "- " + changeDateType(items.end_date)}
                  </>
                ) : (
                  ""
                )}
              </h5>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TourCard2;

import React, { useEffect, useState } from "react";
import { Card, Tabs } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Tab } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// ßimport { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { ApiGet } from "../../helper/API/ApiData";
import CustorServiceDetails from "./CustorServiceDetails";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import CKeditor from "../../components/CKeditor";

function CustomerService() {
  const { t } = useTranslation();

  const [value, setvalue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [faqPageNo, setFaqPageNo] = useState(1);
  const [openNotice, setOpenNotice] = useState(false);
  const [noticeId, setNoticeId] = useState();
  const [noticeData, setNoticeData] = useState({
    count: 0,
    notices: [
      {
        content: "",
        created_at: "",
        id: 0,
        title: "",
        updated_at: "",
        view_count: 0,
      },
    ],
  });

  const [show, setShow] = useState([]);
  const [faqData, setFaqData] = useState({
    count: 0,
    faq: [
      {
        answer: "",
        created_at: "",
        id: 0,
        question: "",
        updated_at: "",
        view_count: 0,
      },
    ],
  });
  const [faqKeyword, setFaqKeyword] = useState("");

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  //For FAQ
  const getFaqData = () => {
    ApiGet(
      `general/faq?keyword=${faqKeyword}&per_page=${9}&page_number=${faqPageNo}`
    ).then((res) => {
      setFaqData(res.data);
    });
  };

  //For Notice
  const getNoticeData = () => {
    ApiGet(
      `general/notice?keyword=${value}&per_page=${10}&page_number=${pageNo}`
    ).then((res) => {
      setNoticeData(res.data);
    });
  };

  useEffect(() => {
    getNoticeData();
  }, [pageNo, openNotice]);

  useEffect(() => {
    getFaqData();
  }, [faqPageNo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openNotice]);

  const handlechange = (id) => {
    setShow(id);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      getNoticeData();
      event.preventDefault();
    }
  };

  return (
    <div className="cnotice-page desk-customer-service">
      <div className="mini-container">
        <div className="CustomerService-title">
          <h1 className="ServiceTitle">
            {t("Customer_Service.Customer_Service")}
          </h1>
          <Tabs className="grid" defaultActiveKey="notice" transition={false}>
            <Tab eventKey="notice" title={t("Customer_Service.Notice")}>
              {!openNotice ? (
                <>
                  <div className="input d-flex align-items-center">
                    <input
                      name=""
                      type="text"
                      value={value}
                      placeholder={t("Customer_Service.Keyword")}
                      // fromrowStyleclass=""
                      // InputstyleClass="input-css"
                      // lablestyleClass=""
                      onChange={(e) => {
                        setvalue(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    <button onClick={() => getNoticeData()}>
                      <img
                        src="./img/CustomerSearch.svg"
                        alt="Customer Search icon"
                      />{" "}
                      <span> {t("Customer_Service.Search")}</span>
                    </button>
                  </div>
                  <div>
                    {/* <CKeditor
                      name="description"
                      onChange={(data) => {
                        setData(data);
                      }}
                      editorLoaded={editorLoaded}
                    /> */}
                  </div>
                  <div className="total-items">
                    <h5>
                      {t("Customer_Service.Total")}
                      {noticeData.count}
                      {t("Customer_Service.Items")}
                    </h5>
                  </div>
                  <table>
                    <tr className="th">
                      <th className="th-no text-center">No</th>
                      <th className="th-title text-center">
                        {t("Customer_Service.Title")}
                      </th>
                      <th className="th-data text-center">
                        {t("Customer_Service.Date")}
                      </th>
                      <th className="th-views text-center">
                        {t("Customer_Service.Views")}
                      </th>
                    </tr>
                    {noticeData.notices &&
                      noticeData.notices.map((items, i) => {
                        const title = items.title.replace(
                          value,
                          `<b>${value}</b>`
                        );
                        return (
                          <tr key={i} className="td">
                            <td className="td-no text-center">
                              {noticeData.count - (pageNo - 1) * 10 - i}
                            </td>
                            <td
                              className="td-title"
                              onClick={() => {
                                setNoticeId(noticeData.notices[i].id);
                                setOpenNotice(true);
                              }}
                            >
                              {ReactHtmlParser(title)}
                            </td>
                            <td className="td-date text-center">
                              {moment(items.created_at).format(
                                "YYYY.MM.DD HH:mm"
                              )}
                            </td>
                            <td className="td-viwes text-center">
                              {items.view_count}
                            </td>
                          </tr>
                        );
                      })}
                  </table>

                  <div className="pagination-tourlist">
                    <Pagination
                      itemClass="page-item-custom"
                      activeLinkClass="activepage"
                      linkClass="page-link-custom"
                      linkClassFirst="page-first-arrow d-none"
                      linkClassPrev="page-first-arrow arrow-previous-custom"
                      linkClassNext="page-first-arrow arrow-next-custom"
                      linkClassLast="page-first-arrow d-none"
                      prevPageText={
                        <img
                          src="./img/previous-page.png"
                          alt="previous-page icon"
                        />
                      }
                      // firstPageText={<><img src="./img/doublePolygon.svg" /></>}
                      // lastPageText={<><img src='./img/DublePolygonRight.svg' /></>}
                      nextPageText={
                        <img src="./img/next-page.png" alt="next-page icon" />
                      }
                      activePage={pageNo}
                      itemsCountPerPage={10}
                      pageRangeDisplayed={10}
                      // totalItemsCount={300}
                      totalItemsCount={noticeData.count}
                      onChange={(e) => {
                        setPageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <CustorServiceDetails
                  key={noticeId}
                  setOpenNotice={setOpenNotice}
                  noticeId={noticeId}
                  getNoticeData={getNoticeData}
                  noticeData={noticeData}
                />
                // <>
                //   <div className="detail-button">
                //     <button
                //       onClick={() => {
                //         setOpenNotice(false);
                //       }}
                //     >
                //       Back
                //     </button>
                //   </div>
                // </>
              )}
            </Tab>

            <Tab eventKey="faq" title={t("Customer_Service.FAQ")}>
              <div className="input d-flex align-items-center">
                <input
                  name=""
                  type="text"
                  value={faqKeyword}
                  placeholder={t("Customer_Service.Keyword")}
                  // fromrowStyleclass=""
                  // InputstyleClass="input-css"
                  // lablestyleClass=""
                  onChange={(e) => {
                    setFaqKeyword(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    getFaqData();
                  }}
                >
                  <img
                    src="./img/CustomerSearch.svg"
                    alt="Customer Search icon"
                  />{" "}
                  <span> {t("Customer_Service.Search")}</span>{" "}
                </button>
              </div>
              <div className="total-items">
                <h5>
                  {t("Customer_Service.Total")}
                  {faqData?.count}
                  {t("Customer_Service.Items")}
                </h5>
              </div>
              <div className="faq-table">
                {faqData?.faq?.map((items, i) => {
                  const faqTitle = items.question.replace(
                    faqKeyword,
                    `<b>${faqKeyword}</b>`
                  );
                  return (
                    <Accordion>
                      <Card className="accordion-card">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={items.id.toString()}
                        >
                          <div className="card-img d-flex align-items-center">
                            <div className="Q-round">
                              <span>Q</span>
                            </div>
                            <div
                              className="w-100"
                              onClick={() => {
                                handlechange(items.id);
                              }}
                            >
                              <p>{ReactHtmlParser(items.question)}</p>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        {show === items.id && (
                          <Accordion.Collapse eventKey={items.id.toString()}>
                            <Card.Body className="faq-body">
                              {/* {items.answer} */}
                              <pre className="noto-text">{items.answer}</pre>
                            </Card.Body>
                          </Accordion.Collapse>
                        )}
                      </Card>
                    </Accordion>
                  );
                })}
              </div>

              <div className="pagination-tourlist">
                <Pagination
                  itemClass="page-item-custom"
                  activeLinkClass="activepage"
                  linkClass="page-link-custom"
                  linkClassFirst="page-first-arrow d-none"
                  linkClassPrev="page-first-arrow arrow-previous-custom"
                  linkClassNext="page-first-arrow arrow-next-custom"
                  linkClassLast="page-first-arrow d-none"
                  prevPageText={
                    <img
                      src="./img/previous-page.png"
                      alt="previous-page icon"
                    />
                  }
                  // firstPageText={<><img src="./img/doublePolygon.svg" /></>}
                  // lastPageText={<><img src='./img/DublePolygonRight.svg' /></>}
                  nextPageText={
                    <img src="./img/next-page.png" alt="next-page icon" />
                  }
                  activePage={faqPageNo}
                  itemsCountPerPage={9}
                  pageRangeDisplayed={10}
                  totalItemsCount={faqData.count}
                  onChange={(e) => {
                    setFaqPageNo(e);
                  }}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default CustomerService;

import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ApiGetNoAuth, ApiPostNoAuth } from "../helper/API/ApiData";
import AuthStorage from "../helper/AuthStorage";
import PrivacyPolicy from "../Terms&Conditions/PrivacyPolicy";
import Terms from "../Terms&Conditions/Terms&Condition";
import Login from "./modal/Login";
import Welcome from "./modal/Welcome";
import "./Signup.css";

const Signup = ({ showSignup, onShow }) => {
  const resetForm = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    nationality: "",
    gender: "",
    bDay: "",
    bMonth: "",
    bYear: "",
    countryCode: "",
    verificationCode: "",
    agreeTerms: false,
    tremsOfUse: false,
    userNameVerify: "",
    emailVerifyCode: "",
  };

  const resetFormError = {
    firstNameError: "",
    lastNameError: "",
    userNameError: "",
    emailError: "",
    passwordError: "",
    confirmPassError: "",
    phoneNumberError: "",
    nationalityError: "",
    genderError: "",
    bDayError: "",
    countryCodeError: "",
    verificationError: "",
    bMonthError: "",
    bYearError: "",
    agreeTerms: "",
    userNameVerifyError: "",
    emailVerifyCodeError: "",
    emailVerifyCodeSuccess: "",
    emailVerifyCodeResult: false,
  };
  const { t } = useTranslation();

  const genderoptions = [
    { value: "FEMALE", label: t("signUp.Placeholder.Female") },
    { value: "MALE", label: t("signUp.Placeholder.Male") },
    { value: "OTHER", label: t("signUp.Placeholder.Other") },
  ];

  const [state, setState] = useState(resetForm);
  const [formError, setFormError] = useState(resetFormError);
  const [UserNameMaxlength, setUserNameMaxLength] = useState(false);
  const [TermsUse, setTermsUse] = useState(false);
  const [Privacypolicy, setPrivacypolicy] = useState(false);
  const [sendVCode, setSendVCode] = useState(false);
  const [signuppopup, setsignuppopup] = useState(false);
  const [welcome, setwelcome] = useState(false);
  const [userName, setUserName] = useState("");

  const [nationality, setNationality] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [countryCode2, setCountryCode2] = useState("");

  const [terms, setTerms] = useState(false);
  const [termsOfUse, settermsOfUse] = useState(false);
  const [privacyAndCookis, setPrivacyAndCookis] = useState(false);

  const [isSubmited, setIsSubmited] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  //Count Down Timer
  const [start, setStart] = useState(false);
  const [otpErr, setOtpErr] = useState("");
  const [incorrectOTP, setIncorrectOTP] = useState("");
  const [showCountDown, setShowCountDown] = useState(false);

  const [welcomeModal, setWelcomeModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);

  const [welcomeTitle, setWelcomeTitle] = useState("");

  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([parseInt("3"), parseInt("0")]);

  // 이메일 인증 코드 전송
  const [sendEVCode, setSendEVCode] = useState(false);

  const tick = () => {
    if (over) return;
    if (m === 0 && s === 0) {
      setOver(true);
      setOtpErr(`${t("signUp.Errors.Time_excede")}`);
      setIncorrectOTP("");
    } else if (s === 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  // const reset = () => {
  //     setTime([parseInt("3"), parseInt("0")]);
  //     setOver(false);
  // };

  useEffect(() => {
    if (start) {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    }
  });

  // const [termPopup, setTermPopup] = useState(false);
  // const [privacyPolicy, setPrivacyPolicy] = useState(false);

  const validateForm = () => {
    let errors = {
      firstNameError: "",
      lastNameError: "",
      userNameError: "",
      userNameVerifyError: "",
      emailError: "",
      emailVerifyCodeError: "",
      passwordError: "",
      confirmPassError: "",
      phoneNumberError: "",
      nationalityError: "",
      genderError: "",
      bDayError: "",
      countryCodeError: "",
      verificationError: "",
      bMonthError: "",
      bYearError: "",
      agreeTerms: "",
    };

    if (!state.firstName) {
      errors.firstNameError = `${t("signUp.Errors.First_Name")}`;
    }
    if (!state.lastName) {
      errors.lastNameError = `${t("signUp.Errors.Last_Name")}`;
    }

    if (!state.userName) {
      errors.userNameError = `${t("signUp.Errors.Username")}`;
    }

    // if (!state.userNameVerify) {
    //   errors.userNameVerifyError = `${t("signUp.Errors.UserNameVerify")}`;
    // }

    const validEmail = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );

    if (state.email.length === 0) {
      errors.emailError = `${t("signUp.Errors.EmailNull")}`;
    } else {
      if (!validEmail.test(state.email)) {
        errors.emailError = `${t("signUp.Errors.Email")}`;
      }
    }

    const validPassword = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,16})");

    if (!validPassword.test(state.password)) {
      errors.passwordError = `${t("signUp.Errors.Password")}`;
    }

    if (state.password !== state.confirmPassword) {
      errors.confirmPassError = `${t("signUp.Errors.Confirm_Password")}`;
    }

    if (!state.confirmPassword) {
      errors.confirmPassError = `${t("signUp.Errors.Confirm_Password")}`;
    }

    if (!state.phoneNumber) {
      errors.phoneNumberError = `${t("signUp.Errors.Phone_Number")}`;
    }

    if (!state.nationality) {
      errors.nationalityError = `${t("signUp.Errors.Country")}`;
    }

    if (!state.gender) {
      errors.genderError = `${t("signUp.Errors.Gender")}`;
    }

    if (!state.bDay) {
      errors.bDayError = `${t("signUp.Errors.DOB")}`;
    }

    if (!state.bMonth) {
      errors.bMonthError = `${t("signUp.Errors.DOB")}`;
    }

    if (!state.bYear) {
      errors.bYearError = `${t("signUp.Errors.DOB")}`;
    }

    if (!state.countryCode) {
      errors.countryCodeError = `${t("signUp.Errors.CountryCode")}`;
    }

    // if (!isVerified || !state.verificationCode) {
    //   errors.verificationError = `${t("signUp.Errors.Phone_Number")}`;
    // }

    // if (isVerified) {
    //   errors.verificationError = "";
    // }

    if (!terms) {
      errors.agreeTerms = `${t("signUp.Errors.TermErr")}`;
    }

    setFormError(errors);

    if (
      !errors.firstNameError &&
      !errors.lastNameError &&
      !errors.userNameError &&
      !errors.emailError &&
      !errors.passwordError &&
      !errors.confirmPassError &&
      !errors.phoneNumberError &&
      !errors.nationalityError &&
      !errors.genderError &&
      !errors.bDayError &&
      // !errors.verificationError &&
      !errors.confirmPassError &&
      !errors.agreeTerms &&
      !errors.userNameVerifyError &&
      !errors.emailVerifyCodeError
    ) {
      return true;
    }
    return false;
  };
  // const handleChange = (e) => {
  //   setState({
  //     ...state,
  //     [e.target.name]: e.target.value,
  //   });

  //   // userName
  //   if (e.target.name === "userName" && e.target.value.length > 10) {
  //     setState({
  //       ...state,
  //       [e.target.name]: e.target.value.substr(0, 10),
  //     });
  //   }

  //   regEXP(e);
  // };

  // Calander Dropdown
  const months = [
    { value: "1", label: "01" },
    { value: "2", label: "02" },
    { value: "3", label: "03" },
    { value: "4", label: "04" },
    { value: "5", label: "05" },
    { value: "6", label: "06" },
    { value: "7", label: "07" },
    { value: "8", label: "08" },
    { value: "9", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  //For year list
  const [years, setYears] = useState([{}]);
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      years.push({ value: i.toString(), label: i.toString() });
    }
    setYears(years);
  };

  //For date list
  const [days, setDays] = useState([]);

  const getDays = () => {
    let lastDate = new Date(+state.bYear, +state.bMonth, 0).getDate();

    let monthsDeys = [];
    for (let i = 1; i <= lastDate; i++) {
      monthsDeys.push({
        value: i.toString(),
        label: i < 10 ? `0${i.toString()}` : i.toString(),
      });
    }
    setDays(monthsDeys);
  };

  useEffect(() => {
    setDays([]);
    setState({
      ...state,
      bDay: "",
    });

    if (!state.bMonth || !state.bYear) {
      return;
    }

    getDays();
  }, [state.bMonth, state.bYear]);

  useEffect(() => {
    getCountryData();
    getYears();
    // setLocalEmail();
  }, []);

  const reset = () => {
    setTime([parseInt("3"), parseInt("0")]);
    setOver(false);
  };

  //------------------

  // OTP Send
  const sendOTP = () => {
    setSendVCode(true);
    setShowCountDown(true);
    setStart(true);
    setOtpErr("");
    reset();
    ApiPostNoAuth("user/otp-send", {
      mobile: state.countryCode + state.phoneNumber,
    });
  };

  const sendEmailOTP = () => {
    setSendEVCode(true);
    formError.emailVerifyCodeError = "";
    formError.emailVerifyCodeSuccess = "";
    setState({ ...state, emailVerifyCode: "" });
    ApiPostNoAuth("user/otp-email-send", {
      email: state.email,
    });
  };

  useEffect(() => {
    const _terms = termsOfUse && termsOfUse === privacyAndCookis;
    setTerms(_terms);
  }, [termsOfUse, privacyAndCookis]);

  useEffect(() => {
    if (isVerified) {
      validateForm();
    }
  }, [isVerified]);

  //Mobile Number Verification
  const mobileVerification = () => {
    ApiPostNoAuth("user/otp-email-verify", {
      email: state.email,
      code: state.emailVerifyCode,
    })
      .then((res) => {
        setIncorrectOTP("");
        setIsVerified(true);
        setOver(true);
        setShowCountDown(false);
      })
      .catch((error) => {
        setIncorrectOTP(error);
        // setOver(true);
      });
  };

  const emailVerification = () => {
    ApiPostNoAuth("user/otp-email-verify", {
      email: state.email,
      code: state.emailVerifyCode,
    })
      .then((res) => {
        if (res.status !== 200) {
          setFormError({
            emailVerifyCodeResult: false,
            emailVerifyCodeError: `${t("signUp.Errors.EmailVerifyCode")}`,
          });
        } else {
          setIsVerified(true);
          setFormError({
            emailVerifyCodeResult: true,
            emailVerifyCodeError: "",
            emailVerifyCodeSuccess: `${t(
              "signUp.Errors.EmailVerifyCodeSuccess"
            )}`,
          });
        }
      })
      .catch((error) => {
        setFormError({
          emailVerifyCodeResult: false,
          emailVerifyCodeError: `${t("signUp.Errors.EmailVerifyCode")}`,
        });
      });
  };

  const userNameVerification = () => {
    ApiPostNoAuth("user/username-verify", {
      username: state.userName,
    })
      .then((res) => {
        setFormError({
          ...formError,
          userNameVerifyError: `${t("signUp.Errors.UserNameVerifySuccess")}`,
        });
        setState({
          ...state,
          userNameConfirm: true,
        });
        // if (res.status !== 200) {
        //   setFormError({
        //     userNameVerifyError: `${t("signUp.Errors.UserNameVerify")}`,
        //   });
        //   setFormError({
        //     userNameVerifyError: `${t("signUp.Errors.UserNameVerify")}`,
        //   });
        //   setFormError({
        //     userNameVerifyError: `${t("signUp.Errors.UserNameVerify")}`,
        //   });
        // } else {
        //   setFormError({
        //     userNameVerifyError: "",
        //   });
        // }
      })
      .catch((error) => {
        setFormError({
          ...formError,
          userNameVerifyError: `${t("signUp.Errors.UserNameVerify")}`,
        });
      });
  };

  const [errMessage, setErrorMessage] = useState("");
  //Sign Up---------
  const SignUp = async () => {
    setIsSubmited(true);
    // debugger
    if (!validateForm()) {
      return;
    }

    ApiPostNoAuth("user/auth/signup", {
      first_name: state.firstName,
      last_name: state.lastName,
      user_name: state.userName,
      email: state.email,
      password: state.password,
      gender: state.gender,
      nationality: state.nationality,
      mobile: `${state.countryCode} ${state.phoneNumber}`,
      dob: `${state.bYear}-${state.bMonth}-${state.bDay}`,
      is_verified: isVerified,
      country_code: state.countryCode,
    })
      .then((res) => {
        let wTitle =
          AuthStorage.getLang() === "en"
            ? "Hi " + state.firstName + " " + state.lastName
            : state.lastName + state.firstName + "님";

        setWelcomeTitle(wTitle);
        setUserName(state.userName);
        setState(resetForm);
        setwelcome(true);
        setsignuppopup(false);
        setFormError(resetFormError);
        setIsVerified(false);
        setSendVCode(false);
        setWelcomeModal(true);
      })

      .catch((error) => {
        setIsVerified(false);

        if (error === "User already exists") {
          setErrorMessage("User already exists");
        }
      });
  };
  //---------

  //Country Data
  const getCountryData = async () => {
    try {
      const res = await ApiGetNoAuth("general/country");

      setNationality(
        res.data.map((x) => {
          return {
            value: x.name,
            label: x.name,
            code: `${x.code.toString()}`,
          };
        })
      );
      setCountryCode(
        res.data.map((x) => {
          return {
            value: `${x.code.toString()}`,
            label: `(${x.code.toString()}) ${x.name}`,
          };
        })
      );
    } catch (error) {}
  };
  //---------------

  const goLoging = () => {
    setLoginModal(true);
  };

  const hideWelcom = () => {
    setWelcomeModal(false);
    setLoginModal(true);
  };

  // 유효성검사
  const handleChange = (e) => {
    let formName = e.target.name;
    let formValue = e.target.value;

    // 이 외
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    if (formName === "firstName") {
      setFormError({
        ...formError,
        firstNameError: "",
      });
    }

    if (formName === "lastName") {
      setFormError({
        ...formError,
        lastNameError: "",
      });
    }

    // 유저네임
    if (formName === "userName") {
      if (formValue.length > 10) {
        setFormError({
          ...formError,
          userNameError: `${t("signUp.Errors.UserNameMaxLength")}`,
        });

        setState({
          ...state,
          [formName]: formValue.substr(0, 10),
        });
      } else {
        setFormError({
          ...formError,
          userNameError: "",
          userNameVerifyError: "",
        });
      }
    }

    // 이메일
    if (formName === "email") {
      const confirmEmail = new RegExp(
        /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
      );
      if (!confirmEmail.test(formValue)) {
        setFormError({
          ...formError,
          emailError: `${t("signUp.Errors.Email")}`,
        });
      } else {
        setFormError({
          ...formError,
          emailError: "",
        });
      }
    }

    // 비밀번호
    if (formName === "password") {
      var regExpPassword = /^(?=.*?[a-zA-Z0-9])(?=.*?[0-9]).{8,16}$/;
      var password = regExpPassword.test(formValue);

      if (!password) {
        // 실패
        setFormError({
          ...formError,
          passwordError: `${t("signUp.Errors.Password")}`,
        });

        setState({
          ...state,
          [formName]: formValue.substr(0, 16),
        });
      } else {
        // 성공
        setState({
          ...state,
          [formName]: formValue.substr(0, 16),
        });
        setFormError({
          ...formError,
          passwordError: "",
        });
      }
    }

    // 비밀번호 확인
    if (formName === "confirmPassword") {
      let password = state.password;

      if (password !== formValue) {
        setFormError({
          ...formError,
          confirmPassError: `${t("signUp.Errors.Confirm_Password")}`,
        });
      } else {
        setFormError({
          ...formError,
          confirmPassError: `${t("signUp.Errors.Confirm_PasswordSuccess")}`,
        });
      }
    }

    // 국가코드
    if (formName === "countryCode") {
      setState({
        ...state,
        [formName]: e.value,
      });
    }

    // 폰번호 확인
    if (formName === "phoneNumber") {
      var phoneNum = Number(formValue);
      setState({
        ...state,
        [formName]: phoneNum,
      });
    }

    // if(state.firstName && state.lastName && formError)
  };

  return (
    <>
      <main id="SignUp">
        <form
          onSubmit={() => {
            SignUp();
          }}
        >
          <h5 className="page-title">{t("signUp.Sign_Up")}</h5>
          <div className="input-field name-filed">
            <label htmlFor="">
              {t("signUp.Name")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <div className="d-flex justify-content-between name-field">
              <div className="position-relative">
                <input
                  type="text"
                  placeholder={t("signUp.Placeholder.First_Name")}
                  value={state.firstName}
                  name="firstName"
                  className="res-input"
                  required
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {isSubmited && formError.firstNameError && (
                  <p className="signup-form-error">
                    {formError.firstNameError}
                  </p>
                )}
              </div>
              <div className="position-relative">
                <input
                  type="text"
                  required
                  placeholder={t("signUp.Placeholder.Last_Name")}
                  value={state.lastName}
                  name="lastName"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {isSubmited && formError.lastNameError && (
                  <p className="signup-form-error">{formError.lastNameError}</p>
                )}
              </div>
            </div>
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Username")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <div className="position-relative">
              <input
                className="inputWithBtn"
                type="text"
                required
                placeholder={t("signUp.Placeholder.Username")}
                value={state.userName}
                name="userName"
                maxLength={10}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <button
                className={
                  state.userName.length === 0 || formError.userNameVerifyError
                    ? "signupButton disabled"
                    : "signupButton"
                }
                disabled={
                  state.userName.length === 0 || formError.userNameVerifyError
                }
                onClick={() => {
                  userNameVerification();
                }}
                dangerouslySetInnerHTML={{ __html: t("signUp.DuplicateCheck") }}
              />
            </div>
            {isSubmited && formError.userNameError ? (
              <p className="signup-form-error">
                {formError.userNameError}
                {/* {t("signUp.Errors.Username")} */}
              </p>
            ) : formError.userNameError ? (
              <p className="signup-form-error">{formError.userNameError}</p>
            ) : formError.userNameVerifyError ===
              t("signUp.Errors.UserNameVerify") ? (
              <p className="signup-form-error">
                {t("signUp.Errors.UserNameVerify")}
              </p>
            ) : formError.userNameVerifyError ===
              t("signUp.Errors.UserNameVerifySuccess") ? (
              <p className="signup-form-ok">
                {t("signUp.Errors.UserNameVerifySuccess")}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Email")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <div
              className={
                isVerified
                  ? "position-relative email-input disabled-input"
                  : "position-relative email-input"
              }
            >
              <input
                type="email"
                className="inputWithBtn"
                required
                placeholder={t("signUp.Placeholder.Email")}
                value={state.email}
                disabled={isVerified}
                name="email"
                onChange={(e) => {
                  handleChange(e);
                }}
                autoComplete="off"
              />
              <button
                className={
                  state.email.length > 0 && formError.emailError === ""
                    ? "signupButton"
                    : "signupButton disabled"
                }
                disabled={
                  isVerified ||
                  state.email.length === 0 ||
                  formError.emailError ||
                  (state.email.length > 0 && sendEVCode)
                }
                onClick={() => {
                  sendEmailOTP();
                }}
              >
                {t("signUp.Send_Verification_Code")}
              </button>
            </div>

            <div
              className={
                !sendEVCode || isVerified
                  ? "position-relative disabled-input"
                  : "position-relative"
              }
            >
              <input
                className="inputWithBtn"
                type="text"
                required
                placeholder={t("signUp.Placeholder.EmailVerify")}
                value={state.emailVerifyCode}
                disabled={isVerified}
                name="emailVerifyCode"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <button
                className={
                  state.emailVerifyCode.length !== 0 &&
                  formError.emailVerifyCodeResult === false
                    ? "signupButton"
                    : "signupButton disabled"
                }
                disabled={
                  state.emailVerifyCode.length === 0 || isVerified === true
                }
                onClick={() => {
                  formError.emailVerifyCodeError !== ""
                    ? sendEmailOTP()
                    : emailVerification();
                }}
              >
                {formError.emailVerifyCodeError === ""
                  ? t("signUp.Verify")
                  : t("signUp.Resend")}
              </button>
            </div>
            {isSubmited && formError.emailError && (
              <p className="signup-form-error">{formError.emailError}</p>
            )}
            {state.emailVerifyCode.length > 0 &&
              formError.emailVerifyCodeError !== "" && (
                <p className="signup-form-error">
                  {formError.emailVerifyCodeError}
                </p>
              )}
            {isVerified && (
              <p className="signup-form-ok">
                {t("signUp.Errors.EmailVerifyCodeSuccess")}
              </p>
            )}
            {sendEVCode === true &&
              formError.emailVerifyCodeError === "" &&
              formError.emailVerifyCodeSuccess === "" && (
                <p className="signup-form-ok">
                  {t("signUp.Errors.EmailVerifyCodeSend")}
                </p>
              )}
            {/* <p className='signup-form-error'>Enter a valid email address</p> */}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Password")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <input
              type="password"
              required
              placeholder={t("signUp.Placeholder.Password")}
              value={state.password}
              name="password"
              // minLength={8}
              // maxLength={16}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {formError.passwordError && (
              <p
                className={`${
                  AuthStorage.getLang() === "en" ? " password-two-error" : ""
                } signup-form-error`}
              >
                {formError.passwordError}
              </p>
            )}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Confirm_Password")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <input
              type="password"
              required
              placeholder={t("signUp.Placeholder.Confirm_Password")}
              value={state.confirmPassword}
              name="confirmPassword"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {formError.confirmPassError ===
            t("signUp.Errors.Confirm_Password") ? (
              <p className="signup-form-error">{formError.confirmPassError}</p>
            ) : formError.confirmPassError === "" ||
              formError.confirmPassError === undefined ? (
              <></>
            ) : (
              <p className="signup-form-ok">
                {t("signUp.Errors.Confirm_PasswordSuccess")}
              </p>
            )}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Country")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <Select
              classNamePrefix={"input-style"}
              // options={nationalitydropdown}
              options={nationality}
              name="nationality"
              required
              placeholder={t("signUp.Placeholder.Country")}
              isSearchable={false}
              onChange={(e) => {
                setState({
                  ...state,
                  nationality: e.value,
                  countryCode: e.code,
                });
                setCountryCode2("(" + e.code + ") " + e.value);
              }}
            />
            {isSubmited && formError.nationalityError && (
              <p className="signup-form-error">{formError.nationalityError}</p>
            )}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Gender")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <Select
              classNamePrefix={"input-style"}
              options={genderoptions}
              name="gender"
              required
              isSearchable={false}
              placeholder={t("signUp.Placeholder.Gender")}
              onChange={(e) =>
                setState({
                  ...state,
                  gender: e.value,
                })
              }
            />
            {isSubmited && formError.genderError && (
              <p className="signup-form-error">{formError.genderError}</p>
            )}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.DOB")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <div className="d-flex align-items-center justify-content-between birth-field">
              <Select
                name="bYear"
                classNamePrefix={"input-style"}
                options={years}
                required
                placeholder="YYYY"
                isSearchable={false}
                onChange={(e) =>
                  setState({
                    ...state,
                    bYear: e.value,
                  })
                }
              />
              <Select
                name="bMonth"
                classNamePrefix={"input-style"}
                required
                options={months}
                placeholder="MM"
                isSearchable={false}
                onChange={(e) =>
                  setState({
                    ...state,
                    bMonth: e.value,
                  })
                }
              />
              <Select
                name="bDay"
                classNamePrefix={"input-style"}
                required
                options={days}
                placeholder="DD"
                isSearchable={false}
                onChange={(e) =>
                  setState({
                    ...state,
                    bDay: e.value,
                  })
                }
              />
            </div>

            {isSubmited &&
            (formError.bDayError ||
              formError.bMonthError ||
              formError.bYearError) ? (
              <p className="signup-form-error">{formError.bDayError}</p>
            ) : null}
          </div>
          <div className="input-field">
            <label>
              {t("signUp.Phone_Number")}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                  fill="#FF5F5F"
                />
              </svg>
            </label>
            <div className="d-flex position-relative phone-field phone-verify">
              <div className="position-relative">
                <Select
                  classNamePrefix={"input-style"}
                  placeholder={t("signUp.Placeholder.Select")}
                  required
                  options={countryCode}
                  value={
                    countryCode2 !== "" && {
                      value: state.countryCode,
                      label: countryCode2,
                    }
                  }
                  isSearchable={false}
                  name="countryCode"
                  onChange={(e) => {
                    setState({
                      ...state,
                      countryCode: e.value,
                    });
                    setCountryCode2(e.label);
                  }}
                />
                {isSubmited && formError.phoneNumberError && (
                  <>
                    <p className="signup-form-error position-unset">
                      {formError.countryCodeError}
                    </p>
                  </>
                )}
              </div>
              <div className="position-relative">
                <input
                  type="text"
                  required
                  name="phoneNumber"
                  placeholder={t("signUp.Placeholder.Phone_Number")}
                  className="input-verify"
                  // value={state.phoneNumber}
                  // disabled={isVerified}
                  // maxLength={10}
                  onChange={(e) => {
                    const value = e.target.value;
                    const re = /^[0-9\b]+$/;
                    re.test(value);
                    if (re.test(value)) {
                      handleChange(e);
                    }
                  }}
                />
                {isSubmited && formError.phoneNumberError && (
                  <>
                    <p className="signup-form-error input-verify position-unset">
                      {formError.phoneNumberError}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="agree-area">
            <div className="input-field agree-all d-flex">
              <input
                type="checkbox"
                required
                id="allAgree"
                name="agree"
                onChange={(e) => {
                  setTerms(e.target.checked);
                  settermsOfUse(e.target.checked);
                  setPrivacyAndCookis(e.target.checked);
                }}
                checked={terms}
              />
              <label htmlFor="allAgree" className="signup-checkbox">
                {terms === true &&
                termsOfUse === true &&
                privacyAndCookis === true ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="13" fill="#42B6E6" />
                    <rect
                      x="8.13574"
                      y="10.9844"
                      width="9.50624"
                      height="3"
                      transform="rotate(44.9589 8.13574 10.9844)"
                      fill="white"
                    />
                    <rect
                      width="18.4209"
                      height="3"
                      transform="matrix(-0.707614 0.706599 0.706599 0.707614 24.0342 5)"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="13" fill="#AEAEAE" />
                    <rect
                      x="8.13477"
                      y="10.9844"
                      width="9.50624"
                      height="3"
                      transform="rotate(44.9589 8.13477 10.9844)"
                      fill="white"
                    />
                    <rect
                      width="18.4209"
                      height="3"
                      transform="matrix(-0.707614 0.706599 0.706599 0.707614 24.0352 5)"
                      fill="white"
                    />
                  </svg>
                )}
                <span
                  dangerouslySetInnerHTML={{ __html: t("signUp.Terms.Agree") }}
                />
              </label>
            </div>
            <div className="input-field d-flex">
              <input
                type="checkbox"
                required
                id="agreeTerms"
                name="agreeTerms"
                onChange={(e) => {
                  settermsOfUse(!termsOfUse);
                }}
              />
              <label htmlFor="agreeTerms" className="signup-checkbox">
                {termsOfUse ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="13" fill="#42B6E6" />
                    <rect
                      x="8.13574"
                      y="10.9844"
                      width="9.50624"
                      height="3"
                      transform="rotate(44.9589 8.13574 10.9844)"
                      fill="white"
                    />
                    <rect
                      width="18.4209"
                      height="3"
                      transform="matrix(-0.707614 0.706599 0.706599 0.707614 24.0342 5)"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="13" fill="#AEAEAE" />
                    <rect
                      x="8.13477"
                      y="10.9844"
                      width="9.50624"
                      height="3"
                      transform="rotate(44.9589 8.13477 10.9844)"
                      fill="white"
                    />
                    <rect
                      width="18.4209"
                      height="3"
                      transform="matrix(-0.707614 0.706599 0.706599 0.707614 24.0352 5)"
                      fill="white"
                    />
                  </svg>
                )}
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("signUp.Terms.Term_of_use"),
                    }}
                  />
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                      fill="#FF5F5F"
                    />
                  </svg>
                </div>
              </label>
              <button className="" onClick={() => setTermsUse(true)}>
                {t("signUp.Placeholder.More")}
              </button>
            </div>
            <div className="input-field d-flex">
              <input
                type="checkbox"
                required
                id="privacyAndCookis"
                name="privacyAndCookis"
                onChange={(e) => {
                  setPrivacyAndCookis(!privacyAndCookis);
                }}
                checked={privacyAndCookis}
              />
              <label htmlFor="privacyAndCookis">
                {privacyAndCookis ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="13" fill="#42B6E6" />
                    <rect
                      x="8.13574"
                      y="10.9844"
                      width="9.50624"
                      height="3"
                      transform="rotate(44.9589 8.13574 10.9844)"
                      fill="white"
                    />
                    <rect
                      width="18.4209"
                      height="3"
                      transform="matrix(-0.707614 0.706599 0.706599 0.707614 24.0342 5)"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="14" cy="14" r="13" fill="#AEAEAE" />
                    <rect
                      x="8.13477"
                      y="10.9844"
                      width="9.50624"
                      height="3"
                      transform="rotate(44.9589 8.13477 10.9844)"
                      fill="white"
                    />
                    <rect
                      width="18.4209"
                      height="3"
                      transform="matrix(-0.707614 0.706599 0.706599 0.707614 24.0352 5)"
                      fill="white"
                    />
                  </svg>
                )}
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("signUp.Terms.Privacy_and_Cookie"),
                    }}
                  />
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.14286 10.2381H6.88095V7.61905L9.14286 8.92857L10 7.42857L7.7381 6.11905L10 4.80952L9.14286 3.30952L6.88095 4.61905V2H5.14286V4.61905L2.85714 3.30952L2 4.80952L4.2619 6.11905L2 7.42857L2.85714 8.92857L5.14286 7.61905V10.2381Z"
                      fill="#FF5F5F"
                    />
                  </svg>
                </div>
              </label>
              <button className="" onClick={() => setPrivacypolicy(true)}>
                {t("signUp.Placeholder.More")}
              </button>
            </div>
          </div>
          {/* end */}
          <div className={"signup-btn-modal w-100 text-center"}>
            <Button
              variant="confirmBtn"
              className="w-100 joinBtn"
              type="submit"
              onClick={() => {
                SignUp();
              }}
            >
              {" "}
              {t("signUp.Sign_Up")}
            </Button>
          </div>
          <p className="have-account-row">
            {t("signUp.Placeholder.Already_have_account")}
            <span
              className="cursor-pointer"
              onClick={() => setLoginModal(true)}
            >
              {" "}
              {t("logIn.Log_In")}{" "}
            </span>
          </p>
        </form>

        <Welcome
          showWelcome={welcomeModal}
          onHideWelocome={() => setWelcomeModal(false)}
          hideWelcom={hideWelcom}
          onShow={onShow}
          welcomeTitle={welcomeTitle}
        />

        <Terms set={setTermsUse} value={TermsUse} />
        <PrivacyPolicy set={setPrivacypolicy} value={Privacypolicy} />

        <Login show={loginModal} onHide={() => setLoginModal(false)} />
      </main>
    </>
  );
};

export default Signup;

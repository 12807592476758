import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ApiPost } from "../helper/API/ApiData";
import Login from "../pages/modal/Login";
import { Button } from "react-bootstrap";

const According = ({
  items,
  index,
  showAcc,
  setShowAcc,
  itineraryId = null,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { is_loggedin } = useSelector((state) => state.login);
  const { t } = useTranslation();
  const checkIsComplete = (date, endTime) => {
    let time = moment(
      moment(date).format("YYYY:MM:DD") + " " + endTime,
      "YYYY:MM:DD HH:mm"
    );
    return time.toDate() < moment(new Date(), "YYYY:MM:DD HH:mm").toDate();
  };

  useEffect(() => {}, [showAcc]);

  const setDate = (startDate, endDate) => {
    let date = "-";
    if (!startDate && !endDate) {
      date = "-";
    } else if (startDate && !endDate) {
      date = `${moment(startDate).format("YYYY.MM.DD")}`;
    } else {
      date = `${moment(startDate).format("YYYY.MM.DD")} - ${moment(
        endDate
      ).format("YYYY.MM.DD")}`;
    }

    return date;
  };

  const [delayLike, setDelayLike] = useState(false);
  const [delayID, setid] = useState([]);
  const Like = (id) => {
    setDelayLike(true);

    if (delayID.length > 0) {
      if (delayID.includes(items.id)) {
        let data = delayID.filter((x) => x !== id);
        setid([...data]);
      } else {
        setid([...delayID, id]);
      }
    } else {
      setid([...delayID, id]);
    }

    ApiPost(`itinerary/tourcoursewishlist/${id}`, { itinerary_id: itineraryId })
      .then((res) => {
        setDelayLike(false);
      })
      .catch((err) => {
        // console.log("Fail", err);
      });
  };

  useEffect(() => {
    if (items.isLike === true) {
      setid([...delayID, items.id]);
      items.isLike = false;
    }
  }, [items.isLike]);

  return (
    <>
      <div
        className="single-accordian-itinery"
        onClick={() => {
          if (showAcc === items.id) {
            setShowAcc(0);
          } else {
            setShowAcc(items.id);
          }
        }}
      >
        <div className="name-with-arrow d-flex justify-content-between align-items-center">
          <div className="num-with-title d-flex align-items-center">
            <h3 className="accor-num circle">{index}</h3>
            <h4 className="accor-title">{items.name}</h4>
          </div>
          <button className="droddown-according d-flex align-items-center">
            <img
              src="./img/acc-image.svg"
              alt=""
              className={showAcc.includes(items.id) ? "rotate" : "secRotate"}
            />
          </button>
        </div>
      </div>
      {showAcc.includes(items.id) && (
        <div className="show-acc d-flex flex-column">
          <div className="d-flex align-items-center showCity">
            <p className="accor-left">{t("Tour_Course_Details.City")}</p>
            <span className="accor-right">
              {items.region ? items.region : "-"}
            </span>
          </div>
          <div className="d-flex align-items-center res-category">
            <p className="accor-left">{t("Tour_Course_Details.Category")}</p>
            <span className="accor-right">
              {" "}
              {items.category ? items.category : "-"}
            </span>
          </div>
          <div className="showAccImg d-flex show-mt">
            <p className="accor-left">{t("Tour_Course_Details.Photos")}</p>
            <div className="image-sub-itinerary accor-right">
              {items.image.length > 0
                ? items.image.map((data, i) => (
                    <div className="imgArea">
                      <img
                        className="objectfit-cover"
                        key={i}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "../../img/download-gray.svg";
                        }}
                        src={data}
                        alt=""
                      />
                    </div>
                  ))
                : "-"}

              {/* {items?.image && items.image.length === 0 && (
                <img src={"../../img/download-gray.svg"} alt="" />
              )} */}
            </div>
          </div>

          <div className="d-flex align-items-center showDate">
            <p className="accor-left">{t("Tour_Course_Details.Date")} </p>
            {/* <span>{items.opening_date}</span> */}
            {/* <span>{items.opening_date}</span> */}
            <span className="accor-right">
              {setDate(items?.opening_date, items?.closing_date)}
            </span>
          </div>

          <div className="d-flex showAbout">
            <p className="accor-left">{t("Tour_Course_Details.About")} </p>
            <span className="accor-right">
              {items.summary ? items.summary : "-"}
            </span>
          </div>

          <div className="d-flex showAddress">
            <p className="accor-left">{t("Tour_Course_Details.Address")} </p>
            <span className="accor-right">
              {items.address ? items.address : "-"}
            </span>
          </div>

          <div className="d-flex align-items-center showUrl">
            <p className="accor-left">{t("Tour_Course_Details.URL")} </p>
            <span className="accor-right">
              <a href={items.website} target="blank">
                {items.website ? items.website : "-"}
              </a>
            </span>
          </div>

          <div className="d-flex align-items-center ShowPhone">
            <p className="accor-left">
              {t("Tour_Course_Details.Phone_Number")}{" "}
            </p>
            <span className="accor-right">
              {items.telephone ? items.telephone : "-"}
            </span>
          </div>

          <div className="add-to-wishlist-row">
            <Button
              variant="confirmBtn"
              className="add-to-wishlist-btn"
              onClick={
                is_loggedin
                  ? (e) => {
                      e.stopPropagation();
                      Like(items.id);
                    }
                  : () => {
                      setModalShow(true);
                    }
              }
            >
              {delayID.includes(items.id) === true
                ? t("Tour_Course_Details.Remove_to_Wishlist")
                : t("Tour_Course_Details.Add_to_Wishlist")}
            </Button>
          </div>
        </div>
      )}
      <Login
        show={modalShow}
        loginValue=""
        onHide={() => setModalShow(false)}
        onHideNew=""
        onShow=""
      />
    </>
  );
};

export default According;

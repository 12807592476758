import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { isLoadingReducer } from "./reducers/loadingReducer";
import { userDataReducer } from "./reducers/userDataReducer";
import { isUserLoginReducer } from "./reducers/loginReducer";
import { chatDataReducer } from "./reducers/chatDataReducer";
import { notificationDataReducer } from "./reducers/notificationReducer";
import { persistDataReducer } from "./reducers/persistDataReducer";
import { trendingNowReducer } from "./reducers/trendingNowReducer";

const middleware = [thunk];

const rootReducer = combineReducers({
  loading: isLoadingReducer,
  userData: userDataReducer,
  login: isUserLoginReducer,
  chatData: chatDataReducer,
  notification: notificationDataReducer,
  persistData: persistDataReducer,
  trendingNowData: trendingNowReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

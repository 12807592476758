import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useLocation, useHistory } from "react-router-dom";
import * as QueryString from "query-string";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import HostList from "./HostList";
import Reviews from "./Reviews";
// import UserHostList from "./UserHostList";
import HostProfile from "./HostProfile";
// import ApplicationPax from "./ApplicationPax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Event from "./Event";
import UserHostList from "./UserHostList";
import ApplicationPax from "./ApplicationPax";
// import HostListMobile from "../mobile-component/HostListMobile";
import "./ItineraryDetails.css";
import AuthStorage from "../../helper/AuthStorage";
import Rating from "react-rating";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import Login from "../modal/Login";
import ReviewPopup from "../modal/ReviewPopup";

const ItineraryDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [data, setData] = useState([]);
  const [centerLat, setCenterLat] = useState();
  const [centerLng, setCenterLng] = useState();
  const [review, setReview] = useState([]);
  const [comment, setComment] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [refreshHost, setRefreshHost] = useState(0);
  const [hostingUser, setHostingUser] = useState([]);
  const [isCompas, setIsCompas] = useState("");
  const [refreshPax, setRefreshPax] = useState(0);
  const [refreshReview, setRefreshReview] = useState(0);
  const [refreshComment, setRefreshComment] = useState(0);
  const [host, setHost] = useState();
  const [isJoined, setIsJoined] = useState();

  const [isHostingCompleted, setIsHostingCompleted] = useState(false);

  const [showHostProfile, setShowHostProfile] = useState(false);

  const { userData } = useSelector((state) => state.userData);
  const { is_loggedin } = useSelector((state) => state?.login);
  const userInfo = useSelector((state) => {
    return state.userData.userData;
  });

  const history = useHistory();
  const params = QueryString.parse(history.location.search);

  const focus = useRef(null);
  const topFocus = useRef(null);

  const pathname = useLocation();
  const isMobile = window.innerWidth <= 767;

  const joinApp = useRef(null);

  const [alredyReviewed, setAlredyReviewed] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [stars, setStars] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [reviewNot, setReviewNot] = useState(false);

  const { t } = useTranslation();

  // const AnyReactComponent = ({ text }) => (
  //   <>
  //     <span class="badge badge-pill badge-primary">{text}</span>
  //   </>
  // );
  useEffect(() => {
    // window.scrollTo(0, 0);
    getHostingUser();

    if (history.action === "POP") {
      setIsCompas("");
      return;
    }
  }, [refreshPax]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        setIsCompas("");
      }
    });
  }, []);
  // console.log("data", data);
  // useEffect(() => {
  //   if (params.hostingId) {
  //     // console.log(params.hostingId)
  //     ApiGet(`hosting/status-all/${params.hostingId}`)
  //       .then((response) => {
  //         console.log("확인", response);
  //         if (response.data.creator === "Host") {
  //           if (response.data.status === "CANCELED") {
  //             history.push(
  //               `/itinerary?id=${params.id}&hostingId=${params.hostingId}&noti=mypage`
  //             );
  //           } else if (response.data.status === "COMPLETED") {
  //             history.push(
  //               `/itinerary?id=${params.id}&hostingId=${params.hostingId}&noti=mypage`
  //             );
  //           }
  //         } else {
  //           if (response.data.status === "CANCELED") {
  //             history.push(`/itinerary?id=${params.id}`);
  //           } else if (response.data.status === "COMPLETED") {
  //             history.push(
  //               `/itinerary?id=${params.id}&hostingId=${params.hostingId}&noti=mypage`
  //             );
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     // console.log("Else called")
  //     setIsCompas("");
  //   }
  // }, [pathname]);

  useEffect(() => {
    if (!window.location.hash) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        if (joinApp.current) {
          joinApp.current.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 1000);
    }
  }, [pathname]);
  const getItinerary = () => {
    ApiGet(`itinerary/itinerary-course/${params.id}`)
      .then((data) => {
        data.data.images = data.data?.images;
        setData(data.data);
        setCenterLat(Number(data.data.courses[0].latitude));
        setCenterLng(Number(data.data.courses[0].longitude));
      })
      .catch((error) => {
        // console.log(error);
        history.push("/error");
        // window.location.href = `/error`;
      });
    ApiGet(`itinerary/review-by-itinerary/${params.id}`)
      .then((data) => {
        setReview(data.data);
      })
      .catch(() => setReview([]));
  };

  useEffect(() => {
    ApiGet(`itinerary/review-by-itinerary/${params.id}`)
      .then((data) => {
        setReview(data.data);
      })
      .catch(() => setReview([]));
  }, [refreshReview]);

  useEffect(() => {
    if (data.id) {
      ApiGet(`comment/itinerary/${data.id}`)
        .then((response) => {
          setComment(response.data);
        })
        .catch((error) => console.error(error));
    }
  }, [data, refreshComment]);

  const getHostingUser = () => {
    ApiGet(`hosting/host-itinerary/${params.id}`).then((res) => {
      setHostingUser(res.data);
      setHost(res?.data.filter((x) => x?.user?.id === userData?.id)[0]);
    });
  };

  useEffect(() => {
    getHostingUser();
  }, [refreshHost, userData]);

  useEffect(() => {
    getItinerary();
    getHostingUser();
  }, []);

  useEffect(() => {
    if (showHostProfile) {
      window.scrollBy({
        behavior: "smooth",
        top: focus.current.getBoundingClientRect().top - 70,
      });
    }
  });

  const goSubPage1 = () => {
    setIsCompas("");
    history.push(`/itinerary?id=${data.id}`);
  };

  const is_active = AuthStorage.isUserActive();
  const isUserAlreadyReviewed =
    review.recentReview?.filter((review) => review?.user?.id === userInfo.id)
      .length !== 0;

  const sendReviews = async () => {
    await ApiPost("hosting/makeReview", {
      star: stars,
      content: feedback,
      itinerary_id: data.id,
    })
      .then((res) => {
        setRefreshReview(Math.random());
      })
      .catch((error) => {
        if (JSON.parse(JSON.stringify(error)).status === 400) {
          setAlredyReviewed(true);
        }
      });
    setReviewPopup(false);
    setStars(0);
    setFeedback("");
    setReviewNot(true);
  };

  // const isUserAlreadyReviewed =
  //   review?.recentReview?.filter((review) => review?.user?.id === userData.id)
  //     .length !== 0;

  const changeDateType = (date) => {
    const x = new Date(date);
    const y = x.getMonth();
    const w = x.getDate();
    const z = x.getFullYear().toString();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (AuthStorage.getLang() === "en") {
      return moment(x).locale(AuthStorage.getLang()).format("MMMM D, yyyy");
    } else {
      return moment(x).locale(AuthStorage.getLang()).format("yyyy년 M월 D일");
    }
  };

  const changeTimeFormat = (time) => {
    if (!time) {
      return "";
    }
    let Time = time.split(":");
    return Time[0] + ":" + Time[1];
  };

  const getCreatorString = (creator, creator_show) => {
    if (creator_show === "User" || creator_show === "Host") {
      return `<span>Created by User</span>`;
    } else {
      return `<span>${creator_show}</span>`;
    }
  };

  const [delayLike, setDelayLike] = useState(false);
  const Like = (id) => {
    setDelayLike(true);
    setData((prev) => {
      return {
        ...prev,
        like: !prev.like,
      };
    });

    ApiPost(`itinerary/wishlist/${id}`, {})
      .then((res) => {
        setDelayLike(false);
      })
      .catch((err) => {
        // console.log("Fail", err);
      });
  };

  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return (
    <main id="ItineraryDetail" className="sub-main">
      <div className="wrap">
        {isMobile === false && (
          <div style={{ marginBottom: "12px", marginTop: "12px" }}>
            <div className="title-single-event d-flex justify-content-between">
              <h3
                className="itinerary-title"
                onClick={goSubPage1}
                style={{ cursor: "pointer", fontWeight: 700 }}
              >
                {data.title}
              </h3>
            </div>
            <div
              className="title-single-event d-flex"
              style={{ marginTop: "5px", color: "#7e7e7e" }}
            >
              <div className="location-single-event">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                    fill="#7E7E7E"
                  />
                </svg>

                <span
                  dangerouslySetInnerHTML={{
                    __html: data.region + ", " + data.country,
                  }}
                ></span>
              </div>
              {data.creator !== "Host" && (
                <div
                  className="star-single-event align-items-center cursor-pointer d-flex"
                  style={{ marginLeft: "10px" }}
                >
                  <div
                    className="ratingStarArea"
                    onClick={() => {
                      if (is_active) {
                        {
                          isUserAlreadyReviewed && is_loggedin
                            ? setAlredyReviewed(true)
                            : setReviewPopup(true);
                        }
                      } else {
                        is_loggedin
                          ? setDeactivatedNotice(true)
                          : setModalShow(true);
                      }
                    }}
                  >
                    <Rating
                      className="ratingStar align-items-center"
                      emptySymbol={
                        <svg
                          className="emptySymbol align-items-center"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8284C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                            stroke="#EBEBEB"
                            strokeWidth="1.4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      fullSymbol={
                        <svg
                          className="fullSymbol align-items-center"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8285C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                            fill="#FF5F5F"
                          />
                        </svg>
                      }
                      initialRating={review.star}
                      readonly={true}
                      stop={5}
                    />
                  </div>

                  <p
                    className={"innere-itinarary-total"}
                    style={{ marginLeft: "10px", color: "#7e7e7e" }}
                  >
                    <span
                      className="cusor-p"
                      onClick={() => {
                        if (is_active) {
                          isUserAlreadyReviewed && is_loggedin
                            ? setAlredyReviewed(true)
                            : setReviewPopup(true);
                        } else {
                          is_loggedin
                            ? setDeactivatedNotice(true)
                            : setModalShow(true);
                        }
                      }}
                    >
                      {review.star !== "NaN" ? review.star : "0.0 (0)"}
                      {review.star !== "NaN"
                        ? review.recentReview &&
                          " (" + review.recentReview.length + ")"
                        : ""}
                    </span>
                    &nbsp;&nbsp;&nbsp;∙&nbsp;&nbsp;&nbsp;
                    <span className="cursor-p">
                      <a href="#comments" style={{ color: "#7e7e7e" }}>
                        {t("TourList.Comments")} ({comment.comments?.length})
                      </a>
                    </span>
                  </p>
                </div>
              )}
              {data.creator === "Host" ? (
                <>
                  {data?.start_date ? (
                    <>
                      <span
                        className="date-start-end"
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          color: "##7e7e7e",
                        }}
                      >
                        {changeDateType(data.start_date)}{" "}
                        {changeTimeFormat(data.start_time)} -{" "}
                        {changeTimeFormat(data.end_time)}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {data?.start_date ? (
                    <>
                      <span
                        className="date-start-end"
                        style={{
                          marginLeft: "10px",
                          fontSize: "16px",
                          color: "#7e7e7e",
                        }}
                      >
                        {changeDateType(data.start_date) +
                          " - " +
                          changeDateType(data.end_date)}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>

            <div className="title-single-event d-flex justify-content-between">
              <p
                className="createdby"
                style={{ marginTop: "5px", fontWeight: 700 }}
              >
                {ReactHtmlParser(
                  getCreatorString(data.creator, data.creator_show)
                )}
              </p>
              <div>
                <div className="heart-section">
                  <input
                    type="checkbox"
                    style={{ display: "none" }}
                    checked={data.like}
                    disabled={delayLike}
                    onChange={
                      is_loggedin === true
                        ? (e) => {
                            e.stopPropagation();
                            Like(data.id);
                          }
                        : () => setModalShow(true)
                    }
                    id={data.id}
                    className="instruments"
                  />
                  <label
                    htmlFor={data.id}
                    className={is_loggedin ? `text-white check` : `text-white`}
                  >
                    {!data.like ? (
                      <svg
                        width="34"
                        height="34"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                          stroke="#C9C9C9"
                          stroke-width="2"
                        />
                        <rect
                          x="22"
                          y="13"
                          width="17"
                          height="14"
                          fill="white"
                        />
                        <rect
                          x="29"
                          y="14"
                          width="2"
                          height="12"
                          fill="#C9C9C9"
                        />
                        <rect
                          x="36"
                          y="19"
                          width="2"
                          height="12"
                          transform="rotate(90 36 19)"
                          fill="#C9C9C9"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="likeOn"
                        width="34"
                        height="34"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.9394 7.02456L30.9418 7.02695C33.6861 9.71905 33.6861 14.1029 30.9418 16.795L30.9417 16.7951L27.6053 20.0694L26.8803 20.7808L26.904 20.8043L16.992 30.5945L3.0446 16.8185C0.318467 14.1259 0.318467 9.7383 3.0446 7.04568C5.7776 4.34627 10.2413 4.34627 12.9743 7.04568L16.2679 10.2988L16.9683 10.9906L17.671 10.301L21.0074 7.02681L21.0097 7.02456C23.7427 4.32515 28.2064 4.32515 30.9394 7.02456Z"
                          fill="#FF5F5F"
                          stroke="#FF5F5F"
                          stroke-width="2"
                        />
                      </svg>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="img-area d-flex">
          {isMobile === false && (
            <>
              <div className="big-img imgArea position-relative">
                <img
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../../../img/event-main-image.svg";
                  }}
                  src={
                    data?.images && data?.images[0]
                      ? data?.images[0]
                      : "../../../img/event-main-image.svg"
                  }
                  alt=""
                />
                {data?.images?.length > 0 && (
                  <button
                    className="itinerary-viewAll-btn"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    View All {data.images && data.images.length} Photos
                  </button>
                )}
              </div>
              <div className="small-img">
                <div className="imgArea">
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../../img/dow-1.svg";
                    }}
                    src={
                      data?.images && data?.images[1]
                        ? data?.images[1]
                        : "../../../img/dow-1.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="imgArea">
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../../img/dow-1.svg";
                    }}
                    src={
                      data?.images && data?.images[2]
                        ? data?.images[2]
                        : "../../../img/dow-1.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="imgArea">
                  <img
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../../img/dow-1.svg";
                    }}
                    src={
                      data?.images && data?.images[3]
                        ? data?.images[3]
                        : "../../../img/dow-1.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="imgArea">
                  <img
                    className="objectfit-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../../img/dow-1.svg";
                    }}
                    src={
                      data?.images && data?.images[4]
                        ? data?.images[4]
                        : "../../../img/dow-1.svg"
                    }
                    alt=""
                  />
                </div>
              </div>
            </>
          )}

          {isMobile === true && (
            <>
              <div
                className="big-img imgArea position-relative"
                style={{ width: "100%" }}
              >
                <img
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "../../../img/event-main-image.svg";
                  }}
                  src={
                    data?.images && data?.images[0]
                      ? data?.images[0]
                      : "../../../img/event-main-image.svg"
                  }
                  alt=""
                />
                {data?.images?.length > 0 && (
                  <button
                    className="itinerary-viewAll-btn"
                    style={{ left: "unset", right: "12px", bottom: "12px" }}
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    View All {data.images && data.images.length} Photos
                  </button>
                )}
              </div>
            </>
          )}

          {isOpen && (
            <Lightbox
              mainSrc={
                data.images[photoIndex] === 0 ||
                data.images[photoIndex] === "-" ||
                data.images[photoIndex] === "" ||
                data.images[photoIndex] === "undefined"
                  ? "../../../img/event-main-image.svg"
                  : data.images[photoIndex]
              }
              nextSrc={data.images[(photoIndex + 1) % data.images.length]}
              prevSrc={
                data.images[
                  (photoIndex + data.images.length - 1) % data.images.length
                ]
              }
              imageTitle={photoIndex + 1 + "/" + data.images.length}
              onCloseRequest={() => {
                setIsOpen(false);
              }}
              onMovePrevRequest={() => {
                setPhotoIndex(
                  (photoIndex + data.images.length - 1) % data.images.length
                );
              }}
              onMoveNextRequest={() => {
                setPhotoIndex((photoIndex + 1) % data.images.length);
              }}
            />
          )}
        </div>
        <div className="main-conttents layout">
          <div className="left">
            <Event
              reviews={review ? review : null}
              setRefreshReview={setRefreshReview}
              commentData={comment ? comment : null}
              focus={focus}
              showHostProfile={showHostProfile}
              setShowHostProfile={setShowHostProfile}
              hostingID={
                hostingUser.find((x) => x.user.id === userData?.id)?.id
              }
              isCompas={isCompas}
              setIsCompas={setIsCompas}
              host={host}
              setHost={setHost}
              setHostingUser={setHostingUser}
              centerLat={centerLat}
              centerLng={centerLng}
            />

            <Reviews
              commentData={comment ? comment : null}
              setRefreshComment={setRefreshComment}
              reviews={review ? review : null}
              setRefreshReview={setRefreshReview}
              itinerary_id={data.id}
              creator={data?.creator}
            />
            {/* {!showHostProfile && (
              <Reviews
                commentData={comment ? comment : null}
                setRefreshComment={setRefreshComment}
                reviews={review ? review : null}
                setRefreshReview={setRefreshReview}
                itinerary_id={data.id}
                creator={data?.creator}
              />
            )} */}
            {host &&
              !isMobile &&
              host?.status !== "COMPLETED" &&
              (data.creator === "Host" || host.id === isCompas) && (
                <div
                  className="w-100 res-applicationpax"
                  ref={joinApp}
                  id="joinApp"
                >
                  <ApplicationPax
                    refreshHost={refreshHost}
                    setRefresh={setRefresh}
                    hostingID={
                      hostingUser.find((x) => x.user.id === userData?.id)?.id
                    }
                    refreshPax={refreshPax}
                    isHostingCompleted={isHostingCompleted}
                    host={host}
                  />
                </div>
              )}
          </div>
          <div className="right">
            {
              !isMobile &&
                (data.creator === "Compastrips" && isCompas === "" ? (
                  <HostList
                    focus={focus}
                    showHostProfile={showHostProfile}
                    setShowHostProfile={setShowHostProfile}
                    data={data}
                    refreshHost={refreshHost}
                    setIsCompas={setIsCompas}
                    setRefreshPax={setRefreshPax}
                    refresh={refresh}
                    setIsJoined={setIsJoined}
                    setIsHostingCompleted={setIsHostingCompleted}
                    setRefreshHost={setRefreshHost}
                    ref={topFocus}
                  />
                ) : (
                  (data.creator === "Host" || isCompas) && (
                    <UserHostList
                      refresh={refresh}
                      isCompas={isCompas}
                      setIsHostingCompleted={setIsHostingCompleted}
                      created_by={data.creator}
                      ref={topFocus}
                    />
                  )
                ))
              // <div ref={topFocus} className="res-content">
              // </div>
            }
          </div>
        </div>
        {showHostProfile ? (
          <div class="create-hosting" ref={focus}>
            <HostProfile
              topFocus={topFocus}
              setRefreshHost={setRefreshHost}
              setShowHostProfile={setShowHostProfile}
              created_by={data.creator_show}
              maxDate={data.end_date}
              minDate={data.start_date}
            />
          </div>
        ) : null}
      </div>
      <Login
        show={modalShow}
        loginValue=""
        onHide={() => setModalShow(false)}
        onHideNew=""
        onShow=""
      />
      <Modal
        show={alredyReviewed}
        onHide={() => {
          setAlredyReviewed(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Review_Notice.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div
            className={
              AuthStorage.getLang() === "en"
                ? "modal-signup-title body-content"
                : "modal-signup-titleko body-content"
            }
          >
            <p className="font-24-normal color-darkgray h-60">
              {t("Review_Notice.ErrorBody")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100"
            variant="confirmBtn"
            onClick={() => {
              setAlredyReviewed(false);
            }}
          >
            {t("Review_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
      <ReviewPopup
        stars={stars}
        setStars={setStars}
        feedback={feedback}
        setFeedback={setFeedback}
        show={reviewPopup}
        onHide={() => {
          setReviewPopup(false);
        }}
        onSubmit={sendReviews}
      />
    </main>
  );
};

export default ItineraryDetails;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthStorage from "../../helper/AuthStorage";

const AccountDeactive = ({ hostDeactive, setHostDeactive }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={hostDeactive}
      onHide={setHostDeactive}
      dialogClassName={
        AuthStorage.getLang() === "en"
          ? "roboto-text deactive-modal custom-modal"
          : "noto-text deactive-modal custom-modal"
      }
      centered
    >
      <Modal.Header>
        <h3 className="custom-modal-title">{t("Account_Deactive.Title")}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="body-content">
          <p> {t("Account_Deactive.Body")}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="confirmBtn"
          className="w-100"
          onClick={setHostDeactive}
        >
          {t("Account_Deactive.OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountDeactive;

import React from "react";

const TermsUsecard_Ko = () => {
  return (
    <>
      <h1> 제 1 조(목적)</h1>
      <p>
        컴파스인터렉티브 주식회사(이하‘회사’)가 제공하는 서비스를 이용해 주셔서
        감사합니다. 회사는 회원이 회사가 제공하는 다양한 인터넷과 모바일서비스에
        더 가깝고 편리하게 다가갈 수 있도록 ‘서비스약관’(이하 ‘본 약관‘)을
        마련하였습니다. 회원은 본 약관에 동의함으로써 회사가 제공하는 서비스에
        가입하여 서비스를 이용할 수 있습니다. 본 약관은 회원이 서비스를 이용하는
        데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을
        규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어 주시기 바랍니다.
      </p>
      <h1> 제 2 조(용어의 정의)</h1>
      <p>
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        ①“서비스”라 함은 구현되는 단말기(PC, TV, 휴대단말기 등의 각종 유무선
        장치를 포함)와 상관없이 회원이 이용할 수 있는 서비스 및 회사가 추가
        개발하거나 다른 회사와 제휴계약 등을 통해 제공되는 일체의 서비스를
        의미합니다.
        <br />
        ②“회원”이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와
        이용계약을 체결하고 계정을 생성하여 회사가 제공하는 서비스를 이용하는
        모든 고객을 의미합니다. 본 약관에서 사용하는 회원의 유형은 다음과
        같습니다.
        <br />
        1. 호스트 : 회사가 제공하는 여행일정정보를 활용하거나 본인이 직접
        여행일정을 수립하여 여행정보 및 개인정보를 소개하는 글을 작성하고 게시할
        수 있으며, 참여자를 모집하고, 신청을 수락하거나 거절할 권리가 있는 회원
        <br />
        2. 참여자 : 회사의 서비스에 게시된 호스트의 여행일정 소개 글을 읽고,
        참여를 신청하여, 호스트의 수락을 통해 여행일정에 참여할 권리가 있는 회원
        <br />
        3. 이용자: 호스트나 참여자가 아니면서, 회사의 서비스에 게시된 여행일정
        소개글을 읽고, 본인의 의사에 따라 해당정보를 이용하는 회원.
        <br />
        ③“계정”이라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가
        승인하는 문자 또는 문자와 숫자의 조합을 의미합니다.
        <br />
        ④“비밀번호”라 함은 회원이 부여받은 계정과 일치되는 회원임을 확인하고
        비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
        <br />
        ⑤“게시물”이라 함은 회원이 서비스를 이용함에 있어 회사가 제공하거나 회원
        본인이 작성하여 서비스에 게시한 모든 부호·문자·음성·음향·화상·동영상
        등의 정보 형태의 글·사진·동영상·채팅메시지 및 각종 파일과 링크 등을
        의미합니다.
        <br />
        ⑥“채팅”이라 함은 회원들이 서비스 내의 채팅창을 통하여 주고받는 텍스트 및
        멀티미디어 등을 포괄하여 의미합니다.
        <br />
        ⑦“호스팅”이라 함은 호스트가 자신의 여행일정에 참여자를 모집하는 행위를
        의미합니다.
        <br />
        ⑧본 약관에서 정의되지 않은 용어는 관련법령이 정하는 바에 따릅니다.
      </p>
      <h1> 제 3 조(약관의 효력 및 변경)</h1>
      <p>
        ① 본 약관에 동의한 모두에게 그 효력이 발생합니다.
        <br />
        ② 본 약관의 내용은 www.compastrips.com(이하 ’웹사이트‘) 초기화면 등에
        게시하며, 회원을 이를 확인할 의무가 있습니다.
        <br />
        ③ 회사는 필요한 경우 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진
        및 정보보호 등에 관한 법률」 등 관련 법령을 위배하지 않는 범위 내에서 본
        약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을
        시행일자 7일 전부터 회원에게 서비스 공지사항에 게시 또는 이메일을
        발송하여 공지하는 것을 원칙으로 하며, 피치 못하게 회원에게 불리한 중대한
        내용으로 변경할 경우에는 그 시행일자 30일 전부터 서비스 계정에 등록된
        이메일 주소로 이메일(이메일 주소가 없는 경우 서비스 내 전자쪽지 발송,
        서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단) 발송 또는 회원이
        등록한 휴대폰번호로 문자메시지를 발송하는 방법 등으로 개별적으로
        알려드리겠습니다.
        <br />
        ④ 회원은 변경된 약관에 대하여 거부의사를 표시할 수 있으며, 그 경우
        서비스 이용을 중단하고 탈퇴할 수 있습니다. 회원이 전 항에 따른 회사의
        공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지
        아니하면 변경된 약관에 동의한 것으로 간주합니다.
        <br />⑤ 본 약관은 회원이 본 약관에 동의한 날로부터 이용계약의 해지시까지
        적용하는 것을 원칙으로 합니다. 단, 본 약관의 일부 조항은 이용계약의 해지
        후에도 유효하게 적용될 수 있습니다.
      </p>
      <h1> 제 4 조(약관 외 준칙)</h1>
      <p>
        ① 회사는 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하
        ’세부지침‘)을 둘 수 있습니다.
        <br />
        ② 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 세부지침의
        규정에 따릅니다.
        <br />③ 본 약관과 세부지침의 내용이 충돌할 경우 세부지침에 따릅니다.
      </p>
      <h1> 제 5 조(서비스 이용계약 등의 성립)</h1>
      <p>
        서비스 이용계약은 회원이 본 약관의 내용에 동의하고 이용신청을 하며, 이를
        회사가 확인하고 승인함으로써 체결됩니다.
      </p>
      <h1> 제 6 조(서비스 가입 또는 이용계약체결의 제한)</h1>
      <p>
        ① 제5조에 따른 가입 신청자에게 회사는 원칙적으로 서비스 가입을
        승낙합니다. 다만, 회사는 아래 각 호의 경우에는 그 사유가 해소될 때까지
        승낙을 유보하거나 승낙하지 않을 수 있습니다.
        <br />
        1. 회원이 다른 사람의 명의나 이메일 주소, 전화번호 등 개인정보를
        이용하여 서비스에 가입하려고 한 경우
        <br />
        2. 서비스 제공 설비 용량에 현실적인 여유가 없는 경우
        <br />
        3. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우
        <br />
        4. 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우
        <br />
        5. 서비스 상의 장애가 발생한 경우
        <br />
        6. 회사로부터 서비스 이용정지 조치 등을 받은 자가 재가입을 신청하거나
        다른 정보를 이용하여 가입 신청하는 경우
        <br />
        7. 등록 내용에 허위, 기재누락, 오기가 있는 경우
        <br />
        8. 회사의 정책에 적합하지 않은 회원으로 판단되는 경우나 서비스 제공이
        곤란하다고 볼 수 있는 경우
        <br />
        9. 부정한 용도로 서비스를 이용하고자 하는 경우
        <br />
        10. 회원의 이용목적이나 행위가 회사의 재산권이나 영업권을 침해하거나
        침해할 우려가 있는 경우
        <br />
        11. 비정상적인 방법을 통하여 계정을 대량으로 생성하는 행위
        <br />
        12. 본국법상 미성년자가 서비스에 가입한 경우
        <br />
        13. 기타 관련 법령에 위배하거나 세부지침 등 회사가 정한 기준에 반하는
        경우
        <br />
        ② 본국법상 미성년자는 서비스에 가입하거나 서비스를 이용할 수 없습니다.
        <br />
        ③만약 회원이 본 조에 위반하여 서비스에 가입한 것으로 판명된 때에는
        회사는 즉시 해당 회원의 서비스 이용을 정지시키거나 계정을 삭제하는 등
        제한조치를 할 수 있습니다.
      </p>
      <h1> 제 7 조(서비스 내용 및 변경)</h1>
      <p>
        ① 회사는 회원들에게 아래와 같은 온라인 서비스를 제공합니다. 회사는
        회원의 유형을 구분하고 서비스 메뉴 등 서비스의 내용을 회원 유형별로
        다르게 제공할 수 있습니다.
        <br />
        1. 회원이 단말기를 이용, 다른 회원들과 채팅을 할 수 있는 서비스
        <br />
        2. 회원이 자신의 정보를 단말기를 통해 올려 다른 회원들과 공유하고, 이를
        통하여 상호소통 할 수 있는 서비스
        <br />
        3. 회원이 회사가 제공한 여행일정정보를 활용하거나 본인이 직접 수립한
        여행일정을 소개하는 글을 작성하고 업로드하여 홍보할 수 있는 서비스
        <br />
        4. 회원이 게시된 여행일정에 관한 글을 읽고, 댓글이나 리뷰를 남길 수 있는
        서비스
        <br />
        5. 회원이 여행일정에 동행할 참여자를 모집하거나 동행을 신청할 수 있는
        서비스
        <br />
        6. __ (PG사)이 제공하는 결제 시스템을 통해 회원이 유료 결제할 수 있는
        서비스
        <br />
        7. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통하여
        회원에게 제공하는 일체의 서비스(기존 서비스를 활용하기 위한 API 서비스
        등 포함)
        <br />
        ② 회원은 회사에 서비스의 개선, 개발 등과 관련된 아이디어를 제공할 수
        있으며, 회사는 필요한 경우 회원이 제시한 아이디어 등을 적극 활용하여
        개발, 개선한 추가 서비스 등의 결과물을 회원에게 서비스할 수 있습니다. 이
        경우 회원이 제시한 아이디어 등을 통하여 개발된 결과물에 대한
        지적재산권은 별도 공지나 요청이 없는 한 회사에 귀속됩니다.
        <br />
        ③ 회사는 회원과 별도로 서면 계약을 체결하여 서비스의 브랜드 특성을
        이용할 수 있는 명시적인 권리를 부여하지 아니하는 한, 회원에게 회사 또는
        서비스의 상호, 서비스표, 로고, 도메인 네임 및 기타 식별력 있는 브랜드
        특성을 이용할 수 있는 권리를 제공하지 않습니다.
        <br />
        ④ 회사는 서비스 내의 공지를 통하여 서비스 시스템 및 회선 점검에 관한
        사항, 서비스 변경에 관한 사항, 서비스 운영에 관한 사항, 서비스 내용
        업데이트/변경에 관한 사항 등을 회원에게 고지하오니 회원은 수시로
        공지사항을 확인하여야 합니다. 공지사항의 미확인으로 인해 발생하는 회원의
        피해는 회사에서 책임을 지지 않습니다. 다만, 유료서비스의 이용에 중대한
        차질이 발생하는 경우 또는 그 외 중대한 금전적인 피해가 예상되는 경우 등
        회원에게 중대하고 명백한 피해가 예상되는 경우에는 회사는 별도의 고지를
        합니다.
        <br />
        ⑤회사는 본 서비스의 일부나 전부를 서비스 운영, 기획상 필요한 경우
        언제든지 변경하거나 폐지할 수 있습니다. 단, 회원에게 불리한 것으로
        판단하는 경우 회사는 회원이 가입 당시 등록한 이메일이나 공지사항을
        통하여 또는 서비스 내에서 회원에게 공지합니다. 이 경우에 회사는 서비스의
        일부나 전부를 폐쇄할 경우, 회원이 쌓은 정보 및 자료를 내려 받을 수
        있도록 지원합니다.
        <br />
        ⑥회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장 통신두절 또는
        운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수
        있습니다. 이 경우 회사는 서비스 내에서 혹은 이메일 등을 통하여 회원에게
        통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
        사후에 통지할 수 있습니다.
        <br />
        ⑦회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
        정기점검시간은 회사가 정한 방법에 따라 공지한 바에 따릅니다.
      </p>
      <h1> 제 8 조(회원정보의 변경)</h1>
      <p>
        ① 회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고
        수정할 수 있습니다. 다만, 서비스 관리에 필요한 계정 등의 정보는 수정이
        불가능합니다.
        <br />
        ② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을
        하거나 이메일 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.
        <br />
        ③ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
        책임지지 않습니다.
        <br />④ 회사는 서비스 제공을 위해 필요한 경우 회원에게 요청하는 회원정보
        및 기타 정보 항목을 추가하거나 변경할 수 있습니다.
      </p>
      <h1> 제 9 조(회원의 계정 및 비밀번호의 관리에 대한 의무)</h1>
      <p>
        ① 회원의 계정 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가
        이용하도록 하여서는 안 됩니다.
        <br />
        ② 회사는 회원의 계정이 개인정보 유출 우려가 있거나, 반사회적 또는
        미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인할 우려가 있는 경우,
        해당 계정의 이용을 제한할 수 있습니다.
        <br />
        ③ 회원은 계정 및 비밀번호가 도용되거나 제3자가 사용하고 있음을
        인지하거나 의심스러울 경우에는 이를 즉시 회사에 통지하고 회사의 안내에
        따라야 합니다.
        <br />④ 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나,
        통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는
        책임지지 않습니다. 회원이 계정과 비밀번호를 대여하거나 소홀히 관리하여
        제3자가 이를 이용함으로 인하여 회사 및 다른 회원에게 손해가 발생한 경우,
        회사는 대여 또는 관리 소홀의 과실이 있는 회원에게 책임을 추궁할 수
        있으며, 회사가 분쟁의 당사자가 될 경우, 회원은 자기의 책임과 비용으로
        회사를 면책 시켜야 합니다.
      </p>
      <h1> 제 10 조(회원에 대한 통지)</h1>
      <p>
        ① 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한
        회원이 지정한 이메일주소, 휴대전화번호 등으로 할 수 있습니다.
        <br />② 회사는 회원 전체에 대해 통지를 하는 경우 7일 이상 웹사이트
        게시판에 게시하거나 팝업 등의 방법으로 공지함으로써 제1항의 통지를
        갈음할 수 있습니다.
      </p>
      <h1> 제 11 조(회원의 의무)</h1>
      <p>
        ① 회원은 서비스를 자유롭게 이용할 수 있으나, 아래 각 호의 행위는
        하여서는 안됩니다.
        <br />
        1. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 사람의 계정 및
        비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 사진을 사용하거나,
        다른 사람의 명의를 사용하거나 명의자의 허락 없이 이메일 인증 또는
        문자메시지(SMS) 인증 등을 수행하는 행위
        <br />
        2. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제
        또는 유통시키거나 상업적으로 이용하는 행위
        <br />
        3. 타인의 명예를 손상시키거나 불이익을 주는 행위
        <br />
        4. 게시글 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위
        <br />
        5. 회사 또는 제3자의 특허권, 상표권, 저작권, 초상/권, 영업비밀 등
        지적재산권을 포함한 기타 권리를 침해하거나 침해하는 방법으로 사용하는
        행위
        <br />
        6. 서비스 내에 회사나 제3자 등에 대한 허위의 사실을 게시하는 등의 행위
        <br />
        7. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을
        게시하거나 유포하는 행위
        <br />
        8. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는
        컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
        <br />
        9. 서비스의 운영을 방해하거나 안정적 운영을 방해할 수 있는 정보 및
        수신자의 명시적인 수신거부의사에 반하여 또는 수신자의 명시적인 동의 없이
        광고성 정보를 게시하거나 전송하는 행위
        <br />
        10. 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사,
        수정, 배포, 판매, 양도, 대여, 담보 제공하거나 타인에게 그 이용을
        허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등
        서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위
        <br />
        11. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
        <br />
        12. 다른 회원의 이메일 등 기타 개인정보를 수집, 저장, 공개하는 행위
        <br />
        13. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가하는 등
        피해를 입힐 목적으로 허위의 정보를 유통시키는 행위
        <br />
        14. 재물을 걸고 도박하거나 사행행위를 하는 행위
        <br />
        15. 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 게시하거나
        유통하는 행위
        <br />
        16. 다른 회원을 희롱하거나, 위협하거나, 지속적으로 고통을 주거나 불편을
        주는 경우
        <br />
        17. 회원가입에 필요한 개인정보에 타인의 정보 도용 등 허위 내용을 등록한
        경우
        <br />
        18. 회사의 허락을 받지 않고 광고 및 홍보의 목적으로 계정을 운영하여,
        회원들에게 스팸 메시지를 보내는 등 이용에 불편을 주는 경우
        <br />
        19. 회사로부터 특별한 권한을 부여 받지 않고 회사의 클라이언트 프로그램을
        변경하거나 회사의 서버를 해킹하거나 웹사이트의 일부분을 임의로 변경하는
        행위
        <br />
        20. 서비스 방문자나 타 회원의 의사와 무관하게 특정 사이트로 방문을
        유도하는 행위
        <br />
        21. 회사가 서비스의 일부로써 회원에게 제공하는 소프트웨어에 대한 수정,
        2차적 저작물 작성, 역설계, 디컴파일 또는 다른 방법으로 소스코드를
        추출하고자 시도하는 행위
        <br />
        22. 불쾌감, 수치심, 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나
        화상 또는 영상을 계속하여 게시하거나 다른 회원에게 도달하게 하는 행위
        <br />
        23. 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램
        포함)의 전송 또는 게시 행위
        <br />
        24. 회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의
        명의를 도용하여 글을 게시하거나 E-mail, 문자 메시지 등을 발송하는 행위
        <br />
        25. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해,
        파괴할 가능성이 있는 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일,
        프로그램을 포함하고 있는 자료를 게시하거나 E-mail, 문자 메시지 등으로
        발송하는 행위
        <br />
        26. 스토킹(stalking), 허위 또는 악의적 신고 남용 등 다른 이용자를
        괴롭히는 행위
        <br />
        27. 범죄와 결부된다고 객관적으로 판단되는 행위
        <br />
        28. 기타 다른 회원의 권리를 침해하거나 커뮤니티 내지 서비스의 질서를
        해하는 것으로 회사가 의심하거나 판단할 수 있는 행위
        <br />
        28. 기타 현행 법령, 본 약관, 회사가 제공하는 서비스 관련 세부지침을
        위반하는 행위
        <br />
        29. 다른 회원으로 하여금 상기 1내지 28호의 금지행위를 하도록 유발하거나
        조장하는 행위
        <br />
        30.회원의 개인 계좌 안내, 타 채널 방문 유도, 일부 대금 별도 송금, 현금
        거래, 개인 연락처 교환을 제안하는 등 회사의 플랫폼을 통하지 않고 금전
        기타 재산상 이익을 수수하거나, 이를 유도하는 행위
        <br />
        31. 기타 상기 1내지 30호에 준하는 목적으로 서비스를 이용하는 행위
        <br />
        ② 회원은 서비스의 이용 권한, 기타 이용계약상 지위를 타인에게 양도하거나
        증여할 수 없으며, 담보로 제공할 수 없습니다.
        <br />
        ③ 혹시라도 회원이 관련 법령, 회사의 모든 약관 또는 세부지침을 준수하지
        않는다면, 회사는 회원의 위반행위 등을 조사할 수 있고, 해당 게시물 등을
        삭제 또는 임시 삭제하거나 회원의 계정·서비스 전체 또는 서비스를 구성하는
        일부 개별 서비스의 이용을 잠시 또는 계속하여 중단 또는 해지하거나,
        서비스 재가입 또는 일부 개별 서비스의 재이용에 제한을 둘 수도 있습니다.
        <br />
        ④ 회원이 서비스와 관련된 설비의 오작동이나 시스템의 파괴 및 혼란을
        유발하는 등 서비스 제공에 악영향을 미치거나 안정적 운영을 심각하게
        방해한 경우, 회사는 이러한 위험 활동이 확인된 회원의 계정들에 대하여
        이용제한을 할 수 있습니다.
        <br />
        ⑤ 회원이 이용제한과 관련하여 조치 결과에 이의가 있을 경우, 웹사이트에
        게시된 회사의 이메일로 이의신청을 할 수 있으며, 그 경우 이용제한조치의
        적절성에 대해 검토한 후 처리과정 및 결과를 알려드리겠습니다.
        <br />⑥ 회사는 회원의 위반행위의 정도, 빈도, 그로 인한 피해 등을
        고려하여 한시적 또는 영구적으로 서비스 일부 또는 전체의 이용을 제한할 수
        있습니다. 음란한 내용의 게시와 유포 및 사행성 도박 홍보 기타 이에 준하는
        행위, 법령에서 금지하는 명백한 불법행위나 타인의 권리침해로서 긴급한
        위험 또는 피해 차단이 요구되는 사안에 대해서는 즉시 영구적으로 이용이
        제한될 수 있습니다.
      </p>
      <h1> 제 12 조 (서비스 이용의 제한)</h1>
      <p>
        ① 회사는 천재지변이나 국가 비상사태 또는 서비스 운영의 심각한 변화 등
        불가항력적인 경우가 발생 또는 발생이 예상될 때는 서비스의 전부 또는
        일부를 예고 없이 제한하거나 중지할 수 있습니다.
        <br />
        ② 회원이 회원의 의무 조항을 어길 경우 회사는 해당 회원에 대한 사전 통지
        없이 해당 회원의 서비스 이용에 제한을 가할 수 있습니다.
        <br />
        ③ 회사의 서비스와 관리영역은 온라인에 국한되면 그 책임 역시
        마찬가지입니다. 서비스를 이용함으로써 회원 사이에 발생할 수 있는 문제
        또는 회원 및 제3자, 또는 제3자들간의 일체의 문제 내지 분쟁에 대해서
        회사는 책임을 지지 않습니다.
        <br />
        ④ 회원의 대여, 또는 관리 소홀로 인한 이메일, 계정정보, 비밀번호 등
        정보의 유출로 인해 본인 또는 다른 회원에게 서비스 이용상의 손해가
        발생하거나, 제3자에 의한 부정이용 등 회원의 의무조항 위반이 발견된 경우
        해당 계정의 이용이 제한될 수 있습니다.
        <br />
        ⑤ 회사가 본 약관 제11조의 위반 행위를 조사하는 과정에서 당해 회원 계정이
        특정 위반행위에 직접적으로 관련되어 있을 것으로 의심하거나 판단할 수
        있는 경우 또는 다른 회원의 권익 보호 또는 커뮤니티의 질서유지를 위해
        불가피할 경우에는 해당 계정 및 해당 회원의 서비스 이용을 일시적 또는
        영구적으로 정지할 수 있습니다. 이에 대해 회원은 이메일 등을 통하여
        이의신청을 할 수 있습니다.
        <br />⑥ 서비스 이용과 관련한 상담 및 문의는 이메일 등 회사에서 정하는
        방식으로 접수하여 처리합니다.
      </p>
      <h1> 제 13 조(서비스의 제공)</h1>
      <p>
        ① 서비스 이용계약이 성립되면, 회원은 원하는 때에 서비스를 자유롭게
        이용할 수 있습니다.
        <br />
        ② 회사는 회원에게 인터넷과 모바일로 즐길 수 있는 서비스를 제공합니다.
        <br />
        ③ 회사는 더 나은 서비스를 제공하기 위하여 회원에게 서비스의 이용과
        관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를 서비스
        내에 표시할 수 있으며, 이를 회원의 계정 정보에 등록되어 있는 연락처로
        직접 발송할 수도 있습니다. 단, 광고성 정보 전송의 경우에는 사전에 수신에
        동의한 경우에만 전송합니다.
        <br />
        ④ 서비스 이용 중 시스템 오류 등 문제점을 발견하신다면 언제든지
        웹사이트에 게시된 회사의 이메일로 알려주시기 바랍니다.
        <br />
        ⑤ 서비스를 이용하면서 Wi-Fi 무선인터넷을 사용하지 않고, 가입하신
        이동통신사의 무선인터넷에 연결하여 이용하는 경우 이동통신사로부터 별도의
        데이터 통신요금이 부과될 수 있는 점을 유의하여 주시기 바랍니다. 서비스
        이용 과정에서 발생하는 데이터 통신요금은 회원이 회원의 비용과 책임 하에
        이동통신사에 납부하셔야 합니다. 데이터 통신요금에 대한 자세한 안내는
        회원이 가입하신 이동통신사에 직접 문의하시기 바랍니다.
        <br />⑥ 회사는 원활한 서비스 운영을 위하여 회원정보를 활용하여 서비스
        내에 맞춤광고를 게재하거나 광고 정보 메시지 등을 전송할 수 있습니다.
        또한 회사는 이메일수신 동의를 한 회원에게 서비스와 관련한 각종 정보 메일
        등을 발송할 수 있으며, 회원이 이를 원하지 않는 경우에는 언제든지
        환경설정 메뉴 혹은 수신 이메일 등에서 수신거부를 할 수 있습니다. 회사는
        본 약관과 법률에 특별한 규정이 없는 경우를 제외하고 회원의 별도의 동의
        없이 회원정보를 제3자에게 제공하지 아니합니다.
      </p>
      <h1> 제 14 조 (서비스의 제한)</h1>
      <p>
        ① 회사는 특정 서비스의 범위를 제한하거나 이용 가능 시간 또는 이용 가능
        횟수를 지정할 수 있지만, 이를 사전에 고지하여야 합니다.
        <br />
        ② 회사는 회원이 서비스를 이용함에 있어 법령을 위반하거나 본 약관 또는
        회사의 세부지침, 서비스 이용 기준 등을 위반한 경우 즉시 본 서비스의
        이용을 정지할 수 있습니다.
        <br />③ 회사는 회원이 서비스나 서비스 제공 시스템에 대해 불법적인 해킹
        시도를 하는 경우, 비정상적인 방식을 통한 어뷰징 또는 네트워크 사용 초과
        등의 시도를 하는 경우 즉각 본 서비스의 사용을 정지시킬 수 있습니다.
      </p>
      <h1> 제 15 조 (서비스 이용의 중지 또는 계약의 해지)</h1>
      <p>
        ① 회원은 언제든 가입에 의한 이용계약의 해지를 요청할 수 있습니다. ②
        회원이 본 약관 제11조 제1항 각 호에 해당하는 행위를 하는 경우, 회사는
        회원에 대한 사전 통지 없이 회원의 회원자격을 제한, 정지 및 해지할 수
        있습니다. 이 경우 회원은 회사에 대해 서비스 가입, 내용, 이용 등과 관련한
        어떠한 형태의 권리도 주장할 수 없습니다.
        <br />
        ③ 회사는 본 조 제2항에 의한 회원자격의 변경사항에 대해, 서비스 내 또는
        해당 회원이 회사의 본 서비스를 사용하면서 추가 가입 또는 사용하는
        외부서비스에 알릴 수 있습니다.
        <br />④ 회원은 본 약관 제11조의 위반을 포함하여, 본 약관상 다른 조항의
        위반, 세부지침의 위반, 기타 불법행위로 인하여 회사나 다른 회원에게
        손해를 입힌 경우, 이를 배상할 책임이 있으며, 그 경우 회사는 면책됩니다.
        단, 회원의 의무 위반 또는 불법행위로 인하여 회사가 분쟁의 당사자가 될
        경우, 해당 회원은 자기의 비용과 책임으로 회사를 면책 시켜야 합니다.
      </p>
      <h1> 제 16 조(서비스의 변경 및 종료)</h1>
      <p>
        ① 회사는 서비스를 지속적으로 제공하기 위하여 최선의 노력을 합니다. 다만,
        아래 각 호의 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수
        있습니다.
        <br />
        1. 서비스용 설비의 유지·보수 등과 같은 정기 또는 임시 점검의 경우
        <br />
        2. 정전, 제반 설비의 장애 발생 또는 이용량의 급증 등 정상적인 서비스
        제공에 지장이 발생한 경우
        <br />
        3. 관계사와의 계약 종료, 정부의 명령 또는 규제, 서비스 또는 회원 정책
        변경 등 회사의 제반 사정으로 서비스의 전부 또는 일부를 유지할 수 없다고
        판단되는 경우 경우
        <br />
        4. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
        <br />② 전항에 의한 서비스 중단이 있을 경우, 회사는 미리 제10조에서 정한
        방법으로 회원에게 통지하거나 공지할 것입니다. 그 경우 서비스나 정책
        변경으로 인해 서비스 이용 시 재로그인 또는 추가적인 동의 절차가 요구될
        수 있습니다. 다만, 회사가 예측할 수 없거나 통제할 수 없는 사유(회사의
        과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로 인해 사전 통지 내지
        공지가 불가능한 경우에는 그러하지 아니합니다. 이러한 경우에도 회사는
        상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록
        노력하겠습니다.
      </p>
      <h1> 제 17 조(게시물 및 채팅메시지 관리 등)</h1>
      <p>
        ① 회사는 회원이 작성한 게시물을 이용하거나 게시할 수 있으며, 회원의 작성
        게시물과 관련하여서는 서비스를 제공하기 위하여 필요한 범위 내에서 완전한
        운영권을 가집니다.
        <br />
        ② 회원의 게시물, 활동으로 인해 발생하는 손해나 책임은 전적으로 회원에게
        있고, 회사는 이에 대하여 책임지지 않습니다.
        <br />
        ③ 회사는 다음 각 호에 해당하는 게시물이나 채팅메시지 등이 발견되는 경우,
        사전통지 없이 이를 삭제하거나, 비공개로 전환할 수 있으며, 다른
        게시공간으로의 이동하거나 또는 등록거부를 할 수 있고, 나아가 해당 회원의
        자격을 제한, 정지 또는 상실 시킬 수 있습니다.
        <br />
        1. 다른 회원 또는 제3자를 비방하거나 명예를 훼손하는 경우, 혹은
        개인정보의 부당한 이용이 의심되는 경우
        <br />
        2. 선정성 또는 음란성이 있거나, 비어 또는 속어가 포함되거나 반사회적,
        저속한 내용의 정보, 기타 사회의 공공질서나 미풍양속을 해하는 정보,
        사회적 편견을 야기하는 정보가 포함된 경우, 또는 이러한 정보들을 포함하는
        문자, 도형, 음향, 동영상, 그림 등을 전송, 게시, 전자우편, 링크 또는
        기타의 방법으로 제3자에게 유포하는 경우
        <br />
        3. 회사의 운영진, 직원, 혹은 관계자를 사칭하거나 회원 또는 제3자로
        하여금 회사의 공식입장이라고 오인하게 할 여지가 있는 내용인 경우
        <br />
        4. 불법 행위 또는 범죄 행위와 관련 있을 것으로 인정되거나 의심되는
        내용인 경우
        <br />
        5. 회사, 다른 회원 또는 제3자의 저작권, 특허권, 상표권, 영업비밀, 영업권
        등 기타 지적재산권을 침해하는 내용이거나 명예, 초상권 등 인격권을
        침해하는 내용인 경우
        <br />
        6. 영리를 목적으로 하는 광고 또는 그에 준하는 내용으로서 회사가 하는
        이용목적과 방법에 반하는 경우
        <br />
        7. 서비스 방문자나 타 회원의 의사와 무관하게 특정 사이트로 방문을
        유도하는 내용인 경우
        <br />
        8. 정상적인 서비스 운영을 방해하거나 회사의 운영정책에 맞지 않는 경우
        <br />
        9. 기타 관련법령 또는 본 약관에 위반된다고 판단되는 경우
        <br />
        ④ 다른 회원 또는 제3자가 회원 또는 제3자의 게시물로 인하여 자신의 권리가
        침해됨을 주장하며 그 사실을 소명하여 회사를 대상으로 이의신청 및 구제
        요청을 하는 경우, 회사는 법적 조치 (예: 형사 고소, 가처분 신청,
        손해배상청구소송 등)를 취하거나, 회원이 제기한 분쟁이 법적 결론에 이를
        때까지 관련 게시물에 대한 접근을 잠정적 또는 영구적으로 제한할 수
        있습니다.
        <br />
        ⑤ 회원이 제3항 각 호에 해당하는 게시물이나 채팅을 하여 손해가 발생하거나
        관계기관에 의하여 법적 조치나 제재를 받게 되는 경우, 그 책임은 해당 회원
        본인이 직접 부담해야 하고, 해당 회원이 행위로 인하여 회사가 법적 분쟁의
        당사자가 될 경우, 해당 회원은 자기의 부담과 책임으로 회사를 면책시켜야
        합니다. 회사는 그와 별도로 해당 회원에 대한 손해배상 등 책임을 추궁할 수
        있습니다.
        <br />
        ⑥ 회원의 게시물 등으로 인하여 제3자와 발생한 법적 책임은 전적으로 회원이
        부담하여야 합니다.
        <br />
        ⑦ 제3자의 게시물로 인하여 발생하는 분쟁에 대하여 회사는 책임지지
        않습니다.
        <br />⑧ 회원의 게시물이 법률, 본 약관, 세부지침을 위반하는 경우, 타인의
        권리 등을 침해하거나 침해할 우려가 있는 경우, 서비스의 취지에 위배되는
        내용을 포함하는 경우, 회사는 회원에게 해당 게시물의 게시중단 및 삭제
        등을 요청하거나, 사전통지 없이 이를 삭제하는 등 시정 조치할 수 있습니다.
      </p>
      <h1> 제 18 조(게시물의 이용 및 권리의 귀속 등)</h1>
      <p>
        ① 회원은 여행일정에 관한 게시글을 작성할 수 있습니다. 회사가 작성한
        게시물 또는 저작물에 대한 저작권, 소유권 기타 일체의 권리는 회사에
        귀속되고, 회원이 서비스를 이용하며 단독 또는 다른 회원과 공동으로
        게시물을 작성한 경우 동 게시물에 관한 저작권 등은 작성한 회원과 공동
        저작자들에게 귀속됩니다.
        <br />② 회원이 저작물에 대하여 삭제, 변경, 공개 설정 변경 등을 한 경우,
        저작물에 대한 이러한 설정이 변경되기 전에 이미 제3자에게 배포 또는
        이용되어 발생한 결과에 대하여 회사는 책임지지 않습니다.
        <br />
        ③ 공동 저작자들 간에 발생한 분쟁에 대해 회사는 책임지지 않습니다.
        <br />
        ④ 회원의 게시물 또는 저작물이 회사 또는 제3자의 저작권 등 지적재산권 및
        영업권을 침해함으로써 발생하는 법적 책임은 전적으로 회원이 부담하여야
        합니다. 타인의 권리를 침해함으로 인하여 회사가 소송 기타의 방법으로
        분쟁의 당사자가 되는 경우, 회원은 자신의 비용과 책임으로 회사를
        면책시켜야 합니다.
        <br />
        ⑤ 회원이 서비스를 이용하며 단독 또는 다른 회원과 공동으로 게시물을
        작성한 게시물이 법률, 본 약관, 회사의 세부지침에 반하여 삭제조치 되는
        등의 경우, 회원은 회사에 저작권, 소유권 기타 게시물에 관한 권리를 주장할
        수 없습니다.
        <br />
        ⑥ 회원은 본 약관에 동의함으로써 서비스 내에 게시한 게시물에 대한 사용,
        저장, 수정, 복제, 공중 송신, 전시, 배포 등의 방식으로 이용할 수 있도록
        사용을 허락하는 영구적이고 전 세계적인 라이선스를 회사에 제공하게
        됩니다. 본 라이선스에서 회원이 회사에게 부여하는 권리는 서비스를 운영,
        개선, 홍보하고 새로운 서비스를 개발하기 위한 범위 내에서 사용되며,
        이러한 목적 범위 내에서 회사와 명시적인 업무계약을 체결한 상대방 또는
        다른 이용자에 대한 서브라이선스 또한 당연히 여기에 포함됩니다. 또한,
        서비스의 개선 및 연구개발 목적으로 회사 및 회사의 계열사에서 게시물을
        사용할 수 있습니다.
        <br />
        ⑦ 전항의 규정에도 불구하고, 회사가 회원의 게시물을 전항 각호에 기재된
        목적 이외에 상업적 목적 (예: 제3자에게 게시물 자체를 제공하고 금전적
        대가를 지급받는 경우 등)으로 사용할 경우에는 사전에 해당 회원으로부터
        동의를 얻어야 합니다. 게시물에 대한 회사의 사용 요청, 회원의 동의 및
        동의철회는 이메일 등 회사가 정한 방식에 따릅니다. 단, 회원정보에 등록된
        연락처가 사실과 다르거나 회원이 회사의 연락에 응하지 않아 사전에 동의를
        구하지 못한 경우, 회사는 사후에 동의 절차를 구할 수 있습니다. 이 경우
        회사는 게시물의 출처를 표시하며 게시물의 사용에 동의한 해당 회원에게
        별도의 보상을 할 수 있습니다.
        <br />
        ⑧ 회사는 게시물이 음란 또는 청소년에게 유해한 게시물, 차별 갈등을
        조장하는 게시물, 도배·광고·홍보·스팸성 게시물, 계정을 양도 또는 거래하는
        내용의 게시물, 타인을 사칭하는 게시물 등이라고 판단되는 경우, 또는 법률,
        본 약관, 회사의 세부지침에 위반하거나 서비스의 취지에 위배될 경우,
        사전통지 없이 회원의 게시글을 삭제하거나 게시를 거부할 수 있습니다.
        회사가 모든 게시물을 검토할 의무가 있는 것은 아니며, 회사는 회원의
        신고를 통해 게시물 가림, 삭제 등 시정조치를 취할 수 있습니다.
        <br />
        ⑨ 회원이 비공개로 설정한 저작물에 대해서는 원칙적으로 회사를 포함하여
        다른 사람이 열람할 수 없습니다. 단, 회원 본인의 요청에 의한 경우, 법원,
        수사 기관이나 기타 행정기관으로부터 정보제공을 요청받은 경우나 기타
        법률에 의해 요구되는 경우에는 회사를 포함한 다른 사람이 해당 저작물을
        열람할 수 있습니다.
        <br />
        ⑩ 회원이 탈퇴를 하거나 약관을 포함한 회사 규정에 의하여 회원 자격을
        상실한 경우에는 본인 계정에 기록되어 있는 다른 회원, 제3자가 기록한
        저작물을 포함한 저작물/게시물 일체는 삭제됩니다. 다만, 제3자에 의하여
        스크랩, 회원 링크, 공개 등으로 다시 게시된 게시물, 제3자에 의하여 덧글이
        첨부된 게시물, 공동으로 작성된 저작물, 원본이 알아보기 힘든 정도로
        훼손된 경우 등 다른 회원의 정상적인 서비스 이용에 필요한 게시물은
        삭제되지 않을 수 있습니다.
        <br />
        ⑪ 회사가 권리를 보유하지 않은 일부 콘텐츠가 서비스에 표시될 수 있습니다.
        그러한 콘텐츠에 대해서는 콘텐츠를 제공한 주체가 단독으로 모든 법적
        책임을 부담하게 됩니다.
        <br />⑫ 회원이 서비스를 이용하더라도 다른 회원 또는 회사의 콘텐츠에
        대하여 어떠한 권리를 가지게 되는 것은 아닙니다. 회원이 다른 회원 또는
        회사의 콘텐츠를 사용하기 위해서는 콘텐츠 소유자로부터 별도로 명시적
        허락을 받아야 합니다.
      </p>
      <h1> 제 19 조(회원 계정 탈퇴 등)</h1>
      <p>
        ① 회원은 계정 탈퇴를 원할 경우, 직접 서비스 내의 설정, 고객지원 메뉴,
        혹은 회사의 이메일로 계정 탈퇴를 할 수 있습니다.
        <br />
        ② 회원이 계정 탈퇴를 하는 경우 본 약관에 의한 서비스 이용계약도 자동으로
        해지됩니다.
        <br />
        ③ 회원이 계정 탈퇴를 하는 경우 관련 법령 및 개인정보 처리방침에 따라
        회원의 일정 정보를 보유하는 경우를 제외하고는 회원의 정보는 삭제됩니다.
        다만, 회원이 작성한 게시물이 제3자에 의하여 스크랩 또는 다른 공유
        기능으로 게시되거나, 회원이 제3자의 게시물에 댓글 등 게시물을 추가하는
        등의 경우에는 해당 게시물 및 댓글은 삭제되지 않으므로, 계정 탈퇴 전에
        이를 확인하시기 바랍니다. 해당 게시물 및 댓글이 삭제되지 아니함으로
        인하여 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
        <br />
        ④ 회원이 일정기간 동안 서비스를 이용하지 않을 경우. 회사는 회원의 정보를
        파기하거나 분리 보관할 수 있습니다.
        <br />⑤ 회원이 계정을 탈퇴한 경우라도 회원은 다시 회사에 대하여 서비스
        가입을 신청할 수 있습니다. 다만, 회원이 관련 법령, 본 약관 및 세부지침을
        준수하지 않아 서비스의 이용이 중단된 상태에서 계정을 탈퇴한 후 다시
        서비스 가입을 신청하는 경우에는 서비스 가입에 제한이 있을 수 있습니다.
      </p>
      <h1> 제 20 조(개인정보의 보호 등)</h1>
      <p>
        회원의 개인정보의 안전한 처리는 회사에게도 중요합니다. 회원의 개인정보는
        서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위 내에서만
        이용됩니다. 회사가 회원의 개인정보를 안전하게 처리하기 위하여 기울이는
        노력이나 기타 자세한 사항에 관하여서는 개인정보처리방침 등을 참고해
        주시기 바랍니다.
      </p>
      <h1> 제 21 조(위치정보의 보호 등)</h1>
      <p>
        ① 회사는 회원의 위치정보를 서비스 및 서비스 기능확대의 용도로 수집,
        이용합니다.
        <br />
        ② 회원은 회사에게 위치정보 이용 및 제공사실에 대한 고지, 오류 정정을
        요구할 수 있습니다. 회사는 최대한 빠른 시일 내에 회원의 요청을 처리하기
        위해 노력합니다.
        <br />
        ③ 회원은 회사에게 개인위치정보가 제3자에게 제공된 경우, 그 이유 및
        내용에 대하여 열람, 고지, 오류 정정을 요구할 수 있습니다. 이때 회사는
        가급적 빠른 시일 내에 회원의 요청을 처리해야 합니다.
        <br />
        ④ 회사는 위치정보의 보유 및 이용 등에 관한 법률 제16조 제2항에 근거하여
        개인위치정보주체에 대한 위치정보 이용 및 제공사실 확인자료를
        위치정보시스템에 자동으로 기록, 보존합니다. 해당 정보와 자료의
        보관기간은 1년입니다.
        <br />
        ⑤ 회사는 회원의 위치정보를 회원이 지정하는 제3자에게 제공하게 될 경우,
        매 회 회원에게 제공받는 자 및 제공일시, 제공목적을 통보하여야 합니다.
        <br />⑥ 회원은 제3자에게 제공되는 개인위치정보의 이용, 제공목적,
        제공받는 자의 범위 및 위치기반서비스의 일부에 대하여 동의를 유보할 수
        있으며, 이 때 반드시 그 구체적 사항을 회사에 통보하여야 합니다.
      </p>
      <h1> 제 22 조(손해배상 등)</h1>
      <p>
        ① 회사는 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인
        사항에 대한 약정이나 보증을 하지 않으며, 그로 인하여 발생하는 손해에
        대해서 책임을 지지 않습니다.
        <br />
        ② 회사는 회사나 회원이 서비스에 게시 또는 전송한 정보, 자료, 사실 등에
        관한 신뢰도, 정확성, 완결성, 품질 등에 대해서는 보증을 하지 않으며, 그로
        인하여 발생하는 손해에 대해서 책임을 지지 않습니다.
        <br />
        ③ 회사는 회사가 서비스를 통하여 제공한 내용과 회원이 입력한 회원정보에
        대한 신뢰성, 정당성, 적법성을 보증하지 않으며, 그로 인하여 발생하는
        손해에 대해서 책임을 지지 않습니다.
        <br />
        ④ 회사는 회사의 서비스에 의해서 제안되고 획득한 상품에 대해 보증하지
        않으며, 또는 회사에서 제공한 링크를 통해서 얻은 물품이나 서비스, 제안 등
        일체의 정보에 대하여 보증하지 않으며, 그로 인하여 발생하는 손해에 대해
        책임을 지지 않습니다.
        <br />
        ⑤ 회사는 아래와 같은 손해 또는 아래와 같은 상황에서 발생하거나 파급된
        손해에 대해서는 책임을 부담하지 않습니다. 또한 회사는 법률상 허용되는
        한도 내에서 직간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적
        손해에 대한 책임을 부담하지 않습니다.
        <br />
        1. 천재지변, 전쟁 또는 이에 준하는 불가항력의 상태에서 발생하는 손해
        <br />
        2. 회원이 법령, 약관, 또는 회사의 세부지침, 조치사항 등을 준수하지 않은
        이용을 한 경우 또는 회원의 귀책사유로 인하여 서비스 중지 또는 이용장애가
        발생한 경우에 발생한 손해
        <br />
        3. 서비스 접속 또는 이용과정에서 발생하는 개인적인 손해
        <br />
        4. 회원 또는 회원이 아닌 자가 불법적으로 회사의 서버에 접속하거나 서버를
        이용함으로써 발생하는 손해
        <br />
        5. 회원 또는 제3자가 회사 서버로의 전송을 방해 또는 회사 서버로부터의
        전송을 방해함으로써 발생하는 손해
        <br />
        6. 회원 또는 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는
        손해
        <br />
        7. 전송된 데이터의 생략, 누락, 손상, 파괴 등으로 발생하는 손해
        <br />
        8. 회원이 아닌 자가 서비스를 이용하는 과정에서 발생한 손해
        <br />
        9. 회원의 컴퓨터 또는 회원의 모바일 기기 환경으로 인하여 발생하는 제반
        손해, 또는 회사의 귀책사유 없이 네트워크 환경으로 인하여 발생하는 손해
        <br />
        10. 사전에 공지된 서비스 설비의 보수, 교체, 정기점검, 공사 등 부득이한
        사유로 서비스가 중지되거나 장애가 발생한 경우에서 발생한 손해
        <br />
        11. 서비스의 사용 불능으로 인하여 회원 또는 비회원에게 발생하는 손해
        <br />
        12. 회원이 회사의 서비스를 사용하여 특정 프로그램이나 정보 등을
        다운받거나 접근함으로써 입게 되는 컴퓨터 시스템 또는 모바일 기기
        시스템상의 손실이나 데이터, 정보의 상실 등에서 발생한 손해
        <br />
        13. 회원의 컴퓨터 또는 모바일 기기 오류, 신상정보 또는 계정정보, 이메일
        주소의 부정확한 기재, 비밀번호 관리의 소홀 등 회원의 귀책사유로 인해
        발생한 손해
        <br />
        14. 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지
        아니하여 발생하는 손해
        <br />
        15. 회원의 사용 환경 등 회사의 권한 범위에 있지 아니한 보안 문제로
        발생하는 제반 문제 또는 현재의 보안기술 수준으로 방어가 곤란할 수 있는
        네트워크 해킹 등 회사의 귀책사유 없이 발생
        <br />
        하는 문제로 인한 손해
        <br />
        16. 기타 회사의 고의 또는 과실이 없는 사유로 인하여 발생하는 손해
        <br />
        ⑥ 회사는 회원이 서비스를 이용하면서 기대하는 효용을 얻지 못한 것에
        대하여 책임을 지지 않으며, 회원이 서비스를 취사 선택하여 발생하는 손해
        등에 대해서도 책임이 면제됩니다.
        <br />
        ⑦ 서비스를 이용하는 회원들의 의무사항은 본 약관에 명시되어 있습니다.
        회원간 또는 회원과 제3자간에 서비스를 매개로 또는 서비스의 직접 또는
        간접적 이용 과정에서 관련하여 발생하는 분쟁에 관하여 회사는 개입하지
        않으며, 그로 인한 회원 또는 제3자의 손해를 배상할 책임도 없습니다.
        <br />⑧ 회사는 회원의 게시물을 동록 전에 사전 심사할 의무가 없으며,
        상시적으로 게시물의 내용을 확인하거나 또는 검토하여야 할 의무가
        없습니다.
      </p>
      <h1> 제 23 조(광고의 게재 등)</h1>
      <p>
        ① 회사가 회원에게 서비스를 제공할 수 있는 시스템 등 기반의 일부는
        광고게재를 통한 수익으로부터 발생할 수 있습니다. 회원은 서비스 이용시
        노출되는 광고 게재에 대해 동의합니다.
        <br />② 회사는 서비스에 게재되어 있거나, 서비스를 통한 광고주의
        판촉활동에 회원이 참여하거나 교신 또는 거래하는 결과로서 발생하는 어떠한
        손실 또는 손해에 대해서도 책임을 지지 않습니다.
      </p>
      <h1> 제 24 조(분쟁의 해결)</h1>
      <p>
        본 약관 또는 서비스는 대한민국 법령에 의하여 규정되고 이행됩니다. 서비스
        이용과 관련하여 회사와 회원 간에 분쟁이 발생할 경우, 회사와 회원은
        문제의 해결을 위해 성실히 협의해야 합니다.
      </p>
      <h1> 제 25 조(준거법)</h1>
      <p>
        회사 또는 회사의 서비스와 관련한 분쟁에 관하여서는 대한민국법을
        준거법으로 합니다.
      </p>
      <h1> 제 26 조(재판관할)</h1>
      <p>
        회사 또는 회사의 서비스와 관련한 소송, 기타 분쟁에 관하여서는 대한민국
        서울중앙지방법원을 전속관할법원으로 합니다.
      </p>
      <h1>부칙</h1>
      <p>본 약관은 2023. 2. 1.부터 유효합니다.</p>
    </>
  );
};

export default TermsUsecard_Ko;

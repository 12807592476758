import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Login from "../../pages/modal/Login";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, removeUserData } from "../../redux/action/userDataAction";
import AuthStorage from "../../helper/AuthStorage";
import { changeLoginState } from "../../redux/action/loginAction";
import { ApiGet, ApiPut } from "../../helper/API/ApiData";
import { toggleNotification } from "../../redux/action/userDataAction";
import { Button, Modal } from "react-bootstrap";
import Switch from "react-switch";
import { db } from "../../firebaseConfig";
import * as QueryString from "query-string";
import { GET_NOTIFICATION } from "../../redux/type";
import firebase from "firebase";
import Chat from "../../components/Chat";
import { setChatState } from "../../redux/action/chatDataAction";
import AccountDeactive from "../../pages/modal/AccountDeactive";
import { clearGlobalSearch } from "../../redux/action/persistDataAction";
import SearchBar from "../../components/SearchBar";
import HeaderLogo from "../../components/HeaderLogo";
import { useRef } from "react";
import useRootClose from "react-overlays/esm/useRootClose";
import Message from "components/Message";
import "./Header.css";

const landropdown = [
  { value: "Korean", label: "Korean" },
  { value: "English", label: "English" },
];

const Header = () => {
  const isMobile = window.innerWidth <= 768;

  const location = useLocation();
  const { t } = useTranslation();
  const { is_loggedin } = useSelector((state) => state?.login);
  const { userData } = useSelector((state) => state?.userData);
  const { user_name, avatar } = useSelector(
    (state) => state?.userData?.userData
  ) || {
    user_name: "",
    avatar: "",
  };
  const [endHeaderDate, setHeaderEndDate] = useState(null);
  const [searchHeaderTerm, setHeaderSearchTerm] = useState();
  // Notification section
  const { notificationData } = useSelector((state) => state.notification);
  const history = useHistory();
  const [startHeaderDate, setHeaderStartDate] = useState(null);
  const persistData = useSelector((state) => state.persistData);
  const [modalShow, setModalShow] = useState(false);
  const [langDropDown, setLangDropDown] = useState({});
  const [langDropDownTwo, setLangDropDownTwo] = useState(false);

  const [isLNB, setLNB] = useState(false);
  const [profile_open, setProfileState] = useState(false);
  const [notify_open, setNotifyState] = useState(false);
  const [lang_open, setLangState] = useState(false);

  const [engTranslate, setEngTranslate] = useState(true);
  const [krnTranslate, setKrnTranslate] = useState(false);

  const [badge, setShowBadge] = useState(false);
  const [loginValue, setLoginValue] = useState(false);

  const [arr, setArr] = useState([]);

  const [activeTab, setActiveTab] = useState("All");

  const { chatData, count, chat_open, message_open, id, otherUser } =
    useSelector((state) => state.chatData);

  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const is_active = AuthStorage.isUserActive();

  const target1 = useRef();
  const target2 = useRef();
  const target3 = useRef();

  const handleRootClose1 = () => {
    setProfileState(!profile_open);
    setLNB(false);
  };
  const handleRootClose2 = () => {
    setNotifyState(!notify_open);
    setLNB(false);
  };
  const handleRootClose3 = () => {
    setLangState(!lang_open);
    setLNB(false);
  };

  // profile rootclose setting
  useRootClose(target1, handleRootClose1, {
    disabled: !profile_open,
  });

  // chat rootclose setting
  useRootClose(target2, handleRootClose2, {
    disabled: !notify_open,
  });

  // notify rootclose setting
  useRootClose(target3, handleRootClose3, {
    disabled: !lang_open,
  });

  useEffect(() => {
    if (userData && userData.hasOwnProperty("id")) {
      let id = userData?.id;
      const date = firebase.firestore.Timestamp.fromDate(
        new Date(moment(new Date()).subtract(14, "d").format("YYYY-MM-DD"))
      ).toDate();

      const unsubscribe = db
        .collection(AuthStorage.getNotificationCollection())
        .orderBy("timestamp", "desc")
        .where("reciever", "==", id)
        .where("timestamp", ">=", date)
        .onSnapshot(
          (snapshot) => {
            if (snapshot.docs) {
              let data = snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
              }));

              dispatch({
                type: GET_NOTIFICATION,
                payload: data,
              });
            }
          },
          (err) => {
            console.log("Error in getNotification: ", err);
          }
        );

      setArr([...arr, unsubscribe]);
    }
  }, [userData]);

  useEffect(() => {
    setShowBadge(notificationData.find((x) => !x?.data.seen) ? true : false);
  }, [notificationData]);

  // console.log("알림확인", notificationData);
  const redirect = (
    docId,
    itiID,
    hostingID,
    type,
    creator,
    disclosure,
    hash = ""
  ) => {
    (async () => {
      await db
        .collection(AuthStorage.getNotificationCollection())
        .doc(docId)
        .update({
          seen: true,
        });
      if (type === "CANCEL") {
        // 취소된 일정
        if (creator === "Host") {
          if (location.pathname === "/itinerary") {
            window.location.href = `/error`;
          } else {
            history.push(`/error`);
          }
        } else {
          if (location.pathname === "/itinerary") {
            window.location.href = `/itinerary?id=${itiID}`;
          } else {
            history.push(`/itinerary?id=${itiID}`);
          }
        }
      } else if (type === "DELETED") {
        // 삭제된 일정
        if (creator === "Host") {
          if (location.pathname === "/deleteitinerary") {
            window.location.href = `/error`;
          } else {
            // history.push(`/error`);
            history.push({
              // `/itinerary?id=${itiID}&hostingId=${hostingID}${hash}`
              pathname: "/error",
              keys: creator,
            });
          }
        } else {
          if (disclosure === "PRIVATE") {
            // 비활성화 일정
            if (location.pathname === "/deleteitinerary") {
              // window.location.href = `/error`;
              history.push({
                // `/itinerary?id=${itiID}&hostingId=${hostingID}${hash}`
                pathname: "/error",
                keys: creator,
              });
            } else {
              // history.push(`/error`);
              history.push({
                // `/itinerary?id=${itiID}&hostingId=${hostingID}${hash}`
                pathname: "/error",
                keys: creator,
              });
            }
          } else {
            if (location.pathname === "/itinerary") {
              window.location.href = `/itinerary?id=${itiID}`;
            } else {
              history.push(`/itinerary?id=${itiID}`);
            }
          }
        }
      } else if (type === "COMPLETE") {
        // 호스팅 정상종료
        window.location.href = `/itinerary?id=${itiID}&hostingId=${hostingID}&noti=mypage`;
      } else if (
        type === "JOIN" ||
        type === "ACCEPTED" ||
        type === "APPLICANT_CANCEL" ||
        type === "DECLINED"
      ) {
        // 참석 승인/거부 & 호스팅 승인 거부
        ApiGet(`hosting/hosting-check?id=${hostingID}&itinerary_id=${itiID}`)
          .then((res) => {
            if (res.data.status !== "COMPLETED") {
              if (location.pathname === "/itinerary") {
                window.location.href = `/itinerary?id=${itiID}&hostingId=${hostingID}${hash}`;
              } else {
                history.push(
                  `/itinerary?id=${itiID}&hostingId=${hostingID}${hash}`
                );
              }
            } else {
              if (location.pathname === "/itinerary") {
                window.location.href = `/itinerary?id=${itiID}&hostingId=${hostingID}&noti=mypage${hash}`;
              } else {
                history.push(
                  `/itinerary?id=${itiID}&hostingId=${hostingID}&noti=mypage${hash}`
                );
              }
            }
          })
          .catch((err) => {});
      } else if (type === "CONFIRMED_REVIEW") {
        // 호스팅 승인 완료 메세지
        if (creator === "Host") {
          // 작성자 정보 유저
          ApiGet(`hosting/status-all/${hostingID}`)
            .then((res) => {
              if (res.data.status === "CANCELED") {
                if (res.data.confirm_status === "forced_cancel_review") {
                  // 강제종료
                  history.push(`/error2`);
                } else {
                  // 유저 자체종료
                  history.push(`/error`);
                }
              } else {
                // 정상 종료 & 그 외 상태
                history.push(
                  `/itinerary?id=${itiID}&hostingId=${hostingID}&noti=mypage`
                );
              }
            })
            .catch((error) => {});
        } else {
          // 작성자 정보 어드민
          ApiGet(`hosting/status-all/${hostingID}`)
            .then((res) => {
              if (res.data.status === "CANCELED") {
                // 강제 종료 & 자체 종료
                history.push(`/itinerary?id=${itiID}`);
              } else if (res.data.status === "COMPLETED") {
                // 정상종료
                history.push(
                  `/itinerary?id=${itiID}&hostingId=${hostingID}&noti=mypage`
                );
              } else {
                // 그 외 상태
                history.push(`/itinerary?id=${itiID}&hostingId=${hostingID}`);
              }
            })
            .catch((error) => {});
        }
      } else {
        window.location.href = `/itinerary?id=${itiID}&hostingId=${hostingID}`;
      }
    })();
  };

  const dataGet = (data) => {
    setModalShow(data);
  };

  let dispatch = useDispatch();

  const [selectedLang, setSelectedLang] = useState("Korean");

  useEffect(() => {
    if (user_name === "" || user_name === undefined) {
      AuthStorage.getToken() && dispatch(getUserData());
    }
    let getLangLocal = localStorage.getItem("i18nextLng");
    if (getLangLocal === "en") {
      setLangDropDown(landropdown[1]);
    } else {
      setLangDropDown(landropdown[0]);
    }
  }, []);

  const [temp, setTemp] = useState(false);

  const params = QueryString.parse(location.search);

  const changeLanguage = (lang, name) => {
    i18next.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    let getLangLocal = localStorage.getItem("i18nextLng");
    if (getLangLocal === "en") {
      setLangDropDown(landropdown[1]);
    } else {
      setLangDropDown(landropdown[0]);
    }

    if (temp) {
      let currentPath = location.pathname + location.search;
      window.location.href = currentPath;
    } else {
      window.location.reload();
    }
    setTemp(true);
  };

  const dataGets = () => {
    setModalShow(true);
  };

  const logout = () => {
    arr.forEach((func) => {
      func();
    });

    dispatch(changeLoginState(false));
    dispatch(clearGlobalSearch());
    dispatch(removeUserData());
    AuthStorage.deauthenticateUser();
  };
  const chatNotification = () => {
    ApiPut("user/notification", {}).then((res) => {
      dispatch(toggleNotification(res?.data?.data?.state));
    });
  };

  const Search = () => {
    let searchParam =
      "?keyword=" +
      (persistData?.globalSearch?.searchHeaderTerm || "") +
      "&startDate=" +
      (persistData?.globalSearch?.startHeaderDate || "") +
      "&endDate=" +
      (persistData?.globalSearch?.endHeaderDate || "");
    history.push({
      pathname: "/",
      search: searchParam,
    });
  };

  useEffect(() => {
    const header = document.getElementById("SmallHeader");
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 350 && location.pathname === "/") {
        header.classList.add("d-block");
        // if (params.keyword || params.endDate || params.startDate) {
        //     if (params?.endDate) {
        //         setHeaderEndDate(new Date(params?.endDate.toString()));
        //     }
        //     if (params?.startDate) {
        //         setHeaderStartDate(new Date(params?.startDate.toString()));
        //     }
        // }
        // setHeaderSearchTerm(params?.keyword?.toString())
      } else {
        header.classList.remove("d-block");

        // if (params.keyword || params.endDate || params.startDate) {
        //     if (params?.endDate) {
        //         setHeaderEndDate(new Date(params?.endDate.toString()));
        //     }
        //     if (params?.startDate) {
        //         setHeaderStartDate(new Date(params?.startDate.toString()));
        //     }
        // }
        // setHeaderSearchTerm(params?.keyword?.toString())
      }
    });

    const windowClicker = window.addEventListener("click", (event) => {
      let targetNode = event?.target;
      if (targetNode?.classList?.contains("notification-font-normal")) {
        targetNode = targetNode?.parentNode;
      }

      if (
        targetNode?.classList?.contains("user-image-login") ||
        targetNode?.classList?.contains("simple-notification-img")
      ) {
        return true;
      }

      if (
        !(
          targetNode?.classList?.contains("profile-drop-link-normal") &&
          targetNode?.parentNode?.parentNode?.parentNode?.classList?.contains(
            "mobile-profile-dropdown"
          )
        )
      ) {
        setProfileDropdown(false);
      }

      if (
        !(
          targetNode?.classList.contains("dropdown-item") &&
          targetNode?.parentNode?.parentNode?.classList.contains(
            "mobile-notification"
          )
        )
      ) {
        setMobileNotification(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
      window.removeEventListener("click", windowClicker);
    };
  }, [location]);

  const onDatePickerClick = (id) => {
    document.getElementById(id)?.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      Search();
      event.preventDefault();
    }
  };

  const [mobNotification, setMobileNotification] = useState(false);

  const openMobNotification = () => {
    setMobileNotification(!mobNotification);
    setProfileDropdown(false);
    setLangDropDownTwo(false);
  };

  const closeMobNotification = () => {
    setMobileNotification(false);
  };

  const [profileDropdown, setProfileDropdown] = useState(false);

  const changeEngTranslate = () => {
    setEngTranslate(true);
    setKrnTranslate(false);
    changeLanguage("en", "English(EN)");
  };

  const changeKrnTranslate = () => {
    setKrnTranslate(true);
    setEngTranslate(false);
    changeLanguage("ko", "Korean");
  };

  // lnb reset
  const resetLNB = () => {
    setLNB(false);
    setProfileState(false);
    setNotifyState(false);
    setLangState(false);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const [logoSize, setLogoSize] = useState("95px");
  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setLogoSize("160px");
    } else {
      setLogoSize("205px");
    }
    setResize(window.innerWidth);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setLogoSize("160px");
    } else {
      setLogoSize("205px");
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header
        id="header"
        className={
          location.pathname === "/"
            ? "d-flex sticky-top main-header align-items-center"
            : "d-flex sticky-top sub-header align-items-center"
        }
      >
        <div className="wrap d-flex align-items-center justify-content-between position-relative">
          {location.pathname === "/" ? (
            <Link className="logo position-relative" to={"/"}>
              {/* <HeaderLogo></HeaderLogo> */}
              <img
                src={
                  isMobile === true
                    ? "./img/cst_logo.svg"
                    : "./img/cst_logo_white.svg"
                }
                width={logoSize}
                style={{ marginTop: isMobile === true ? "-3px" : "0px" }}
                alt="cst logo img 1"
              />
            </Link>
          ) : resize <= 768 ? (
            <Link
              variant="no-line"
              to={"/"}
              // onClick={() => {
              //   history.goBack();
              // }}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6.49951L8 13.9995L18 21.4995"
                  stroke="#1B1C1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          ) : (
            <Link className="logo position-relative" to={"/"}>
              {/* <HeaderLogo></HeaderLogo> */}
              <img
                src={"./img/cst_logo.svg"}
                width={logoSize}
                alt="cst logo img 3"
              />
            </Link>
          )}
          <div
            className={
              !is_loggedin
                ? "right-button d-flex align-items-center justify-content-center g-16"
                : "login gm-12 right-button d-flex align-items-center justify-content-center g-16"
            }
          >
            {/* login button */}
            {is_loggedin ? (
              <>
                <button
                  className="profile d-flex align-items-center justfy-content-between"
                  onClick={() => {
                    setLNB(true);
                    setProfileState(true);
                  }}
                >
                  <div className="user-img circle">
                    <img
                      src={avatar || "./img/Avatar.png"}
                      alt="user profile img"
                    />
                  </div>
                  <p className="user-name desc-bold">
                    {AuthStorage.getLang() === "en"
                      ? userData?.user_name?.length <= 8
                        ? user_name?.slice(0, 8)
                        : user_name?.slice(0, 7) + ".."
                      : userData?.user_name?.length <= 4
                      ? user_name?.slice(0, 4) + "님"
                      : user_name?.slice(0, 3) + ".." + "님"}
                  </p>
                </button>

                {userData?.notification ? (
                  count > 0 ? (
                    <button
                      className="position-relative chatIcon msg-badge bg-transparent"
                      onClick={() => {
                        if (is_active) {
                          dispatch(setChatState(!chat_open));
                        } else {
                          setDeactivatedNotice(true);
                        }
                      }}
                    >
                      <svg
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.7875 22L16.5371 21.9749L16.5128 21.25H15.7875V22ZM15.9053 25.5123L16.6549 25.4871V25.4871L15.9053 25.5123ZM16.7564 25.8566L17.2783 26.3952L16.7564 25.8566ZM22.1963 20.5859L21.7814 19.9612L21.7239 19.9993L21.6744 20.0473L22.1963 20.5859ZM6.75 13.5C6.75 9.21979 10.2198 5.75 14.5 5.75V4.25C9.39137 4.25 5.25 8.39137 5.25 13.5H6.75ZM14.5 21.25C10.2198 21.25 6.75 17.7802 6.75 13.5H5.25C5.25 18.6086 9.39137 22.75 14.5 22.75V21.25ZM15.7875 21.25H14.5V22.75H15.7875V21.25ZM15.0379 22.0251L15.1557 25.5374L16.6549 25.4871L16.5371 21.9749L15.0379 22.0251ZM15.1557 25.5374C15.192 26.6197 16.4985 27.1508 17.2783 26.3952L16.2345 25.318C16.3913 25.1661 16.6477 25.2722 16.6549 25.4871L15.1557 25.5374ZM17.2783 26.3952L22.7182 21.1246L21.6744 20.0473L16.2345 25.318L17.2783 26.3952ZM25.25 13.5C25.25 16.1965 23.8735 18.5718 21.7814 19.9612L22.6112 21.2107C25.1041 19.5552 26.75 16.72 26.75 13.5H25.25ZM17.5 5.75C21.7802 5.75 25.25 9.21979 25.25 13.5H26.75C26.75 8.39137 22.6086 4.25 17.5 4.25V5.75ZM14.5 5.75H17.5V4.25H14.5V5.75Z"
                          fill="white"
                        />
                        <rect
                          x="10.2012"
                          y="12.5"
                          width="2.5"
                          height="2.5"
                          rx="1.25"
                          fill="white"
                        />
                        <rect
                          x="14.7012"
                          y="12.5"
                          width="2.5"
                          height="2.5"
                          rx="1.25"
                          fill="white"
                        />
                        <rect
                          x="19.2012"
                          y="12.5"
                          width="2.5"
                          height="2.5"
                          rx="1.25"
                          fill="white"
                        />
                        <circle cx="29" cy="3" r="3" fill="#FF5F5F" />
                      </svg>
                    </button>
                  ) : (
                    <button
                      className="position-relative chatIcon bg-transparent"
                      onClick={() => {
                        if (is_active) {
                          dispatch(setChatState(!chat_open));
                        } else {
                          setDeactivatedNotice(true);
                        }
                      }}
                    >
                      <svg
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.7875 22L16.5371 21.9749L16.5128 21.25H15.7875V22ZM15.9053 25.5123L16.6549 25.4871V25.4871L15.9053 25.5123ZM16.7564 25.8566L17.2783 26.3952L16.7564 25.8566ZM22.1963 20.5859L21.7814 19.9612L21.7239 19.9993L21.6744 20.0473L22.1963 20.5859ZM6.75 13.5C6.75 9.21979 10.2198 5.75 14.5 5.75V4.25C9.39137 4.25 5.25 8.39137 5.25 13.5H6.75ZM14.5 21.25C10.2198 21.25 6.75 17.7802 6.75 13.5H5.25C5.25 18.6086 9.39137 22.75 14.5 22.75V21.25ZM15.7875 21.25H14.5V22.75H15.7875V21.25ZM15.0379 22.0251L15.1557 25.5374L16.6549 25.4871L16.5371 21.9749L15.0379 22.0251ZM15.1557 25.5374C15.192 26.6197 16.4985 27.1508 17.2783 26.3952L16.2345 25.318C16.3913 25.1661 16.6477 25.2722 16.6549 25.4871L15.1557 25.5374ZM17.2783 26.3952L22.7182 21.1246L21.6744 20.0473L16.2345 25.318L17.2783 26.3952ZM25.25 13.5C25.25 16.1965 23.8735 18.5718 21.7814 19.9612L22.6112 21.2107C25.1041 19.5552 26.75 16.72 26.75 13.5H25.25ZM17.5 5.75C21.7802 5.75 25.25 9.21979 25.25 13.5H26.75C26.75 8.39137 22.6086 4.25 17.5 4.25V5.75ZM14.5 5.75H17.5V4.25H14.5V5.75Z"
                          fill="white"
                        />
                        <rect
                          x="10.2012"
                          y="12.5"
                          width="2.5"
                          height="2.5"
                          rx="1.25"
                          fill="white"
                        />
                        <rect
                          x="14.7012"
                          y="12.5"
                          width="2.5"
                          height="2.5"
                          rx="1.25"
                          fill="white"
                        />
                        <rect
                          x="19.2012"
                          y="12.5"
                          width="2.5"
                          height="2.5"
                          rx="1.25"
                          fill="white"
                        />
                        <circle cx="29" cy="3" r="3" fill="transparent" />
                      </svg>
                    </button>
                  )
                ) : (
                  <button
                    className="position-relative d-flex align-items-center chatIcon chatOff bg-transparent"
                    disabled
                    onClick={() => {
                      if (is_active) {
                        dispatch(setChatState(!chat_open));
                      } else {
                        setDeactivatedNotice(true);
                      }
                    }}
                  >
                    <svg
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.7875 22L16.5371 21.9749L16.5128 21.25H15.7875V22ZM15.9053 25.5123L16.6549 25.4871V25.4871L15.9053 25.5123ZM16.7564 25.8566L17.2783 26.3952L16.7564 25.8566ZM22.1963 20.5859L21.7814 19.9612L21.7239 19.9993L21.6744 20.0473L22.1963 20.5859ZM6.75 13.5C6.75 9.21979 10.2198 5.75 14.5 5.75V4.25C9.39137 4.25 5.25 8.39137 5.25 13.5H6.75ZM14.5 21.25C10.2198 21.25 6.75 17.7802 6.75 13.5H5.25C5.25 18.6086 9.39137 22.75 14.5 22.75V21.25ZM15.7875 21.25H14.5V22.75H15.7875V21.25ZM15.0379 22.0251L15.1557 25.5374L16.6549 25.4871L16.5371 21.9749L15.0379 22.0251ZM15.1557 25.5374C15.192 26.6197 16.4985 27.1508 17.2783 26.3952L16.2345 25.318C16.3913 25.1661 16.6477 25.2722 16.6549 25.4871L15.1557 25.5374ZM17.2783 26.3952L22.7182 21.1246L21.6744 20.0473L16.2345 25.318L17.2783 26.3952ZM25.25 13.5C25.25 16.1965 23.8735 18.5718 21.7814 19.9612L22.6112 21.2107C25.1041 19.5552 26.75 16.72 26.75 13.5H25.25ZM17.5 5.75C21.7802 5.75 25.25 9.21979 25.25 13.5H26.75C26.75 8.39137 22.6086 4.25 17.5 4.25V5.75ZM14.5 5.75H17.5V4.25H14.5V5.75Z"
                        fill="white"
                      />
                      <rect
                        x="10.2012"
                        y="12.5"
                        width="2.5"
                        height="2.5"
                        rx="1.25"
                        fill="white"
                      />
                      <rect
                        x="14.7012"
                        y="12.5"
                        width="2.5"
                        height="2.5"
                        rx="1.25"
                        fill="white"
                      />
                      <rect
                        x="19.2012"
                        y="12.5"
                        width="2.5"
                        height="2.5"
                        rx="1.25"
                        fill="white"
                      />
                      <circle cx="29" cy="3" r="3" fill="transparent" />
                    </svg>
                  </button>
                )}
                {/* notify button */}
                {badge ? (
                  <button
                    className="notifyIcon msg-badge bg-transparent"
                    onClick={() => {
                      setLNB(true);
                      setNotifyState(true);
                    }}
                  >
                    <svg
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="noFill"
                        d="M23 24V14.7691C23 11.035 19.8614 8 16 8C12.1386 8 9 11.035 9 14.7691V24"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M7 23.7148H25"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M16 8V5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        className="noFill"
                        d="M19 23.7148C19 25.3737 17.6553 26.7148 16 26.7148C14.3447 26.7148 13 25.3701 13 23.7148"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <circle cx="29" cy="3" r="3" fill="#FF5F5F" />
                    </svg>
                  </button>
                ) : (
                  <button
                    className="notifyIcon bg-transparent"
                    onClick={() => {
                      setLNB(true);
                      setNotifyState(true);
                    }}
                  >
                    <svg
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="noFill"
                        d="M23 24V14.7691C23 11.035 19.8614 8 16 8C12.1386 8 9 11.035 9 14.7691V24"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M7 23.7148H25"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        d="M16 8V5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                      />
                      <path
                        className="noFill"
                        d="M19 23.7148C19 25.3737 17.6553 26.7148 16 26.7148C14.3447 26.7148 13 25.3701 13 23.7148"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                      />
                      <circle cx="29" cy="3" r="3" fill="transparent" />
                    </svg>
                  </button>
                )}

                {chat_open && (
                  <Modal
                    show={chat_open}
                    dialogClassName={
                      location.pathname === "/"
                        ? AuthStorage.getLang() === "en"
                          ? "roboto-text wrap position-relative m-auto allchatmodal allchatMain"
                          : "noto-text wrap position-relative m-auto allchatmodal allchatMain"
                        : AuthStorage.getLang() === "en"
                        ? "roboto-text wrap position-relative m-auto allchatmodal"
                        : "noto-text wrap position-relative m-auto allchatmodal"
                    }
                    contentClassName="position-absolute chatModal "
                    backdrop={false}
                  >
                    <Modal.Header className="d-flex justify-content-between">
                      <h4 className="modal-title">{t("Chat.Chat")}</h4>
                      <div className="footerClose">
                        <svg
                          onClick={() => dispatch(setChatState(false))}
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="20.6991"
                            y1="7.76073"
                            x2="7.76085"
                            y2="20.699"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <line
                            x1="20.5711"
                            y1="20.6981"
                            x2="7.63281"
                            y2="7.75986"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </Modal.Header>
                    <Modal.Body className="p-0 mt-40">
                      <div className="chat-scroll">
                        {chatData.map((x, i) => {
                          if (x.data.lastMessage)
                            return (
                              <Chat
                                key={i}
                                data={x.data}
                                id={x.id}
                                readOnly={x.readOnly}
                              />
                            );
                        })}
                      </div>
                    </Modal.Body>
                  </Modal>
                )}
              </>
            ) : (
              <>
                <button
                  className="loginBtn"
                  onClick={() => {
                    setLoginValue(true);
                    setModalShow(true);
                  }}
                >
                  {t("logIn.Log_In")}
                </button>
                {/* language button */}
              </>
            )}
            <button
              disabled={true}
              className="lang circle d-flex align-items-center justify-content-center"
              onClick={() => {
                // setLNB(true);
                // setLangState(true);
              }}
            >
              {location.pathname === "/" && resize > 768 ? (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="5.75"
                    y="5.75"
                    width="20.5"
                    height="20.5"
                    rx="10.25"
                    stroke="#919191"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 6.07367C16 5.40358 15.258 5.03587 14.7882 5.51372C12.4955 7.84598 11 11.676 11 16.0054C11 20.3348 12.4955 24.1648 14.7882 26.497C15.258 26.9749 16 26.6072 16 25.9371C16 25.7138 15.9081 25.5015 15.7545 25.3395C15.4445 25.0126 15.1471 24.6472 14.8652 24.2443C13.4333 22.1971 12.5 19.2888 12.5 16.0054C12.5 12.7219 13.4333 9.81366 14.8652 7.76644C15.1471 7.36349 15.4445 6.99815 15.7545 6.67119C15.9081 6.50922 16 6.29689 16 6.07367Z"
                    fill="#919191"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 6.07367C16 5.40358 16.742 5.03587 17.2118 5.51372C19.5045 7.84598 21 11.676 21 16.0054C21 20.3348 19.5045 24.1648 17.2118 26.497C16.742 26.9749 16 26.6072 16 25.9371C16 25.7138 16.0919 25.5015 16.2455 25.3395C16.5555 25.0126 16.8529 24.6472 17.1348 24.2443C18.5667 22.1971 19.5 19.2888 19.5 16.0054C19.5 12.7219 18.5667 9.81366 17.1348 7.76644C16.8529 7.36349 16.5555 6.99815 16.2455 6.67119C16.0919 6.50922 16 6.29689 16 6.07367Z"
                    fill="#919191"
                  />
                  <rect
                    className="noFill"
                    x="6"
                    y="18.5"
                    width="20"
                    height="1.5"
                    fill="#919191"
                  />
                  <rect
                    className="noFill"
                    x="6"
                    y="12"
                    width="20"
                    height="1.5"
                    fill="#919191"
                  />
                </svg>
              ) : (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="5.75"
                    y="5.75"
                    width="20.5"
                    height="20.5"
                    rx="10.25"
                    stroke="#919191"
                    stroke-width="1.5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 6.07367C16 5.40358 15.258 5.03587 14.7882 5.51372C12.4955 7.84598 11 11.676 11 16.0054C11 20.3348 12.4955 24.1648 14.7882 26.497C15.258 26.9749 16 26.6072 16 25.9371C16 25.7138 15.9081 25.5015 15.7545 25.3395C15.4445 25.0126 15.1471 24.6472 14.8652 24.2443C13.4333 22.1971 12.5 19.2888 12.5 16.0054C12.5 12.7219 13.4333 9.81366 14.8652 7.76644C15.1471 7.36349 15.4445 6.99815 15.7545 6.67119C15.9081 6.50922 16 6.29689 16 6.07367Z"
                    fill="#919191"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 6.07367C16 5.40358 16.742 5.03587 17.2118 5.51372C19.5045 7.84598 21 11.676 21 16.0054C21 20.3348 19.5045 24.1648 17.2118 26.497C16.742 26.9749 16 26.6072 16 25.9371C16 25.7138 16.0919 25.5015 16.2455 25.3395C16.5555 25.0126 16.8529 24.6472 17.1348 24.2443C18.5667 22.1971 19.5 19.2888 19.5 16.0054C19.5 12.7219 18.5667 9.81366 17.1348 7.76644C16.8529 7.36349 16.5555 6.99815 16.2455 6.67119C16.0919 6.50922 16 6.29689 16 6.07367Z"
                    fill="#919191"
                  />
                  <rect
                    x="6.375"
                    y="18.875"
                    width="19.25"
                    height="0.75"
                    fill="#919191"
                    stroke="#919191"
                    stroke-width="0.75"
                  />
                  <rect
                    x="6.375"
                    y="12.375"
                    width="19.25"
                    height="0.75"
                    fill="#919191"
                    stroke="#919191"
                    stroke-width="0.75"
                  />
                </svg>
              )}
            </button>
          </div>
          {location.pathname === "/SignUp" || location.pathname === "/" ? (
            <div id="SmallHeader" className="fixed-top-header d-none">
              <SearchBar
                isMob={false}
                setActiveIndex={setActiveIndex}
                setActiveTab={setActiveTab}
              />
            </div>
          ) : (
            <div id="SmallHeader" className="fixed-top-header d-block">
              <SearchBar isMob={false} />
            </div>
          )}
          {isLNB && profile_open && (
            <div className="lnb lnb_profile" ref={target1}>
              <Link
                to="/profile"
                onClick={(e) => {
                  resetLNB(e);
                }}
                className={
                  location.pathname === "/profile"
                    ? " profile-drop-link-a"
                    : "profile-drop-link-normal"
                }
              >
                {t("Header.DropDown.My_Account")}
              </Link>
              <Link
                to="/myhosting"
                onClick={(e) => {
                  resetLNB(e);
                }}
                className={
                  location.pathname === "/myhosting"
                    ? " profile-drop-link-a"
                    : "profile-drop-link-normal"
                }
              >
                {t("Header.DropDown.My_Hosting")}
              </Link>
              <Link
                to="/appliedhosting"
                onClick={(e) => {
                  resetLNB(e);
                }}
                className={
                  location.pathname === "/appliedhosting"
                    ? " profile-drop-link-a"
                    : "profile-drop-link-normal"
                }
              >
                {t("Header.DropDown.Applied_Hosting")}
              </Link>
              <Link
                to="/wishlist"
                onClick={(e) => {
                  resetLNB(e);
                }}
                className={
                  location.pathname === "/wishlist"
                    ? " profile-drop-link-a"
                    : "profile-drop-link-normal"
                }
              >
                {t("Header.DropDown.Wishlist")}
              </Link>
              <Link
                to="/edit-profile"
                onClick={(e) => {
                  resetLNB(e);
                }}
                className={
                  location.pathname === "/edit-profile"
                    ? " profile-drop-link-a"
                    : "profile-drop-link-normal"
                }
              >
                {t("Header.DropDown.Edit_Profile")}
              </Link>
              <Link
                to="/"
                onClick={(e) => {
                  resetLNB(e);
                  logout();
                }}
              >
                {t("Header.DropDown.Log_Out")}
              </Link>
              <div className="d-flex mt-30 chatbox  ">
                <div className="d-flex align-items-center ChatBoxInner w-100 text-bold">
                  <p className="chat-setting">{t("Chat.Chat")}</p>

                  <div
                    className="ml-auto d-flex align-items-center "
                    style={{ height: "20px", lineHeight: "20px" }}
                  >
                    <p
                      className={
                        userData?.notification
                          ? "on font-blue"
                          : "off font-blue"
                      }
                    >
                      {userData?.notification ? "ON" : "OFF"}
                    </p>

                    <Switch
                      onChange={chatNotification}
                      checked={userData?.notification}
                      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 3px rgba(0, 0, 0, 0.2)"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#E7E7E7"
                      onHandleColor="#42B6E6"
                      handleDiameter={20}
                      height={14}
                      width={34}
                      className="chat-toggle"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isLNB && notify_open && (
            <div className="lnb lnb_wrap">
              <div className="lnb_notify" ref={target2}>
                {notificationData.map((x, i) =>
                  x.data.seen ? (
                    <p
                      className="mb-0"
                      key={x.id}
                      onClick={() => {
                        redirect(
                          x.id,
                          x.data.itinerary_id,
                          x.data.hosting_id,
                          x.data.type,
                          x.data.creator,
                          x.data.disclosure,
                          "#joinApp"
                        );
                        resetLNB();
                      }}
                    >
                      {AuthStorage.getLang() === "en"
                        ? x.data.msg
                        : x.data.msg_ko}
                    </p>
                  ) : (
                    <p
                      className="mb-0 new-notify"
                      key={x.id}
                      onClick={() => {
                        redirect(
                          x.id,
                          x.data.itinerary_id,
                          x.data.hosting_id,
                          x.data.type,
                          x.data.creator,
                          x.data.disclosure,
                          "#joinApp"
                        );
                        resetLNB();
                      }}
                    >
                      {AuthStorage.getLang() === "en"
                        ? x.data.msg
                        : x.data.msg_ko}
                    </p>
                  )
                )}
              </div>
            </div>
          )}
          {isLNB && lang_open ? (
            <div className="lnb lnb_lang" ref={target3}>
              <button
                className={AuthStorage.getLang() === "en" ? "on" : ""}
                onClick={() => {
                  changeEngTranslate();
                  resetLNB();
                }}
              >
                English
              </button>
              <button
                className={AuthStorage.getLang() === "en" ? "" : "on"}
                onClick={() => {
                  changeKrnTranslate();
                  resetLNB();
                }}
              >
                Korean
              </button>
            </div>
          ) : null}
          {/* {isLNB && lang_open && (
            <div className="lnb lnb_lang" ref={target3}>
              <p
                onClick={() => {
                  changeEngTranslate();
                  resetLNB();
                }}
              >
                EN
              </p>
              <p
                onClick={() => {
                  changeKrnTranslate();
                  resetLNB();
                }}
              >
                KR
              </p>
            </div>
          )} */}
        </div>
      </header>

      <Login
        show={modalShow}
        loginValue={loginValue}
        onHide={(paramHide = false) => {
          console.log("onHide   ", paramHide);
          setModalShow(paramHide);
        }}
        onHideNew={dataGet}
        onShow={dataGets}
      />
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </>
  );
};

export default Header;

import moment from "moment";
import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { ApiPost } from "../../helper/API/ApiData";
import HostDetails from "../modal/HostDetails";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LikedPlaces = ({ tourCoures, searchToursCoures, setRefresh }) => {
  const [tourIndex, setTourIndex] = useState(0);
  const [tourDetails, setTourDetails] = useState(false);
  const location = useLocation();
  const setDate = (startDate, endDate) => {
    let date = "-";
    if (!startDate && !endDate) {
      date = "-";
    } else if (startDate && !endDate) {
      date = `${moment(startDate).format("YYYY.MM.DD")}`;
    } else {
      date = `${moment(startDate).format("YYYY.MM.DD")} - ${moment(
        endDate
      ).format("YYYY.MM.DD")}`;
      // date = `${moment(startDate).format("YYYY.MM.DD") + " (" + moment(startDate).format("ddd") + ") " + moment(startDate).format("h:mm") + " - " + moment(endDate).format("h:mm")}`;
    }

    return date;
  };

  const Like = (id) => {
    ApiPost(`itinerary/tourcoursewishlist/${id}`, {})
      .then((res) => {
        searchToursCoures();
        setRefresh(Math.random());
      })
      .catch((err) => {});
  };
  const { t } = useTranslation();
  return (
    <>
      {tourCoures.length === 0 ? (
        <div className="noFeed w-100">
          <h4>{t("My_Account.No_Wishlist")}</h4>
        </div>
      ) : (
        <div className="modal-card-wrapper">
          {tourCoures &&
            tourCoures?.map((cards, i) =>
              location.pathname !== "/profile" ? (
                <div
                  className=" modal-card b-trans position-relative tourCardContent"
                  key={i}
                >
                  <div
                    className="modal-card-content"
                    onClick={() => {
                      setTourIndex(i);
                      setTourDetails(true);
                    }}
                  >
                    <div className="imgArea">
                      <Image
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "../../../img/Image-Base.svg";
                        }}
                        src={
                          cards?.image && cards?.image[0]
                            ? cards?.image[0]
                            : "../../../img/Image-Base.svg"
                        }
                        alt="Tour Course Image"
                        className="w-100 course-image"
                      />
                    </div>
                    <div className="card-details">
                      <h3 className="place-name">{cards.name}</h3>
                      <h4 className="place-info">
                        @{cards.region} , {cards.country}{" "}
                      </h4>
                      <h5 className="place-category">{cards.category}</h5>
                      {/* <h5 className="font-16-normal color-black mt-28">{cards.always_open ? "-" : setDate(cards.opening_date, cards.closing_date)}</h5> */}
                      <h5 className="place-timeInfo">
                        {setDate(cards.opening_date, cards.closing_date)}
                      </h5>
                    </div>
                  </div>

                  <div className="checkboxes">
                    <label htmlFor={`card${i}`}>
                      <input
                        type="checkbox"
                        name="agree"
                        id={`card${i}`}
                        value="agree"
                        checked={cards.is_like}
                        onChange={(e) => {
                          Like(cards.id);
                        }}
                      />
                      <img
                        src="./img/heart.svg"
                        alt=""
                        className="wishlist-img"
                      />
                    </label>
                  </div>
                </div>
              ) : (
                i <= 4 && (
                  <div
                    className=" modal-card b-trans position-relative tourCardContent"
                    key={i}
                  >
                    <div
                      className="modal-card-content"
                      onClick={() => {
                        setTourIndex(i);
                        setTourDetails(true);
                      }}
                    >
                      <div className="imgArea">
                        <Image
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../../../img/Image-Base.svg";
                          }}
                          src={
                            cards?.image && cards?.image[0]
                              ? cards?.image[0]
                              : "../../../img/Image-Base.svg"
                          }
                          alt="Tour Course Image"
                          className="w-100 course-image"
                        />
                      </div>
                      <div className="card-details">
                        <h3 className="place-name">{cards.name}</h3>
                        <h4 className="place-info">
                          @{cards.region} , {cards.country}{" "}
                        </h4>
                        <h5 className="place-category">{cards.category}</h5>
                        {/* <h5 className="font-16-normal color-black mt-28">{cards.always_open ? "-" : setDate(cards.opening_date, cards.closing_date)}</h5> */}
                        <h5 className="place-timeInfo">
                          {setDate(cards.opening_date, cards.closing_date)}
                        </h5>
                      </div>
                    </div>

                    <div className="checkboxes">
                      <label htmlFor={`card${i}`}>
                        <input
                          type="checkbox"
                          name="agree"
                          id={`card${i}`}
                          value="agree"
                          checked={cards.is_like}
                          onChange={(e) => {
                            Like(cards.id);
                          }}
                        />
                        <img
                          src="./img/heart.svg"
                          alt=""
                          className="wishlist-img"
                        />
                      </label>
                    </div>
                  </div>
                )
              )
            )}
        </div>
      )}
      {tourIndex >= 0 && (
        <HostDetails
          tourCourse={tourCoures?.[tourIndex]}
          tourDetails={tourDetails}
          setTourDetails={setTourDetails}
        />
      )}
    </>
  );
};
export default LikedPlaces;

import STORAGEKEY from "../config/APP/app.config";
import { getCookie } from "./utils";

class AuthStorage {
  static setStorageData(key, data, keepMeLoggedIn) {
    // keepMeLoggedIn ? localStorage.setItem(key, data) : sessionStorage.setItem(key, data);
    keepMeLoggedIn
      ? this.setCookie(key, data)
      : sessionStorage.setItem(key, data);
  }

  static setStorageJsonData(key, data, keepMeLoggedIn) {
    // keepMeLoggedIn ? localStorage.setItem(key, JSON.stringify(data)) : sessionStorage.setItem(key, JSON.stringify(data));
    const encodedData = escape(JSON.stringify(data));
    keepMeLoggedIn
      ? this.setCookie(key, encodedData)
      : sessionStorage.setItem(key, encodedData);
  }

  static getStorageData(key) {
    // return localStorage.getItem(key) ? localStorage.getItem(key) : sessionStorage.getItem(key);
    return this.getCookie(key)
      ? this.getCookie(key)
      : sessionStorage.getItem(key);
  }

  static getStorageJsonData(key) {
    // const data = localStorage.getItem(key) ? localStorage.getItem(key) : sessionStorage.getItem(key);
    const data = this.getCookie(key)
      ? this.getCookie(key)
      : sessionStorage.getItem(key);
    const decodedData = unescape(data);
    return JSON.parse(decodedData);
  }

  static getToken() {
    // return localStorage.getItem(STORAGEKEY.token) ? localStorage.getItem(STORAGEKEY.token) : sessionStorage.getItem(STORAGEKEY.token);
    return this.getCookie(STORAGEKEY.token)
      ? this.getCookie(STORAGEKEY.token)
      : sessionStorage.getItem(STORAGEKEY.token);
  }

  static isUserAuthenticated() {
    // return (localStorage.getItem(STORAGEKEY.token) !== null || sessionStorage.getItem(STORAGEKEY.token) !== null);
    return (
      this.getCookie(STORAGEKEY.token) !== null ||
      sessionStorage.getItem(STORAGEKEY.token) !== null
    );
  }

  static isUserActive() {
    const userData = this.getStorageJsonData(STORAGEKEY.userData);
    return userData?.is_active
      ? this.getStorageJsonData(STORAGEKEY.userData)?.is_active
      : false;
  }

  static deauthenticateUser() {
    this.deleteKey(STORAGEKEY.token);
    this.deleteKey(STORAGEKEY.userId);
    this.deleteKey(STORAGEKEY.userName);
    this.deleteKey(STORAGEKEY.userData);
  }

  static deleteKey(key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
    this.deleteCookie(key);
  }

  static getLang() {
    return (
      localStorage.getItem(STORAGEKEY.lang) || getCookie("i18next") || "ko"
    );
  }

  static setCookie(name, value) {
    document.cookie =
      name + "=" + (value || "") + "; path=/; expires=31572500;";
  }

  static getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  static deleteCookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  static getMessageCollection() {
    return process.env.REACT_APP_ENV === "develop" ? "users_dev" : "users";
  }

  static getNotificationCollection() {
    return process.env.REACT_APP_ENV === "develop"
      ? "notification_dev"
      : "notification";
  }
}

export default AuthStorage;

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import MyWishlistFeed from "./MyWishlistFeed";
import "./MyPage.css";
import MyHostingFeed from "./MyHostingFeed";
import AppliedHostingFeed from "./AppliedHostingFeed";

function Profile() {
  // const userInfo = AuthStorage.getStorageJsonData(STORAGEKEY.userData);
  const { userData } = useSelector((state) => state.userData);

  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  //For Language Translation
  const { t } = useTranslation();

  return (
    <main id="MyPage" className="wrap">
      <h1 className="page-title">{t("My_Account.MyAccount")}</h1>
      <div className="profile-card d-flex align-items-center">
        <div className="host-img circle">
          <img
            src={userData?.avatar ? userData?.avatar : "./img/Avatar.png"}
            alt="Profile_Picture"
          />
        </div>

        <div className="EditProfileTitle">
          <h3 className="user-name"> {userData?.user_name}</h3>
          <Link className="edit-profile-link" to="/edit-profile">
            {t("My_Account.Edit_Profile")}
          </Link>
        </div>
      </div>

      <div className="my-account-feeds">
        <MyHostingFeed />
        <AppliedHostingFeed />
        <MyWishlistFeed />
      </div>
    </main>
  );
}

export default Profile;

import React, { useEffect, useRef } from "react";
import { ApiPostFile } from "../helper/API/ApiData";

export default function CKeditor({
  onChange,
  disabled,
  editorLoaded,
  name,
  value,
}) {
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("ckeditor5-custom-build/build/ckeditor"),
    };
  }, []);

  const customUploadAdapter = (loader) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then((file) => {
            data.append("file", file);
            ApiPostFile(`general/contents/fileUpload`, data)
              .then((res) => {
                resolve({ default: res.data.Location });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return customUploadAdapter(loader);
    };
  }
  return (
    <>
      {editorLoaded ? (
        <CKEditor
          type=""
          name={name}
          editor={ClassicEditor}
          config={{
            extraPlugins: [uploadPlugin],
            fontSize: {
              options: ["7px", "12px", "15px"],
            },
            image: {
              toolbar: [
                "toggleImageCaption",
                "imageStyle:inline",
                "imageStyle:wrapText",
                "imageStyle:breakText",
              ],
            },
            link: {
              addTargetToExternalLinks: true,
              decorators: [
                {
                  mode: "manual",
                  label: "Downloadable",
                  attributes: {
                    download: "download",
                  },
                },
              ],
            },
          }}
          data={value}
          disabled={disabled}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </>
  );
}

// import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const DatePickerCustomHeaderOneMonth = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}) => {
  return (
    <div className="datepickerHeader">
      <button
        aria-label="Previous Month"
        className={"previous-btn btn"}
        style={customHeaderCount === 1 ? { visibility: "hidden" } : {}}
        onClick={decreaseMonth}
        type="button"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="noFill"
            d="M13 15L7 10L13 5"
            stroke="#7E7E7E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString("en-US", {
          year: "numeric",
        })}
        .
        {monthDate.toLocaleString("en-US", {
          month: "2-digit",
        })}
      </span>
      <button
        aria-label="Next Month"
        className="next-btn btn"
        style={customHeaderCount === 0 ? { visibility: "visible" } : {}}
        type="button"
        onClick={increaseMonth}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="noFill"
            d="M7 15L13 10L7 5"
            stroke="#7E7E7E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default DatePickerCustomHeaderOneMonth;

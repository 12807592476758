import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import AuthStorage from "../../helper/AuthStorage";
import PopulatHost from "./PopulatHost";
import TourList from "./TourList";
import TredingNow from "./TredingNow";
import * as QueryString from "query-string";
import { useTranslation } from "react-i18next";
import Login from "../modal/Login";
import { ApiGet } from "../../helper/API/ApiData";
import AlreadyHostModal from "../modal/AlreadyHostModal";
import TredingNowMobile from "./mobile/TredingNowMobile";
import PopulatHostMobile from "./mobile/PopulatHostMobile";
import TourListMobile from "./mobile/TourListMobile";
import AccountDeactive from "../modal/AccountDeactive";
// import { setGlobalSearch } from "../../redux/action/persistDataAction";
import SearchBar from "../../components/SearchBar";
import "./Dashboard.css";
import { Button } from "react-bootstrap";
registerLocale("ko", ko);

function Dashboard() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [hostingNotice, setHostingNotice] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const persistData = useSelector((state) => state.persistData);
  const { is_loggedin } = useSelector((state) => state?.login);
  const { userData } = useSelector((state) => state.userData);
  const params = QueryString.parse(location.search);
  const is_active = AuthStorage.isUserActive();
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const isMobile = window.innerWidth <= 428;
  const [resize, setResize] = useState(window.innerWidth);
  const [activeIndex, setActiveIndex] = useState(0);

  // dispatch(getUserData());

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    is_loggedin === true ? isAlreadyHosting() : setCanHost(false);
    window.scrollTo(0, 0);
  }, [is_loggedin]);

  //Homepage search filter
  const Search = () => {
    let searchParam =
      "?keyword=" +
      (persistData?.globalSearch?.searchHeaderTerm || "") +
      "&startDate=" +
      (persistData?.globalSearch?.startHeaderDate || "") +
      "&endDate=" +
      (persistData?.globalSearch?.endHeaderDate || "");
    history.push({
      pathname: "/",
      search: searchParam,
    });
  };

  const ViewHost = () => {
    is_loggedin ? history.push("/viewhost") : setModalShow(true);
  };

  const hostown = () => {
    is_loggedin ? history.push("/hostitinerary") : setModalShow(true);
  };

  const LoginModalClick = () => {
    setModalShow(true);
  };

  const dataGet = (data) => {
    setModalShow(data);
  };

  const dataGets = () => {
    setModalShow(true);
  };

  const onDatePickerClick = (id) => {
    document.getElementById(id)?.click();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      Search();
      event.preventDefault();
    }
  };

  const [activeTab, setActiveTab] = useState("All");

  const bgImage = [
    "img/bg01.png",
    "img/bg02.png",
    "img/bg03.png",
    "img/bg04.png",
  ];

  const heroTitle = [
    {
      lineone: t("Homepage.Hero.Title1"),
      lineTwo: t("Homepage.Hero.Title2"),
    },
    {
      lineone: t("Homepage.Hero.Title11"),
      lineTwo: t("Homepage.Hero.Title22"),
    },
    {
      lineone: t("Homepage.Hero.Title111"),
      lineTwo: t("Homepage.Hero.Title222"),
    },
    {
      lineone: t("Homepage.Hero.Title1111"),
      lineTwo: t("Homepage.Hero.Title2222"),
    },
  ];
  const viewKeyword = searchTerm?.length;
  const [canHost, setCanHost] = useState(false);
  const isAlreadyHosting = () => {
    ApiGet("hosting/isAlreadyHosted").then((res) => {
      setCanHost(res?.data?.status);
    });
  };
  const [randomHero, setrandomHero] = useState();
  const [randomItem, setrandomItem] = useState();
  const [bannerText, setBannerText] = useState();
  const [bannerImage, setBannerImage] = useState();
  useEffect(() => {
    is_loggedin && isAlreadyHosting();
    setrandomHero(heroTitle[Math.floor(Math.random() * heroTitle.length)]);
    setrandomItem(bgImage[Math.floor(Math.random() * bgImage.length)]);

    ApiGet("general/main-banner/get/text").then((res) => {
      const rtnRes = res.data;
      if (rtnRes.length != 0) {
        const replaceText = rtnRes[0].message.replace("\n", "<br>");
        setBannerText(replaceText);
      }
    });

    ApiGet("general/main-banner/get/image").then((res) => {
      const rtnRes = res.data;
      if (rtnRes.length != 0) {
        setBannerImage(rtnRes[0].image_url);
      }
    });
  }, []);
  return (
    <main id="Dashboard">
      <div
        className="main-banner"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="wrap">
          <div className="main-banner-contents position-relativ">
            <h2
              className="text-center"
              dangerouslySetInnerHTML={{ __html: bannerText }}
            ></h2>
            <div className="position-relative">
              <SearchBar
                setActiveIndex={setActiveIndex}
                setActiveTab={setActiveTab}
              />
              <div className="mobileBtn ">
                <Button
                  className="view-host-btn"
                  variant="transparent"
                  onClick={() => {
                    ViewHost();
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 9C10.8243 9 11.538 8.70436 12.1212 8.12107C12.7044 7.53787 13 6.82429 13 5.99991C13 5.17581 12.7044 4.46213 12.1211 3.87874C11.5378 3.29564 10.8242 3 10 3C9.17564 3 8.46208 3.29564 7.8789 3.87884C7.29572 4.46204 7 5.17571 7 5.99991C7 6.82429 7.29572 7.53796 7.879 8.12116C8.46227 8.70427 9.17593 9 10 9Z"
                      fill="white"
                    />
                    <path
                      d="M15.9709 13.5143C15.9515 13.2127 15.9122 12.8838 15.8544 12.5365C15.796 12.1865 15.7208 11.8557 15.6308 11.5534C15.5379 11.2408 15.4115 10.9322 15.2552 10.6365C15.093 10.3295 14.9025 10.0622 14.6888 9.84226C14.4652 9.61216 14.1916 9.42715 13.8751 9.29221C13.5597 9.15797 13.2102 9.08996 12.8363 9.08996C12.6895 9.08996 12.5475 9.15478 12.2733 9.34687C12.1045 9.46529 11.9071 9.60224 11.6867 9.75372C11.4983 9.88288 11.2431 10.0039 10.9278 10.1135C10.6202 10.2205 10.3079 10.2748 9.99967 10.2748C9.69142 10.2748 9.37923 10.2205 9.07131 10.1135C8.75637 10.004 8.50112 9.883 8.31293 9.75383C8.09467 9.60378 7.89714 9.46682 7.72584 9.34675C7.45195 9.15466 7.30984 9.08984 7.16301 9.08984C6.78903 9.08984 6.43964 9.15797 6.12437 9.29232C5.80811 9.42703 5.53432 9.61204 5.31057 9.84238C5.09691 10.0625 4.9063 10.3296 4.74433 10.6365C4.58818 10.9322 4.46177 11.2407 4.36871 11.5535C4.27884 11.8558 4.20367 12.1865 4.14529 12.5365C4.08746 12.8834 4.04817 13.2124 4.02875 13.5146C4.00966 13.8107 4 14.1181 4 14.4285C4 15.2362 4.23867 15.8902 4.70933 16.3725C5.17417 16.8484 5.78923 17.0898 6.53719 17.0898H13.4628C14.2108 17.0898 14.8256 16.8485 15.2906 16.3725C15.7613 15.8906 16 15.2365 16 14.4283C15.9999 14.1165 15.9901 13.809 15.9709 13.5143Z"
                      fill="white"
                    />
                  </svg>

                  <span> {t("Homepage.Hero.View_Hosts")} </span>
                </Button>
                {is_loggedin ? (
                  <button
                    className="host-my-own"
                    onClick={() => {
                      if (is_active) {
                        !canHost ? setHostingNotice(true) : hostown();
                      } else {
                        setDeactivatedNotice(true);
                      }
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3.45742"
                        y="5.24258"
                        width="13.0857"
                        height="11.3"
                        rx="2.25714"
                        stroke="white"
                        stroke-width="1.2"
                      />
                      <path
                        d="M6.28589 3.45645C6.28589 3.12507 6.55452 2.85645 6.88589 2.85645C7.21726 2.85645 7.48589 3.12507 7.48589 3.45645V5.08859H6.28589V3.45645Z"
                        fill="white"
                      />
                      <path
                        d="M7.50024 10.9926C7.50024 10.6612 7.76887 10.3926 8.10024 10.3926H11.9002C12.2316 10.3926 12.5002 10.6612 12.5002 10.9926C12.5002 11.3239 12.2316 11.5926 11.9002 11.5926H8.10024C7.76887 11.5926 7.50024 11.3239 7.50024 10.9926Z"
                        fill="white"
                      />
                      <path
                        d="M10.0646 13.4287C9.73323 13.4287 9.4646 13.1601 9.4646 12.8287L9.4646 9.02871C9.4646 8.69734 9.73323 8.42871 10.0646 8.42871C10.396 8.42871 10.6646 8.69734 10.6646 9.02871L10.6646 12.8287C10.6646 13.1601 10.396 13.4287 10.0646 13.4287Z"
                        fill="white"
                      />
                      <path
                        d="M12.3218 3.45645C12.3218 3.12507 12.5904 2.85645 12.9218 2.85645C13.2531 2.85645 13.5218 3.12507 13.5218 3.45645V5.08859H12.3218V3.45645Z"
                        fill="white"
                      />
                    </svg>

                    <span> {t("Homepage.Hero.Host_MyOwn")}</span>
                  </button>
                ) : (
                  <button
                    className="host-my-own"
                    onClick={() => setModalShow(true)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3.45742"
                        y="5.24258"
                        width="13.0857"
                        height="11.3"
                        rx="2.25714"
                        stroke="white"
                        stroke-width="1.2"
                      />
                      <path
                        d="M6.28589 3.45645C6.28589 3.12507 6.55452 2.85645 6.88589 2.85645C7.21726 2.85645 7.48589 3.12507 7.48589 3.45645V5.08859H6.28589V3.45645Z"
                        fill="white"
                      />
                      <path
                        d="M7.50024 10.9926C7.50024 10.6612 7.76887 10.3926 8.10024 10.3926H11.9002C12.2316 10.3926 12.5002 10.6612 12.5002 10.9926C12.5002 11.3239 12.2316 11.5926 11.9002 11.5926H8.10024C7.76887 11.5926 7.50024 11.3239 7.50024 10.9926Z"
                        fill="white"
                      />
                      <path
                        d="M10.0646 13.4287C9.73323 13.4287 9.4646 13.1601 9.4646 12.8287L9.4646 9.02871C9.4646 8.69734 9.73323 8.42871 10.0646 8.42871C10.396 8.42871 10.6646 8.69734 10.6646 9.02871L10.6646 12.8287C10.6646 13.1601 10.396 13.4287 10.0646 13.4287Z"
                        fill="white"
                      />
                      <path
                        d="M12.3218 3.45645C12.3218 3.12507 12.5904 2.85645 12.9218 2.85645C13.2531 2.85645 13.5218 3.12507 13.5218 3.45645V5.08859H12.3218V3.45645Z"
                        fill="white"
                      />
                    </svg>

                    <span> {t("Homepage.Hero.Host_MyOwn")}</span>
                  </button>
                )}
              </div>
              {is_loggedin ? (
                <button
                  className="host-my-own pcBtn"
                  onClick={() => {
                    if (is_active) {
                      !canHost ? setHostingNotice(true) : hostown();
                    } else {
                      setDeactivatedNotice(true);
                    }
                  }}
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="4.75"
                      y="7.25"
                      width="18.5"
                      height="16"
                      rx="3.25"
                      stroke="white"
                      stroke-width="1.5"
                    />
                    <path
                      d="M8.80005 4.75C8.80005 4.33579 9.13584 4 9.55005 4C9.96426 4 10.3 4.33579 10.3 4.75V7.125H8.80005V4.75Z"
                      fill="white"
                    />
                    <path
                      d="M10.5 15.3008C10.5 14.8866 10.8358 14.5508 11.25 14.5508H16.75C17.1642 14.5508 17.5 14.8866 17.5 15.3008C17.5 15.715 17.1642 16.0508 16.75 16.0508H11.25C10.8358 16.0508 10.5 15.715 10.5 15.3008Z"
                      fill="white"
                    />
                    <path
                      d="M14 18.8008C13.5858 18.8008 13.25 18.465 13.25 18.0508L13.25 12.5508C13.25 12.1366 13.5858 11.8008 14 11.8008C14.4142 11.8008 14.75 12.1366 14.75 12.5508L14.75 18.0508C14.75 18.465 14.4142 18.8008 14 18.8008Z"
                      fill="white"
                    />
                    <path
                      d="M17.25 4.75C17.25 4.33579 17.5858 4 18 4C18.4142 4 18.75 4.33579 18.75 4.75V7.125H17.25V4.75Z"
                      fill="white"
                    />
                  </svg>

                  <span> {t("Homepage.Hero.Host_MyOwn")}</span>
                </button>
              ) : (
                <button
                  className="host-my-own pcBtn"
                  onClick={() => setModalShow(true)}
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="4.75"
                      y="7.25"
                      width="18.5"
                      height="16"
                      rx="3.25"
                      stroke="white"
                      stroke-width="1.5"
                    />
                    <path
                      d="M8.80005 4.75C8.80005 4.33579 9.13584 4 9.55005 4C9.96426 4 10.3 4.33579 10.3 4.75V7.125H8.80005V4.75Z"
                      fill="white"
                    />
                    <path
                      d="M10.5 15.3008C10.5 14.8866 10.8358 14.5508 11.25 14.5508H16.75C17.1642 14.5508 17.5 14.8866 17.5 15.3008C17.5 15.715 17.1642 16.0508 16.75 16.0508H11.25C10.8358 16.0508 10.5 15.715 10.5 15.3008Z"
                      fill="white"
                    />
                    <path
                      d="M14 18.8008C13.5858 18.8008 13.25 18.465 13.25 18.0508L13.25 12.5508C13.25 12.1366 13.5858 11.8008 14 11.8008C14.4142 11.8008 14.75 12.1366 14.75 12.5508L14.75 18.0508C14.75 18.465 14.4142 18.8008 14 18.8008Z"
                      fill="white"
                    />
                    <path
                      d="M17.25 4.75C17.25 4.33579 17.5858 4 18 4C18.4142 4 18.75 4.33579 18.75 4.75V7.125H17.25V4.75Z"
                      fill="white"
                    />
                  </svg>
                  <span> {t("Homepage.Hero.Host_MyOwn")}</span>
                </button>
              )}
              <div
                className="12px"
                style={{
                  width: "12px",
                  height: "10px",
                  backgroundColor: "black",
                  position: "absolute",
                  top: "50%",
                  left: "calc(50% + 380px)",
                  transform: "translate(-50%,-50%)",
                  display: "none",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <section className="main-contents">
        <div className={"wrap d-flex justify-content-between layout"}>
          <div className="main-left">
            {resize <= 767 ? (
              <TredingNowMobile
                setActiveIndex={setActiveIndex}
                setActiveTab={setActiveTab}
              />
            ) : (
              <TredingNow
                setActiveIndex={setActiveIndex}
                setActiveTab={setActiveTab}
              />
            )}
            {resize <= 767 ? (
              <PopulatHostMobile ViewHost={ViewHost} />
            ) : (
              <PopulatHost ViewHost={ViewHost} />
            )}
          </div>
          <div className="main-right">
            {resize <= 767 ? (
              <TourListMobile
                LoginModalClick={LoginModalClick}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            ) : (
              <TourList
                LoginModalClick={LoginModalClick}
                setActiveIndex={setActiveIndex}
                activeIndex={activeIndex}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
            )}
          </div>
        </div>
      </section>
      <Login
        show={modalShow}
        loginValue={""}
        onHide={() => setModalShow(false)}
        onHideNew={dataGet}
        onShow={dataGets}
      />
      <AlreadyHostModal
        hostingNotice={hostingNotice}
        setHostingNotice={() => setHostingNotice(false)}
      />
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </main>
  );
}

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ApiGet } from "../../../helper/API/ApiData";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalSearch } from "redux/action/persistDataAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const initialData = {
  count: 0,
  itinerary: [],
};

const TredingNowMobile = ({ setActiveIndex, setActiveTab }) => {
  const [perPage, setPerPage] = useState(5);
  const [pageNo, setPageNo] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const persistData = useSelector((state) => state.persistData);
  const [tnList, setTNList] = useState([]);

  const getData = () => {
    ApiGet("general/trendingnow/get/list?code=all").then((res) => {
      setTNList(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, [pageNo]);

  return (
    <div className="trending-now-section">
      <h4 className="heading"> {t("Homepage.Popular_Destination")}</h4>
      <div className="container">
        {tnList.map((items, i) => (
          <div
            key={i}
            className="single-trending-row d-flex align-items-center"
          >
            <h4 className="trending-number">{i + 1}</h4>
            <h5
              className="trending-pro-content"
              onClick={(e) => {
                setActiveIndex(i + 5);
                dispatch(
                  setGlobalSearch({
                    field: "searchHeaderTerm",
                    value: items.message,
                  })
                );
                setActiveTab("All");
                dispatch(
                  setGlobalSearch({
                    field: "searchHeaderTerm",
                    value: items.message,
                  })
                );
                let searchParam =
                  "?keyword=" +
                  (items.message || "") +
                  "&startDate=" +
                  (persistData?.globalSearch?.startHeaderDate || "") +
                  "&endDate=" +
                  (persistData?.globalSearch?.endHeaderDate || "");
                history.push({
                  pathname: "/",
                  search: searchParam,
                });
              }}
            >
              {items.message}
              {/* <div className="trending-pro-content">
                <h6>
                  {items.region}, {items.country}
                </h6>
              </div> */}
            </h5>
          </div>
        ))}
      </div>
      <div className="res-more-viewhost">
        {tnList.count > perPage && (
          <button
            onClick={() => {
              setPageNo(pageNo + 1);
            }}
          >
            More
          </button>
        )}
      </div>
    </div>
  );
};

export default TredingNowMobile;

import React from "react";

const PrivacyUsecard_Ko = () => {
  return (
    <>
      <h1>1. 개인정보의 처리목적</h1>
      <p>
        컴파스인터렉티브㈜는 다음의 목적을 위하여 개인정보를 처리합니다.
        처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,
        이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의
        동의를 받는 등 필요한 조치를 이행할 예정입니다.
        <br />
        가. 회원 가입 및 관리
        <br />
        회원가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격
        유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 등.
        <br />
        나. 서비스 개발 및 마케팅·광고에의 활용
        <br />
        컴파스트립스 서비스 이용에 관한 통계 분석, 호스팅 서비스의 검토, 서비스
        개선에 필요한 설문 조사 및 분석, 서비스의 유효성 확인, 이벤트 및 광고성
        정보 제공 및 참여기회 제공, 접속 빈도 파악 또는 회원의 서비스 이용에
        대한 통계.
        <br />
        다. 중개 서비스 제공에 따른 요금정산
        <br />
        여행상품 예약, 컨텐츠 제공, 구매 성향 분석, 구매 및 요금 결제, 물품배송
        또는 청구지 등 발송, 금융거래 본인 인증 및 금융서비스, 요금추심 등.
        <br />
      </p>
      <h1>2. 개인정보의 처리 및 보유기간</h1>
      <p>
        가. 컴파스인터렉티브(주)는 법령에 따른 개인정보 보유·이용기간 또는
        정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간
        내에서 개인정보를 처리·보유합니다.
        <br />
        나. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
        <br />
        1) 홈페이지 회원 가입 및 관리: 사업자/단체 홈페이지 탈퇴 시까지. 다만,
        다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
        <br />
        가) 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당
        수사·조사 종료 시까지
        <br />
        나) 홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계
        정산 시까지
        <br />
        2) 「위치정보의 보호 및 이용 등에 관한 법률」에 따른 위치정보에 관한
        기록: 6개월.
        <br />
        3) 재화 또는 서비스 제공: 재화·서비스 공급완료 및 요금결제·정산
        완료시까지.
        <br />
        다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
        <br />
        가) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고,
        계약내용 및 이행 등 거래에 관한 기록
        <br />
        -표시·광고에 관한 기록: 6개월.
        <br />
        -계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년.
        <br />
        -소비자 불만 또는 분쟁처리에 관한 기록: 3년.
        <br />
        나) 「통신비밀보호법」에 따른 통신사실확인자료 보관.
        <br />
        -서비스 웹사이트 방문 기록: 3개월.
      </p>
      <h1>3. 처리하는 개인정보 항목</h1>
      <p>
        컴파스인터렉티브(주)는 다음의 개인정보 항목을 처리하고 있습니다.
        <br />
        가. 회원 가입 및 관리
        <br />
        [계정]
        <br />
        ◎필수항목: 성명, 닉네임, 이메일주소. 비밀번호, 국적, 전화번호, 생년월일,
        성별
        <br />
        ◎선택항목: 없음
        <br />
        [SNS 회원 가입시]
        <br />
        없음
        <br />
        [위치기반 서비스 제공]
        <br />
        개인 위치정보
        <br />
        [본인인증 시]
        <br />
        이름, 성별, 생년월일, 휴대폰번호, 통신사업자, 내/외국인 여부. 암호화된
        이용자 확인값(CI), 중복가입확인정보(DI)
        <br />
        [법정대리인 동의 시]
        <br />
        법정대리인 정보(이름, 성별, 생년월일, 휴대폰번호, 통신사업자, 내/외국인
        여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI))
        <br />
        [불법/부정 이용 방지 및 서비스 분석]
        <br />
        자동생성정보·기기정보(기기종류, OS버전), 이용기록(IP주소, 쿠키, 서비스
        이용기록 등)
        <br />
        나. 유료서비스
        <br />
        ·신용카드 결제 시: 카드번호(일부), 카드사명 등<br />
        ·휴대전화번호 결제 시: 휴대전화번호, 결제승인번호 등<br />
        ·계좌이체 시: 예금주명, 계좌번호, 계좌은행 등<br />
        ·상품권 이용 시: 상품권 번호, 해당 사이트 아이디
        <br />
        ·환불처리 시: 계좌은행, 계좌번호, 예금주명, 이메일
        <br />
        ·현금영수증 발행 시: 휴대폰번호, 현금영수증 카드번호
        <br />
        다. 고객센터 문의
        <br />
        이메일, 전화번호, 예약번호
        <br />
        라. 부가 서비스, 맞춤식 서비스 이용 또는 이벤트 응모
        <br />
        성명, 휴대폰번호, 메신저 ID, 주소, 개인 웹사이트/블로그 주소, SNS 연동
        정보(페이스북, 구글+)등
      </p>
      <h1>4. 개인정보의 제3자 제공</h1>
      <p>
        가. 컴파스인터렉티브(주)는 정보주체의 개인정보를 개인정보의 처리
        목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한
        규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만
        개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를
        제3자에게 제공하지 않습니다.
        <br />
        나. 컴파스트립스 서비스 이용 도중 프로필 정보는 다른 이용자와 공유될 수
        있지만, 다른 정보는 본인의 동의를 구하지 않는 한 제3자에게 공개되거나
        제공되지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        <br />
        ·이용자들이 사전에 동의한 경우
        <br />
        ·법령의 규정에 의한 경우
        <br />
        ·서비스 제공에 따른 요금정산을 위해 필요한 경우
        <br />
        ·수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는
        경우
        <br />
        ·법원명령이나 법적인 문제에 관한 경우: 소송 방어를 위해 또는 달리 법에
        정한 바와 같이 소환장, 법원명령이나 기타 소송에 응하거나 법적 권리를
        정립하거나 행사하기 위한 경우에 귀하의 정보를 제공할 수 있습니다. 이
        경우 법적인 이의제기나 권리행사를 회사가 행할 수도, 포기할 수도
        있습니다.
        <br />
        ·기업분할, 합병, 병합이나 자산매매와 같은 회사의 거래 또는 파산의 경우
        <br />
        ·회사의 판단 하에 불법행위 혹은 불법일 우려가 있는 행위에 관하여 조사,
        금지 혹은 조치를 하는데 필요하다고 생각되는 경우: 회원이나 타인의 권리,
        재산이나 안전을 보호하고 방어하는데 필요하거나, 회사 규정 혹은 기타
        계약에 합치된다고 생각되는 경우에 귀하의 정보를 제공할 수 있습니다.
        <br />
        다. 컴파스인터렉티브(주)는 추후 원활한 서비스 제공을 위해 다음의 경우
        정보주체의 동의를 얻어 필요 최소한의 범위로만 제공할 수있습니다.
        <br />
        제공받는 자<br />
        제공목적
        <br />
        제공항목
        <br />
        보유 및 이용기간
      </p>
      <h1>5. 개인정보 처리의 위탁</h1>
      <p>
        가. 컴파스인터렉티브(주)는 추후 원활한 개인정보 업무처리를 위하여 다음과
        같이 개인정보 처리업무를 위탁할 수 있습니다.
        <br />
        위탁받는 자(수탁자)
        <br />
        위탁업무
        <br />
        보유 및 이용기간
        <br />
        나. 컴파스인터렉티브(주)는 위탁계약 체결 시 「개인정보 보호법」 제26조에
        따라 위탁업무 수행목적외 개인정보 처리금지, 기술적·관리적 보호조치,
        재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을
        계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
        감독하고 있습니다.
        <br />
        다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
        처리방침을 통하여 공개하도록 하겠습니다.
      </p>
      <h1>6. 개인정보의 파기 절차 및 방법</h1>
      <p>
        가. 컴파스인터렉티브(주)는 개인정보 보유기간의 경과, 처리목적 달성 등
        개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        <br />
        나. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
        달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는
        경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
        달리하여 보존합니다.
        <br />
        다. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        <br />
        1) 파기절차
        <br />
        이용자가 서비스 이용 등을 위해 입력한 정보는 목적이 달성된 후, 내부 방침
        및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라
        일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가
        아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
        <br />
        2) 파기방법
        <br />
        컴파스인터렉티브(주)는 전자적 파일 형태로 기록·저장된 개인정보는 기록을
        재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
        분쇄하거나 소각하여 파기합니다.
      </p>
      <h1>7. 미이용자의 개인정보 파기 등에 관한 조치</h1>
      <p>
        가. 컴파스인터렉티브(주)는 1년간 서비스를 이용하지 않은 이용자의 정보를
        파기하고 있습니다. 다만, 다른 법령에서 정한 보존기간이 경과할 때까지
        다른 이용자의 개인정보와 분리하여 별도로 저장·관리할 수 있습니다.
        <br />
        나. 컴파스인터렉티브(주)는 개인정보의 파기 30일 전까지 개인정보가
        파기되는 사실, 기간 만료일 및 파기되는 개인정보의 항목을 이메일, 문자 등
        이용자에게 통지 가능한 방법으로 알리고 있습니다.
        <br />
        다. 개인정보의 파기를 원하지 않으시는 경우, 기간 만료 전 서비스 로그인을
        하시면 됩니다.
      </p>
      <h1>8. 미용자의 개인정보 파기 등에 관한 조치</h1>
      <p>
        가. 컴파스인터렉티브(주)는 1년간 서비스를 이용하지 않은 이용자는
        휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리
        보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.
        <br />
        나. 컴파스인터렉티브(주)는 휴면전환 30일 전까지 휴먼예정 회원에게 별도
        분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을
        이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
        <br />
        다. 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스
        로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는
        경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할
        수 있습니다.
      </p>
      <h1>9. 정보주체와 법정대리인의 권리·의무 및 행사방법</h1>
      <p>
        가. 정보주체는 컴파스인터렉티브(주)에 대해 언제든지 개인정보
        열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
        <br />
        ※만 14세 미만 아동에 관한 개인정보의 열람등 요구는 법정대리인이 직접
        해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에
        관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를
        행사할 수도 있습니다.
        <br />
        나. 권리 행사는 컴파스인터렉티브(주)에 대해 「개인정보 보호법」 시행령
        제41조 제1항에 따라 서면, 전자우편모사전송(FAX) 등을 통하여 하실 수
        있으며, 컴파스인터렉티브(주)는 이에 대해 지체없이 조치하겠습니다
        <br />
        다. 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
        통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한
        고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        <br />
        라. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
        제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
        <br />
        마. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
        대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        <br />
        바. 컴파스인터렉티브(주)는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
        요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
        대리인인지를 확인합니다.
      </p>
      <h1>10. 개인정보의 안전성 확보조치 </h1>
      <p>
        컴파스인터렉티브(주)는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
        취하고 있습니다.
        <br />
        가. 관리적 조치: 내부관리계획 수립 및 시행, 전담조직 운영, 정기적 직원
        교육
        <br />
        나. 기술적 조치: 개인정보처리 시스템 등의 접근권한 관리, 접근통제시스템
        설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
        <br />
        다. 물리적 조치: 전산실, 자료보관실 등의 접근 통제
      </p>
      <h1>11. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</h1>
      <p>
        가. 컴파스인터렉티브(주)는 이용자에게 개별적인 맞춤서비스를 제공하기
        위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
        <br />
        나. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
        브라우저에게 보내는 소량의 정보이며 이용자들의 단말기(PC, 스마트폰,
        태블릿 PC 등)의 하드디스크에 저장되며, 웹브라우저의 종료 시나
        로그아웃(Logout)시 만료됩니다.
        <br />
        1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문
        및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게
        최적화된 정보 제공을 위해 사용됩니다.
        <br />
        2) 쿠키의 설치·운영 및 거부: 웹브라우저 상단의 도구>인터넷 옵션>개인정보
        메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.
        <br />
        3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
        있습니다.
      </p>
      <h1>12. 행태정보의 수집·이용 및 거부 등에 관한 사항</h1>
      <p>
        가. 컴파스인터렉티브(주)는 서비스 이용과정에서 정보주체에게 최적화된
        맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여
        행태정보를 수집·이용하고 있습니다.
        <br />
        나. 컴파스인터렉티브(주)는 다음과 같이 행태정보를 수집합니다.
        <br />
        항목
        <br />
        행태정보 수집 방법
        <br />
        행태정보 수집 목적
        <br />
        보유·이용기간 및 이후 정보처리 방법
        <br />
        이용자의 웹사이트/앱 서비스 방문이력, 검색이력, 구매이
        <br />력<br />
        이용자의 웹 사이트 및 앱 방문/실행시 자동 수집
        <br />
        이용자의 관심, 성향에 기반한 개인 맞춤형 상품추천서비스(광고포함)를 제공
        <br />
        수집일로부터 2년 후 파기
        <br />
        제공받는 자<br />
        제공 항목
        <br />
        제공받는 자의 이용목적
        <br />
        이용자의 웹사이트 방문이력, 이용자의 앱 사용 이력, 이용자의 검색 이력,
        광고 식별자
        <br />
        사용자 관심 기반의 맞춤형 광고제공
      </p>
      <h1>13. 추가적인 이용·제공 판단기준</h1>
      <p>
        가. 컴파스인터렉티브(주)는 「개인정보 보호법」 제15조 제3항 및 제17조
        제4항에 따라 「개인정보보호법」 시행령 제14조의2에 따른 사항을 고려하여
        정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.
        <br />
        항목
        <br />
        이용·제공 목적 보유 및 이용기간
        <br />
        이름, 연락처, 주소
        <br />
        정기적으로 제공하는 서비스 관련 추가 안내물 발송
        <br />
        목적 달성 시 즉시 파기
        <br />
        나. 이에 따라 컴파스인터렉티브(주)는 정보주체의 동의 없이 추가적인
        이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
        <br />
        ◎개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이
        있는지 여부.
        <br />
        ◎개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인
        이용·제공에 대한 예측 가능성이 있는지 여부.
        <br />
        ◎개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지
        여부.
        <br />
        ◎가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부.
      </p>
      <h1>14. 가명정보의 처리</h1>
      <p>
        컴파스인터렉티브(주)는 통계작성, 과학적 연구, 공익적 기록보존 등을
        위하여 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명처리하여
        다음과 같이 처리할 수 있습니다.
        <br />
        -가명정보의 처리에 관한 사항
        <br />
        구분
        <br />
        처리목적
        <br />
        처리항목
        <br />
        보유 및 이용기간
        <br />
        -가명정보의 제3자 제공에 관한 사항(해당시)
        <br />
        제공받는 자<br />
        제공받는 자의 이용 목적
        <br />
        제공하는 항목
        <br />
        제공받는 자의 보유 및 이용기간
        <br />
        -가명정보 처리의 위탁에 관한 사항(해당시)
        <br />
        위탁받는 자(수탁자)
        <br />
        위탁업무
        <br />
        보유 및 이용기간
        <br />
        -법 제28조의4(가명정보에 대한 안전조치 의무 등)에 따른 가명정보의 안전성
        확보조치에 관한 사항
        <br />
        ◎관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육.
        <br />
        ◎기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
        설치, 고유식별정보 등의 암호화, 보안프로그램 설치.
        <br />
        ◎물리적 조치: 전산실, 자료보관실 등의 접근통제.
      </p>
      <h1>15. 개인정보 보호책임자 </h1>
      <p>
        가. 컴파스인터렉티브(주)는 개인정보 처리에 관한 업무를 총괄해서
        책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을
        위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        <br />
        ◎개인정보 보호책임자
        <br />
        성명:문성준
        <br />
        직책:대표이사
        <br />
        연락처:전화번호:02-511-8285,
        <br />
        이메일 compastrips@compastrips.com,팩스번호 02-511-8286
        <br />
        ※개인정보보호 담당부서로 연결됩니다.
        <br />
        ◎개인정보보호 담당부서
        <br />
        부서명:관리팀
        <br />
        담당자:문성준
        <br />
        연락처:전화번호:02-511-8285,
        <br />
        이메일 compastrips@compastrips.com,팩스번호 02-511-8286
        <br />
        나. 정보주체는 컴파스인터렉티브(주)의 서비스(또는 사업)을 이용하시면서
        발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
        개인정보 보호책임자 및 담당부서로 문의할 수 있습니다.
        컴파스인터렉티브(주)는 정보주체의 문의에 대해 지체없이 답변 및
        처리해드릴 것입니다.
      </p>
      <h1>16. 개인정보 열람청구</h1>
      <p>
        정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를
        아래의 부서에 할 수 있습니다. 컴파스인터렉티브(주)는 정보주체의 개인정보
        열람청구가 신속하게 처리되도록 노력하겠습니다.
        <br />
        ◎개인정보 열람청구 접수·처리 부서
        <br />
        부서명:관리팀
        <br />
        담당자:문성준
        <br />
        연락처:전화번호:02-511-8285,
        <br />
        이메일 compastrips@compastrips.com,팩스번호 02-511-8286
      </p>
      <h1>17. 권익침해 구제방법 </h1>
      <p>
        가. 정보주체는 개인정보침해로 인한 구제를 받기 위하여
        개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
        분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
        신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        <br />
        1) 개인정보분쟁조정위원회:(국번없이) 1833-6972 (www.kopico.go.kr)
        <br />
        2) 개인정보침해신고센터:(국번없이) 118 (privacy.kisa.or.kr)
        <br />
        3) 대검찰청:(국번없이) 1301 (www.spo.go.kr)
        <br />
        4) 경찰청:(국번없이) 182 (ecrm.cyber.go.kr)
        <br />
        나. 컴파스인터렉티브(주)는 정보주체의 개인정보자기결정권을 보장하고,
        개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나
        상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.
        <br />
        ◎개인정보보호 관련 고객 상담 및 신고
        <br />
        부서명:관리팀
        <br />
        담당자:문성준
        <br />
        연락처:전화번호:02-511-8285,
        <br />
        이메일 compastrips@compastrips.com,팩스번호 02-511-8286
        <br />
        다.「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의
        정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여
        공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를
        받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
        <br />
        -중앙행정심판위원회:(국번없이) 110 (www.simpan.go.kr)
      </p>
      <h1>18. 개인정보 처리방침의 변경</h1>
      <p>
        가. 이 개인정보 처리방침은 2023.2 .1. 부터 적용됩니다.
        <br />
        나. 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다
      </p>
      <h1>19. 적용 범위 </h1>
      <p>
        본 처리방침에 대한 책임 및 권한은 컴파스인터렉티브(주)에 있으며, 한국어
        버전의 개인정보취급방침은 컴파스트립스를 이용하는 한국 내 서비스
        이용자에게만 적용됩니다.
        <br />
        또한 링크 페이지 등 다른 사업자에 의한 개인정보 수집은 본
        개인정보처리방침의 적용 범위가 아닙니다.
      </p>
    </>
  );
};

export default PrivacyUsecard_Ko;

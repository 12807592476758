import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { ApiPostNoAuth } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import "./Login.css";

const ForgotPass = ({
  onHideForgot,
  showForgot,
  // setForgotModal
}) => {
  const { t } = useTranslation();

  //Reset Password ---------
  const [resetpassError, setResetpassError] = useState("");
  const [resetPassEmail, setResetPassEmail] = useState("");
  const [isResetPassSubmited, setIsResetPassSubmited] = useState(false);
  const [noUserFound, setNoUserFound] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);

  const resetPassValidation = () => {
    let resetpassError = "";

    if (resetPassEmail === "") {
      resetpassError = `${t("Reset_Password.Errors.Email")}`;
    }
    setResetpassError(resetpassError);
    setNoUserFound("");

    if (!resetpassError) {
      return true;
    }
    return false;
  };

  const ResetPassword = () => {
    setIsResetPassSubmited(true);
    if (!resetPassValidation()) {
      return;
    }

    ApiPostNoAuth("user/sendForgotlink", {
      email: resetPassEmail,
    })
      .then((res) => {
        setSuccessMsg(true);
        setIsResetPassSubmited(false);
      })
      .catch((error) => {
        setSuccessMsg(false);
        setIsResetPassSubmited(false);
        setNoUserFound(`${t("Reset_Password.Errors.User_Not_Found")}`);
      });
    // ApiPostNoAuth("user/sendForgotlink", {
    //     email: resetPassEmail,
    // })
    //     .then((res: any) => {
    //         setSuccessMsg(true);
    //         // if(res.message == "Link sent successfully."){
    //         // setResetpassError("");
    //         // setSuccessMsg(false)
    //         // // setResetPassEmail("");
    //         // }
    //     })
    //     .catch((error) => {
    //         setSuccessMsg(false);
    //         if (error == "Email Link Not Send") {
    //             if (AuthStorage.getLang() == "en") {
    //                 setNoUserFound(error)
    //             } else {
    //                 setNoUserFound("비밀번호 재설정 이메일이 발송되지 않았습니다.")
    //             }

    //         } else {
    //             if (AuthStorage.getLang() == "en") {
    //                 setNoUserFound("User Not Found")
    //             } else {
    //                 setNoUserFound("사용자를 찾을 수 없음")
    //             }

    //         }

    //     });
  };

  useEffect(() => {
    setResetpassError("");
    setSuccessMsg(false);
    setResetPassEmail("");
  }, [showForgot]);

  useEffect(() => {
    //Reset Password
    if (isResetPassSubmited) {
      resetPassValidation();
    }
  }, [resetPassEmail]);

  const emailRegExp = (e) => {
    const confirmEmail = new RegExp(
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
    );
    if (!confirmEmail.test(e)) {
      setNoUserFound(`${t("logIn.Errors.EmailForm")}`);
    } else {
      setNoUserFound("");
    }
  };

  return (
    <Modal
      show={showForgot}
      // onHide={() => {
      //     setSuccessMsg(false);
      //     setIsResetPassSubmited(false)
      //     setResetpassError("");
      //     // setForgotModal(false)
      //     setResetPassEmail("");
      // }}
      centered
      className="forgot-modal"
      dialogClassName={
        AuthStorage.getLang() === "en"
          ? "roboto-text  custom-modal position-relative"
          : "noto-text custom-modal position-relative"
      }
    >
      <Modal.Header className="d-flex position-relative">
        <Modal.Title className="w-100 position-relative">
          <Button
            variant="transparentBtn"
            className="mobileClose"
            onClick={onHideForgot}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6.49951L8 13.9995L18 21.4995"
                stroke="#1B1C1E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
          <h5 className="custom-modal-title">
            {t("Reset_Password.Reset_Password")}
          </h5>
          <Button
            variant="transparentBtn"
            className="pcClose position-absolute right-close-btn"
            onClick={onHideForgot}
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="20.6973"
                y1="7.75988"
                x2="7.75901"
                y2="20.6981"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <line
                x1="20.5702"
                y1="20.6992"
                x2="7.63194"
                y2="7.76096"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="forgot-modal-body">
        <div className="forgot-content body-content">
          <p
            dangerouslySetInnerHTML={{
              __html: t("Reset_Password.Enter_registered_email"),
            }}
          ></p>
        </div>
        <div className="forgot-modal-content">
          <div className="position-relative input-group">
            <label className="input-title">{t("Reset_Password.Email")}</label>
            <input
              type="email"
              placeholder={t("Reset_Password.Placeholder.Email")}
              name="forgotemail"
              value={resetPassEmail}
              onChange={(e) => {
                emailRegExp(e.target.value);
                setResetPassEmail(e.target.value);
              }}
            />
            {resetpassError && (
              <p className="forgot-form-error">{resetpassError}</p>
            )}
            {noUserFound && <p className="forgot-form-error">{noUserFound}</p>}

            {/* <p className='forgot-form-error'>Invalid email address</p> */}
          </div>
        </div>
        {!noUserFound ? (
          <Button
            disabled={
              resetPassEmail !== "" ? resetpassError !== "" && false : true
            }
            onClick={ResetPassword}
            variant="confirmBtn"
            className="forgot-btn-modal w-100 text-center"
          >
            {" "}
            {t("Reset_Password.Send_reset_link")}{" "}
          </Button>
        ) : (
          <Button
            onClick={ResetPassword}
            disabled
            variant="confirmBtn"
            className="forgot-btn btn-secondary-modal w-100 text-center"
          >
            {" "}
            {t("Reset_Password.Send_reset_link")}{" "}
          </Button>
        )}

        {successMsg && (
          <Button variant="successBtn" disabled className="w-100">
            {t("Reset_Password.Password_reset_email_sent")}
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
      {isResetPassSubmited && <Loading />}
    </Modal>
  );
};

export default ForgotPass;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HostPro from "../../../components/HostPro";
import { ApiGet } from "../../../helper/API/ApiData";
import { checkImageURL } from "../../../helper/utils";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const initialData = {
  count: 0,
  host: [],
};

function PopulatHostMobile({ ViewHost }) {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const { t } = useTranslation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [hostingId, setHostingId] = useState("");

  const [data, setData] = useState(initialData);

  const getData = () => {
    ApiGet(`hosting/getPopularHost`)
      .then((data) => {
        setData({
          count: data.data.host
            .filter((x) => x.hosting_confirm_status !== "request_review")
            .slice(0, 30).length,
          host: data.data.host
            .filter((x) => x.hosting_confirm_status !== "request_review")
            .slice(0, 30),
        });
      })
      .catch(console.error);
  };

  useEffect(() => {
    getData();
    // ApiGet("hosting/getPopularHost").then((data: any) =>
    //   setData(data.data.host)
    // );
  }, []);

  useEffect(() => {
    getData();
  }, [pageNo]);

  const [modalShow, setModalShow] = useState(false);
  const { is_loggedin } = useSelector((state) => state?.login);

  return (
    <>
      <div className="popular-host-section">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="heading">{t("Homepage.Popular_Hosts")}</h4>
          <Button
            variant="blackBtn viewHostBtn"
            onClick={() => {
              ViewHost();
            }}
          >
            {t("Homepage.Hero.View_AllHosts")}
          </Button>
        </div>
        <div className="container">
          {data.host.map(
            (items, i) =>
              i < perPage && (
                <div
                  key={i}
                  className="popular-host-row d-flex align-items-center"
                  onClick={() => {
                    // history.push(`/itinerary?id=${items.itinerary_id}`)
                    setHostingId(items.hosting_id);
                    setShow(true);
                  }}
                >
                  <div className="popular-host-img circle">
                    <img src={items.avatar || "./img/Avatar.png"} alt="" />
                  </div>
                  <div className="popular-pro-content">
                    <h5>{items.name}</h5>
                    <h6>
                      {items.region}, {items.country}
                    </h6>
                  </div>
                  <div className="popular-host-flag circle">
                    <img src={checkImageURL(items.nationality)} alt="" />
                  </div>
                </div>
              )
          )}
        </div>
        {data.count > perPage && (
          <Button
            className="mobile-more-btn"
            variant="outline-blue"
            onClick={() => {
              setPerPage(perPage + perPage);
            }}
          >
            {t("Show.All2")}
          </Button>
        )}
      </div>
      {show && (
        <HostPro
          hostingId={hostingId}
          show={show}
          setShow={setShow}
          data={data}
        ></HostPro>
      )}
    </>
  );
}

export default PopulatHostMobile;

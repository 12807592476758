/* eslint-disable no-restricted-globals */
import moment from "moment";
import React, { useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AuthStorage from "../helper/AuthStorage";
import { dateFunction } from "../helper/utils";
import {
  setChatId,
  setChatState,
  setMessageState,
  setOtherUserData,
  setReadOnly,
} from "../redux/action/chatDataAction";
import { db } from "../firebaseConfig";
import { useTranslation } from "react-i18next";

const Chat = ({ data, id, readOnly }) => {
  const { userData } = useSelector((state) => state.userData);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const deleteChat = () => setShow(true);
  // const deleteChat = (e: any) => {
  //   e.preventDefault();
  //   setShow(true);
  // };
  const { t } = useTranslation();
  const user_id = data.ids.find((x) => x !== userData?.id);

  const d = dateFunction(
    moment(new Date(data?.timestamp?.toDate().toDateString())).toString()
  );

  const otherUserData = {
    user_id,
    name: data[user_id]?.name,
    avatar: data[user_id]?.profile_url,
    time:
      d === "Today"
        ? moment(data?.timestamp?.toDate())
            .locale(AuthStorage.getLang())
            .format("h:mm A")
        : d,
    lastMessage: data?.lastMessage,
    count: data[`${user_id}_count`],
  };

  const other_user_data = {
    name: otherUserData.name,
    avatar: otherUserData.avatar,
    id: otherUserData.user_id,
  };

  const dispatch = useDispatch();

  // async function deleteQueryBatch(db: any, query: any, resolve: any) {
  //   const snapshot = await query.get();

  //   const batchSize = snapshot.size;
  //   if (batchSize === 0) {
  //     // When there are no documents left, we are done
  //     resolve();
  //     return;
  //   }

  //   // Delete documents in a batch
  //   const batch = db.batch();
  //   snapshot.docs.forEach((doc: any) => {
  //     batch.delete(doc.ref);
  //   });
  //   await batch.commit();

  //   // Recurse on the next process tick, to avoid
  //   // exploding the stack.
  //   process.nextTick(() => {
  //     deleteQueryBatch(db, query, resolve);
  //   });
  // }

  const deleteCancle = () => {
    setShow(false);
  };

  const deleteconfirm = async (e) => {
    // id = "f51e608e-1c5c-4db9-a6b1-b02fc1d7ab2eadmin"
    if (id) {
      try {
        const message = await db
          .collection(AuthStorage.getMessageCollection())
          .doc(id)
          .delete()
          .then(async (response) => {
            var docRef = (
              await db
                .collection(AuthStorage.getMessageCollection())
                .doc(id)
                .collection("messages")
                .get()
            ).docs.forEach((doc) => {
              doc.ref.delete();
            });
            handleClose();
          })
          .catch((error) => {
            //console.log(error)
          });
      } catch (error) {
        // console.log(error);
      }
    }

    return;
  };

  return (
    <>
      <div className="chat-list-row d-flex align-items-center ">
        <div className="chat-info d-flex align-items-center w-100 justify-content-between cursor-p">
          <div
            className="modal-chat-left"
            onClick={() => {
              dispatch(setOtherUserData(other_user_data));
              dispatch(setChatId(id));
              dispatch(setMessageState(true));
              dispatch(setChatState(false));
              dispatch(setReadOnly(readOnly));
            }}
          >
            <div className="wishlistimg circle">
              <img
                className="objectfit-cover"
                src={otherUserData.avatar || "./img/Avatar.png"}
                alt=""
              />
            </div>
            <div className="chat-message">
              <h3 className="user-name">{otherUserData.name}</h3>
              <p className="msg-content">
                {otherUserData.lastMessage.slice(0, 30)}
                {otherUserData.lastMessage.length > 30 ? "..." : ""}
              </p>
            </div>
            <div
              className="badge-with-time cursor-p"
              onClick={() => {
                dispatch(setOtherUserData(other_user_data));
                dispatch(setChatId(id));
                dispatch(setMessageState(true));
                dispatch(setChatState(false));
                dispatch(setReadOnly(readOnly));
              }}
            >
              {otherUserData.count !== 0 && userData.notification ? (
                <Badge pill variant="danger">
                  {otherUserData.count}
                </Badge>
              ) : null}
              <p className="msg-time">{otherUserData.time}</p>
            </div>
          </div>
          <div className="modal-chat-right">
            {otherUserData.name === "Admin" ? (
              <div className="blank-trash-btn"></div>
            ) : (
              <button
                type="button"
                className="ChattrashBtn"
                onClick={deleteChat}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="5.5"
                    y="5.88477"
                    width="9"
                    height="11.3077"
                    stroke="#C9C9C9"
                  />
                  <line
                    x1="14"
                    y1="2.80859"
                    x2="6"
                    y2="2.80859"
                    stroke="#C9C9C9"
                  />
                  <line x1="8.5" y1="14" x2="8.5" y2="9" stroke="#C9C9C9" />
                  <line
                    x1="11.5771"
                    y1="14"
                    x2="11.5771"
                    y2="9"
                    stroke="#C9C9C9"
                  />
                  <line
                    x1="17"
                    y1="5.88477"
                    x2="3"
                    y2="5.88476"
                    stroke="#C9C9C9"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>

        {/* {!readOnly && ( */}

        {/* )} */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text delete-modal-center custom-modal"
            : "noto-text delete-modal-center custom-modal"
        }
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("DeleteChat.Title")}</h3>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="body-content">
            <span> {t("DeleteChat.Msg")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="cancelBtn" onClick={deleteCancle}>
            {t("DeleteChat.Cancle")}
          </Button>
          <Button variant="confirmBtn" onClick={deleteconfirm}>
            {t("DeleteChat.Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Chat;

import moment from "moment";

const transportation = {
  Car: "승용차",
  Taxi: "택시",
  Public_Transportation: "대중교통",
};

const gender = {
  MALE: "남성",
  FEMALE: "여성",
  ETC: "기타",
};

const city = {
  South_Korea: "대한민국",
};

export const setCookie = (cname, cvalue, minutes) => {
  var d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
  return null;
};

export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");
  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const dateFunction = (date) => {
  if (
    moment(date).format("YYYY.MM.DD") ===
    moment(new Date()).format("YYYY.MM.DD")
  ) {
    return "Today";
  } else if (
    moment(date).format("YYYY.MM.DD") ===
    moment(new Date()).subtract(1, "days").format("YYYY.MM.DD")
  ) {
    return "Yesterday";
  }
  return moment(date).format("YYYY.MM.DD");
};

export const checkImageURL = (nationality) => {
  const pngImages = ["Antarctica"];

  let url_image = `./img/flags/${nationality}.svg`;
  if (pngImages.includes(nationality)) {
    url_image = `./img/flags/${nationality}.png`;
  }
  return url_image;
};

export const transporTation = (keyword) => {
  // @ts-ignore
  return Object.keys(transportation).includes(keyword)
    ? transportation[keyword]
    : keyword;
};

export const Gender = (keyword) => {
  // @ts-ignore
  return Object.keys(gender).includes(keyword) ? gender[keyword] : keyword;
};

export const City = (keyword) => {
  // @ts-ignore
  return Object.keys(city).includes(keyword) ? city[keyword] : keyword;
};

export function urlify(text) {
  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  //var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text?.replace(urlRegex, function (url, b, c) {
    var url2 = c === "www." ? "http://" + url : url;
    return '<a href="' + url2 + '" target="_blank">' + url + "</a>";
  });
}

import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import According from "../../common/According";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import * as QueryString from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "react-image-lightbox/style.css";
import Rating from "react-rating";
import moment from "moment";
import AuthStorage from "../../helper/AuthStorage";
// import { urlify } from "../../helper/utils";
import ReactHtmlParser from "react-html-parser";
import { Button, Modal } from "react-bootstrap";
import ReviewPopup from "../modal/ReviewPopup";
import AccountDeactive from "../modal/AccountDeactive";
import UserHostList from "./UserHostList";
import HostList from "./HostList";
import Login from "../modal/Login";
// import GoogleMapReact from "google-map-react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import ApplicationpaxMobile from "pages/mobile-component/ApplicationpaxMobile";
import CKeditor from "components/CKeditor";

function Event(props) {
  // const [clicked, setClicked] = useState("0");
  // const [photoIndex, setPhotoIndex] = useState(0);
  const [showAcc, setShowAcc] = useState([]);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [data, setData] = useState([]);
  const [centerMap, setCenteMap] = useState([]);
  const [review, setReview] = useState([]);
  // const [isOpen, setIsOpen] = useState(false);
  const [stars, setStars] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [reviewNot, setReviewNot] = useState(false);
  const [alredyReviewed, setAlredyReviewed] = useState(false);
  // const [selectedMarker, setSelectedMarker] = useState(null);

  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const is_active = AuthStorage.isUserActive();
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  // let lastElement = data?.courses?.slice(-1);
  const { userData } = useSelector((state) => state.userData);

  const { t } = useTranslation();
  const history = useHistory();

  const { is_loggedin } = useSelector((state) => state?.login);
  const params = QueryString.parse(history.location.search);

  const [refreshHost, setRefreshHost] = useState(0);
  const [refreshPax, setRefreshPax] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [isJoined, setIsJoined] = useState();
  const [isHostingCompleted, setIsHostingCompleted] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const isMobile = window.innerWidth <= 767;

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  // const Maker = ({ text }) => (
  //   <>
  //     <div className="mapFlag d-flex position-relative">
  //       <svg viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  //         <circle cx="11.5" cy="11.5" r="11.5" fill="#42B6E6" />
  //         <path d="M11.5 30L3.27276 18L19.7272 18L11.5 30Z" fill="#42B6E6" />
  //       </svg>
  //       <span className="position-absolute">{text}</span>
  //     </div>
  //   </>
  // );

  useEffect(() => {
    getItinerary();
  }, []);

  // const handleToggle = (index) => {
  //   if (clicked === index) {
  //     return setClicked("0");
  //   }
  //   setClicked(index);
  // };

  const accordianViewToggle = (id) => {
    let _showAcc = [...showAcc];
    const exists = _showAcc.find((data) => data === id);
    if (exists) {
      _showAcc = _showAcc.filter((data) => data !== id);
    } else {
      _showAcc.push(id);
    }
    setShowAcc(_showAcc);
  };

  const getItinerary = () => {
    if (props.deleted === "deleted") {
      ApiGet(
        `itinerary/itinerary-course-deleted/${params.id}?creator=${props.creator}`
      )
        .then((data) => setData(data.data))
        .catch(() => setData([]));
      ApiGet(
        `itinerary/review-by-itinerary-deleted/${params.id}?creator=${props.creator}`
      )
        .then((data) => setReview(data.data))
        .catch(() => setReview([]));
    } else {
      ApiGet(`itinerary/itinerary-course/${params.id}`)
        .then((res) => {
          setCenteMap({
            lat: Number(res.data.courses[0].latitude),
            lng: Number(res.data.courses[0].longitude),
          });
          setData(res.data);
        })
        .catch(() => setData([]));
      ApiGet(`itinerary/review-by-itinerary/${params.id}`)
        .then((data) => setReview(data.data))
        .catch(() => setReview([]));
    }
  };

  //Like function
  const [delayLike, setDelayLike] = useState(false);
  const Like = (id) => {
    setDelayLike(true);
    setData((prev) => {
      return {
        ...prev,
        like: !prev.like,
      };
    });

    ApiPost(`itinerary/wishlist/${id}`, {})
      .then((res) => {
        setDelayLike(false);
      })
      .catch((err) => {
        // console.log("Fail", err);
      });
  };

  // const setDate = (startDate, endDate) => {
  //   let date = "-";
  //   if (!startDate && !endDate) {
  //     date = "-";
  //   } else if (startDate && !endDate) {
  //     if (AuthStorage.getLang() === "en") {
  //       date = `${moment(startDate).format("MMMM D, yyyy")}`;
  //     } else {
  //       date = `${moment(startDate).format("yyyy년 M월 D일")}`;
  //     }
  //   } else {
  //     if (AuthStorage.getLang() === "en") {
  //       date = `${moment(startDate).format("MMMM D, yyyy")} - ${moment(
  //         endDate
  //       ).format("MMMM D, yyyy")}`;
  //     } else {
  //       date = `${moment(startDate).format("yyyy년 M월 D일")} - ${moment(
  //         endDate
  //       ).format("yyyy년 M월 D일")}`;
  //     }
  //     // date = `${moment(startDate).format("YYYY.MM.DD") + " (" + moment(startDate).format("ddd") + ") " + moment(startDate).format("h:mm") + " - " + moment(endDate).format("h:mm")}`;
  //   }

  //   return date;
  // };

  const changeDateType = (date) => {
    const x = new Date(date);
    const y = x.getMonth();
    const w = x.getDate();
    const z = x.getFullYear().toString();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // return month[y] + " " + w + ", " + z;
    if (AuthStorage.getLang() === "en") {
      return moment(x).locale(AuthStorage.getLang()).format("MMMM D, yyyy");
    } else {
      return moment(x).locale(AuthStorage.getLang()).format("yyyy년 M월 D일");
    }
  };

  const changeTimeFormat = (time) => {
    if (!time) {
      return "";
    }
    let Time = time.split(":");
    return Time[0] + ":" + Time[1];
  };

  const getCreatorString = (creator, creator_show) => {
    if (creator_show === "User" || creator_show === "Host") {
      return `<span>Created by User</span>`;
    } else {
      return `<span>${creator_show}</span>`;
    }
  };

  const sendReviews = async () => {
    await ApiPost("hosting/makeReview", {
      star: stars,
      content: feedback,
      itinerary_id: data.id,
    })
      .then((res) => {
        props.setRefreshReview(Math.random());
      })
      .catch((error) => {
        if (JSON.parse(JSON.stringify(error)).status === 400) {
          setAlredyReviewed(true);
        }
      });
    setReviewPopup(false);
    setStars(0);
    setFeedback("");
    setReviewNot(true);
  };

  const isUserAlreadyReviewed =
    props?.reviews?.recentReview?.filter(
      (review) => review?.user?.id === userData.id
    ).length !== 0;
  const getHostingUser = () => {
    ApiGet(`hosting/host-itinerary/${params.id}`).then((res) => {
      props.setHostingUser(res.data);
      props.setHost(res?.data.filter((x) => x?.user?.id === userData?.id)[0]);
    });
  };

  useEffect(() => {
    getHostingUser();
  }, [refreshHost, userData]);

  const goSubPage1 = () => {
    props.setIsCompas("");
    history.push(`/itinerary?id=${data.id}`);
  };

  // ckeditor
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <>
      {isMobile === true && (
        <div>
          <div className="title-single-event d-flex justify-content-between">
            <h3
              className="itinerary-title"
              onClick={goSubPage1}
              style={{ cursor: "pointer" }}
            >
              {data.title}
            </h3>

            <div className="heart-section">
              {/* <img src="./img/blank-heart.svg" alt="" /> */}
              <input
                type="checkbox"
                checked={data.like}
                disabled={delayLike}
                onChange={
                  is_loggedin === true
                    ? (e) => {
                        e.stopPropagation();
                        Like(data.id);
                      }
                    : () => setModalShow(true)
                }
                id={data.id}
                className="instruments"
              />
              <label
                htmlFor={data.id}
                className={is_loggedin ? `text-white check` : `text-white`}
              >
                {!data.like ? (
                  <svg
                    width="39"
                    height="38"
                    viewBox="0 0 39 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                      stroke="#C9C9C9"
                      stroke-width="2"
                    />
                    <rect x="22" y="13" width="17" height="14" fill="white" />
                    <rect x="29" y="14" width="2" height="12" fill="#C9C9C9" />
                    <rect
                      x="36"
                      y="19"
                      width="2"
                      height="12"
                      transform="rotate(90 36 19)"
                      fill="#C9C9C9"
                    />
                  </svg>
                ) : (
                  <svg
                    className="likeOn"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.9394 7.02456L30.9418 7.02695C33.6861 9.71905 33.6861 14.1029 30.9418 16.795L30.9417 16.7951L27.6053 20.0694L26.8803 20.7808L26.904 20.8043L16.992 30.5945L3.0446 16.8185C0.318467 14.1259 0.318467 9.7383 3.0446 7.04568C5.7776 4.34627 10.2413 4.34627 12.9743 7.04568L16.2679 10.2988L16.9683 10.9906L17.671 10.301L21.0074 7.02681L21.0097 7.02456C23.7427 4.32515 28.2064 4.32515 30.9394 7.02456Z"
                      fill="#FF5F5F"
                      stroke="#FF5F5F"
                      stroke-width="2"
                    />
                  </svg>
                )}
              </label>
              {/* <img src="./img/heart.svg" alt="" /> */}
            </div>
          </div>
          <div className="ratting-country-row">
            <div className="location-single-event">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.48161 1C10.0022 1 11.3021 1.5446 12.3813 2.6338C13.4604 3.723 14 5.04382 14 6.59624C14 7.37246 13.8099 8.26761 13.4298 9.28169C13.0496 10.2958 12.5897 11.2347 12.0502 12.0986C11.5106 12.9624 10.971 13.7762 10.4314 14.5399C9.89186 15.3036 9.43813 15.9108 9.07023 16.3615L8.48161 17C8.33445 16.8498 8.13824 16.6244 7.89298 16.3239C7.64771 16.0235 7.21237 15.4413 6.58696 14.5775C5.96154 13.7136 5.40357 12.8748 4.91304 12.061C4.42252 11.2473 3.98105 10.3271 3.58863 9.30047C3.19621 8.27387 3 7.37246 3 6.59624C3 5.04382 3.53344 3.723 4.60033 2.6338C5.66722 1.5446 6.96098 1 8.48161 1ZM8.48161 8.58685C9.02118 8.58685 9.48718 8.3928 9.8796 8.00469C10.272 7.61659 10.4682 7.1471 10.4682 6.59624C10.4682 6.04538 10.272 5.5759 9.8796 5.18779C9.48718 4.79969 9.02118 4.60563 8.48161 4.60563C7.94203 4.60563 7.48216 4.79969 7.10201 5.18779C6.72185 5.5759 6.53177 6.04538 6.53177 6.59624C6.53177 7.1471 6.72185 7.61659 7.10201 8.00469C7.48216 8.3928 7.94203 8.58685 8.48161 8.58685Z"
                  fill="#7E7E7E"
                />
              </svg>

              <span
                dangerouslySetInnerHTML={{
                  __html: data.region + ", " + data.country,
                }}
              >
                {/* {data.region}, {data.country} */}
              </span>
            </div>
            {data.creator !== "Host" && (
              <div className="star-single-event d-flex align-items-center cursor-pointer">
                {!isMobile && (
                  <div
                    className="ratingStarArea"
                    onClick={() => {
                      if (is_active) {
                        {
                          isUserAlreadyReviewed && is_loggedin
                            ? setAlredyReviewed(true)
                            : setReviewPopup(true);
                        }
                      } else {
                        is_loggedin
                          ? setDeactivatedNotice(true)
                          : setModalShow(true);
                      }
                    }}
                  >
                    <Rating
                      className="ratingStar align-items-center"
                      emptySymbol={
                        <svg
                          className="emptySymbol align-items-center"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8284C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                            stroke="#EBEBEB"
                            stroke-width="1.4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      fullSymbol={
                        <svg
                          className="fullSymbol align-items-center"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8285C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                            fill="#FF5F5F"
                          />
                        </svg>
                      }
                      initialRating={props?.reviews.star}
                      readonly={true}
                      stop={5}
                    />
                  </div>
                )}

                {/* Responsive Review star start*/}
                {isMobile && props.reviews.star !== "NaN" ? (
                  <div
                    className="ratingStarArea ratingStarArea-mobile d-flex align-items-center"
                    onClick={() => {
                      if (is_active) {
                        isUserAlreadyReviewed && is_loggedin
                          ? setAlredyReviewed(true)
                          : setReviewPopup(true);
                      } else {
                        is_loggedin
                          ? setDeactivatedNotice(true)
                          : setModalShow(true);
                      }
                    }}
                  >
                    <svg
                      className="res-ratingStar fullSymbol align-items-center"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.39977 2.47298C7.44103 2.33579 7.52291 2.21597 7.63354 2.13091C7.74416 2.04586 7.87776 2 8.01493 2C8.1521 2 8.2857 2.04586 8.39633 2.13091C8.50695 2.21597 8.58884 2.33579 8.63009 2.47298L9.74096 6.05542H13.3244C13.4665 6.04979 13.6064 6.09309 13.7228 6.1787C13.8392 6.26431 13.9257 6.38754 13.969 6.52954C14.0124 6.67154 14.0102 6.82452 13.9628 6.96509C13.9154 7.10567 13.8254 7.22611 13.7066 7.30802L10.7981 9.51886L11.9089 13.1076C11.9529 13.2443 11.954 13.3921 11.9121 13.5295C11.8702 13.6669 11.7876 13.7867 11.6762 13.8713C11.5648 13.956 11.4305 14.0011 11.2929 14C11.1554 13.9989 11.0217 13.9518 10.9116 13.8654L7.99701 11.6295L5.08845 13.8466C4.97826 13.933 4.84463 13.9801 4.70707 13.9812C4.56952 13.9823 4.43524 13.9372 4.32384 13.8525C4.21245 13.7679 4.12977 13.6481 4.08789 13.5107C4.04601 13.3733 4.04712 13.2255 4.09105 13.0888L5.20192 9.50008L2.29335 7.28923C2.17455 7.20732 2.08459 7.08688 2.03721 6.9463C1.98983 6.80573 1.98764 6.65275 2.03097 6.51075C2.0743 6.36875 2.16078 6.24552 2.27718 6.15991C2.39358 6.0743 2.53351 6.031 2.67559 6.03663H6.25904L7.39977 2.47298Z"
                        fill="#FF5F5F"
                      />
                    </svg>
                  </div>
                ) : (
                  <div
                    className="ratingStarArea ratingStarArea-mobile"
                    onClick={() => {
                      if (is_active) {
                        isUserAlreadyReviewed && is_loggedin
                          ? setAlredyReviewed(true)
                          : setReviewPopup(true);
                      } else {
                        is_loggedin
                          ? setDeactivatedNotice(true)
                          : setModalShow(true);
                      }
                    }}
                  >
                    <svg
                      className="res-ratingStar emptySymbol align-items-center"
                      width="16"
                      height="16"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.1997 2.63064C9.2547 2.44772 9.36388 2.28796 9.51138 2.17455C9.65888 2.06114 9.83701 2 10.0199 2C10.2028 2 10.3809 2.06114 10.5284 2.17455C10.6759 2.28796 10.7851 2.44772 10.8401 2.63064L12.3213 7.40723H17.0992C17.2886 7.39972 17.4752 7.45745 17.6304 7.5716C17.7856 7.68575 17.9009 7.85005 17.9587 8.03939C18.0165 8.22872 18.0136 8.43269 17.9504 8.62012C17.8872 8.80756 17.7673 8.96815 17.6089 9.07736L13.7308 12.0252L15.2119 16.8101C15.2705 16.9924 15.272 17.1895 15.2161 17.3727C15.1603 17.5559 15.0501 17.7156 14.9015 17.8284C14.753 17.9413 14.574 18.0014 14.3906 18C14.2072 17.9986 14.029 17.9357 13.8821 17.8205L9.99602 14.8393L6.11793 17.7955C5.97101 17.9106 5.79284 17.9735 5.60943 17.9749C5.42602 17.9763 5.24698 17.9162 5.09845 17.8034C4.94993 17.6905 4.8397 17.5309 4.78386 17.3476C4.72802 17.1644 4.72949 16.9673 4.78807 16.785L6.26923 12.0001L2.39114 9.05231C2.23274 8.9431 2.11279 8.78251 2.04961 8.59507C1.98644 8.40764 1.98352 8.20367 2.0413 8.01433C2.09907 7.825 2.21437 7.6607 2.36957 7.54655C2.52478 7.43239 2.71135 7.37466 2.90079 7.38217H7.67872L9.1997 2.63064Z"
                        stroke="#EBEBEB"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                )}
                {/* Responsive Review star End */}

                <p className={"innere-itinarary-total"}>
                  <span
                    className="cusor-p"
                    onClick={() => {
                      if (is_active) {
                        isUserAlreadyReviewed && is_loggedin
                          ? setAlredyReviewed(true)
                          : setReviewPopup(true);
                      } else {
                        is_loggedin
                          ? setDeactivatedNotice(true)
                          : setModalShow(true);
                      }
                    }}
                  >
                    {props.reviews.star !== "NaN"
                      ? props.reviews.star
                      : "0.0 (0)"}
                    {props.reviews.star !== "NaN"
                      ? props.reviews.recentReview &&
                        " (" + props.reviews.recentReview.length + ")"
                      : ""}
                  </span>
                  &nbsp;&nbsp;&nbsp;∙&nbsp;&nbsp;&nbsp;
                  <span className="cursor-p">
                    <a href="#comments">
                      {t("TourList.Comments")} ({props.commentData?.count})
                    </a>
                  </span>
                </p>
              </div>
            )}
          </div>
          {data.creator === "Host" ? (
            <>
              {data?.start_date ? (
                <>
                  <span className="date-start-end">
                    {changeDateType(data.start_date)}{" "}
                    {changeTimeFormat(data.start_time)} -{" "}
                    {changeTimeFormat(data.end_time)}
                  </span>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {data?.start_date ? (
                <>
                  <span className="date-start-end">
                    {changeDateType(data.start_date) +
                      " - " +
                      changeDateType(data.end_date)}
                  </span>
                </>
              ) : (
                ""
              )}
            </>
          )}
          <p className="createdby">
            {ReactHtmlParser(getCreatorString(data.creator, data.creator_show))}
          </p>
        </div>
      )}

      {/* Responsive Host list start  */}
      <div className="mobile-host-list">
        {data.creator === "Compastrips" && props.isCompas === "" ? (
          <HostList
            focus={props.focus}
            showHostProfile={props.showHostProfile}
            setShowHostProfile={props.setShowHostProfile}
            data={data}
            refreshHost={refreshHost}
            setIsCompas={props.setIsCompas}
            setRefreshPax={setRefreshPax}
            refresh={refresh}
            setIsJoined={setIsJoined}
            setIsHostingCompleted={setIsHostingCompleted}
            setRefreshHost={setRefreshHost}
          />
        ) : (
          (data.creator === "Host" || props.isCompas) && (
            <>
              <UserHostList
                refresh={refresh}
                isCompas={props.isCompas}
                setIsHostingCompleted={setIsHostingCompleted}
                created_by={data.creator}
                refreshHost={refreshHost}
                setRefresh={setRefresh}
                hostingID={props.hostingID}
                refreshPax={refreshPax}
                host={props.host}
              />
              {props.host &&
                props.host?.status !== "COMPLETED" &&
                (data.creator === "Host" ||
                  props.host.id === props.isCompas) && (
                  <ApplicationpaxMobile
                    refreshHost={props.refreshHost}
                    setRefresh={setRefresh}
                    hostingID={props.hostingID}
                    refreshPax={props.refreshPax}
                    setRefreshPax={setRefreshPax}
                    isHostingCompleted={isHostingCompleted}
                    host={props.host}
                  />
                )}
              {/* {props.hostingID === props.isCompas && (
                <ApplicationpaxMobile
                  refreshHost={props.refreshHost}
                  setRefresh={props.setRefresh}
                  hostingID={props.hostingID}
                  refreshPax={props.refreshPax}
                  isHostingCompleted={isHostingCompleted}
                  host={props.host}
                />
              )} */}
            </>
          )
        )}
      </div>
      <div className="contents">
        <div
          className={
            AuthStorage.getLang() === "en"
              ? "roboto-text about-tour-event readOnly"
              : "noto-text about-tour-event readOnly"
          }
        >
          {/* <div className="about-tour-event" > */}
          {/* <h5 className="itinerary-subtitle">
            {t("Itinerary_Desc.About_the_tour")}
          </h5> */}
          {/* <h6>{data.info?.split('\n').map((str: any) => <p style={{ wordBreak: 'break-word' }}>{str}</p>)}</h6> */}
          <CKeditor
            name="aboutTour"
            value={data.info}
            onChange={(data) => {
              // setFormData({
              //   ...formData,
              //   aboutTour: data,
              // });
            }}
            disabled={true}
            editorLoaded={editorLoaded}
          />
          <div className="readOnly"></div>
          {/* {data.info && (
            <>
              <pre
                className={
                  AuthStorage.getLang() === "en"
                    ? "roboto-text ck-content"
                    : "noto-text ck-content"
                }
                dangerouslySetInnerHTML={{
                  __html: ReactHtmlParser(urlify(data.info)),
                }}
              ></pre>
            </>
          )} */}
          {data.image_about != null && (
            <img src={data.image_about} alt="image_about" />
          )}
        </div>

        <div className="itenery-accordin">
          <h5 className="itinerary-subtitle">
            {t("Itinerary_Desc.Itinerary")}
          </h5>
          <div className="accordian-itinery d-flex flex-column">
            {data.courses &&
              data.courses.map((items, i) => (
                // setShowAcc([...showAcc, { items.id:false}])
                <According
                  key={i}
                  items={items}
                  index={i + 1}
                  showAcc={showAcc}
                  setShowAcc={accordianViewToggle}
                  itineraryId={params.id}
                />
              ))}
          </div>
        </div>
        <div className="about-map">
          {/* <h5 className="itinerary-subtitle">{t("Host_Own.About_Map")}</h5> */}
          {/* {AuthStorage.getLang() === "en" ? null : (
            <h6>{t("Host_Own.About_Map_desc")}</h6>
          )} */}
          <div className="map-area">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              language={AuthStorage.getLang()}
              libraries={false}
            >
              <GoogleMap
                mapContainerClassName="google-map"
                mapContainerStyle={
                  resize <= 728
                    ? {
                        width: "100vw",
                        marginLeft: "-16px",
                        position: "inherit",
                        minHeight: "230px",
                      }
                    : {
                        position: "inherit",
                        minHeight: "440px",
                      }
                }
                options={{
                  disableDefaultUI: true,
                  styles: [
                    {
                      featureType: "poi",
                      elementType: "labels",
                      stylers: [{ visibility: "off" }],
                    },
                  ],
                }}
                center={centerMap}
                zoom={11}
              >
                {data.courses &&
                  data.courses.map((item, i) => (
                    <>
                      <Marker
                        icon={"./img/marker.svg"}
                        position={{
                          lat: Number(item.latitude),
                          lng: Number(item.longitude),
                        }}
                        // label={`${i + 1}`}
                        label={{
                          color: "white",
                          text: `${i + 1}`,
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          window.open(
                            // `https://www.google.com/maps/place/${item.latitude} ${item.longitude}`,
                            // `https://www.google.com/maps/place/${item.latitude} ${item.longitude}`,
                            `https://www.google.com/maps/place?${
                              item.latitude
                            },${item.longitude}
                            &q=${item.name.replaceAll("'','+'")}`,
                            "_blank",
                            "noreferrer"
                          );
                        }}
                        onMouseOver={() => {
                          handleActiveMarker(i);
                        }}
                        onMouseOut={() => {
                          setActiveMarker(null);
                        }}
                      >
                        {activeMarker === i ? (
                          <InfoWindow
                          // onCloseClick={() => setActiveMarker(null)}
                          >
                            <div>
                              <p>{item.name}</p>
                            </div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    </>
                  ))}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>

      <ReviewPopup
        stars={stars}
        setStars={setStars}
        feedback={feedback}
        setFeedback={setFeedback}
        show={reviewPopup}
        onHide={() => {
          setReviewPopup(false);
        }}
        onSubmit={sendReviews}
      />
      {/* Review Notice Popup */}
      <Modal
        show={reviewNot}
        onHide={() => {
          setReviewNot(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Review_Notice.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <span>{t("Review_Notice.Body")}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              setReviewNot(false);
            }}
          >
            {t("Review_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Login
        show={modalShow}
        loginValue=""
        onHide={() => setModalShow(false)}
        onHideNew=""
        onShow=""
      />

      <Modal
        show={alredyReviewed}
        onHide={() => {
          setAlredyReviewed(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Review_Notice.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div
            className={
              AuthStorage.getLang() === "en"
                ? "modal-signup-title body-content"
                : "modal-signup-titleko body-content"
            }
          >
            <p className="font-24-normal color-darkgray h-60">
              {t("Review_Notice.ErrorBody")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100"
            variant="confirmBtn"
            onClick={() => {
              setAlredyReviewed(false);
            }}
          >
            {t("Review_Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />
    </>
  );
}

export default Event;

import React, { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { t } from "i18next";

const ErrorPage = () => {
  const location = useLocation();
  const creator = location.hash;
  useEffect(() => {}, [location]);
  return (
    <div className="error-page">
      <div className="w-100 text-center">
        <label className="btn-cancelBtn">
          {creator === "#Compastrips"
            ? t("This_itinerary_has_been_deleted_by_Admin")
            : t("This_itinerary_has_been_deleted")}
        </label>
      </div>
    </div>
  );
};

export default withRouter(ErrorPage);

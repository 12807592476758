import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HostPro from "../../components/HostPro";
import { ApiGet } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import { checkImageURL } from "../../helper/utils";
// import HostPro from "../../components/HostPro";
import Pagination from "react-js-pagination";
import { Button } from "react-bootstrap";

const initialHostData = {
  count: 0,
  hosts: [],
};

const ViewHostList = (props) => {
  const { hostType, gender, ageGroup } = props;
  const [hosts, setHosts] = useState(initialHostData);
  const [hostsPC, setHostsPC] = useState([]);
  const [hostingId, setHostingID] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const [perPagePC, setPerPagePC] = useState(80);
  const [pageNoPC, setPageNoPC] = useState(1);
  const [total, setTotal] = useState(1);

  const [resize, setResize] = useState(window.innerWidth);

  // dispatch(getUserData());

  const handleResize = () => {
    setResize(window.innerWidth);
    getHosts(true);
    setPerPage(20);
    setPageNoPC(1);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [hosts, hostsPC]);

  const getHosts = (loadNew = true, page_number = pageNo) => {
    const url =
      resize <= 767
        ? `hosting/hosts?host=${
            hostType.length === 1 ? hostType[0] : ""
          }&gender=${gender.length === 1 ? gender[0] : ""}&age=${ageGroup.join(
            ","
          )}`
        : `hosting/hosts?host=${
            hostType.length === 1 ? hostType[0] : ""
          }&gender=${gender.length === 1 ? gender[0] : ""}&age=${ageGroup.join(
            ","
          )}&per_page=${perPagePC}&page_number=${pageNoPC}`;
    ApiGet(url)
      .then((res) => {
        if (loadNew) {
          if (resize <= 767) {
            setHosts({
              count: res.data.hosts.filter(
                (x) => x.hostings_confirm_status !== "request_review"
              ).length,
              hosts: res.data.hosts.filter(
                (x) => x.hostings_confirm_status !== "request_review"
              ),
            });
            setTotal(
              res.data.hosts.filter(
                (x) => x.hostings_confirm_status !== "request_review"
              ).length
            );
          } else {
            setTotal(
              res.data.hosts.filter(
                (x) => x.hostings_confirm_status !== "request_review"
              ).length
            );
            setHostsPC((prev) => {
              if (res.data.hosts > 0 || pageNoPC > 1) {
                return [
                  ...res.data.hosts.filter(
                    (x) => x.hostings_confirm_status !== "request_review"
                  ),
                ];
              } else {
                return [
                  ...res.data.hosts.filter(
                    (x) => x.hostings_confirm_status !== "request_review"
                  ),
                ];
              }
            });
          }
        } else {
          const _hosts = { ...hosts };
          _hosts.count = res.data.count;
          _hosts.hosts =
            _hosts.hosts.length === 0
              ? res.data.hosts
              : _hosts.hosts.concat(res.data.hosts);
          // setHosts(_hosts);
          setHosts({
            count: res.data.hosts.filter(
              (x) => x.hostings_confirm_status !== "request_review"
            ).length,
            hosts: res.data.hosts.filter(
              (x) => x.hostings_confirm_status !== "request_review"
            ),
          });
        }
      })
      .catch((err) => {
        console.log("에러", err);
      });
  };

  console.log("확인", hosts);

  useEffect(() => {
    getHosts(true);
  }, [pageNo, pageNoPC]);

  useEffect(() => {
    setPageNo(1);
    getHosts(true, 1);
  }, [props]);

  useEffect(() => {
    pageNo > 1 && getHosts(false);
  }, [pageNo]);

  const [welcome, setWelcome] = useState(false);

  //for translation
  const { t } = useTranslation();

  return (
    <>
      <div className="view-host-list">
        {resize <= 767
          ? hosts.hosts.map((items, i) =>
              i < perPage ? (
                <div
                  className="single-local-host d-flex align-items-center"
                  key={i}
                  onClick={() => {
                    setHostingID(items.hosting_id);
                    setWelcome(true);
                  }}
                >
                  <div className="host-img circle">
                    <img src={items.avatar || "./img/Avatar.png"} alt="" />
                  </div>
                  <div className="pro-tag-name">
                    <div className="name-with-flag d-flex align-items-center">
                      <h3 className="user-name">{items.user_name}</h3>
                      {/* <h3>{items.name} {items.surname}</h3> */}
                      {/* <img src={items.avatar ? items.avatar : "./img/flag.svg"} alt="" /> */}
                      <div className="flag-wrap circle">
                        <img src={checkImageURL(items?.nationality)} alt="" />
                      </div>
                    </div>
                    <div className="pro-tag-suah d-flex align-items-center">
                      {items.type === "Local" ? (
                        <div className="host-category">
                          <p>{items.type === "Local" ? t("Local_Host") : ""}</p>
                        </div>
                      ) : (
                        <div className="travel-host-category">
                          <p>
                            {items.type === "Travel" ? t("Traveler_Host") : ""}
                          </p>
                        </div>
                      )}

                      {items.gender === "FEMALE" ? (
                        <div className="host-gender">
                          <p>{t("Female")}</p>
                        </div>
                      ) : (
                        <div className="host-gender">
                          <p>{t("Male")}</p>
                        </div>
                      )}
                      <div className="host-ages">
                        {AuthStorage.getLang() === "en" ? (
                          <p>
                            {items.age_group}'{t("Age_Groups")}
                          </p>
                        ) : (
                          <p className="d-flex align-items-center justify-content-center">
                            {items.age_group}
                            <span>{t("Age_Groups")}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null
            )
          : hostsPC.map((items, i) => (
              <div
                className="single-local-host d-flex align-items-center"
                key={i}
                onClick={() => {
                  setHostingID(items.hosting_id);
                  setWelcome(true);
                }}
              >
                <div className="host-img circle">
                  <img src={items.avatar || "./img/Avatar.png"} alt="" />
                </div>
                <div className="pro-tag-name">
                  <div className="name-with-flag d-flex align-items-center">
                    <h3 className="user-name">{items.user_name}</h3>
                    {/* <h3>{items.name} {items.surname}</h3> */}
                    {/* <img src={items.avatar ? items.avatar : "./img/flag.svg"} alt="" /> */}
                    <div className="flag-wrap circle">
                      <img src={checkImageURL(items?.nationality)} alt="" />
                    </div>
                  </div>
                  <div className="pro-tag-suah d-flex align-items-center">
                    {items.type === "Local" ? (
                      <div className="host-category">
                        <p>{items.type === "Local" ? t("Local_Host") : ""}</p>
                      </div>
                    ) : (
                      <div className="travel-host-category">
                        <p>
                          {items.type === "Travel" ? t("Traveler_Host") : ""}
                        </p>
                      </div>
                    )}

                    {items.gender === "FEMALE" ? (
                      <div className="host-gender">
                        <p>{t("Female")}</p>
                      </div>
                    ) : (
                      <div className="host-gender">
                        <p>{t("Male")}</p>
                      </div>
                    )}
                    <div className="host-ages">
                      {AuthStorage.getLang() === "en" ? (
                        <p>
                          {items.age_group}'{t("Age_Groups")}
                        </p>
                      ) : (
                        <p className="d-flex align-items-center justify-content-center">
                          {items.age_group}
                          <span>{t("Age_Groups")}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
      </div>

      {/* Responsive More button start */}
      {resize <= 767 ? (
        total > perPage ? (
          <div className="res-more-viewhost">
            <Button
              style={{ marginBottom: "32px" }}
              variant="cancelBtn w-100"
              onClick={() => {
                setPerPage(perPage + perPage);
              }}
            >
              More
            </Button>
          </div>
        ) : null
      ) : (
        total > perPagePC && (
          <div className="pagination-hostlist">
            <Pagination
              itemClass="page-item-custom"
              activeLinkClass="activepage"
              linkClass="page-link-custom"
              linkClassFirst="page-first-arrow"
              linkClassPrev="page-prev-arrow"
              linkClassNext="page-next-arrow"
              linkClassLast="page-last-arrow"
              firstPageText={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 15L4.5 10L10.5 5"
                    stroke="#DCDCDC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5 15L9.5 10L15.5 5"
                    stroke="#DCDCDC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              prevPageText={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 15L7 10L13 5"
                    stroke="#DCDCDC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              nextPageText={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 15L13 10L7 5"
                    stroke="#DCDCDC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              lastPageText={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 5L15.5 10L9.5 15"
                    stroke="#DCDCDC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.5 5L10.5 10L4.5 15"
                    stroke="#DCDCDC"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              activePage={pageNoPC}
              itemsCountPerPage={perPagePC}
              pageRangeDisplayed={10}
              totalItemsCount={total}
              onChange={(e) => {
                setPageNoPC(e);
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
              }}
            />
          </div>
        )
      )}
      {/* Responsive More button End */}

      {/* PC */}
      {/* {total > perPagePC && (
        <div className="pagination-hostlist">
          <Pagination
            itemClass="page-item-custom"
            activeLinkClass="activepage"
            linkClass="page-link-custom"
            linkClassFirst="page-first-arrow"
            linkClassPrev="page-prev-arrow"
            linkClassNext="page-next-arrow"
            linkClassLast="page-last-arrow"
            firstPageText={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15L4.5 10L10.5 5"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.5 15L9.5 10L15.5 5"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            prevPageText={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 15L7 10L13 5"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            nextPageText={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 15L13 10L7 5"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            lastPageText={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 5L15.5 10L9.5 15"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.5 5L10.5 10L4.5 15"
                  stroke="#DCDCDC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
            activePage={pageNoPC}
            itemsCountPerPage={perPagePC}
            pageRangeDisplayed={10}
            totalItemsCount={total}
            onChange={(e) => {
              setPageNoPC(e);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          />
        </div>
      )} */}

      {welcome && (
        <HostPro
          hostingId={hostingId}
          show={welcome}
          setShow={setWelcome}
        ></HostPro>
      )}
    </>
  );
};

export default ViewHostList;

import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { ApiDelete, ApiPost } from "../../helper/API/ApiData";
import { useTranslation } from "react-i18next";
import Rating from "react-rating";
import moment from "moment";
import AuthStorage from "../../helper/AuthStorage";
import "./TourCard.css";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { PlusCircle } from "react-bootstrap-icons";
import { Router } from "react-router-dom/cjs/react-router-dom";

const TourCard = ({ items, setRefresh, LoginModalClick }) => {
  // const [like, setLike] = useState(items.isLike);
  const [data, setData] = useState(items);
  const isMobile = window.innerWidth <= 428;
  const { is_loggedin } = useSelector((state) => state.login);

  const history = useHistory();

  const { t } = useTranslation();

  //Like Function
  const [delayLike, setDelayLike] = useState(false);
  const Like = (id) => {
    setDelayLike(true);
    setData((prev) => {
      return {
        ...prev,
        isLike: !prev.isLike,
      };
    });
    // setLike((prev: boolean) => !prev);
    // if (!like) {
    //   UpdateCount((prev: number) => prev);
    // }
    // else {
    //   UpdateCount((prev: number) => prev);
    // }
    ApiPost(`itinerary/wishlist/${id}`, {}).then((res) => {
      setRefresh(Math.random());
      setDelayLike(false);
    });
  };

  const Remove = (id) => {
    setDelayLike(true);

    ApiDelete(`user/deleteItineraryUserApplied/${id}`, {}).then((res) => {
      setRefresh(Math.random());
      // setDelayLike(false);
    });
  };

  const Removewishlist = (id) => {
    setDelayLike(true);

    ApiPost(`itinerary/wishlist/${id}`, {}).then((res) => {
      setRefresh(Math.random());
      setDelayLike(false);
    });
  };

  const changeDateType = (date) => {
    const x = new Date(date);
    // const y = x.getMonth();
    // const w = x.getDate();
    // const z = x.getFullYear().toString();
    // const month = [
    //   "January",
    //   "February",
    //   "March",
    //   "April",
    //   "May",
    //   "June",
    //   "July",
    //   "August",
    //   "September",
    //   "October",
    //   "November",
    //   "December",
    // ];
    // return month[y] + " " + w + "," + z;
    if (AuthStorage.getLang() === "en") {
      return moment(x).locale(AuthStorage.getLang()).format("LL");
    } else {
      return moment(x).locale(AuthStorage.getLang()).format("yyyy년 M월 D일");
    }
  };

  const changeTimeFormat = (time) => {
    if (!time) {
      return "";
    }
    let Time = time.split(":");
    return Time[0] + ":" + Time[1];
  };

  const getCreatorString = (creator, creator_show) => {
    if (creator_show === "User" || creator_show === "Host") {
      // return `<span class="font-green">${creator_show}</span>`;
      return `<span>Created by User</span>`;
    } else {
      // return `<span class="black-font">${creator_show}</span>`;
      return `<span>${creator_show}</span>`;
    }
  };

  const elementRef = useRef(null);
  const [avatarHeight, setAvatarHeight] = useState("0px");
  const [avatarBottom, setAvatarBottom] = useState("0px");

  const [avatarIconMr, setAvatarIconMr] = useState("5px");
  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      setAvatarIconMr("4px");
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      setAvatarIconMr("5px");
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      setAvatarIconMr("8px");
    } else if (window.innerWidth <= 500) {
      setAvatarIconMr("8px");
      if (isMobile) {
        setAvatarIconMr("7px");
      }
    }

    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth <= 500) {
      setAvatarHeight("5px");
    }

    setResize(window.innerWidth);
  };

  useEffect(() => {
    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      setAvatarIconMr("4px");
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      setAvatarIconMr("5px");
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      setAvatarIconMr("8px");
    } else if (window.innerWidth <= 500) {
      setAvatarIconMr("8px");

      if (isMobile) {
        setAvatarIconMr("7px");
      }
    }

    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth <= 500) {
      setAvatarHeight("5px");
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 1794 && window.innerWidth <= 1920) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth >= 1424 && window.innerWidth <= 1793) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth >= 1054 && window.innerWidth <= 1423) {
      if (elementRef?.current?.clientHeight >= 38) {
        setAvatarHeight("5px");
      } else {
        setAvatarHeight("25px");
      }
    } else if (window.innerWidth <= 500) {
      setAvatarHeight("5px");
    }
  }, [elementRef?.current?.clientHeight]);

  return (
    <div className={`tour-card res-card relative`}>
      <div className="imgArea-link position-relative w-100 d-block">
        {/* <div className='wishlistimg' style={{ backgroundImage: `url(${items?.image[0]})` }} ></div> */}
        <div className="imgArea w-100 h-100">
          <Link
            to={`/itinerary?id=${items?.id}${
              items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
            }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
            // target={resize <= 768 ? "_self" : "_blank"}
            target={"_blank"}
          >
            <img
              src={
                items?.image && items?.image[0]
                  ? items?.image[0]
                  : "../../../img/main-page.svg"
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "../../../img/main-page.svg";
              }}
              alt=""
            />
          </Link>
        </div>
        <div className="heart-div position-absolute">
          <input
            type="checkbox"
            id={`wish${items.id}`}
            checked={items.isLike}
            disabled={delayLike}
            onChange={
              is_loggedin
                ? () => Like(items.id)
                : () => {
                    LoginModalClick();
                  }
            }
            // onClick={canlike ? () => Like(data.id) : () => { }}
            className="instruments"
          />
          <label htmlFor={`wish${items.id}`} className={`text-white check`}>
            {!items.isLike ? (
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_d_2698_302425)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.9977 10.9319L12.5835 9.51766L10.1192 7.05343C8.71938 5.65357 6.44976 5.65357 5.0499 7.05343C3.65004 8.45329 3.65003 10.7229 5.04989 12.1228L7.51413 14.587L8.92717 16L8.92689 16.0003L13.9997 21.0732L13.9998 21.0731L14.0013 21.0746L15.3646 19.7113H18.193L14.0015 23.9028L13.9977 23.899L13.9974 23.8993L6.09977 16.0016L6.09963 16.0015L6.09991 16.0012L3.63568 13.537C1.45477 11.3561 1.45478 7.82012 3.63568 5.63922C5.81659 3.45831 9.35254 3.4583 11.5335 5.63921L12.5835 6.68923L13.9977 8.10345L15.4119 6.68923L16.4655 5.63568L17.8797 7.04989L15.4119 9.51766L13.9977 10.9319ZM25.9988 9.61082C26.0056 8.17287 25.4604 6.73281 24.3632 5.63568C22.1823 3.45478 18.6464 3.45477 16.4655 5.63568L17.8797 7.04989C19.2795 5.65004 21.5491 5.65004 22.949 7.0499C23.6556 7.7565 24.0055 8.68472 23.9988 9.61082H25.9988Z"
                    fill="white"
                  />
                  <rect
                    x="20.2852"
                    y="10.7305"
                    width="1.6832"
                    height="8.97706"
                    fill="white"
                  />
                  <rect
                    x="25.5605"
                    y="14.3164"
                    width="1.6832"
                    height="8.97706"
                    transform="rotate(90 25.5605 14.3164)"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_2698_302425"
                    x="0"
                    y="2"
                    width="27.998"
                    height="23.9023"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_2698_302425"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_2698_302425"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ) : (
              <svg
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                  fill="#FF5F5F"
                  stroke="#FF5F5F"
                  stroke-width="2"
                />
              </svg>
            )}
          </label>
        </div>
      </div>
      <div className=" card-box">
        <Link
          to={`/itinerary?id=${items?.id}${
            items.hosting_id ? `&hostingId=${items.hosting_id}` : ""
          }${items.status === "COMPLETED" ? "&noti=mypage" : ""}`}
          // target={resize <= 768 ? "_self" : "_blank"}
          target={"_blank"}
        >
          <h4
            ref={elementRef}
            id={"cardTitle_" + items?.id}
            className="cardTitle"
          >
            {items.title}
          </h4>
        </Link>

        <div className="d-flex location-with-comment align-items-center justify-content-between">
          <div className="location-row d-flex align-items-center">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.48495 2.13281C8.7291 2.13281 9.79264 2.5784 10.6756 3.46956C11.5585 4.36073 12 5.44139 12 6.71156C12 7.34664 11.8445 8.07903 11.5334 8.90874C11.2224 9.73844 10.8462 10.5067 10.4047 11.2135C9.96321 11.9203 9.52174 12.5861 9.08027 13.2109C8.6388 13.8358 8.26756 14.3326 7.96656 14.7013L7.48495 15.2237C7.36455 15.1008 7.20401 14.9164 7.00334 14.6706C6.80268 14.4247 6.44649 13.9484 5.93478 13.2416C5.42308 12.5349 4.96656 11.8486 4.56522 11.1827C4.16388 10.5169 3.80268 9.76405 3.48161 8.9241C3.16054 8.08416 3 7.34664 3 6.71156C3 5.44139 3.43645 4.36073 4.30936 3.46956C5.18227 2.5784 6.2408 2.13281 7.48495 2.13281ZM7.48495 8.34024C7.92642 8.34024 8.30769 8.18147 8.62876 7.86393C8.94983 7.54638 9.11037 7.16226 9.11037 6.71156C9.11037 6.26085 8.94983 5.87673 8.62876 5.55919C8.30769 5.24165 7.92642 5.08288 7.48495 5.08288C7.04348 5.08288 6.66722 5.24165 6.35619 5.55919C6.04515 5.87673 5.88963 6.26085 5.88963 6.71156C5.88963 7.16226 6.04515 7.54638 6.35619 7.86393C6.66722 8.18147 7.04348 8.34024 7.48495 8.34024Z"
                fill="#7E7E7E"
              />
            </svg>

            <p>
              {items.region}, {items.country}
            </p>
          </div>
          <div className="d-flex star-with-comment align-items-center">
            <div
              className={
                items.creator === "Compastrips"
                  ? "star-reviews"
                  : "star-reviews star-reviews star-reviews-responsive ml-1"
              }
            >
              {/* <div className="star-reviews"> */}
              {items.creator === "Compastrips" ? (
                <p className="card-comment">
                  {items.comment_count > 0 && (
                    <span>
                      {t("TourList.Comments")} ({items.comment_count})
                    </span>
                  )}
                  {items.creator === "Compastrips" &&
                    items.review_count > 0 && (
                      <>
                        <div className="res-star-list align-items-center justify-content-center">
                          <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.39977 2.97298C7.44103 2.83579 7.52291 2.71597 7.63354 2.63091C7.74416 2.54586 7.87776 2.5 8.01493 2.5C8.1521 2.5 8.2857 2.54586 8.39633 2.63091C8.50695 2.71597 8.58884 2.83579 8.63009 2.97298L9.74096 6.55542H13.3244C13.4665 6.54979 13.6064 6.59309 13.7228 6.6787C13.8392 6.76431 13.9257 6.88754 13.969 7.02954C14.0124 7.17154 14.0102 7.32452 13.9628 7.46509C13.9154 7.60567 13.8254 7.72611 13.7066 7.80802L10.7981 10.0189L11.9089 13.6076C11.9529 13.7443 11.954 13.8921 11.9121 14.0295C11.8702 14.1669 11.7876 14.2867 11.6762 14.3713C11.5648 14.456 11.4305 14.5011 11.2929 14.5C11.1554 14.4989 11.0217 14.4518 10.9116 14.3654L7.99701 12.1295L5.08845 14.3466C4.97826 14.433 4.84463 14.4801 4.70707 14.4812C4.56952 14.4823 4.43524 14.4372 4.32384 14.3525C4.21245 14.2679 4.12977 14.1481 4.08789 14.0107C4.04601 13.8733 4.04712 13.7255 4.09105 13.5888L5.20192 10.0001L2.29335 7.78923C2.17455 7.70732 2.08459 7.58688 2.03721 7.4463C1.98983 7.30573 1.98764 7.15275 2.03097 7.01075C2.0743 6.86875 2.16078 6.74552 2.27718 6.65991C2.39358 6.5743 2.53351 6.531 2.67559 6.53663H6.25904L7.39977 2.97298Z"
                              fill="#1B1C1E"
                            />
                          </svg>

                          <span>{items.star}</span>
                        </div>
                      </>
                    )}
                </p>
              ) : (
                <p className="card-comment">
                  {items.comment_count > 0 && (
                    <span>
                      {t("TourList.Comments")} ({items.comment_count})
                    </span>
                  )}
                </p>
              )}
            </div>
            {/* <div className='d-flex align-items-center star-row main-star-row'> */}
          </div>
        </div>

        <div className="d-flex align-items-center">
          <h5 className="createdBy">
            {ReactHtmlParser(
              getCreatorString(items.creator, items.creator_show)
            )}
          </h5>
          {items.host_count > 0 && (
            <h6 className="ml-auto tour-total-host">
              <span className="font-blue">
                {items.host_count}
                {AuthStorage.getLang() !== "en" && "명"}
              </span>
              {AuthStorage.getLang() !== "en" && "이"}&nbsp;
              {AuthStorage.getLang() === "en"
                ? items.host_count === 1
                  ? t("My_Account.Tour")
                  : t("My_Account.Tours")
                : t("My_Account.Tours")}
            </h6>
          )}
        </div>
        {items.creator === "Host" ? (
          <h5 className="date-tour-card">
            {items?.start_date ? (
              <>
                {changeDateType(items.start_date)}{" "}
                {changeTimeFormat(items.start_time)}{" "}
                {changeTimeFormat(items.end_time) !== "" &&
                  "- " + changeTimeFormat(items.end_time)}
              </>
            ) : (
              ""
            )}
          </h5>
        ) : (
          <h5 className="date-tour-card">
            {items.start_date ? (
              items?.end_date !== null ? (
                <>
                  {changeDateType(items.start_date)}{" "}
                  {changeDateType(items.end_date) !== "" &&
                    "- " + changeDateType(items.end_date)}
                </>
              ) : (
                <>
                  {changeDateType(items.start_date)}
                  {" -"}
                </>
              )
            ) : (
              ""
            )}
          </h5>
        )}
      </div>
      <div
        className="d-flex align-items-center"
        style={{ marginTop: avatarHeight, marginBottom: avatarBottom }}
      >
        {items.hostings?.map((item, i) => {
          if (i < 5) {
            return (
              <div
                className="host-img circle"
                style={{ marginRight: avatarIconMr }}
                onClick={() => {
                  window.open(
                    "itinerary?id=" +
                      item.user?.itinerary_id?.id +
                      "&hostingId=" +
                      item.user?.hosting_id
                  );
                }}
              >
                <img
                  src={item.user.avatar || "./img/Avatar.png"}
                  alt=""
                  className="HostProfileImg"
                  style={{
                    objectFit: "cover",
                    cursor: "pointer",
                    width: "54px",
                    height: "54px",
                  }}
                />
              </div>
            );
          } else if (i == 5) {
            return (
              <div
                className="host-img circle"
                onClick={() => {
                  window.open("itinerary?id=" + item.user?.itinerary_id?.id);
                }}
              >
                <img
                  src={"./img/avatar-right-icon.jpg"}
                  alt=""
                  className="HostProfileImg"
                  style={{
                    objectFit: "cover",
                    cursor: "pointer",
                    width: "54px",
                    height: "54px",
                  }}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default TourCard;

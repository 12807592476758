import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AuthStorage from "../helper/AuthStorage";
import PrivacyUsecard_Ko from "components/PrivacyUsecard_Ko";
import PrivacyUsecard_En from "components/PrivacyUsecard_En";

const PrivacyPolicy = ({ set, value }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={value}
      onHide={() => {
        set(false);
      }}
      dialogClassName={
        AuthStorage.getLang() === "en"
          ? "roboto-text privacy-usecard custom-modal"
          : "noto-text privacy-usecard custom-modal"
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="d-flex">
        <h1 className="custom-modal-title">{t("Privacy_Policy.Title")}</h1>
        <Button
          variant="transparentBtn"
          onClick={() => {
            set(false);
          }}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="20.6973"
              y1="7.75988"
              x2="7.75901"
              y2="20.6981"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <line
              x1="20.5702"
              y1="20.6992"
              x2="7.63194"
              y2="7.76096"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="body-scroll">
          {AuthStorage.getLang() === "en" ? (
            <PrivacyUsecard_En />
          ) : (
            <PrivacyUsecard_Ko />
          )}
        </div>
        {/* <div className="terms-popup">
          <div className="popup-Privacy-policy mt-30">
            <p className="font-18-normal color-darkgray mt-20">
              {t("Privacy_Policy.Body")}
            </p>

            <div className="mt-50">
              <p className="font-18-normal color-darkgray ">
                {t("Privacy_Policy.Point1")}
              </p>
              <p className="font-18-normal color-darkgray ">
                {t("Privacy_Policy.Point2")}
              </p>
              <p className="font-18-normal color-darkgray ">
                {t("Privacy_Policy.Point3")}
              </p>
              <p className="font-18-normal color-darkgray ">
                {t("Privacy_Policy.Point4")}
              </p>
            </div>
          </div>
          <div className="detail-Closebutton d-flex">
            <button
              onClick={() => {
                set(false);
              }}
              // ButtonStyle="termsClose-button"
            >
              {t("Privacy_Policy.Close")}
            </button>
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import { ApiGet } from "../../helper/API/ApiData";
import ViewHostList from "./ViewHostList";
import "./ViewHost.css";

const ViewHost = () => {
  const { t } = useTranslation();

  const [hostType, setHostType] = useState([]);
  const [gender, setGender] = useState([]);
  const [ageGroup, setAgeGroup] = useState([]);

  const handleCheckbox = (e) => {
    switch (e.target.name) {
      case "hosttype":
        if (e.target.checked) {
          setHostType([...hostType, e.target.value]);
        } else {
          setHostType((prev) =>
            prev.filter((currItem) => currItem !== e.target.value)
          );
        }
        break;
      case "gender":
        if (e.target.checked) {
          setGender([...gender, e.target.value]);
        } else {
          setGender((prev) =>
            prev.filter((currItem) => currItem !== e.target.value)
          );
        }
        break;
      case "agegrup":
        if (e.target.checked) {
          setAgeGroup([...ageGroup, e.target.value]);
        } else {
          setAgeGroup((prev) =>
            prev.filter((currItem) => currItem !== e.target.value)
          );
        }
        break;
    }
  };

  const pathname = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main id="ViewHost" className="wrap">
      <div className="layout">
        <div className="left">
          <h3 className="page-title">{t("ViewHost.Header")}</h3>
          <div className="filter-tab-view-host">
            <div className="single-tab-host">
              <h5 className="tab-title">{t("ViewHost.Host_Type")}</h5>

              <div className="res-checkbox">
                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="localHost"
                    value="Local"
                    name="hosttype"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="localHost" className="radio-label">
                    {t("ViewHost.Local_Host")}
                  </label>
                </div>

                {/* <button className='button' name="hosttype" onChange={(e: any) => handleCheckbox(e)}>{t('ViewHost.Traveler_Host')}</button> */}

                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="travelerHost"
                    value="Travel"
                    name="hosttype"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="travelerHost" className="radio-label">
                    {t("ViewHost.Traveler_Host")}
                  </label>
                </div>
              </div>
            </div>

            <div className="single-tab-host-gender">
              <h5 className="tab-title">{t("ViewHost.Gender")}</h5>
              <div className="res-checkbox">
                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="male"
                    value="MALE"
                    name="gender"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="male" className="radio-label">
                    {t("ViewHost.Male")}
                  </label>
                </div>

                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="female"
                    value="FEMALE"
                    name="gender"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="female" className="radio-label">
                    {t("ViewHost.Female")}
                  </label>
                </div>
              </div>
            </div>

            <div className="single-tab-host-age">
              <h5 className="tab-title">{t("ViewHost.Age_Group")}</h5>
              <div className="res-checkbox">
                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="20s"
                    value="20"
                    name="agegrup"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="20s" className="radio-label">
                    {t("ViewHost.20s")}
                  </label>
                </div>

                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="30s"
                    value="30"
                    name="agegrup"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="30s" className="radio-label">
                    {t("ViewHost.30s")}
                  </label>
                </div>

                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="40s"
                    value="40"
                    name="agegrup"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="40s" className="radio-label">
                    {t("ViewHost.40s")}
                  </label>
                </div>

                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="50s"
                    value="50"
                    name="agegrup"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="50s" className="radio-label">
                    {t("ViewHost.50s")}
                  </label>
                </div>

                <div className="inputGroup">
                  <input
                    className="radiobutton"
                    type="checkbox"
                    id="hosttype"
                    value="60"
                    name="agegrup"
                    onChange={(e) => handleCheckbox(e)}
                  />
                  <label htmlFor="hosttype">{t("ViewHost.60s")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <ViewHostList
            hostType={hostType}
            gender={gender}
            ageGroup={ageGroup}
          />
        </div>
      </div>
    </main>
  );
};

export default ViewHost;

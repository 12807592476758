import Pagination from "react-js-pagination";
import { Button, Image } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AuthStorage from "../../helper/AuthStorage";
import AlreadyHostModal from "../modal/AlreadyHostModal";
import Footer from "../../layout/footer/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom";

const initialTourCourse = {
  count: 0,
  data: [
    {
      address: "",
      always_open: false,
      category: "",
      closing_date: "",
      country: "",
      id: "",
      image: [""],
      telephone: "",
      n_p_transportation: "",
      name: "",
      opening_date: "",
      region: "",
      summary: "",
      website: "",
    },
  ],
};

let sameregion = "";
const AddEventsAndPlaces = ({
  set,
  value,
  setCourseList,
  selectedCourse,
  wishlist,
}) => {
  const { t } = useTranslation();

  const initial = {
    data: [
      {
        value: AuthStorage.getLang() === "en" ? "All" : "전체",
        label: `${t("Homepage.NavBar.All")}`,
      },
      {
        value: AuthStorage.getLang() === "en" ? "Local Picks" : "현지인만 아는",
        label: `${t("Homepage.NavBar.Local_Picks")}`,
      },
      {
        value: AuthStorage.getLang() === "en" ? "Arts & Culture" : "문화예술",
        label: AuthStorage.getLang() === "en" ? "Arts & Culture" : "문화예술",
      },
      {
        value:
          AuthStorage.getLang() === "en"
            ? "Festivals & Events"
            : "축제와 이벤트",
        label: `${t("Homepage.NavBar.Festivals")}`,
      },
      {
        value:
          AuthStorage.getLang() === "en" ? "Popular Destinations" : "인기명소",
        label: `${t("Homepage.NavBar.Popular")}`,
      },
    ],
  };

  const [tourDetails, setTourDetails] = useState(false);

  const [tourIndex, setTourIndex] = useState(0);
  const [hostingNotice, setHostingNotice] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const isMobile = window.innerWidth <= 428;

  // const selectedCountry = "South Korea";

  //Country
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("All");

  const getCountry = () => {
    ApiGet("tc/country").then((res) => {
      setCountry([
        { country: AuthStorage.getLang() === "en" ? "All" : "전체" },
        ...res.data.map((x) => {
          return {
            country: AuthStorage.getLang() === "en" ? x.country : x.country_ko,
          };
        }),
      ]);
    });
  };

  //Region
  const [region, setRegion] = useState([]);
  const [selectedReg, setSelectedReg] = useState("");
  // const [selectedWishList, setWishList] = useState<boolean>(false);

  const getRegion = (region) => {
    // ApiGet("itinerary/region").then((res) => {
    //   setRegion([
    //     { region: AuthStorage.getLang() === "en" ? "All" : "전체" },
    //     ...res.data.region.map((x) => {
    //       return { region: x.region };
    //     }),
    //   ]);
    // });
    ApiGet("tc/region?name=" + region).then((res) => {
      setRegion([
        { region: AuthStorage.getLang() === "en" ? "All" : "전체" },
        ...res.data.map((x) => {
          return {
            region: AuthStorage.getLang() === "en" ? x.region : x.region_ko,
          };
        }),
      ]);
    });
  };

  //Categories
  const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState<string>('')

  const getCategory = () => {
    // })
    ApiGet("tc/category").then((res) => {
      setCategories([
        {
          cat_label: AuthStorage.getLang() === "en" ? "All" : "전체",
          category: AuthStorage.getLang() === "en" ? "All" : "전체",
          isSelected: true,
        },
        ...res.data.map((x) => {
          return {
            cat_label:
              AuthStorage.getLang() === "en" ? x.category : x.category_ko,
            category:
              AuthStorage.getLang() === "en" ? x.category : x.category_ko,
            isSelected: false,
          };
        }),
      ]);
    });
  };

  useEffect(() => {
    getCountry();
  }, [AuthStorage.getLang()]);

  useEffect(() => {
    getRegion("All");
  }, [AuthStorage.getLang()]);

  useEffect(() => {
    setPageNo(1);
  }, [categories]);

  //Itinerary List
  const [itinerary, setItinerary] = useState([]);

  // responsive state for more button
  const [perPage, setPerPage] = useState(
    window.screen.availWidth >= 310 && window.screen.availWidth <= 428 ? 4 : 16
  );

  // Tour courses details
  const [tourCourse, setTourCourse] = useState(initialTourCourse);

  const tourCard = (loadNew = false, pCountry = null) => {
    const setTourCountry = pCountry !== null ? pCountry : selectedCountry;
    console.log("setTourCountry  ", setTourCountry);
    ApiPost(
      `itinerary/tourcourse/filter?per_page=${perPage}&page_number=${pageNo}&keyword=${searchInput}`,
      {
        country: setTourCountry,
        region: selectedReg,
        category:
          categories
            .filter(
              (x) =>
                x.isSelected && x.category !== "All" && x.category !== "전체"
            )
            .map((y) => y.category).length > 0
            ? categories
                .filter(
                  (x) =>
                    x.isSelected &&
                    x.category !== "All" &&
                    x.category !== "전체"
                )
                .map((y) => y.category)
                .join(",")
            : "",
        selectedWishList: wishlist,
      }
    ).then((res) => {
      if (isMobile) {
        if (loadNew) {
          setTourCourse(res.data);
        } else {
          const _tourCourse = { ...tourCourse };
          _tourCourse.count = res.data.count;
          _tourCourse.data =
            _tourCourse.data[0].id === ""
              ? res.data.data
              : _tourCourse.data.concat(res.data.data);
          setTourCourse(_tourCourse);
        }
      } else {
        setTourCourse(res.data);
      }
    });
  };

  useEffect(() => {
    setPageNo(1);
    if (value) {
      tourCard(true);
    }
  }, [selectedReg, categories, value]);

  useEffect(() => {
    if (value) {
      tourCard(false);
    }
  }, [pageNo]);

  useEffect(() => {
    if (!value) {
      getCategory();
      setSelectedReg("");
    }
  }, [value]);

  //For tour course checkbox options
  const setList = (e, iti) => {
    if (!itinerary.length) {
      sameregion = iti.region;

      if (e.target.checked) {
        setItinerary([...itinerary, iti]);
      }
    }

    if (itinerary.length) {
      if (e.target.checked) {
        setItinerary([...itinerary, iti]);
      } else {
        setItinerary((prev) =>
          prev.filter((x) => {
            return x.id !== iti.id;
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!itinerary.length) {
      sameregion = "";
    }
  }, [itinerary]);

  function handleResize() {
    if (window.screen.availWidth >= 310 && window.screen.availWidth <= 428) {
      setPerPage(4);
    } else {
      setPerPage(16);
    }
  }

  useEffect(() => {
    getCategory();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Search Filter
  const [searchInput, setSearchInput] = useState("");

  //Checkbox selection
  const checkboxState = (e, selectedCat) => {
    setCategories(
      categories.map((category) => {
        if (category.category === selectedCat.category) {
          category.isSelected = e.target.checked;
        }
        return category;
      })
    );
  };

  // For selected Tour Courses
  useEffect(() => {
    setItinerary(selectedCourse);
  }, [selectedCourse, value]);

  const setDate = (startDate, endDate) => {
    let date = "-";
    if (!startDate && !endDate) {
      date = "-";
    } else if (startDate && !endDate) {
      date = `${moment(startDate).format("YYYY.MM.DD")}`;
    } else {
      date = `${moment(startDate).format("YYYY.MM.DD")} - ${moment(
        endDate
      ).format("YYYY.MM.DD")}`;
      // date = `${moment(startDate).format("YYYY.MM.DD") + " (" + moment(startDate).format("ddd") + ") " + moment(startDate).format("h:mm") + " - " + moment(endDate).format("h:mm")}`;
    }

    return date;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      tourCard(true);
      event.preventDefault();
    }
  };

  const totalIteneary = () => {
    return itinerary.filter((e) => e.is_wishlist === wishlist).length;
  };

  const [perCourse, setPerCourse] = useState();

  useEffect(() => {
    window.innerWidth <= 767 ? setPerCourse(4) : setPerCourse(16);
  }, [perCourse]);

  return (
    <>
      <Modal
        show={value}
        onHide={() => {
          setSelectedReg("");
          set(false);
          setItinerary([]);
          setSearchInput("");
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text tour-courses custom-modal"
            : "noto-text tour-courses custom-modal"
        }
      >
        <Modal.Header>
          <Modal.Title
            id="tour-course-title"
            className="d-flex justify-content-between w-100"
          >
            {wishlist ? (
              <h6 className="font-30-bold color-dark h-40">
                {t("Add_Place.HeaderWislist")}
              </h6>
            ) : (
              <h6 className="font-30-bold color-dark h-40">
                {t("Add_Place.Header")}
              </h6>
            )}
            <Button variant="transparentBtn" onClick={() => set(false)}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="20.6973"
                  y1="7.75988"
                  x2="7.75901"
                  y2="20.6981"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <line
                  x1="20.5702"
                  y1="20.6992"
                  x2="7.63194"
                  y2="7.76096"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </Button>

            {/* Responsive close Button End */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="top-serach-tour">
            <div className="d-flex search-field">
              <p className="filter-title">{t("Add_Place.Country")}</p>
              <div className="d-flex filter-items">
                {country.map((items, i) => (
                  <div className="filter-item" key={i}>
                    <input
                      type="radio"
                      name="country"
                      id={`country${i}`}
                      value={items.country}
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                        getRegion(e.target.value);
                        tourCard(true, e.target.value);
                        // setSelectedReg("All");
                      }}
                      defaultChecked={i === 0 ? true : false}
                    />
                    <label htmlFor={`country${i}`} key={i}>
                      {items.country}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex search-field">
              <p className="filter-title">{t("Add_Place.City")}</p>
              <div className="d-flex filter-items">
                {region.map((items, i) => (
                  <div className="filter-item" key={i}>
                    <input
                      type="radio"
                      name="city"
                      id={`city${i}`}
                      value={items.region}
                      defaultChecked={i === 0 ? true : false}
                      onChange={(e) => {
                        setSelectedReg(e.target.value);
                      }}
                    />
                    <label htmlFor={`city${i}`}>{items.region}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex search-field">
              <p className="filter-title2">
                {t("Add_Place.Category")}
                <span className="font-blue">{t("Add_Place.Span")}</span>
              </p>
              <div className="d-flex filter-items">
                {categories.map((cat, i) => (
                  <div className="filter-item" key={i}>
                    <input
                      type="checkbox"
                      name="category"
                      id={`categories${i}`}
                      value={cat.category}
                      checked={cat.isSelected}
                      onChange={(e) => {
                        if (
                          e.target.value ===
                          (AuthStorage.getLang() === "en" ? "All" : "전체")
                        ) {
                          setCategories(
                            categories.map((category) => {
                              if (category.category === e.target.value) {
                                category.isSelected = e.target.checked;
                              } else {
                                category.isSelected = false;
                              }
                              return category;
                            })
                          );
                        } else {
                          setCategories(
                            categories.map((category) => {
                              if (
                                category.category ===
                                (AuthStorage.getLang() === "en"
                                  ? "All"
                                  : "전체")
                              ) {
                                category.isSelected = false;
                              }
                              return category;
                            })
                          );
                          checkboxState(e, cat);
                        }
                        // setWishList(false)
                      }}
                    />
                    <label htmlFor={`categories${i}`}>{cat.cat_label}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="search-keywords d-flex justify-content-between align-items-center">
            <input
              name="itnerySearch"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              placeholder={t("Add_Place.Keyword")}
              type="text"
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="confirmBtn"
              onClick={() => tourCard(true)}
              className="search"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 17L12.0962 12.0962M12.0962 12.0962C13.2725 10.9199 14 9.29493 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C9.29493 14 10.9199 13.2725 12.0962 12.0962Z"
                  stroke="white"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <span>{t("Add_Place.Search")}</span>
            </Button>
          </div>

          <h3 className="filter-title filter-total d-flex align-items-center">
            {t("Add_Place.Results1")}
            <span className="font-blue">&nbsp;{tourCourse?.count}&nbsp;</span>
            {t("Add_Place.Results2")}&nbsp;｜&nbsp;{t("Add_Place.Selected1")}
            <span className="font-blue">&nbsp;{totalIteneary()}&nbsp;</span>
            {t("Add_Place.Selected2")}
          </h3>

          <div className="filter-results-items d-grid">
            {tourCourse &&
              tourCourse?.data?.map((cards, i) => (
                <div
                  key={i}
                  className={
                    itinerary.map((x) => x.id).includes(cards.id)
                      ? "course-card b-red position-relative"
                      : "course-card b-trans position-relative"
                  }
                  onClick={() => {}}
                >
                  <div
                    className="imgArea w-100"
                    onClick={() => {
                      setTourIndex(i);
                      setTourDetails(true);
                    }}
                  >
                    <Image
                      className="objectfit-cover"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../../../img/Image-Base.svg";
                      }}
                      src={
                        cards?.image && cards?.image[0]
                          ? cards?.image[0]
                          : "../../../img/Image-Base.svg"
                      }
                      alt="Tour Course Image"
                    />
                  </div>
                  <div className="card-details">
                    <h3 className="course-title">{cards.name}</h3>
                    <p className="course-region">
                      @{cards.region} , {cards.country}{" "}
                    </p>
                    <h5 className="course-category">{cards.category}</h5>
                    {/* <h5 className="font-16-normal color-black mt-28">{cards.always_open ? "-" : setDate(cards.opening_date, cards.closing_date)}</h5> */}
                    <h5 className="course-date">
                      {setDate(cards.opening_date, cards.closing_date)}
                    </h5>
                  </div>

                  <div className="position-absolute tour-check">
                    <input
                      type="checkbox"
                      name="course-card"
                      id={`course${i}`}
                      value="agree"
                      checked={itinerary
                        .map((x) => x.id)
                        .includes(tourCourse.data[i].id)}
                      onChange={(e) => {
                        const iti = {
                          id: tourCourse.data[i].id,
                          name: tourCourse.data[i].name,
                          image: tourCourse.data[i].image,
                          region: tourCourse.data[i].region,
                          country: tourCourse.data[i].country,
                          is_wishlist: wishlist,
                          category: tourCourse.data[i].category,
                        };

                        setList(e, iti);
                      }}
                    />
                    <label
                      htmlFor={`course${i}`}
                      className="check-marker circle"
                    ></label>
                  </div>
                </div>
              ))}

            {/* Responsive tour model content end */}
          </div>
          {tourCourse.count > 3 ? (
            <Pagination
              itemClass="page-item-custom"
              activeLinkClass="activepage"
              linkClass="page-link-custom"
              linkClassFirst="page-first-arrow page-arrow"
              linkClassPrev="page-prev-arrow page-arrow"
              linkClassNext="page-next-arrow page-arrow"
              linkClassLast="page-last-arrow page-arrow"
              activePage={pageNo}
              itemsCountPerPage={perCourse}
              pageRangeDisplayed={10}
              totalItemsCount={tourCourse.count}
              onChange={(e) => {
                setPageNo(e);
              }}
            />
          ) : null}

          {tourCourse.count > tourCourse.data.length && (
            <div className="res-button">
              <Button variant="cancelBtn" onClick={() => setPageNo(pageNo + 1)}>
                More
              </Button>
            </div>
          )}
          <Modal.Footer className="d-flex">
            <Button
              variant="cancelBtn"
              // ButtonStyle="host-own-cancel-btn w-280"
              onClick={() => {
                set(false);
                setItinerary([]);
                setSelectedReg("");
              }}
            >
              {t("Add_Place.Cancel")}
            </Button>

            <Button
              variant="confirmBtn"
              // ButtonStyle="host-btn w-280"
              onClick={() => {
                setCourseList(itinerary);
                set(false);
                // setItinerary([]);
              }}
            >
              {t("Add_Place.Add")}
            </Button>
          </Modal.Footer>
          <div className="mobile-footer">
            <Footer />
          </div>
          {/* Responsive Show Footer End */}
        </Modal.Body>
      </Modal>

      {/*Tour Course Details Modal */}
      <Modal
        show={tourDetails}
        onHide={() => {
          setTourDetails(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text tour-details-modal custom-modal"
            : "noto-text tour-details-modal custom-modal"
        }
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title
            id="tour-course-title"
            className="d-flex justify-content-between w-100"
          >
            {wishlist ? (
              <h6 className="font-30-bold color-dark h-40">
                {t("Tour_Course_Details.Header")}
              </h6>
            ) : (
              <h6 className="font-30-bold color-dark h-40">
                {t("Tour_Course_Details.Header")}
              </h6>
            )}
            <Button
              variant="transparentBtn"
              onClick={() => setTourDetails(false)}
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="20.6973"
                  y1="7.75988"
                  x2="7.75901"
                  y2="20.6981"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <line
                  x1="20.5702"
                  y1="20.6992"
                  x2="7.63194"
                  y2="7.76096"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </Button>

            {/* Responsive close Button End */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="itinery_details_modal p-0">
          {tourCourse.data && (
            <table className="tour-details-table">
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.City")}</h6>
                </th>
                <td>
                  <pre>
                    {tourCourse?.data[tourIndex]?.region +
                      ", " +
                      tourCourse?.data[tourIndex]?.country}
                  </pre>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.Category")}</h6>
                </th>
                <td>
                  <pre>{tourCourse?.data[tourIndex]?.category}</pre>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.Name")}</h6>
                </th>
                <td>
                  <pre>{tourCourse?.data[tourIndex]?.name}</pre>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.Photos")}</h6>
                </th>
                <td>
                  <div className="upload-pic d-flex flex-wrap">
                    {tourCourse?.data[tourIndex]?.image.map((data, i) => (
                      <div className="imgArea">
                        <img
                          className="objectfit-cover"
                          key={i}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "../../../img/download.svg";
                          }}
                          src={data}
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.Date")}</h6>
                </th>
                <td>
                  <pre>
                    {setDate(
                      tourCourse?.data[tourIndex]?.opening_date,
                      tourCourse?.data[tourIndex]?.closing_date
                    )}
                  </pre>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6 className="font-16-bold color-black about-th">
                    {t("Tour_Course_Details.About")}
                  </h6>
                </th>
                <td>
                  <pre className="about-td">
                    {tourCourse?.data[tourIndex]?.summary}
                  </pre>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.Address")}</h6>
                </th>
                <td>
                  <pre>{tourCourse?.data[tourIndex]?.address}</pre>
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.URL")}</h6>
                </th>
                <td>
                  {tourCourse?.data[tourIndex]?.website !== "-" ? (
                    <a
                      className="url"
                      href={tourCourse?.data[tourIndex]?.website}
                      target="_bank"
                    >
                      {tourCourse?.data[tourIndex]?.website}
                    </a>
                  ) : (
                    <pre className="url">
                      {tourCourse?.data[tourIndex]?.website}
                    </pre>
                  )}
                </td>
              </tr>
              <tr>
                <th className="h-100">
                  <h6>{t("Tour_Course_Details.Phone_Number")}</h6>
                </th>
                <td>
                  <pre>{tourCourse?.data[tourIndex]?.telephone}</pre>
                </td>
              </tr>
            </table>
          )}
        </Modal.Body>
      </Modal>
      <AlreadyHostModal
        hostingNotice={hostingNotice}
        setHostingNotice={() => setHostingNotice(false)}
      />
    </>
  );
};

export default AddEventsAndPlaces;

import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
// import Buttons from "../../components/Buttons";
import { ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import * as QueryString from "query-string";
import { useTranslation } from "react-i18next";
import moment from "moment";
// import Rating from "react-rating";
// import InputField from "../../components/Inputfield";
import { checkImageURL, transporTation } from "../../helper/utils";
import {
  setChatId,
  setMessageState,
  setOtherUserData,
} from "../../redux/action/chatDataAction";
import { db } from "../../firebaseConfig";
import firebase from "firebase";
import AuthStorage from "../../helper/AuthStorage";
// import TextareaAutosize from "react-textarea-autosize";
import Login from "../modal/Login";
// import { getNotification } from "../../redux/action/notificationAction";
// import STORAGEKEY from "../../config/APP/app.config";
import AccountDeactive from "../modal/AccountDeactive";
import { urlify } from "../../helper/utils";
import Lightbox from "react-image-lightbox";

const UserHostList = (props) => {
  const [joinHost, setjoinHost] = useState(false);
  const [appApply, setappApply] = useState(false);
  const [cancleApp, setcancleApp] = useState(false);
  const [cancleConfirm, setcancleConfirm] = useState(false);
  const [reqCancle, setreqCancle] = useState(false);
  const [conDelete, setconDelete] = useState(false);
  const location = useLocation();
  //Review
  // const [feedback, setFeedback] = useState("");
  // const [stars, setStars] = useState(0);

  const { is_loggedin } = useSelector((state) => state.login);

  //Popup State
  const [hostNotice, sethostNotice] = useState(false);
  const [cancleHosting, setCancleHosting] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [reviewNot, setreviewNot] = useState(false);
  const [isOpenImageTwo, setIsOpenImageTwo] = useState("");

  const history = useHistory();
  const params = QueryString.parse(history.location.search);

  const [userHostList, setUserHostList] = useState();
  const [participantList, setParticipantList] = useState([]);
  const { userData } = useSelector((state) => state.userData);
  const [isLiked, setIsLiked] = useState(false);
  const [isOpenImage, setIsOpenImage] = useState(false);

  // buttons states
  const [checkJoinButton, setCheckJoinButton] = useState(false);
  const [checkCancleBox, setCheckCancleBox] = useState(false);
  const [checkHostingCancle, setCheckHostingCancle] = useState(false);
  const [checkDeclined, setCheckDeclined] = useState(false);
  const [applicationAccepted, setApplicationAccepted] = useState(false);
  const [standBy, setStandBy] = useState(false);
  const [checkReviewButton, setCheckReviewButton] = useState(false);
  const [hostingCom, SethostingCom] = useState(false);
  const [comHostNotice, SetcomHostNotice] = useState(false);
  const [isHostingCompleted, setIsHostingCompleted] = useState(false);
  const [isHostingStandBy, setIsHostingStandBy] = useState(false);
  const [isReviewSubmited, setIsReviewSubmited] = useState(false);
  const { notificationData } = useSelector((state) => state.notification);
  const [deactivatedNotice, setDeactivatedNotice] = useState(false);
  const [hostMsgOff, setHostMsgOff] = useState(false);

  const reqdelBtn = () => {
    setreqCancle(false);
    setconDelete(true);

    ApiPost(`hosting/participate/${userHostList.id}`, {}).then((res) => {
      getHosting();
      setCheckCancleBox(false);
    });
  };

  //For Translation
  const { t } = useTranslation();

  // handlers
  const getHosting = () => {
    if (params.noti === "list" || params.noti === "mypage") {
      ApiGet(`hosting/host-itinerary-complited/${params.id}`).then((res) => {
        const data = props.isCompas
          ? res?.data?.find((x) => x.id === props.isCompas)
          : res.data[0];
        setIsLiked(data?.user.like);
        setUserHostList(data);
        getParticipants(data?.id);
        if (data?.status === "COMPLETED") {
          setIsHostingCompleted(true);
          setCheckReviewButton(true);
        }
        if (data?.status === "STAND_BY") {
          setIsHostingStandBy(true);
        }
      });
    } else if (params.noti === "host") {
      ApiGet(`hosting/host-itinerary-cancle/${params.id}`).then((res) => {
        const data = props.isCompas
          ? res?.data?.find((x) => x.id === props.isCompas)
          : res.data[0];
        setIsLiked(data?.user.like);
        setUserHostList(data);
        getParticipants(data?.id);
        if (data?.status === "COMPLETED") {
          setIsHostingCompleted(true);
          setCheckReviewButton(true);
        }
      });
    } else {
      const data = notificationData.filter(
        (x) =>
          x.data.creator === "Host" &&
          x.data.itinerary_id === params.id &&
          x.data.type === "CANCEL"
      );

      if (data.length > 0) {
        if (location.pathname === "/itinerary") {
          // window.location.href = `/deleteitinerary?id=${params.id}&creator=${data[0].data.creator}`;
          window.location.href = `/error`;
        } else {
          history.push(`/error`);
          // history.push(`/deleteitinerary?id=${params.id}&creator=${data[0].data.creator}`);
        }
      } else {
        const dataDeleted = notificationData.filter(
          (x) =>
            x.data.creator === "Host" &&
            x.data.itinerary_id === params.id &&
            x.data.type === "DELETED"
        );
        if (dataDeleted.length > 0) {
          if (dataDeleted[0].data.creator === "Host") {
            if (location.pathname === "/itinerary") {
              // window.location.href = `/deleteitinerary?id=${params.id}&creator=${dataDeleted[0].data.creator}`;
              window.location.href = `/error`;
            } else {
              history.push(`/error`);
              // history.push(`/deleteitinerary?id=${params.id}&creator=${dataDeleted[0].data.creator}`);
            }
          } else {
            if (dataDeleted[0].data.disclosure === "PRIVATE") {
              if (location.pathname === "/itinerary") {
                // window.location.href = `/deleteitinerary?id=${params.id}&creator=${dataDeleted[0].data.creator}`;
                window.location.href = `/error`;
              } else {
                history.push(`/error`);
                // history.push(`/deleteitinerary?id=${params.id}&creator=${dataDeleted[0].data.creator}`);
              }
            } else {
              if (location.pathname === "/itinerary") {
                window.location.href = `/itinerary?id=${params.id}`;
              } else {
                history.push(`/itinerary?id=${params.id}`);
              }
            }
          }
        } else {
          const dataNew = notificationData.filter(
            (x) =>
              x.data.itinerary_id === params.id &&
              x.data.hosting_id === params.hostingId &&
              x.data.type === "CANCEL"
          );

          if (dataNew.length > 0) {
            if (location.pathname === "/itinerary") {
              window.location.href = `/itinerary?id=${params.id}`;
            } else {
              history.push(`/itinerary?id=${params.id}`);
            }
          } else {
            ApiGet(`hosting/host-itinerary/${params.id}`).then((res) => {
              const data = props.isCompas
                ? res?.data?.find((x) => x.id === props.isCompas)
                : res.data[0];
              setIsLiked(data?.user.like);
              setUserHostList(data);
              getParticipants(data?.id);
              if (data?.status === "COMPLETED") {
                setIsHostingCompleted(true);
                setCheckReviewButton(true);
              }
            });
          }
        }
      }
    }

    // ApiGet(`hosting/hosting-check-detail?itinerary_id=${params.id}`).then((res:any)=>{
    //   if(res.data.status == "CANCELED"){
    //     if(res.data.creator == "Host"){
    //       if (location.pathname === "/itinerary") {
    //         window.location.href = `/deleteitinerary?id=${params.id}&creator=${res.data.creator}`;
    //     } else {
    //         history.push(`/deleteitinerary?id=${params.id}&creator=${res.data.creator}`);
    //     }
    //     }else{
    //       if (location.pathname === "/itinerary") {
    //         window.location.href = `/itinerary?id=${params.id}`;
    //     } else {
    //         history.push(`/itinerary?id=${params.id}`);
    //     }
    //     }

    //   }else{
    //   ApiGet(`hosting/host-itinerary/${params.id}`).then((res: any) => {
    //     const data = props.isCompas ? res?.data?.find((x: any) =>
    //       x.id === props.isCompas
    //     ) : res.data[0]
    //     setIsLiked(data?.user.like)
    //     setUserHostList(data);
    //     getParticipants(data?.id);
    //     if (data?.status === "COMPLETED") {
    //       setIsHostingCompleted(true);
    //       setCheckReviewButton(true);
    //     }
    //   });
    // }

    // }).catch((err:any)=>{

    // })

    // }
  };

  const setapplyBtn = () => {
    setjoinHost(false);
    setappApply(true);
  };

  // const ReviewSubBtn = () => {
  //   setReviewPopup(false);
  //   setreviewNot(true);
  // };

  const cancleConfirmBtn = () => {
    setcancleApp(false);
    setcancleConfirm(true);

    ApiPost(`hosting/participate/${userHostList.id}`, {}).then((res) => {
      getHosting();
      setCheckCancleBox(false);
    });
  };

  const getParticipants = (id) => {
    ApiGet(`hosting/accept-participants/${id}`).then((res) => {
      setParticipantList(res.data.participants);
      setIsReviewSubmited(res.data.is_reviewed);
    });
  };

  const sendApplication = () => {
    ApiPost(`hosting/participate/${userHostList.id}`, {}).then((res) => {
      setCheckCancleBox(true);
    });
  };

  const handleJoinHosting = () => {
    sendApplication();
    setapplyBtn();
  };

  const SethostingComBtn = () => {
    SethostingCom(false);
    SetcomHostNotice(true);
  };

  const cancleHostingFunction = () => {
    setCancleHosting(false);
    ApiPut(`hosting/cancelHosting/${userHostList.id}`, {}).then((res) => {
      sethostNotice(true);
    });
  };

  //Complete Hosting
  const completeHosting = () => {
    ApiPut(`hosting/completeHosting/${userHostList.id}`, {}).then((res) => {
      SethostingComBtn();
      setIsHostingCompleted(true);
      setCheckReviewButton(true);
      props.setIsHostingCompleted(true);
      // props.setRefreshPax(Math.random());
    });
  };

  //Like functonality
  const [delayLike, setDelayLike] = useState(false);

  const Like = (id) => {
    setIsLiked(!isLiked);
    setDelayLike(true);

    ApiPost(`user/wishlist/${id}`, {
      hosting_id: params?.hostingId,
    }).then((res) => {
      setDelayLike(false);
    });
  };

  //Checking for hosting completion
  const checkIsComplete = (date, endTime) => {
    let time = moment(
      moment(date).format("YYYY:MM:DD") + " " + endTime,
      "YYYY:MM:DD HH:mm"
    );
    return time.toDate() < moment(new Date(), "YYYY:MM:DD HH:mm").toDate();
  };

  // Effects
  useEffect(() => {
    getHosting();
  }, [props.refresh]);

  useEffect(() => {
    if (notificationData) {
      getHosting();
    }
  }, [notificationData]);

  useEffect(() => {
    if (userHostList) {
      // getHosting();
      if (userData && userHostList.user.id === userData.id) {
        setCheckHostingCancle(true);
      } else {
        userData &&
          is_loggedin &&
          ApiGet(`hosting/isrequested-participants/${userHostList.id}`).then(
            (res) => {
              if (res.data) {
                if (res.data.status === "DECLINED") {
                  setCheckDeclined(true);
                } else {
                  setCheckCancleBox(true);
                }
                setApplicationAccepted(res.data.status === "ACCEPTED");
                setStandBy(res.data.status === "STAND_BY");
              } else {
                setCheckJoinButton(true);
              }
            }
          );
        setCheckJoinButton(true);
      }
    }
  }, [userHostList, userData]);

  useEffect(() => {
    handleButtons();
  }, [checkDeclined, checkCancleBox, checkHostingCancle, userHostList]);

  // Create a chat
  const dispatch = useDispatch();
  const { chatData, message_open } = useSelector((state) => state.chatData);
  const createChat = async (current_user, other_user) => {
    let doc_id;
    if (
      !current_user.id &&
      !other_user.id &&
      current_user.id === other_user.id
    ) {
      return;
    }
    if (current_user.id > other_user.id) {
      doc_id = current_user.id + other_user.id;
    } else {
      doc_id = other_user.id + current_user.id;
    }

    if (chatData.find((x) => x.id === doc_id)) {
      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    } else {
      const ref = db.collection(AuthStorage.getMessageCollection()).doc(doc_id);
      await ref.set(
        {
          [`${current_user.id}_count`]: 0,
          [`${other_user.id}_count`]: 0,
          lastMessage: "",
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          ids: [current_user.id, other_user.id],
          [`${current_user.id}`]: {
            id: current_user.id,
            name: current_user.name,
            profile_url: current_user.profile_url,
          },
          [`${other_user.id}`]: {
            id: other_user.id,
            name: other_user.name,
            profile_url: other_user.profile_url,
          },
        },
        { merge: true }
      );

      dispatch(setChatId(doc_id));
      dispatch(setOtherUserData(other_user));
      dispatch(setMessageState(!message_open));
    }
  };

  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const notlogin = () => {
    setModalShow2(true);
  };

  const openImageSection = () => {
    setIsOpenImage(true);
  };

  // component helper functions
  const hostDetails = () => (
    <>
      <div className="like-with-chat d-flex justify-content-end align-items-center">
        {userData?.notification ? (
          userHostList?.user.notification_on ? (
            userData.id !== userHostList?.user.id ? (
              <>
                <svg
                  className="chatbox"
                  onClick={(e) => {
                    is_loggedin ? (
                      is_active ? (
                        <>
                          {userData?.notification &&
                            userHostList?.user.notification_on &&
                            createChat(
                              {
                                id: userData.id,
                                name: userData.user_name,
                                profile_url: userData.avatar,
                              },
                              {
                                id: userHostList?.user.id,
                                name: userHostList?.user.user_name,
                                profile_url: userHostList?.user.avatar,
                              }
                            )}
                        </>
                      ) : (
                        setDeactivatedNotice(true)
                      )
                    ) : (
                      notlogin()
                    );
                  }}
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7342 29.9695C13.0516 30.1267 13.369 30.5984 13.2103 31.07L12.0994 34.3712C11.782 34.5284 12.0994 35 12.4168 35L24.478 30.9128C30.0325 28.8691 34 23.6815 34 17.3934C34 8.90451 26.5411 2.14483 17.8126 3.08804C11.1472 3.71685 5.75146 9.06171 5.11666 15.8214C4.32316 21.9523 7.65585 27.4543 12.7342 29.9695Z"
                    stroke="#42B6E6"
                    stroke-width="2"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M15 15.5469H24.5477"
                    stroke="#42B6E6"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M15 20.2891H24.5477"
                    stroke="#42B6E6"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                </svg>
              </>
            ) : null
          ) : (
            <svg
              onClick={(e) => {
                is_loggedin
                  ? is_active
                    ? setHostMsgOff(true)
                    : setDeactivatedNotice(true)
                  : notlogin();
              }}
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7342 29.9695C13.0516 30.1267 13.369 30.5984 13.2103 31.07L12.0994 34.3712C11.782 34.5284 12.0994 35 12.4168 35L24.478 30.9128C30.0325 28.8691 34 23.6815 34 17.3934C34 8.90451 26.5411 2.14483 17.8126 3.08804C11.1472 3.71685 5.75146 9.06171 5.11666 15.8214C4.32316 21.9523 7.65585 27.4543 12.7342 29.9695Z"
                stroke="#c9c9c9"
                stroke-width="2"
                stroke-miterlimit="10"
              />
              <path
                d="M15 15.5469H24.5477"
                stroke="#c9c9c9"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M15 20.2891H24.5477"
                stroke="#c9c9c9"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </svg>
          )
        ) : (
          <svg
            onClick={(e) => {
              is_loggedin ? (
                is_active ? (
                  <>
                    {userData?.notification &&
                      userHostList?.user.notification_on &&
                      createChat(
                        {
                          id: userData.id,
                          name: userData.user_name,
                          profile_url: userData.avatar,
                        },
                        {
                          id: userHostList?.user.id,
                          name: userHostList?.user.user_name,
                          profile_url: userHostList?.user.avatar,
                        }
                      )}
                  </>
                ) : (
                  setDeactivatedNotice(true)
                )
              ) : (
                notlogin()
              );
            }}
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7342 29.9695C13.0516 30.1267 13.369 30.5984 13.2103 31.07L12.0994 34.3712C11.782 34.5284 12.0994 35 12.4168 35L24.478 30.9128C30.0325 28.8691 34 23.6815 34 17.3934C34 8.90451 26.5411 2.14483 17.8126 3.08804C11.1472 3.71685 5.75146 9.06171 5.11666 15.8214C4.32316 21.9523 7.65585 27.4543 12.7342 29.9695Z"
              stroke="#c9c9c9"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M15 15.5469H24.5477"
              stroke="#c9c9c9"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M15 20.2891H24.5477"
              stroke="#c9c9c9"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </svg>
        )}
        <div className="heart-div">
          <label>
            <input
              type="checkbox"
              checked={isLiked}
              disabled={delayLike}
              onChange={() => {
                is_loggedin ? Like(userHostList?.user.id) : notlogin();
              }}
              className="instruments"
            />
            {!isLiked ? (
              <svg
                width="39"
                height="38"
                viewBox="0 0 39 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                  stroke="#C9C9C9"
                  stroke-width="2"
                />
                <rect x="22" y="13" width="17" height="14" fill="white" />
                <rect x="29" y="14" width="2" height="12" fill="#C9C9C9" />
                <rect
                  x="36"
                  y="19"
                  width="2"
                  height="12"
                  transform="rotate(90 36 19)"
                  fill="#C9C9C9"
                />
              </svg>
            ) : (
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.9394 8.02456L32.9418 8.02695C35.6861 10.7191 35.6861 15.1029 32.9418 17.795L32.9417 17.7951L29.6053 21.0694L28.8803 21.7808L28.904 21.8042L18.992 31.5945L5.0446 17.8185C2.31847 15.1259 2.31847 10.7383 5.0446 8.04568C7.7776 5.34627 12.2413 5.34627 14.9743 8.04568L18.2679 11.2988L18.9683 11.9906L19.671 11.301L23.0074 8.02681L23.0097 8.02456C25.7427 5.32515 30.2064 5.32515 32.9394 8.02456Z"
                  fill="#FF5F5F"
                  stroke="#FF5F5F"
                  stroke-width="2"
                />
              </svg>
            )}
          </label>
        </div>
      </div>
      <div className="single-host-info">
        <div className="host-inner d-flex align-items-center">
          <div className="postion-releative">
            <div className="host-img circle">
              <img
                src={userHostList.user.avatar || "./img/Avatar.png"}
                alt=""
                style={{ objectFit: "cover", cursor: "pointer" }}
                onClick={openImageSection}
              />
            </div>
          </div>
          {isOpenImage && (
            <div className="open-image-overlap">
              <Lightbox
                wrapperClassName="lightbox-profile"
                mainSrc={userHostList.user?.avatar || "./img/Avatar.png"}
                onCloseRequest={() => {
                  setIsOpenImage(false);
                }}
              />
            </div>
          )}
          <div className="pro-tag-name">
            <div className="name-with-flag d-flex">
              <h3 className="pro-name-suah">
                {userHostList.user.user_name.length < 9
                  ? userHostList.user.user_name
                  : userHostList.user.user_name.slice(0, 9) + "..."}
              </h3>
              <div className="host-flag circle">
                <img
                  src={checkImageURL(userHostList.user.nationality)}
                  style={{ objectFit: "cover" }}
                  alt="flag"
                />
              </div>
            </div>
            <div className="pro-tag-suah d-flex">
              {userHostList.type === "Local" ? (
                <div className="host-category">
                  <p>{t("Local_Host")}</p>
                </div>
              ) : (
                <div className="travel-host-category">
                  <p>{t("Traveler_Host")}</p>
                </div>
              )}
              {userHostList.user.gender === "MALE" ? (
                <div className="host-gender">
                  <p>{t("Male")}</p>
                </div>
              ) : (
                <div className="host-gender">
                  <p>{t("Female")}</p>
                </div>
              )}
              <div className="host-ages">
                {AuthStorage.getLang() === "en" ? (
                  <p>
                    {userHostList.user.age_group}'{t("Age_Groups")}
                  </p>
                ) : (
                  <p className="d-flex align-items-center justify-content-center">
                    {userHostList.user.age_group}
                    <span>{t("Age_Groups")}</span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const is_active = AuthStorage.isUserActive();
  const joinButton = () => (
    <>
      <div className="btnbox">
        {/* <Button
        className="hosts-btn mt-15 p-0"
        onClick={() => {
          // applicationAccepted ?
          is_loggedin && setReviewPopup(true);
        }}
      >
        {t("Host_Details.Write_Review")}
      </Button> */}
        <Button
          variant="confirmBtn"
          className="w-100"
          onClick={() => {
            if (is_loggedin) {
              if (is_active) {
                setjoinHost(true);
              } else {
                setDeactivatedNotice(true);
              }
            } else {
              notlogin();
            }
          }}
        >
          {t("Host_Details.Join")}
        </Button>
      </div>
    </>
  );

  const completeHostingBtn = () => (
    <>
      {userHostList.participate_count === userHostList.pax ? (
        checkIsComplete(userHostList.date, userHostList.end_time) ? (
          <Button
            variant="confirmBtn"
            className="single-host-appstatus w-100"
            onClick={() => {
              is_loggedin && SethostingCom(true);
            }}
          >
            <img
              src="./img/whiteWrite.svg"
              className=""
              alt="whiteWrite icon"
            />
            {t("Host_Details.Hosting_Complete.P1")}{" "}
            {t("Host_Details.Hosting_Complete.P2")}
          </Button>
        ) : (
          <>
            {userData.id === userHostList?.user?.id ? (
              <Button
                className="w-100 single-host-appstatus"
                variant="confirmBtn"
              >
                <img src="./img/time-left.svg" alt="" className="time-left" />
                {t("Host_Details.Hosting_Now")}
              </Button>
            ) : (
              <Button
                variant="confirmBtn"
                className="single-host-appstatus w-100"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27ZM11.5 15.5C12.3284 15.5 13 14.8284 13 14C13 13.1716 12.3284 12.5 11.5 12.5C10.6716 12.5 10 13.1716 10 14C10 14.8284 10.6716 15.5 11.5 15.5ZM22 14C22 14.8284 21.3284 15.5 20.5 15.5C19.6716 15.5 19 14.8284 19 14C19 13.1716 19.6716 12.5 20.5 12.5C21.3284 12.5 22 13.1716 22 14ZM9.26273 18.8157C8.87962 18.2188 9.3559 17.5 10.0651 17.5H10.2259C10.5829 17.5 10.9075 17.6942 11.1151 17.9847C12.2032 19.5074 13.9857 20.5 16 20.5C18.0143 20.5 19.7968 19.5074 20.8849 17.9847C21.0925 17.6942 21.4171 17.5 21.7741 17.5H21.9349C22.6441 17.5 23.1204 18.2188 22.7373 18.8157C21.3148 21.0317 18.8289 22.5 16 22.5C13.1711 22.5 10.6852 21.0317 9.26273 18.8157Z"
                    fill="white"
                  />
                </svg>

                {t("Host_Details.Full_House")}
              </Button>
            )}
          </>
        )
      ) : checkIsComplete(userHostList.date, userHostList.end_time) ? (
        userHostList.confirm_status === "request_review" ? null : (
          <Button
            variant="confirmBtn"
            className="single-host-appstatus w-100"
            onClick={() => {
              is_loggedin && SethostingCom(true);
            }}
          >
            <img src="./img/whiteWrite.svg" alt="whiteWrite icon" />
            {t("Host_Details.Hosting_Complete.P1")}{" "}
            {t("Host_Details.Hosting_Complete.P2")}
          </Button>
        )
      ) : userHostList.confirm_status === "request_review" ? null : (
        <Button variant="confirmBtn" className="w-100 single-host-appstatus">
          {(userHostList?.date, userHostList?.end_time) ? (
            <img
              src="./img/time-left.svg"
              alt="time-left icon"
              className="time-left"
            />
          ) : (
            <img src="./img/whiteWrite.svg" alt="whiteWrite icon" />
          )}
          {(userHostList?.date, userHostList?.end_time)
            ? t("Host_Details.Hosting_Now")
            : t("Host_Details.Hosting_Complete.P3")}
        </Button>
      )}
      {userHostList.confirm_status === "request_review" ? null : (
        <div className="d-flex justify-content-end">
          <button
            className="cancleApplicationbtn"
            onClick={() => {
              is_loggedin && setCancleHosting(true);
            }}
          >
            {t("Host_Details.Cancel_Btn.P1")}&nbsp;
            {t("Host_Details.Cancel_Btn.P2")}
          </button>
        </div>
      )}
    </>
  );

  const cancleHostingButton = () => (
    <>
      {isHostingStandBy ? (
        <></>
      ) : isHostingCompleted ? (
        <>
          <Button variant="cancelBtn" className="w-100 single-host-appstatus">
            <img src="./img/blueWrite.svg" alt="" />
            {t("Host_Details.Hosting_Completed")}
          </Button>
        </>
      ) : (
        completeHostingBtn()
      )}
    </>
  );

  const cancleApplicationButton = () => (
    <>
      {checkReviewButton ? (
        reviewButton()
      ) : (
        <>
          <Button variant="cancelBtn" className="single-host-appstatus w-100">
            {applicationAccepted ? (
              <img src="./img/blueWrite.svg" alt="" />
            ) : (
              <img src="./img/applicationsent.svg" alt="" />
            )}
            {applicationAccepted
              ? t("Host_Details.Application_Accepted")
              : t("Host_Details.Application_Sent")}
          </Button>

          <div className="cancleApplicationbtnbox d-flex justify-content-end">
            <button
              className="cancleApplicationbtn"
              onClick={() => {
                // applicationAccepted ?
                is_loggedin &&
                  (applicationAccepted
                    ? setreqCancle(true)
                    : setcancleApp(true));
              }}
            >
              {t("Host_Details.Cancel_My_Application")}
            </button>
          </div>
        </>
      )}
    </>
  );

  const showParticipants = () => (
    <>
      <div className="total-pax-joining">
        <p>
          <span className="font-blue">
            {userHostList.participate_count}
            {t("Host_Own.pax3")}
          </span>
          /{userHostList.pax}&nbsp;
          {t("Host_Own.pax2")}
        </p>
      </div>
      {participantList.map((participant, i) => (
        <>
          <div
            className="single-pax-list d-flex justify-content-between"
            key={i}
          >
            <div className="profile-left d-flex">
              <div className="host-img circle">
                <img
                  src={participant.user.avatar || "./img/Avatar.png"}
                  alt=""
                  onClick={(e) => setIsOpenImageTwo(participant.id)}
                />
              </div>

              <h5 className="host-name">
                {participant.user.user_name.length >= 8
                  ? participant.user.user_name.slice(0, 8) + ".."
                  : participant.user.user_name}
              </h5>
            </div>
            <div className="profile-right d-flex">
              <div className="host-flag circle">
                <img
                  src={checkImageURL(participant.user.nationality)}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
              <div className="host-tag d-flex">
                {participant.user.gender === "MALE" ? (
                  <div className="host-gender">
                    <p>{t("Male")}</p>
                  </div>
                ) : (
                  <div className="host-gender">
                    <p>{t("Female")}</p>
                  </div>
                )}
                <div className="host-ages">
                  {AuthStorage.getLang() === "en" ? (
                    <p>
                      {participant.user.age_group}'{t("Age_Groups")}
                    </p>
                  ) : (
                    <p className="d-flex align-items-center justify-content-center">
                      {participant.user.age_group}
                      <span>{t("Age_Groups")}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isOpenImageTwo === participant.id && (
            <div className="open-image-overlap">
              <Lightbox
                wrapperClassName="lightbox-profile"
                mainSrc={participant.user.avatar || "./img/Avatar.png"}
                onCloseRequest={() => {
                  setIsOpenImageTwo("");
                }}
              />
            </div>
          )}
        </>
      ))}
    </>

    // <div className="info-joinhost pax-host-list mt-30">
    //   <div className="mt-30">
    //     <p className="font-16-bold">{t("Host_Own.pax1")}
    //       <span className={(userHostList.participate_count === userHostList.pax) ? "orange-font" : "font-blue"}> {userHostList.participate_count}{t("Host_Own.pax3")}</span>/
    //       {userHostList.pax}{t("Host_Own.pax3")} {t("Host_Own.pax2")}
    //     </p>
    //   </div>
    //   {participantList.map((participant: any) => (
    //     <div className=" mt-20 host-info ">
    //       <div className="d-flex align-items-center w-380px">
    //         <img src={participant.user.avatar || "./img/Avatar.png"} className="pax-img" alt="" />
    //         <h5 className="font-20-bold color-dark mr-9 ml-30">
    //           {(participant.user.user_name).length >= 8 ? (participant.user.user_name).slice(0, 8) + ".." : participant.user.user_name}
    //         </h5>
    //         <img src={checkImageURL(participant.user.nationality)} alt="flag" className="round-flags" />
    //         <div className="d-flex ml-auto">
    //           <div className="hots-tags ml-10 mr-10 w-60">
    //             <p className="info mb-0 h-24">{participant.user.gender === "MALE" ? t("Male") : t("Female")}</p>
    //           </div>
    //           <div className="hots-tags w-60 mr-0">
    //             <p className="info mb-0">{participant.user.age_group}{t("Age_Groups")}</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </div>
  );

  const emptyParticipant = () => (
    <>
      {AuthStorage.getLang() === "en" ? (
        <>
          <div className="emptymain">
            <p>
              <span className="font-blue">
                {userHostList.participate_count}
                {t("Host_Own.pax3")}
              </span>
              /{userHostList.pax}&nbsp;
              {t("Host_Own.pax2")}
            </p>
          </div>
          <p className="empty">{t("Empty_Participates.Text")}</p>
        </>
      ) : (
        <>
          <div className="emptymain">
            <p>
              <span className="font-blue">
                {userHostList.participate_count}
                {t("Host_Own.pax3")}
              </span>
              /{userHostList.pax}&nbsp;
              {t("Host_Own.pax2")}
            </p>
          </div>
          <p className="empty">{t("Empty_Participates.Text")}</p>
        </>
      )}
    </>
  );

  const showHostingData = () => (
    <>
      <div className="single-row-about">
        <h3>{t("Host_Details.Date&Time")}</h3>
        <h4>
          {userHostList.date.replaceAll("-", ".")}{" "}
          {userHostList.start_time.slice(0, 5)}
          {" - "}
          {userHostList.end_time.slice(0, 5)}
        </h4>
      </div>
      <div className="single-row-about">
        <h3>{t("Host_Details.Starts_At")}</h3>
        <h4>{userHostList.location}</h4>
      </div>
      <div className="single-row-about">
        <h3>{t("Host_Details.Transportation")}</h3>
        <h4>
          {AuthStorage.getLang() === "ko"
            ? transporTation(userHostList.transportation)
            : userHostList.transportation}
        </h4>
      </div>
    </>
  );

  const showHostingInformationData = () => (
    <>
      <pre
        dangerouslySetInnerHTML={{
          __html: urlify(userHostList.host_information),
        }}
      ></pre>
    </>
  );

  const applicationClosed = () => (
    <div className="third-party-full-house">
      <Button variant="confirmBtn" className="single-host-appstatus w-100">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27ZM11.5 15.5C12.3284 15.5 13 14.8284 13 14C13 13.1716 12.3284 12.5 11.5 12.5C10.6716 12.5 10 13.1716 10 14C10 14.8284 10.6716 15.5 11.5 15.5ZM22 14C22 14.8284 21.3284 15.5 20.5 15.5C19.6716 15.5 19 14.8284 19 14C19 13.1716 19.6716 12.5 20.5 12.5C21.3284 12.5 22 13.1716 22 14ZM9.26273 18.8157C8.87962 18.2188 9.3559 17.5 10.0651 17.5H10.2259C10.5829 17.5 10.9075 17.6942 11.1151 17.9847C12.2032 19.5074 13.9857 20.5 16 20.5C18.0143 20.5 19.7968 19.5074 20.8849 17.9847C21.0925 17.6942 21.4171 17.5 21.7741 17.5H21.9349C22.6441 17.5 23.1204 18.2188 22.7373 18.8157C21.3148 21.0317 18.8289 22.5 16 22.5C13.1711 22.5 10.6852 21.0317 9.26273 18.8157Z"
            fill="white"
          />
        </svg>

        {t("Host_Details.Full_House")}
      </Button>
    </div>
  );

  const applicationDeclined = () => (
    <>
      <Button variant="outline-red" className="single-host-appstatus w-100">
        <img src="./img/declientImg.svg" alt="declientImg icon" />
        <label className=" p-0">{t("Host_Details.Rejected_By_Host")}</label>
      </Button>
    </>
  );

  //Review Popup
  const reviewButton = () => {
    return (
      <>
        {!standBy ? (
          <>
            <Button variant="cancelBtn" className="w-100 single-host-appstatus">
              <img src="./img/blueWrite.svg" alt="" />
              {t("Host_Details.Hosting_Completed")}
            </Button>

            <div className="">
              {
                isReviewSubmited ? (
                  <Button className="single-host-appstatus2 w-100">
                    <label className=" p-0">
                      {t("Host_Details.Review_Submitted")}
                    </label>
                  </Button>
                ) : (
                  ""
                )
                // <Button
                //   className="hosts-btn mt-15 p-0"
                //   onClick={() => {
                //     // applicationAccepted ?
                //     is_loggedin && setReviewPopup(true);
                //   }}
                // >
                //   {t("Host_Details.Write_Review")}
                // </Button>
              }
            </div>
          </>
        ) : (
          <Button variant="cancelBtn" className="single-host-appstatus w-100">
            <label className=" p-0">{t("Host_Details.Closed")}</label>
          </Button>
        )}
      </>
    );
  };

  const handleButtons = () => {
    if (checkDeclined) {
      return applicationDeclined();
    }
    if (checkCancleBox) {
      return cancleApplicationButton();
    }
    if (checkHostingCancle) {
      return cancleHostingButton();
    }
    if (userHostList?.participate_count === userHostList?.pax) {
      return applicationClosed();
    }
    if (checkJoinButton) {
      if (
        (userHostList && userHostList.participate_count === userHostList.pax) ||
        checkIsComplete(userHostList?.date, userHostList?.end_time)
      ) {
        return applicationClosed();
      }
      return joinButton();
    }
    if (checkReviewButton) {
      return reviewButton();
    }
  };

  return (
    <>
      {userHostList && (
        <>
          <div className="hosting-inner-list byHost">
            <div className="single-local-host">
              {hostDetails()}
              {handleButtons()}
            </div>
            <div className="about-itinery-info d-flex flex-column">
              {showHostingData()}
            </div>
            <div className="about-itinery-desc">
              {showHostingInformationData()}
            </div>
            <div className="pax-list-host">
              {userHostList.participate_count
                ? showParticipants()
                : emptyParticipant()}
            </div>
          </div>
        </>
      )}

      {/* Join Hosting Popup */}
      <Modal
        show={joinHost}
        onHide={() => {
          setjoinHost(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Join_Popup.Join")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="body-title">{t("Join_Popup.Question")}</p>
            <p dangerouslySetInnerHTML={{ __html: t("Join_Popup.Text") }} />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            className="joincancle"
            onClick={() => {
              setjoinHost(false);
            }}
          >
            {t("Join_Popup.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            // ButtonStyle="join-apply-btn"
            onClick={() => {
              handleJoinHosting();
            }}
            className="joinApply"
          >
            {t("Join_Popup.Apply")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Application Sent Popup */}
      <Modal
        show={appApply}
        onHide={() => {
          setappApply(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Application_Sent.Application_Sent")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              className="body-title"
              dangerouslySetInnerHTML={{ __html: t("Application_Sent.title") }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: t("Application_Sent.Text") }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              setappApply(false);
            }}
          >
            {t("Application_Sent.OK")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Application Popup */}
      <Modal
        show={cancleApp}
        onHide={() => {
          setcancleApp(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Cancel_Application.Cancel_Application")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Cancel_Application.Question"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            onClick={() => {
              setcancleApp(false);
            }}
          >
            {t("Cancel_Application.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            // ButtonStyle="join-apply-btn"
            onClick={() => {
              cancleConfirmBtn();
            }}
          >
            {t("Cancel_Application.Confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Application Notice Popup */}
      <Modal
        show={cancleConfirm}
        onHide={() => {
          setcancleConfirm(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Cancel_Application.Cancel_Application")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Cancel_Application.Confirm_Statement"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              setcancleConfirm(false);
            }}
          >
            {t("Cancel_Application.OK")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Hosting Popup */}
      <Modal
        show={cancleHosting}
        onHide={() => {
          setCancleHosting(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Cancel_Hosting_Popup.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="body-content">
            <h3 className="body-title">{t("Cancel_Hosting_Popup.Question")}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t("Cancel_Hosting_Popup.Text"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="cancelBtn"
            // ButtonStyle="join-cancle-btn"
            onClick={() => {
              setCancleHosting(false);
            }}
          >
            {t("Cancel_Hosting_Popup.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            // ButtonStyle="join-apply-btn"
            onClick={() => cancleHostingFunction()}
          >
            {t("Cancel_Hosting_Popup.Confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Hosting Notice Popup */}
      <Modal
        show={hostNotice}
        onHide={() => {
          sethostNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Cancel_Hosting_Popup.Notice.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="h-60 ">{t("Cancel_Hosting_Popup.Notice.Body")}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok w-100"
            onClick={() => {
              sethostNotice(false);
              props.created_by === "Compastrips"
                ? history.push(`/itinerary?id=${params.id}`)
                : history.push("/");
              // history.push(`/itinerary?id=${params.id}`)
            }}
          >
            {t("Cancel_Hosting_Popup.Notice.OK")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Accepted Application Popup  */}

      <Modal
        show={reqCancle}
        onHide={() => {
          setreqCancle(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Cancel_Accepted.Title")}</h3>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{ __html: t("Cancel_Accepted.Body") }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="cancelBtn"
            //  ButtonStyle="join-cancle-btn"
            onClick={() => {
              setreqCancle(false);
            }}
          >
            {t("Cancel_Accepted.Cancel")}
          </Button>
          <Button
            variant="confirmBtn"
            // ButtonStyle="join-apply-btn"
            onClick={() => {
              reqdelBtn();
            }}
          >
            {t("Cancel_Accepted.Continue")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Accepted Application Notice Popup */}

      <Modal
        show={conDelete}
        onHide={() => {
          setconDelete(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal"
            : "noto-text custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">
            {t("Cancel_Accepted_Success.Title")}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p
              dangerouslySetInnerHTML={{
                __html: t("Cancel_Accepted_Success.Body"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            // ButtonStyle="app-sent-ok w-240"
            onClick={() => {
              setconDelete(false);
            }}
          >
            {t("Cancel_Accepted_Success.Continue")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Hosting Completed Popup */}
      <Modal
        show={hostingCom}
        onHide={() => {
          SethostingCom(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal"
            : "noto-text welcome-modal host-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div className="modal-signup-title ">
            <h3 className="h-34">{t("Hosting_Complete.Title")}</h3>
          </div>
          <div className="com-hosting mt-60">
            <div className="comHostingInner">
              <p className="text-center font-18-normal color-darkgray h-27">
                {t("Hosting_Complete.Text")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex justify-content-between mt-50 comHostingBtnBox">
          <div className="cancle">
            <button
              //  ButtonStyle="join-cancle-btn"
              onClick={() => SethostingCom(false)}
            >
              {t("Hosting_Complete.Cancel")}
            </button>
          </div>
          <div className="yes">
            <button
              //  ButtonStyle="join-apply-btn"
              onClick={() => completeHosting()}
            >
              {t("Hosting_Complete.Yes")}
            </button>
          </div>
        </div>
      </Modal>

      {/* Hosting Complete Notice Popup */}
      <Modal
        show={comHostNotice}
        onHide={() => {
          SetcomHostNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal"
            : "noto-text welcome-modal host-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0">
          <div className="modal-signup-title h-34">
            <h3>{t("Hosting_Complete.Notice.Title")}</h3>
          </div>
          <div className="yes-notice mt-60">
            <div className="ComHostingNotice text-center">
              <p className="font-17-normal">
                {t("Hosting_Complete.Notice.Body")}
              </p>
            </div>
          </div>
        </Modal.Body>
        <div className="w-100 mt-50">
          <div className="ComHostNoticeBtn">
            <button
              // ButtonStyle="app-sent-ok"
              onClick={() => {
                SetcomHostNotice(false);
              }}
            >
              {t("Hosting_Complete.Notice.OK")}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={modalShow1}
        onHide={() => {
          SetcomHostNotice(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text welcome-modal host-modal custom-modal"
            : "noto-text welcome-modal host-modal custom-modal"
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="custom-modal-title">
            {t("Hosting_Complete.Notice.Title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p className="font-17-normal">{t("Modal.BeforeLogin")}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-100"
            variant="confirmBtn"
            // ButtonStyle="app-sent-ok"
            onClick={() => {
              setModalShow2(true);
              setModalShow1(false);
            }}
          >
            {t("Modal.OK")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Login
        show={modalShow2}
        loginValue=""
        onHide={() => setModalShow2(false)}
        onHideNew=""
        onShow=""
      />
      <AccountDeactive
        hostDeactive={deactivatedNotice}
        setHostDeactive={() => setDeactivatedNotice(false)}
      />

      <Modal
        show={hostMsgOff}
        onHide={() => {
          setHostMsgOff(false);
        }}
        dialogClassName={
          AuthStorage.getLang() === "en"
            ? "roboto-text custom-modal msg-off-modal"
            : "noto-text custom-modal msg-off-modal"
        }
        centered
      >
        <Modal.Header>
          <h3 className="custom-modal-title">{t("Chat_Off.Title")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <p dangerouslySetInnerHTML={{ __html: t("Chat_Off.Host_Off") }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="confirmBtn"
            className="w-100"
            onClick={() => {
              setHostMsgOff(false);
            }}
          >
            {t("Chat_Off.Button")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserHostList;

import React from "react";

const Loading = () => {
  return (
    <div className="loading-dim">
      <div className="img-box">
        <img src="./img/loading.svg" alt="loading icon" />
        <h5>Loading...</h5>
      </div>
    </div>
  );
};

export default Loading;

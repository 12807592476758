import React, { useState, useEffect } from "react";
import { ApiGet } from "../../helper/API/ApiData";
import TourCard2 from "./TourCard2";
import { useTranslation } from "react-i18next";

const MyHostingPage = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  const [feeds, setFeeds] = useState([]);
  const [filter, setFilter] = useState([]);
  const [myOnly, setMyOnly] = useState(false);
  const [refresh, setRefresh] = useState(0);
  useEffect(() => {
    ApiGet("hosting/getHostingOfUser?all=true").then((res) => {
      setFeeds(res.data.itinerary);
      filterData(tabs[activeTab].tag, tabs[activeTab].status);
      console.log("결과 확인", res);
    });
  }, []);

  useEffect(() => {
    filterData(tabs[activeTab].tag, tabs[activeTab].status);
  }, [feeds]);
  const tabs = [
    {
      name: `${t("My_Account.Standing")}`,
      status: "request_review",
      tag: ["UPCOMING", "ONGOING"],
    },
    {
      name: `${t("My_Account.Coming_Up")}`,
      status: "confirmed_review",
      tag: ["UPCOMING", "ONGOING"],
    },
    // { name: `${t("My_Account.Coming_Up")}`, tag: ["confirmed_review"] },
    {
      name: `${t("My_Account.Completed")}`,
      status: ["completed_review", "confirmed_review"],
      tag: ["COMPLETED", "CANCELED"],
    },
    {
      name: `${t("My_Account.Declined")}`,
      status: "reject_review",
      tag: ["COMPLETED", "CANCELED", "UPCOMING", "ONGOING"],
    },
  ];

  // console.log("feed : ", feeds);

  useEffect(() => {
    ShowmyOnly(myOnly);
  }, [myOnly]);

  const ShowmyOnly = (myOnly) => {
    if (myOnly)
      setFilter(filter.filter((feed) => feed.creator !== "Compastrips"));
    else filterData(tabs[activeTab].tag, tabs[activeTab].status);
  };
  const filterData = (tag, status) => {
    // setFilter(feeds.filter((feed) => tag.includes(feed.status)));
    setFilter(
      feeds.filter(
        (feed) =>
          status.includes(feed.hosting_confirm_status) &&
          tag.includes(feed.status)
      )
    );
  };

  const search = (index, tag, status) => {
    setActiveTab(index);
    setMyOnly(false);
    filterData(tag, status);
  };

  const countNumberOfItems = (tag, status) => {
    return feeds.filter(
      (feed) =>
        status.includes(feed.hosting_confirm_status) &&
        tag.includes(feed.status)
    ).length;
  };

  console.log("확인", filter);
  return (
    <main id="singleMyHosting" className="wrap single-page">
      <h2 className="page-title">{t("My_Account.My_Hostings")}</h2>
      <div className="myPage-tabs page-tabs h-39 mt-79 mb-66">
        {tabs.map((tab, index) => (
          <>
            <span
              className={activeTab === index ? `active` : ``}
              onClick={() => search(index, tab.tag, tab.status)}
            >
              {tab.name}&nbsp;({countNumberOfItems(tab.tag, tab.status)})
            </span>
          </>
        ))}
        <div className="-mt-27 my-host-check">
          <label
            className={
              myOnly
                ? "myOnly container_check checked d-flex align-items-center"
                : "myOnly container_check d-flex align-items-center"
            }
          >
            {/* <label className="container_check"> */}
            <input
              type="checkbox"
              name="agree"
              id="agree"
              value=""
              // styleCheck="checkmark"
              onChange={(e) => {
                setMyOnly((prev) => !prev);
              }}
              checked={myOnly}
            />
            {!myOnly ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="9" fill="#AEAEAE" />
                <rect
                  x="4.94141"
                  y="6.91211"
                  width="6.58125"
                  height="2.07692"
                  transform="rotate(44.9589 4.94141 6.91211)"
                  fill="white"
                />
                <rect
                  width="12.7529"
                  height="2.07692"
                  transform="matrix(-0.707614 0.706599 0.706599 0.707614 15.9473 2.76953)"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="9" cy="9" r="9" fill="#42B6E6" />
                <rect
                  x="4.94141"
                  y="6.91406"
                  width="6.58125"
                  height="2.07692"
                  transform="rotate(44.9589 4.94141 6.91406)"
                  fill="white"
                />
                <rect
                  width="12.7529"
                  height="2.07692"
                  transform="matrix(-0.707614 0.706599 0.706599 0.707614 15.9492 2.76953)"
                  fill="white"
                />
              </svg>
            )}
            {t("My_Account.Show_My_Own_Host_Only")}
          </label>
        </div>
      </div>
      <div className="tour-card-list">
        {filter.map((feed) => (
          <TourCard2
            items={feed}
            status={feed.status}
            setRefresh={setRefresh}
          />
        ))}
      </div>
      {filter.length === 0 && (
        <div className="noFeed">
          <h4>{t("My_Account.No_Schedule")}</h4>
        </div>
      )}
    </main>
  );
};
export default MyHostingPage;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApiGet } from "../../helper/API/ApiData";
import TourCard2 from "./TourCard2";
import { Link } from "react-router-dom";
const AppliedHostingFeed = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  const [feeds, setFeeds] = useState([]);
  const [filter, setFilter] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const tabs = [
    { name: `${t("My_Account.Standing_By")}`, tag: ["STAND_BY"] },
    { name: `${t("My_Account.Accepted")}`, tag: ["ACCEPTED"] },
    { name: `${t("My_Account.Declined")}`, tag: ["DECLINED"] },
  ];
  useEffect(() => {
    ApiGet("hosting/getAppliedHostingOfUser?all=true").then((res) => {
      setFeeds([...res.data.itinerary]);
      filterData(tabs[activeTab].tag);
    });
  }, [refresh]);

  useEffect(() => {
    filterData(tabs[activeTab].tag);
  }, [feeds]);

  const filterData = (tag) => {
    setFilter(feeds.filter((feed) => tag.includes(feed.req_status)));
  };

  const search = (index, tag) => {
    setActiveTab(index);
    ApiGet("hosting/getAppliedHostingOfUser?all=true").then((res) => {
      setFeeds([...res.data.itinerary]);
      filterData(tag);
    });
  };

  const countNumberOfItems = (tag) => {
    return feeds.filter((feed) => tag.includes(feed.req_status)).length;
  };
  return (
    <section id="request">
      <h2 className="myPage-title">{t("My_Account.Applied_Hostings")}</h2>
      <div className="details mt-241">
        <div className="details-header"></div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="myPage-tabs page-tabs ">
            {tabs.map((tab, index) => (
              <>
                <span
                  className={activeTab === index ? `active` : ``}
                  onClick={() => search(index, tab.tag)}
                >
                  {tab.name}&nbsp;({countNumberOfItems(tab.tag)})
                </span>
              </>
            ))}
          </div>
          <Link to="/appliedhosting" className="moreLink">
            {t("My_Account.View_All")}{" "}
          </Link>
        </div>
        <div className="tour-card-list">
          {filter.map((item, i) => {
            return (
              i <= 4 && (
                <TourCard2
                  className="mt-66"
                  items={item}
                  setData={setFilter}
                  status={item.status}
                  status2={item.req_status}
                  setRefresh={setRefresh}
                />
              )
            );
          })}
        </div>
        {filter.length === 0 && (
          <div className="noFeed">
            <h4>{t("My_Account.No_Application")}</h4>
          </div>
        )}
      </div>
    </section>
  );
};
export default AppliedHostingFeed;

import React from "react";

const TermsUsecard_En = () => {
  return (
    <>
      <h1>Article 1 (Purpose)</h1>
      <p>
        Thank you for using the services provided by Compas Interactive Inc.
        (hereinafter referred to as the "Company"). The Company has prepared the
        'Terms of Service' (hereinafter referred to as the ‘Terms’, 'these
        Terms' or ‘this Terms’, or ‘This Terms of Service’ ) so that members can
        get closer and more convenient to the various Internet and mobile
        services provided by the Company. By agreeing to these Terms, members
        can subscribe to and use the services provided by the Company. These
        Terms stipulate basic matters such as rights, obligations and
        responsibilities, terms, conditions, and procedures necessary for
        members to use the Services, so please take a little time to read them
        carefully.
      </p>
      <h1>Article 2 (Definitions of Terms)</h1>
      <p>
        The terms used in the Terms of Service are defined as follows :<br />
        ① “Service" refers to a service that can be used by a member regardless
        of the terminal (including various wired and wireless devices such as
        PCs, TVs, and portable terminals) on which it is implemented, and any
        service further developed by the Company or provided through partnership
        agreements with other companies.
        <br />
        ② “Member" refers to any customer who accesses the Company's services
        and concludes a use contract with the Company in accordance with these
        Terms and Conditions and creates an account to use the services provided
        by the Company. The types of members used in these Terms are as follows.
        <br />
        1. Host: A member who utilizes the itinerary information provided by the
        Company or establishes his/her own itinerary to create and post posts
        introducing travel information and personal information and has the
        right to recruit participants and accept or reject applications.
        <br />
        2. Guest: A member who has the right to read the host's itinerary
        introduction posted on the Company's service, apply for participation,
        and participate in the itinerary through the host's acceptance.
        <br />
        3. User: A member who is neither a host nor a participant, who reads the
        introduction of an itinerary posted on the Company's service and uses
        the information according to his/her own intention.
        <br />
        ③ "Account" refers to a letter or a combination of letters and numbers
        determined by the member and approved by the Company to identify the
        member and use the Service.
        <br />
        ④ "Password" refers to a combination of letters or numbers determined by
        the member to confirm that the member is the member who matches the
        account granted to the member and to protect its confidentiality.
        <br />
        ⑤ "Post/Posting" refers to any information in the form of text, photos,
        videos, chat messages, and various files and links provided by the
        Company or created by a member and posted on the Service when the member
        uses the Service, such as all symbols, characters, voice, sound, images,
        and videos.
        <br />
        ⑥ “Chat” refers to a process of communicating, interacting, and/or
        exchanging text messages, multimedia, etc, send and received through the
        chatroom in the Service.
        <br />
        ⑦ "Hosting" means the act of the host recruiting participants for
        his/her travel itinerary.
        <br />⑧ Any terms not defined in this Terms of Service shall be defined
        in accordance with relevant laws and regulations.
      </p>
      <h1>Article 3 (Effect and Modification of Terms of Service)</h1>
      <p>
        ① The Terms of Service shall enter into force between all parties that
        have consented hereto.
        <br />
        ② The Terms of Service shall be posted on the initial page of
        www.compastrips.com (hereinafter, referred as to the ‘website’), and
        members shall be obligated to check it.
        <br />
        ③ The Company if deemed necessary, can change these Terms within the
        scope of not violating the relevant laws and regulations, such as the
        “Act on the Regulation of Terms and Conditions” and the “Act on the
        Promotion of Information and Communications Network Utilization and
        Information Protection”, etc. In the event of a change to these Terms,
        the Company shall, in principle, notify members of the change by posting
        it on the service notice or sending an email to members 7 days prior to
        the effective date, and if the change is unavoidably material and
        disadvantageous to members, the Company shall notify members
        individually by sending an email to the email address registered in the
        service account (if there is no email address, by other electronic means
        such as sending an electronic message or displaying a notification
        message in the Service) or by sending a text message to the mobile phone
        number registered by the member 30 days prior to the effective date.
        <br />
        ④ Member may express intention to refuse to accept the revised Terms of
        Service, in which case they may stop using the Service and withdraw from
        the Account. Unless a member explicitly refuses to accept the
        modification in the preceding paragraph within a set period, from the
        date of announcement or notification hereby to 7 days after the
        effective date, he/she shall be deemed to have agreed to the revised
        terms.
        <br />⑤ This Terms of Service remains in force from the date when a
        member consent hereto until the date of withdrawing/termination by a
        member and/or the company; however some of the provisions hereof may
        remain effective after the foregoing withdrawal/termination.
      </p>
      <h1>Article 4 (Additional Terms)</h1>
      <p>
        ① The Company may establish separate terms of use and policies
        (hereinafter, referred as to the "Detailed Guidelines") for individual
        services.
        <br />
        ② Any matters that are not prescribed herein shall be pursuant to the
        relevant laws and regulations, or Detailed Guidelines.
        <br />③ In the event of a conflict between the Terms and the Detailed
        Guidelines, the Detailed Guidelines shall apply.
      </p>
      <h1>Article 5 (Establishment of Service Use Agreement, etc)</h1>
      <p>
        The Service Agreement is concluded when the member agrees to the Terms
        of Service which is confirmed and approved by the Company.
      </p>
      <h1>
        Article 6 (Restrictions on Service Subscription or Conclusion of Use
        Agreement)
      </h1>
      <p>
        ① The Company shall, in principle, approve the Service subscription to
        the applicant pursuant to Article 5. However, in each of the following
        cases, the Company may suspend or refuse to approve until the following
        issue is resolved ;<br />
        1. If a member attempts to subscribe to the Service using another
        person's personal information such as name, email address, phone number,
        etc.
        <br />
        2. If there is no real margin in the capacity of the service provision
        facilities
        <br />
        3. If it is judged that there is a problem with the technical part of
        providing the service.
        <br />
        4. In other cases deemed necessary by the Company for financial and
        technical reasons.
        <br />
        5. In case of service failure.
        <br />
        6. If a person who has been suspended/terminated from using the Service
        by the Company applies for a re/subscription or applies for a
        subscription using different information.
        <br />
        7. If there are any falsehoods, omissions, or errors in the registration
        contents
        <br />
        8. If it is determined that the member is not suitable for the company's
        policy or if it is difficult to provide the service
        <br />
        9. If a member intends to use the Service for fraudulent purposes
        <br />
        10. If a member's purpose of use or behavior infringes or may infringe
        on the Company's property rights or business rights
        <br />
        11. If a member creates a large number of accounts through abnormal
        methods.
        <br />
        12. If a minor under the law of his/her home country subscribes to the
        Service
        <br />
        13. If a member violates these Terms of Service, relevant laws or
        violates the standards set by the Company such as Detailed guidelines.
        <br />
        ② Minors under the laws of their home country are not allowed to sign up
        for the Service or use the Service.
        <br />③ If it is found that a member has signed up for the Service in
        violation of this Article, the Company may immediately suspend the
        member's use of the Service or take restrictive measures such as
        deleting the account.
      </p>
      <h1>Article 7 (The Service and its Change)</h1>
      <p>
        ① The Company provides the following online services to its members. The
        Company may classify members by types and may differentiate the
        provision of the service such as the service menu, and contents.
        <br />
        1. Service that allows members to chat with other members
        <br />
        2. Service that allows members to upload their information and share it
        with other members and interact with them through it.
        <br />
        3. Service that allows members to utilize the itinerary information
        provided by the Company or to write and upload articles introducing
        their own itinerary to promote it.
        <br />
        4. Service that allows members to read, and leave comments or reviews on
        post
        <br />
        5. Service that allows members to recruit participants to accompany them
        on their travel itineraries or to apply to accompany them.
        <br />
        6. Service that allows members to pay for services through the payment
        system provided by PG Company.
        <br />
        7. Any services further developed by other companies or provided to
        members through partnerships with other companies (including API
        services to utilize existing services).
        <br />
        ② Members may provide ideas related to the improvement and development
        of Services to the Company, and the Company may, if necessary, actively
        utilize the ideas presented by the members to provide the results of
        additional services developed and improved to the members. In this case,
        the intellectual property rights of the results developed through the
        ideas presented by the member shall belong to the company unless
        otherwise notified or requested.
        <br />
        ③ The Company shall not provide the member with the right to use the
        Company's or the Service's trade name, service mark, logo, domain/name,
        and other forms of identifiable brand characteristics unless the Company
        separately concludes a written contract with a member to grant an
        explicit right to use it.
        <br />
        ④ The Company shall notify members of matters related to the service
        system and line inspection, changes to the service, service operation,
        and updates/changes to the service contents through a notice in the
        service, and a member must check the notices from time to time. The
        Company shall not be liable for any damage to a member caused by failure
        to check the notices. However, if serious and obvious damage to a member
        is expected, such as if there is a significant interruption in the use
        of paid services or other significant financial damage expected, the
        Company shall make a separate notice.
        <br />
        ⑤ The Company may change or abolish part or all of the Service at any
        time if necessary for service operation and planning. However, if the
        Company determines that it is unfavorable to the member, the Company
        will notify the member through the email registered by the member at the
        time of registration or notice within the Service. In this case, if the
        Company closes part or all the service, the Company will support the
        download of information and materials created by the member.
        <br />
        ⑥ The Company may temporarily suspend the Service if there are
        considerable reasons for maintenance inspection, replacement, and
        failure of information and communication facilities such as computers,
        or operation. In this case, the company will notify the member within
        the Service or via e-mail. However, if there is an unavoidable reason
        that the Company cannot notify in advance, it can be notified afterward.
        <br />⑦ The Company may conduct regular maintenance checks if necessary
        to provide the Service, and the time of regular maintenance checks shall
        be announced according to the method determined by the Company.
      </p>
      <h1>Article 8 (Change of Member Information)</h1>
      <p>
        ① On the page of My Account, a member may watch and edit his/her
        personal information. But information such as Account which is necessary
        to manage the Service cannot be changed.
        <br />
        ② If there is a change in the information provided when applying for
        membership, the member shall modify it online or notify the company of
        the change by e-mail or other means.
        <br />
        ③The Company shall not be liable for any disadvantages caused by not
        notifying the Company of the changes in Paragraph 2.
        <br />
        ④The Company may add or change the items or information requested to a
        member if necessary to provide the Service.
      </p>
      <h1>
        Article 9 (Obligations for Management of Member’s Account and Password)
      </h1>
      <p>
        ① Member shall be responsible for the management of the member's account
        password and shall not allow a third party to use it.
        <br />
        ② The Company may restrict or suspend the use of a member's account if
        there is a risk of personal information leakage, or if it is anti-social
        or against public morals, or if there is a risk of being mistaken for
        the Company and its operators.
        <br />
        ③ If a member recognizes or suspects that the account and password have
        been stolen or are being used by a third party, the member shall
        immediately notify the Company and follow the Company's instructions.
        <br />④ In the case of Paragraph 3, the Company shall not be liable for
        any disadvantages caused by the member's failure of immediate
        notification to the Company or, even if notified, failure to follow the
        Company's instructions. If the member lends or negligently manages the
        account and password and a third party uses it, causing damage to the
        Company and other members, the Company may hold the member responsible
        for the lending or negligent management of the account, and if the
        Company becomes a party to the dispute, the member shall indemnify the
        Company at its own risk and expense.
      </p>
      <h1>Article 10 (Notification to Member)</h1>
      <p>
        ① When the Company notifies a member, it may do so to the member's
        designated email address, mobile phone number, etc. unless otherwise
        provided in the Terms of Service.
        <br />② When notifying all members, the Company may replace the notice
        in Paragraph 1 by posting it on the board of the website for more than 7
        days or by announcing it in a pop-up or another method.
      </p>
      <h1>Article 11(Obligations of Member) </h1>
      <p>
        ① Members may use the Service freely, but shall not engage in any of the
        following acts ;<br />
        1. Make false statements when applying for or changing use, steal or
        illegally use other people's accounts and passwords, use other person's
        photos, or names, or perform email authentication or text message (SMS)
        authentication without the permission of the authorized person.
        <br />
        2. Reproduce, distribute, or commercially utilize information obtained
        by using the Company's service information without the prior consent of
        the Company.
        <br />
        3. Acts that defame, slender, damage, or disadvantage the honor of
        others.
        <br />
        4. Posting obscene materials, or linking to obscene sites.
        <br />
        5. Infringe other rights including intellectual property rights such as
        patent rights, trademark rights, copyright, portrait/rights, and trade
        secrets of the Company or third parties, or use them in a way that
        infringes on them.
        <br />
        6. Post false facts/statements about the Company or a third party within
        the Service, etc.
        <br />
        7. Post or disseminate information, sentences, shapes, voices, etc that
        violate public order and morals.
        <br />
        8. Register or disseminate computer virus-infected materials that cause
        malfunctions of facilities related to the Service, or destruction or
        confusion of information and data, etc.
        <br />
        9. Post or transmit information that may interfere with the operation of
        the Service or interfere with its stable operation and advertising
        information against the recipient's express refusal to receive it or
        without the recipient's express consent.
        <br />
        10. Copy, modify, alter, distribute, sell, transfer, lend, pledge, or
        allow others to use the Service or any part of the software contained
        therein without the Company's consent, and any act to reproduce,
        disassemble, imitate, or otherwise modify the Service, such as reverse
        engineering the software or attempting to extract the source code.
        <br />
        11. Impersonate others or misrepresent one’s relationship with others.
        <br />
        12. Collect, store, or disclose other person’s information such as
        emails of other members.
        <br />
        13. Circulate false information to provide oneself or others with
        proprietary benefits, or to damage others
        <br />
        14. Gambling for financial gain or engaging in vagrancy.
        <br />
        15. Posting or distributing information that facilitates or mediates
        prostitution or pornography
        <br />
        16. Harassing, threatening, or causing continuous pain or inconvenience
        to other members,
        <br />
        17. Provide false information such as a third party’s information, in
        the application for use.
        <br />
        18. Operate an account for the purpose of advertising and/or promotion
        without the permission of the Company, causing inconvenience to the
        members, such as sending spam messages.
        <br />
        19. Change, attempt to or hack the Company's client program, sub/server,
        or arbitrarily change any part of the website without being granted
        permission by the Company.
        <br />
        20. Induce/Encourage visits to (a) certain sites regardless of the
        intentions of visitors or other member(s).
        <br />
        21. Modify, create derivative works of, reverse engineer, decompile, or
        any attempt to extract the source code of any software provided by the
        Company to the member as part of the Service.
        <br />
        22. Continue to post or cause to reach other member(s) any speech or
        sound, writing or image or video that causes offense, shame, disgust or
        fear.
        <br />
        23. Transmit or post/publish any information (including a computer
        program) prohibited by the law and regulations.
        <br />
        24. Post or send e-mails, messages, etc by pretending or impersonating
        an employee or operator of the Company or its affiliates, partners, or
        other person.
        <br />
        25. Post materials or send e-mails, text messages, etc. that contain
        software viruses or other computer code, files, or programs that are
        likely to interfere with or destroy the normal operation of computer
        software, hardware, or telecommunications equipment.
        <br />
        26. Harass other users, including stalking and abuse of false or
        malicious reports.
        <br />
        27. Acts that are recognized as being linked to a criminal act
        <br />
        28. Any other acts that the Company may judge or be suspicious of that
        infringe on the rights of others or harm the manner or order of the
        community or Service.
        <br />
        29. Acts that induce or encourage or cause other member(s) to engage in
        prohibited acts referred to in subparagraphs 1 to 28 above.
        <br />
        30. Offer, receive or induce money or any form of financial benefits out
        of the Company's platform, or service, such as guiding member(s) to
        their personal accounts, inducing them to visit other channels, sending
        partial payments separately, cash transactions, or offering to exchange
        personal contacts, etc.
        <br />
        31. Other acts for the purpose that is equivalent to prohibited acts
        referred to in subparagraphs 1 to 30 above.
        <br />
        ② Members shall neither transfer nor grant their rights to use the
        Service or status hereby to others nor may they provide it as
        collateral.
        <br />
        ③ If a member fails to comply with relevant laws, all terms and
        conditions of the company, or detailed guidelines, and instructions, the
        Company may investigate the member's violations, delete or temporarily
        delete the relevant posts, or suspend or terminate the member's account,
        use of the entire service or some individual service comprising the
        service, temporarily or continuously, or restrict the member from
        rejoining the service or reusing some individual services.
        <br />
        ④ If a member adversely affects the provision of the Service or
        seriously interferes with its stable operation, such as causing
        malfunction of facilities related to the Service or destruction and
        disruption of the system, the Company may restrict the use of the
        member's account where such dangerous activities are confirmed.
        <br />
        ⑤ If a member is dissatisfied with the imposed restriction, he/she can
        appeal through the Company’s e-mail. In that case, the Company shall
        deliver the handling process and/or results to the member after
        reviewing whether the imposed restriction is reasonable.
        <br />⑥ The Company may restrict the use of some or all of the Services
        temporarily or permanently in consideration of the degree, frequency,
        and damage caused by a member's violation. In the case of posting and
        dissemination of obscene content, promotion of gambling and other
        similar acts, obvious illegal acts prohibited by laws and regulations,
        or infringement of the rights of others, which require urgent risk or
        damage prevention, the use of the Service may be restricted immediately
        and permanently.
      </p>
      <h1>Article 12 (Restriction of Service Use)</h1>
      <p>
        ① The Company may restrict or suspend all or part of the Services
        without notice when force majeure events such as natural disasters,
        national emergencies, or serious changes in the operation of the
        Services occur or are expected to occur.
        <br />
        ② If a member violates the provisions of the member's obligations
        Article 11, the company may impose restrictions on the member's use of
        the Service without prior notice to the member.
        <br />
        ③ The Company's Service and management area is limited to online, and
        its responsibility is also limited. The Company shall not be liable for
        any problems or disputes that may arise between members or between
        member(s) and third parties or third parties by using the Service.
        <br />
        ④ If the leakage of information such as email, account information,
        password, etc. due to the member's false provision of personal
        information, or negligence of management causes damage to the use of the
        Service to oneself or other member(s), or if a violation of the member's
        obligations such as unauthorized use by a third party is found, the use
        of the account may be restricted.
        <br />
        ⑤ If, in the course of investigating a violation of Article 11 of these
        Terms, the Company suspects or determines that the member's account may
        be directly involved in a specific violation, or if it is necessary to
        protect the rights and interests of other member(s) or maintain the
        order of the community, the Company may temporarily or permanently
        suspend the account and the member's use of the Service. In this regard,
        the member may appeal via email.
        <br />⑥ Inquiries and complaints related to the use of the Service is
        dealt by methods suggested by the Company such as e-mail.
      </p>
      <h1>Article 13( Provision of Service)</h1>
      <p>
        ① Once a member agrees to the Terms of Service, the member can freely
        use the Service whenever she/he wants.
        <br />
        ② The Company provides members with services that can be enjoyed on the
        Internet and mobile phones.
        <br />
        ③ In order to provide better services, the Company may display various
        information including notices, management messages, and other
        advertisements related to the use of the Service to the member within
        the Service, and may also send them directly to the contact information
        registered in the member's account information. However, in the case of
        sending advertising information, it will be sent only if the member has
        agreed to receive it in advance.
        <br />
        ④ If a member finds any problems such as system errors while using the
        Service, please feel free to notify us at the Company's e-mail address
        posted on the website.
        <br />
        ⑤ Please note that if a member does not use Wi-Fi wireless Internet
        while using the Service, but connects to the wireless Internet of the
        mobile operator a member has subscribed to, he/she may be charged a
        separate data communication fee by the mobile operator. Data
        communication charges incurred in the course of using the Service shall
        be paid by the member to the mobile carrier at the member's expense and
        responsibility. For more information on data communication charges,
        please contact the mobile phone company to which the member subscribes
        directly.
        <br />⑥ The Company may use member information to post customized
        advertisements or send advertising information messages within the
        Service for smooth service operation. In addition, the Company may send
        various information emails related to the Service to members who have
        consented to receive emails, and if the member does not want to receive
        such emails, the member may unsubscribe at any time through the
        preference menu or the email received. The Company shall not provide
        member information to a third party without the separate consent of the
        member unless there is no special provision in this Agreement and the
        law.
      </p>
      <h1>Article 14 (Limitation of the Service)</h1>
      <p>
        ① The Company may limit the scope of certain services or specify the
        time or number of times can be used but shall notify the member in
        advance.
        <br />
        ② The Company may immediately suspend the use of the Service if the
        member violates any laws or regulations in using the Service or violates
        the Terms of Service or the Company's detailed guidelines or Service use
        standards.
        <br />③ The Company may immediately suspend the use of the Service if a
        member attempts to illegally hack into the Service or the system
        providing the Service, or if a member attempts to abuse or exceed
        network usage in an abnormal manner.
      </p>
      <h1>Article 15 (Suspension of Service Use or Termination)</h1>
      <p>
        ① Members may request the termination of the use contract by signing up
        at any time.
        <br />
        ② If the member engages in any act falling under each of the
        subparagraphs of Article 11, Paragraph 1 of these Terms and Conditions,
        the Company may restrict, suspend, and terminate the member's membership
        without prior notice. In this case, the member shall not be entitled to
        claim any form of right against the Company in connection with the
        subscription, contents, use of the Service, etc.
        <br />
        ③ The Company may notify the member of any change in membership pursuant
        to Paragraph 2 of this Article within the Service or to any external
        service to which the member subscribes or uses while using the Company's
        Service.
        <br />④ A member shall be responsible for compensating the Company or
        other member(s) for any damage caused to the Company or other member(s)
        due to violation of other provisions of the Terms of Service including
        violation of Article 11, detailed guidelines, or other illegal acts, and
        the Company shall be indemnified in that case. However, if the Company
        becomes a party to a dispute due to the member's breach of obligations
        or illegal acts, the member shall indemnify the Company at its own
        expense and responsibility.
      </p>
      <h1>Article 16 (Modification and Termination of Services)</h1>
      <p>
        ① The Company shall use its best efforts to provide the Services.
        However, in any of the following cases, the Company may limit or suspend
        all or part of the Service. :<br />
        1. In the case of regular or temporary inspections such as maintenance
        and repair of facilities for the Service
        <br />
        2. In the event of an interruption in the normal provision of the
        Service, such as a power outage, failure of various facilities, or a
        surge in usage.
        <br />
        3. When the Company determines that all or part of the Service cannot be
        maintained due to various circumstances, such as the termination of a
        contract with a related company, partners, government orders or
        regulations, changes to the Service or member policies, etc.
        <br />
        4. If there is a force majeure reason such as natural disasters,
        national emergencies, etc.
        <br />② In the event of Service interruption under the preceding
        paragraph, the Company will notify or inform the member in advance in
        the manner prescribed in Article 10. In that case, due to changes in the
        Service or policy, re-login or additional consent procedures may be
        required when using the Service. However, this shall not apply if prior
        notice or announcement is not possible due to reasons that the Company
        cannot predict or control (such as disk or server failure, system
        breakdown, etc. that are not the fault of the Company). Even in such
        cases, the Company will endeavor to restore the Service as soon as
        possible upon realizing the situation.
      </p>
      <h1>Article 17 (Management of Posts and Chat Messages, etc.)</h1>
      <p>
        ① The Company may use or publish posts created by member and has full
        operational rights with respect to posts created by member to the extent
        necessary to provide the Service.
        <br />
        ② A member shall be solely responsible for any damages or liabilities
        arising from his/her posts and activities, and the Company shall not be
        liable for them.
        <br />
        ③ If the Company finds posts or chat messages that fall under any of the
        following items, it may delete them without prior notice, make them
        private, move them to another posting space, or refuse to register them
        on the Service, and/or further restrict, suspend, or lose the
        qualifications of the member;
        <br />
        1. Slanderous or defamatory to other member(s) or third parties or
        suspected of improper use of personal information.
        <br />
        2. Transmits, including e-mails, and links containing/connecting the
        information that is sexually explicit or obscene, containing slang
        words, antisocial or vulgar, or containing harmful contents to the
        public or morals of society, or causing social prejudice, or
        disseminating such content with any text, shape, sound, video, picture,
        etc. to a third party
        <br />
        3. Impersonates the company or its management, employees, or related
        parties, or contains content that may cause member(s) or third parties
        to mistakenly believe that it is the official position of the company.
        <br />
        4. Recognized or suspected to be related to illegal acts or criminal
        activities
        <br />
        5. Infringes other intellectual property rights such as copyright,
        patent, trademark, trade secret, business, etc. of the Company, other
        member(s), or third parties, or infringes on personal rights such as
        honor, portrait, publicity, etc.
        <br />
        6. An advertisement or similar content for commercial purposes and is
        contrary to the purpose and method of use of the Company.
        <br />
        7. Induces a visit to a specific site regardless of the intention of
        visitor or member.
        <br />
        8. Interferes with the normal operation of the Service, or is
        inconsistent with the Company's operating policies.
        <br />
        9. If it is deemed to violate other relevant laws or the Terms of
        Service.
        <br />
        ④ If another member or third party claims that his/her rights are
        infringed by the member's or third party's postings and files a
        complaint and request for relief with the Company, the Company may take
        legal action (e.g., criminal prosecution, application for a provisional
        injunction, lawsuit for damages, etc.) or temporarily or permanently
        restrict access to the relevant postings until the dispute raised by the
        member reaches a legal conclusion.
        <br />
        ⑤ If a member causes damage or is subjected to legal action or sanctions
        by relevant organizations due to posts or chats falling under each of
        the subparagraphs of Paragraph 3 above, the member shall bear the
        responsibility himself/herself, and if the company becomes a party to a
        legal dispute due to the act of the member, the member shall indemnify
        the company at his/her own expense and responsibility. The company may
        separately pursue liability such as damages against the member.
        <br />
        ⑥ A member shall be solely responsible for any legal liability incurred
        with a third party due to the member's post, chat, etc.
        <br />
        ⑦ The Company shall not be liable for any disputes arising from the
        posts of third parties.
        <br />⑧ If a member's post violates the law, the Terms of Service,
        detailed guidelines, infringes or may infringe on the rights of others,
        or contains content that violates the purpose of the service, the
        company may request the member to suspend or delete the post, or take
        corrective measures such as deleting the post without prior notice.
      </p>
      <h1>Article 18 (Use of Posts and Attribution of Rights, etc.)</h1>
      <p>
        ① Members may create posts about their travel itineraries in the
        Service. The copyright, ownership rights, and any other rights to the
        posts or works created by the Company shall belong to the Company, and
        if a member uses the Service and creates a post alone or jointly with
        other member(s), the copyright, etc. of the post shall belong to the
        member who created the post and the co-authors.
        <br />
        ② If a member deletes, changes, or changes the disclosure settings of a
        work, the Company shall not be liable for any consequences caused by the
        work being distributed or used by a third party before such settings are
        changed.
        <br />
        ③ The Company is not responsible for any disputes between co-authors.
        <br />
        ④ Any legal liability arising from the infringement of intellectual
        property rights and business rights, including copyrights, of the
        Company or a third party by a member's post or work shall be borne
        solely by the member. If the Company becomes a party to a dispute
        through litigation or other means due to infringement of the rights of
        others, the member shall indemnify the Company at his/her own expense
        and responsibility.
        <br />
        ⑤ If a member uses the Service and the postings made by the member alone
        or jointly with other member(s) are deleted in violation of the law,
        these terms and conditions, or the company's detailed guidelines, the
        member shall not claim copyright, ownership, or other rights to the
        postings to the company.
        <br />
        ⑥ By agreeing to these Terms, a member permanently grants the Company a
        worldwide license to use, store, edit/modify, reproduce, publicly
        transmit, display, distribute, and make available the Post within the
        Service. The rights granted by the member to the Company in this license
        shall be used within the scope of operating, improving, and promoting
        the Service and developing new services, and shall naturally include
        sublicenses to the other party or other users with whom the Company has
        entered into an agreement within the scope of these purposes. In
        addition, the Postings may be used by the Company and its affiliates,
        partners for the purpose of improvement and research, and development of
        the Service.
        <br />
        ⑦ Notwithstanding the provisions of the preceding paragraph, if the
        Company uses a member's Postings for commercial purposes other than the
        purposes listed in each of the preceding paragraphs (e.g., providing the
        Postings themselves to a third party and receiving monetary
        consideration), the Company must obtain the consent of the member in
        advance. The Company's request for use of the Post, the member's
        consent, and the withdrawal of consent shall be in accordance with the
        method prescribed by the Company, such as email. However, if the contact
        information registered in the member's information is different from the
        facts or the member does not respond to the Company's contact and the
        Company cannot obtain consent in advance, the Company may seek consent
        after the fact. In this case, the Company will indicate the source of
        the post and may separately compensate the member who consented to the
        use of the post.
        <br />
        ⑧ The Company may delete or refuse to post a member's post without prior
        notice if the Company determines that the post is obscene or harmful to
        youth, promotes discriminatory conflicts, is abusive, advertising,
        promotional, or spammy, involves transferring or trading accounts,
        impersonates others, or otherwise violates the law, these Terms, the
        Company's detailed guidelines, or violates the purpose of the Service.
        The Company is not obligated to review all posts, and the Company may
        take corrective measures such as obscuring or deleting posts through
        reports from members.
        <br />
        ⑨ In principle, works set as private by a member cannot be viewed by
        others, including the Company. However, if the member requests it, if
        the member is requested to provide information by a court, police,
        investigative agency, or other administrative agency, or if required by
        other laws, others, including the Company, may view the work.
        <br />
        ⑩ If a member withdraws from the Company or loses membership in
        accordance with the Company's regulations, including the Terms of the
        Service, all work/posts, including works recorded by other member(s) and
        third parties, recorded in the member's account will be deleted.
        However, posts that are necessary for other members to use the Service
        normally, such as posts that have been reposted by a third party as
        clippings, member links, or disclosures, posts with comments attached by
        a third party, jointly created works, or when the original is damaged to
        the point of being unrecognizable, may not be deleted.
        <br />
        ⑪ Some content to which the Company does not hold the rights may appear
        on the Service; the entity that provided the content shall bear all
        legal responsibility for such content alone.
        <br />⑫ Member’s use of the Service does not give a member any rights to
        the content of other members, or the Company. If a member wishes to use
        the content of other members, or the company, the member must obtain
        separate and explicit permission from the content owner.
      </p>
      <h1>Article 19 (Withdrawal of Member Account, etc.)</h1>
      <p>
        ① If a member wishes to cancel his/her account, he/she may do so
        directly through the settings within the Service, the customer support
        menu, or by emailing to the Company.
        <br />
        ② If a member withdraws from the account, the Service use agreement
        under the Terms of Service is automatically terminated.
        <br />
        ③ If a member withdraws from the account, the member's information will
        be deleted, except in the case of retaining certain information of the
        member in accordance with relevant laws and regulations and the privacy
        policy. However, if a post made by a member is re-posted by a third
        party as a clip or other sharing function, or if the member adds a post
        such as a comment to others’ post, the post and comment will not be
        deleted, so please check this before withdrawing/canceling your account.
        The Company shall not be liable for any damages caused by such posts and
        comments not being deleted.
        <br />
        ④ If a member does not use the Service for a certain period of time, the
        Company may destroy or segregate the member's information.
        <br />⑤ Even if a member has withdrawn from the account, the member may
        apply for the Service subscription to the company again. However, if the
        member’s previous account has been suspended where the member did not
        comply with the relevant laws, Terms, and/or detailed guidelines, a
        member re-applying for the Service subscription may be reviewed and
        restricted.
      </p>
      <h1>Article 20 (Privacy policy, etc)</h1>
      <p>
        The safe handling of members' personal information is important to the
        Company. members' personal information will be used only for purposes
        within the scope agreed to by the member in order to provide the
        Service. For more information on the Company's efforts to safely process
        members' personal information and other details, please refer to our
        Privacy Policy.
      </p>
      <h1>Article 21 (Protection of Location Information, etc.)</h1>
      <p>
        ① The Company collects and uses the location information/data of the
        member for the purpose of expanding the service and its functions.
        <br />
        ② Member may request the Company to notify them of the use and provision
        of location information/data and to correct errors. The Company shall
        endeavor to process the member's request as soon as possible.
        <br />
        ③ Member may request the Company to view, notify, and correct errors
        regarding the reasons and contents of personal location information
        provided to a third party. In this case, the Company shall process the
        member's request as soon as possible.
        <br />
        ④ The Company automatically records and preserves the confirmation data
        of the use and provision of location information to the personal
        location information subject in the location information system based on
        Article 16 (2) of the ‘Act on the Retention and Use of Location
        Information, etc.’ The retention period for such information and
        materials is 1year.
        <br />
        ⑤ If the Company provides a member's location information/data to a
        third party designated by the member, the Company shall notify the
        member of the recipient, the date and time of provision, and the purpose
        of providing each time.
        <br />⑥ The member may withhold consent partially to the use of personal
        location information provided to a third party, the purpose of providing
        it, the scope of the recipient, and the location-based service, and must
        notify the company of the specifics at this time.
      </p>
      <h1>Article 22 (Damages, etc.)</h1>
      <p>
        ① The Company does not make any promises or guarantees regarding any
        specific matters not explicitly specified in the Terms of Service, and
        shall not be liable for any damages arising therefrom.
        <br />
        ② The Company does not guarantee the reliability, accuracy,
        completeness, quality, etc. of the information, data, facts, etc. posted
        or transmitted by the Company or the member to the Service, and shall
        not be liable for any damages arising therefrom.
        <br />
        ③ The Company does not guarantee the reliability, legitimacy, or
        legality of the contents provided by the Company through the Service or
        a member’s information entered by the member, and shall not be liable
        for any damages arising therefrom.
        <br />
        ④ The Company does not guarantee the products offered and obtained
        through the Company's Service, or any information such as products,
        services, offers, etc. obtained through the links provided by the
        Company, and shall not be liable for any damages arising therefrom.
        <br />
        ⑤ In addition, the Company shall not be liable for in/direct, special,
        consequential, exemplary, and punitive damages to the extent permitted
        by law;
        <br />
        1. Damages caused by force majeure such as natural disasters, war, or
        circumstances equivalent to force majeure.
        <br />
        2. Damages incurred when a member uses a Service that does not comply
        with laws and regulations, the terms, or the company's detailed
        guidelines and measures, or when the Service is suspended or disabled
        due to reasons attributable to the member.
        <br />
        3. Personal damages incurred in the process of accessing or using the
        Service.
        <br />
        4. Damages caused by illegal access to or use of the Company's server by
        a member or a person who is not a member
        <br />
        5. Damages caused by a member or a third-party interfering with the
        transmission to or from the company's server
        <br />
        6. Damages caused by the transmission or dissemination of malicious
        programs by a member or a third party.
        <br />
        7. Damages caused by omission, damage, destruction of transmitted data,
        etc.
        <br />
        8. Damages caused by a person other than the member using the Service
        <br />
        9. Any damage caused by the environment of the member's computer or the
        member's mobile device, or any damage caused by the network environment
        without any reason attributable to the company.
        <br />
        10. Damages caused by service interruption, suspension, disabled, or
        failure due to unavoidable reasons such as repair, replacement, periodic
        inspection, or construction of service facilities announced in advance.
        <br />
        11. Damages caused to members or non-members due to the unavailability
        of the Service.
        <br />
        12. Damages caused by loss of computer system or mobile device system,
        loss of data, information, etc. caused by a member using the Company's
        services to download or access certain programs or information, etc.
        <br />
        13. Damages caused by reasons attributable to a member, such as errors
        in the member's computer or mobile device, inaccuracies in
        identification and/or account information, email address, or neglect of
        password management.
        <br />
        14. Damages caused by the telecommunications service provider's
        suspension or failure to provide telecommunications services.
        <br />
        15. Damages caused by any problems arising from security issues that are
        not within the scope of the Company's authority, such as the member's
        use environment, or problems that occur without the Company's fault,
        such as network hacking that may be difficult to defend with the current
        security technology level.
        <br />
        16. Damages caused by other reasons without intention or negligence of
        the Company.
        <br />
        ⑥ The Company shall not be liable for the failure of a member to obtain
        the expected benefits while using the Service and shall be exempted from
        liability for any damages caused by the member's choice to use the
        Service.
        <br />
        ⑦ The obligations of members using the service are specified in the
        Terms of Service. The Company shall not intervene in any disputes
        arising between members or between members and third parties through the
        Service or in connection with the direct or indirect use of the Service
        and shall not be liable for any damages to a member or third parties
        resulting therefrom.
        <br />⑧ The Company is not obligated to pre-screen a member(s) ‘s posts
        before posting and is not obligated to check or review the contents of
        posts at any time.
      </p>
      <h1>Article 23 (Placement of Advertisements, etc.)</h1>
      <p>
        ① Part of the foundation, such as the system that enables the Company to
        provide the Service to members, may be generated from revenue through
        the posting of advertisements. The members agree to the advertisements
        exposed when using the Service.
        <br />② The Company shall not be liable for any loss or damage arising
        as a result of a member's participation in, communication with, or
        transaction with advertisers' promotional activities posted on or
        through the Service.
      </p>
      <h1>Article 24 (Resolution of Disputes)</h1>
      <p>
        This Terms of the Service or the Service shall be governed and
        implemented by the laws and regulations of the Republic of Korea. In the
        event of a dispute between the Company and a member regarding the use of
        the Service, the Company and the member shall consult in good faith to
        resolve the matter.
      </p>
      <h1>Article 25 (Governing Law)</h1>
      <p>
        The laws of the Republic of Korea shall be applied to disputes relating
        to the Company or the Company's services.
      </p>
      <h1>Article 26 (Jurisdiction)</h1>
      <p>
        The Seoul Central District Court in the Republic of Korea shall be the
        exclusive jurisdiction for all lawsuits and/or any disputes related to
        the Company or the Company's services.
      </p>
      <h1>Appendix</h1>
      <p>The Terms of Service is effective as of 2023. 2. 1.</p>
    </>
  );
};

export default TermsUsecard_En;

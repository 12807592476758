import { CHANGE_GLOBAL_SEARCH, CLEAR_GLOBAL_SEARCH } from "../type";

// const getPersistDataState = localStorage.getItem('persist_state');
const getPersistDataState = null;
const initialState = getPersistDataState
  ? JSON.parse(getPersistDataState)
  : {
      globalSearch: {
        searchHeaderTerm: "",
        startHeaderDate: null,
        endHeaderDate: null,
      },
    };

// function syncPersistDataReducerState(state: any) {
//     localStorage.setItem('persist_state', JSON.stringify(state))
// }

export const persistDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_GLOBAL_SEARCH:
      let newState = {
        ...state,
        globalSearch: {
          ...state.globalSearch,
          [action.payload.field]: action.payload.value,
        },
      };
      // syncPersistDataReducerState(newState)
      return newState;

    case CLEAR_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: {
          searchHeaderTerm: "",
          startHeaderDate: null,
          endHeaderDate: null,
        },
      };

    default:
      return state;
  }
};

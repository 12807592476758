import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyDWHVbOfF2R_I1TX8cP6C7oydgDPXF34Ew",
//   authDomain: "compastrips.firebaseapp.com",
//   projectId: "compastrips",
//   storageBucket: "compastrips.appspot.com",
//   messagingSenderId: "819037704776",
//   appId: "1:819037704776:web:77591a295929bf7220be7d",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCIvqC7vV5MYD0frRcW_-6wEZHjidf0w6I",
  authDomain: "compastrip-8a1ea.firebaseapp.com",
  projectId: "compastrip-8a1ea",
  storageBucket: "compastrip-8a1ea.appspot.com",
  messagingSenderId: "549557015011",
  appId: "1:549557015011:web:d6788d3684610aa173d0d6",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export { db };

import { PUB_TRENDINGNOW_SEARCH, PUB_TRENDINGNOW_UPDATE_LIST } from "../type";

export const pubTrendingNowState = (value) => (dispatch) => {
  dispatch({
    type: PUB_TRENDINGNOW_SEARCH,
    payload: value,
  });
};

export const pubTrendingNowUpdateListState = (value) => (dispatch) => {
  dispatch({
    type: PUB_TRENDINGNOW_UPDATE_LIST,
    payload: value,
  });
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ApiGet } from "../../helper/API/ApiData";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalSearch } from "redux/action/persistDataAction";

const initialData = {
  count: 0,
  itinerary: [],
};

const TredingNow = ({ setActiveIndex, setActiveTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { country_code } = useSelector((state) => state.trendingNowData);

  const [tnList, setTNList] = useState([]);
  useEffect(() => {
    // const browserLang = navigator.language || navigator.userLanguage;
    ApiGet("general/trendingnow/get/list?code=all").then((res) => {
      setTNList(res.data);
    });
    // ApiGet("itinerary/getTrendingItinerary").then((res) => setData(res.data));
  }, [country_code]);

  const history = useHistory();
  const persistData = useSelector((state) => state.persistData);

  return (
    <div className="trending-now-section">
      <h4 className="heading"> {t("Homepage.Popular_Destination")}</h4>
      <div className="container">
        {tnList.map((item, i) => {
          return (
            <div
              key={i}
              className="single-trending-row d-flex align-items-center"
            >
              <h4 className="trending-number">{i + 1}</h4>
              <h5
                className="trending-pro-content"
                onClick={(e) => {
                  setActiveIndex(i + 5);
                  dispatch(
                    setGlobalSearch({
                      field: "searchHeaderTerm",
                      value: item.message,
                    })
                  );
                  setActiveTab("All");
                  dispatch(
                    setGlobalSearch({
                      field: "searchHeaderTerm",
                      value: item.message,
                    })
                  );
                  let searchParam =
                    "?keyword=" +
                    (item.message || "") +
                    "&startDate=" +
                    (persistData?.globalSearch?.startHeaderDate || "") +
                    "&endDate=" +
                    (persistData?.globalSearch?.endHeaderDate || "");
                  history.push({
                    pathname: "/",
                    search: searchParam,
                  });
                }}
              >
                {item.message}
              </h5>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TredingNow;

/* eslint-disable no-restricted-globals */
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "./DatePickerCustomHeaderTwoMonth";
import DatePickerCustomHeaderOneMonth from "./DatePickerCustomHeaderOneMonth";
import DatePickerCustomDay from "./DatePickerCustomDay";
import useRootClose from "react-overlays/esm/useRootClose";
import { Tooltip } from "react-bootstrap";
import { setGlobalSearch } from "../redux/action/persistDataAction";
import moment from "moment";
import AuthStorage from "../helper/AuthStorage";
import { useHistory } from "react-router-dom";
import { ApiGet, ApiPost } from "../helper/API/ApiData";
// import { DateRange } from "react-date-range";
// import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css"; // theme css file
// import { addDays } from "date-fns";

import { pubTrendingNowUpdateListState } from "../redux/action/trendingNowAction";

const SearchBar = ({ isMob, setActiveIndex, setActiveTab }) => {
  let dispatch = useDispatch();

  const formData = {
    country: "",
    city: "",
    startDate: "",
    endDate: "",
  };

  // const [range, setRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: "selection",
  //   },
  // ]);

  const { t } = useTranslation();
  const [state, setState] = useState(formData);
  const persistData = useSelector((state) => state.persistData);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tooltip, setTooltip] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [countryOpen, setCountryOpen] = useState(false);
  const [countryIdx, setCountrIdx] = useState("");
  const [cityIdx, setCityIdx] = useState("");
  const [cityOpen, setCityOpen] = useState(false);
  const [chooseEnd, setChooseEnd] = useState(false);
  const history = useHistory();
  const target1 = useRef();
  const target2 = useRef();
  const target3 = useRef();

  const inputSearch = useRef(null);

  // API로 가져온 국가, 지역 정보 저장
  const [country, setCountry] = useState([]);
  const [region, setRegion] = useState([]);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    dispatch(
      setGlobalSearch({
        field: "startHeaderDate",
        value: start ? moment(start).format("Y-MM-DD") : null,
      })
    );
    setEndDate(end);
    dispatch(
      setGlobalSearch({
        field: "endHeaderDate",
        value: end ? moment(end).format("Y-MM-DD") : null,
      })
    );

    if (start && end) {
      setCalendarOpen(false);
    }
  };

  const [resize, setResize] = useState(window.innerWidth);

  const handleResize = () => {
    setResize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const onChangeDate2 = (dates) => {
  //   const [end] = dates;

  //   setEndDate(end);
  //   dispatch(
  //     setGlobalSearch({
  //       field: "endHeaderDate",
  //       value: end ? moment(end).format("Y/MM/DD") : null,
  //     })
  //   );

  //   setCalendarOpen(false);
  //   setChooseEnd(false);
  // };

  const handleRootClose1 = () => setCountryOpen(false);
  const handleRootClose2 = () => setCityOpen(false);
  const handleRootClose3 = () => setCalendarOpen(false);
  const handleRootClose4 = () => setCalendarOpen(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });

    if (e.target.name === "country") {
      setCountryOpen(false);
      getRegion(e.target.value);
      setCityOpen(true);

      // trending now update list
      const countryCode = e.target.dataset.countryCode;
      dispatch(pubTrendingNowUpdateListState(countryCode));
    }

    if (e.target.name === "city") {
      setCityOpen(false);
    }
  };

  // location rootclose setting
  useRootClose(target1, handleRootClose1, {
    disabled: !countryOpen,
  });

  // datepicker rootclose setting
  useRootClose(target2, handleRootClose2, {
    disabled: !cityOpen,
  });

  // datepicker rootclose setting
  useRootClose(target3, handleRootClose3, {
    disabled: !calendarOpen,
  });

  const OpenCalendar = (e) => {
    setCalendarOpen(true);

    const name = e.target.name;

    if (name === "startDate") {
      setChooseEnd(false);
    } else {
      setChooseEnd(true);
    }
  };

  // 임시 데이터 설정
  const CountryData = [
    { country: "대한민국", city: ["서울", "인천", "부산", "제주"] },
    { country: "일본", city: ["오사카", "도쿄", "훗카이도"] },
    { country: "중국", city: ["상하이", "운남성", "베이징"] },
  ];

  //Homepage search filter
  const Search = () => {
    let searchParam =
      "?keyword=" +
      (persistData?.globalSearch?.searchHeaderTerm || "") +
      "&startDate=" +
      (persistData?.globalSearch?.startHeaderDate || "") +
      "&endDate=" +
      (persistData?.globalSearch?.endHeaderDate || "");
    history.push({
      pathname: "/",
      search: searchParam,
    });
    setActiveIndex(0);
    setActiveTab("All");
  };

  const getCountry = () => {
    ApiGet("tc/country").then((res) => {
      setCountry([
        ...res.data.map((x) => {
          return {
            country: AuthStorage.getLang() === "en" ? x.country : x.country_ko,
            country_code: x.country_code,
          };
        }),
      ]);
    });
  };

  const getRegion = (region) => {
    ApiGet("tc/region?name=" + region).then((res) => {
      setRegion([
        {
          value: "All",
          region: "All",
        },
        ...res.data.map((x) => {
          return {
            region: AuthStorage.getLang() === "en" ? x.region : x.region_ko,
          };
        }),
      ]);
    });
  };

  useEffect(() => {
    getCountry();
  }, [AuthStorage.getLang()]);

  // console.log("검색바 확인: " + JSON.stringify(persistData));

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      if (inputSearch.current.value !== "") {
        Search();
      }
    }
  };

  return (
    <div
      id="searchBar"
      className={
        "w-100 position-relative search-bar searchBar d-flex align-items-center h-100"
      }
    >
      <div
        action="sbmit"
        className="search-bar-inner w-100 d-flex justify-content-between"
      >
        {/* location */}
        <div
          className="find-city-location d-flex justify-content-between align-items-center"
          onMouseEnter={() => setTooltip(true)}
          onMouseLeave={() => setTooltip(false)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.97993 1C11.6388 1 13.0569 1.57864 14.2341 2.73592C15.4114 3.89319 16 5.29656 16 6.94601C16 7.77074 15.7926 8.72183 15.3779 9.7993C14.9632 10.8768 14.4615 11.8744 13.8729 12.7923C13.2843 13.7101 12.6957 14.5747 12.107 15.3862C11.5184 16.1976 11.0234 16.8427 10.6221 17.3216L9.97993 18C9.8194 17.8404 9.60535 17.6009 9.33779 17.2817C9.07023 16.9624 8.59532 16.3439 7.91304 15.4261C7.23077 14.5082 6.62207 13.617 6.08696 12.7523C5.55184 11.8877 5.07023 10.91 4.64214 9.81925C4.21405 8.72848 4 7.77074 4 6.94601C4 5.29656 4.58194 3.89319 5.74582 2.73592C6.9097 1.57864 8.32107 1 9.97993 1ZM9.97993 9.06103C10.5686 9.06103 11.0769 8.85485 11.505 8.44249C11.9331 8.03013 12.1472 7.5313 12.1472 6.94601C12.1472 6.36072 11.9331 5.86189 11.505 5.44953C11.0769 5.03717 10.5686 4.83099 9.97993 4.83099C9.3913 4.83099 8.88963 5.03717 8.47492 5.44953C8.0602 5.86189 7.85284 6.36072 7.85284 6.94601C7.85284 7.5313 8.0602 8.03013 8.47492 8.44249C8.88963 8.85485 9.3913 9.06103 9.97993 9.06103Z"
              fill="#42B6E6"
            />
          </svg>

          <input
            type="text"
            name="Location"
            ref={inputSearch}
            placeholder={t("Homepage.Hero.Search_Placeholder")}
            autoComplete="off"
            value={
              persistData?.globalSearch?.searchHeaderTerm
                ? persistData?.globalSearch?.searchHeaderTerm
                : ""
            }
            onChange={(e) => {
              dispatch(
                setGlobalSearch({
                  field: "searchHeaderTerm",
                  value: e.target.value,
                })
              );
            }}
            onKeyDown={handleOnKeyPress}
          />
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setCountryOpen(true)}
          >
            <path
              d="M15 7L10 13L5 7"
              stroke="#42B6E6"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          {/* <img src="./img/res-downarrow-color.svg" alt="" /> */}
          {CountryData.lenght === 1 && tooltip && (
            <div className="tooltiptext">
              <img src="./img/ic_flag_kr.svg" alt="" />{" "}
              {t("SouthKorea.South_Korea")}
            </div>
          )}
        </div>
        {/* calendar & search button */}
        <div className="search-calendar d-flex align-items-center">
          <svg
            onClick={(e) => {
              OpenCalendar(e);
            }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              className="noFill"
              x="2.75"
              y="4.75"
              width="14.5"
              height="12.5"
              rx="2.25"
              fill="white"
              stroke="#42B6E6"
              stroke-width="1.5"
            />
            <path
              d="M5.69922 2.75C5.69922 2.33579 6.03501 2 6.44922 2C6.86343 2 7.19922 2.33579 7.19922 2.75V4.5H5.69922V2.75Z"
              fill="#42B6E6"
            />
            <path
              d="M5.5 11.25C5.5 10.8358 5.83579 10.5 6.25 10.5H6.75C7.16421 10.5 7.5 10.8358 7.5 11.25C7.5 11.6642 7.16421 12 6.75 12H6.25C5.83579 12 5.5 11.6642 5.5 11.25Z"
              fill="#42B6E6"
            />
            <path
              d="M9 11.25C9 10.8358 9.33579 10.5 9.75 10.5H10.25C10.6642 10.5 11 10.8358 11 11.25C11 11.6642 10.6642 12 10.25 12H9.75C9.33579 12 9 11.6642 9 11.25Z"
              fill="#42B6E6"
            />
            <path
              d="M12.5 11.25C12.5 10.8358 12.8358 10.5 13.25 10.5H13.75C14.1642 10.5 14.5 10.8358 14.5 11.25C14.5 11.6642 14.1642 12 13.75 12H13.25C12.8358 12 12.5 11.6642 12.5 11.25Z"
              fill="#42B6E6"
            />
            <path
              d="M5.5 14C5.5 13.5858 5.83579 13.25 6.25 13.25H6.75C7.16421 13.25 7.5 13.5858 7.5 14C7.5 14.4142 7.16421 14.75 6.75 14.75H6.25C5.83579 14.75 5.5 14.4142 5.5 14Z"
              fill="#42B6E6"
            />
            <path
              d="M9 14C9 13.5858 9.33579 13.25 9.75 13.25H10.25C10.6642 13.25 11 13.5858 11 14C11 14.4142 10.6642 14.75 10.25 14.75H9.75C9.33579 14.75 9 14.4142 9 14Z"
              fill="#42B6E6"
            />
            <path
              d="M12.5 14C12.5 13.5858 12.8358 13.25 13.25 13.25H13.75C14.1642 13.25 14.5 13.5858 14.5 14C14.5 14.4142 14.1642 14.75 13.75 14.75H13.25C12.8358 14.75 12.5 14.4142 12.5 14Z"
              fill="#42B6E6"
            />
            <path
              d="M12.5996 2.75C12.5996 2.33579 12.9354 2 13.3496 2C13.7638 2 14.0996 2.33579 14.0996 2.75V4.5H12.5996V2.75Z"
              fill="#42B6E6"
            />
            <rect
              x="18"
              y="7.5"
              width="1.5"
              height="16"
              rx="0.75"
              transform="rotate(90 18 7.5)"
              fill="#42B6E6"
            />
          </svg>
          <div className="dateArea d-flex">
            <input
              className="schedule"
              type="text"
              name="Date"
              readOnly
              autoComplete="off"
              value={
                AuthStorage.getLang() === "en"
                  ? persistData?.globalSearch?.startHeaderDate &&
                    persistData?.globalSearch?.endHeaderDate &&
                    moment(persistData?.globalSearch?.startHeaderDate).format(
                      "MMM DD"
                    ) +
                      " - " +
                      moment(persistData?.globalSearch?.endHeaderDate).format(
                        "MMM DD"
                      )
                  : persistData?.globalSearch?.startHeaderDate &&
                    persistData?.globalSearch?.endHeaderDate &&
                    moment(persistData?.globalSearch?.startHeaderDate).format(
                      "MM/DD"
                    ) +
                      " - " +
                      moment(persistData?.globalSearch?.endHeaderDate).format(
                        "MM/DD"
                      )
              }
              locale={AuthStorage.getLang()}
              placeholder={
                isMob
                  ? t("Homepage.Hero.FromMob") +
                    " - " +
                    t("Homepage.Hero.UntilMob")
                  : t("Homepage.Hero.From") + " - " + t("Homepage.Hero.Until")
              }
              onClick={(e) => {
                OpenCalendar(e);
              }}
            />
          </div>
        </div>
        {/* search button */}
        <div className="d-flex align-items-center">
          <button
            className="searchIcon searchBtn d-flex align-items-center justify-content-center"
            onClick={(e) => Search()}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 17L12.0962 12.0962M12.0962 12.0962C13.2725 10.9199 14 9.29493 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14C9.29493 14 10.9199 13.2725 12.0962 12.0962Z"
                stroke="white"
                stroke-width="1.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <span> {t("Homepage.Hero.Find_Tours")} </span>
          </button>
        </div>
      </div>
      {countryOpen && (
        <div className="searchContainer location" ref={target1}>
          <div className="searchFlex">
            <h5 className="searc-bar-title">
              {t("Search_Bar.Select_country")}
            </h5>
          </div>
          <div className="country_list d-flex">
            {country.map((data, i) => (
              <div className="country_list_detail" key={i}>
                <input
                  className="hide"
                  type="radio"
                  name="country"
                  key={i}
                  id={"country" + i}
                  value={data.country}
                  onChange={(e) => {
                    handleChange(e);
                    setCountrIdx(i);
                    setCityIdx("");
                  }}
                  data-country-code={data.country_code}
                />
                <label htmlFor={"country" + i} value={data.country}>
                  {data.country}
                </label>
              </div>
            ))}
          </div>

          {/* {CountryData.map((data, i) => (
              <div className="country_list_detail">
                <input
                  className="hide"
                  type="radio"
                  name="country"
                  id={i}
                  value={data.country}
                />
                <label htmlFor={i}>{data.country}</label>
              </div>
            ))} */}
        </div>
      )}
      {cityOpen && (
        <div className="searchContainer location" ref={target2}>
          <div className="searchFlex">
            <h5 className="searc-bar-title">{t("Search_Bar.Select_city")}</h5>
            <button
              className="backBtn d-flex align-items-center desc-bold"
              onClick={() => {
                setCountryOpen(true);
                setCityOpen(false);
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="noFill"
                  d="M13 15L7 10L13 5"
                  stroke="#1B1C1E"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {t("Search_Bar.BackBtn")}
            </button>
          </div>
          <div className="country_list d-flex flex-wrap">
            {region.map((data2, i2) => (
              <div className="country_list_detail" key={i2}>
                <input
                  className="hide"
                  type="radio"
                  name="city"
                  id={"city" + i2}
                  value={data2.region}
                  onChange={(e) => {
                    handleChange(e);
                    setCityIdx(i2);
                    dispatch(
                      setGlobalSearch({
                        field: "searchHeaderTerm",
                        value: e.target.value + ", " + state.country,
                      })
                    );
                    inputSearch.current.focus();
                    Search();
                  }}
                />
                <label htmlFor={"city" + i2}>{data2.region}</label>
              </div>
            ))}
          </div>
        </div>
      )}
      {calendarOpen &&
        (resize > 768 ? (
          <div className={"searchContainer calendar pc-calendar"} ref={target3}>
            {/* <DateRange
              className="custom-calendar"
              onChange={(item) => setRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={range}
              direction="horizontal"
            /> */}
            <DatePicker
              calendarClassName={"custom-container"}
              monthClassName="custom-month"
              selected={startDate}
              // onChange={chooseEnd ? onChangeDate : onChangeDate2}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              monthsShown={2}
              showPopperArrow={false}
              inline
              minDate={new Date()}
              locale={AuthStorage.getLang()}
              formatWeekDay={
                AuthStorage.getLang() === "en"
                  ? (nameOfDay) => nameOfDay.substr(0, 3)
                  : (nameOfDay) => nameOfDay.substr(0, 1)
              }
              renderCustomHeader={(p) => (
                <DatePickerCustomHeaderTwoMonth {...p} />
              )}
              renderDayContents={(day, date) => (
                <DatePickerCustomDay dayOfMonth={day} date={date} />
              )}
            />
          </div>
        ) : (
          <div
            className={"searchContainer calendar mobile-calendar"}
            ref={target3}
          >
            <DatePicker
              calendarClassName={"custom-container"}
              monthClassName="custom-month"
              selected={startDate}
              // onChange={chooseEnd ? onChangeDate : onChangeDate2}
              onChange={onChangeDate}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              monthsShown={1}
              showPopperArrow={false}
              inline
              minDate={new Date()}
              locale={AuthStorage.getLang() === "en" ? "en-US" : "ko"}
              formatWeekDay={
                AuthStorage.getLang() === "en"
                  ? (nameOfDay) => nameOfDay.substr(0, 3)
                  : (nameOfDay) => nameOfDay.substr(0, 1)
              }
              renderCustomHeader={(p) => (
                <DatePickerCustomHeaderOneMonth {...p} />
              )}
              renderDayContents={(day, date) => (
                <DatePickerCustomDay dayOfMonth={day} date={date} />
              )}
            />
          </div>
        ))}
    </div>
  );
};

export default SearchBar;

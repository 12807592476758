import { db } from "../../firebaseConfig";
import AuthStorage from "../../helper/AuthStorage";
import {
  CHAT_DATA,
  CHAT_ID,
  CHAT_STATE,
  MESSAGE_STATE,
  READ_ONLY_CHAT,
  SET_OTHER_USER,
} from "../type";

export const getChatData = (id) => (dispatch) => {
  let unsubscribe = db
    .collection(AuthStorage.getMessageCollection())
    .where("ids", "array-contains", id)
    .onSnapshot((snapshot) => {
      if (snapshot.docs) {
        let data = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            data: doc.data(),
            readOnly: doc.data().readOnly !== undefined,
          }))
          .sort((a, b) => {
            if (a.data.timestamp < b.data.timestamp) {
              return 1;
            } else if (a.data.timestamp > b.data.timestamp) {
              return -1;
            }
            return 0;
          });

        let count;
        count = data
          .map((x) => {
            const key = x.data.ids.find((y) => y !== id);
            return x.data[`${key}_count`];
          })
          .reduce((ans, val) => ans + val, 0);

        dispatch({
          type: CHAT_DATA,
          payload: { data, count },
        });
      } else {
        dispatch({
          type: CHAT_DATA,
          payload: [],
        });
      }
    });
  return unsubscribe;
};

export const setMessageState = (value) => (dispatch) => {
  dispatch({
    type: MESSAGE_STATE,
    payload: value,
  });
};

export const setChatState = (value) => (dispatch) => {
  dispatch({
    type: CHAT_STATE,
    payload: value,
  });
};

export const setOtherUserData = (value) => (dispatch) => {
  dispatch({
    type: SET_OTHER_USER,
    payload: value,
  });
};

export const setChatId = (id) => (dispatch) => {
  dispatch({
    type: CHAT_ID,
    payload: id,
  });
};

export const setReadOnly = (value) => (dispatch) => {
  dispatch({
    type: READ_ONLY_CHAT,
    payload: value,
  });
};

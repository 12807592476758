// import React from "react";
// import Footer from "./footer/Footer";
// import Header from "./header/Header";

const Layout = ({ children, ...props }) => {
  // useEffect(() => {
  //     window.addEventListener('unload', unAuthoriseUser);
  //     return () => {
  //         window.removeEventListener('unload', unAuthoriseUser)
  //     }
  // }, [])

  // const unAuthoriseUser = ()=>{
  //     AuthStorage.deauthenticateUser();
  // }

  return <div className="layout-section">{children}</div>;
};

export default Layout;
